import React, { useEffect } from "react"

import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import * as z from "zod"

import { IconNext } from "@/components/_icons"
import { LoadingButton } from "@/components/_uiext"
import useApplicationDraftStore from "@/stores/useApplicationDraftStore"

import { Button } from "@repo/ui/components/ui/button"
import { Form, FormField, FormItem, FormMessage } from "@repo/ui/components/ui/form"
import { APPLICATION_STEPS, FUNDING_SOLUTIONS, LOAN_TYPES } from "@repo/util/constant"
import { CLIENT_MSG } from "@repo/i18n"

import FundingSolutionItem from "../_components/FundingSolutionItem"
import { LOADING_TIMEOUT } from "../util"
import { useAppContext } from "../AppContext"

const FormSchema = z.object({
  funding_solution: z.string({
    invalid_type_error: CLIENT_MSG.CHOOSE_ONE_ITEM,
    required_error: CLIENT_MSG.CHOOSE_ONE_ITEM
  })
})

type FormSchemaType = z.infer<typeof FormSchema>

export default function FundingSolution() {
  const { steps, setSteps, activeStep, setActiveStep, setLoading, data, setData } = useAppContext()
  const navigate = useNavigate()
  const store = useApplicationDraftStore()

  const form = useForm<FormSchemaType>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      funding_solution: undefined
    }
  })

  const handleBackClick = () => {
    setLoading(true)

    store.updateAction(
      data.uuid,
      {
        params: {
          ...form.getValues(),
          current_step: steps[activeStep - 1]
        }
      },
      (newData) => {
        setData(newData)
        setActiveStep((prev: number) => prev - 1)

        setTimeout(() => {
          setLoading(false)
        }, LOADING_TIMEOUT)
      }
    )
  }

  const handleSaveClick = () => {
    setLoading(true)

    store.updateAction(
      data.uuid,
      {
        params: {
          ...form.getValues(),
          current_step: steps[activeStep]
        }
      },
      (newData) => {
        setData(newData)

        setTimeout(() => {
          setLoading(false)
          navigate("/applications/list")
        }, LOADING_TIMEOUT)
      }
    )
  }

  const onSubmit = (values: FormSchemaType) => {
    store.updateAction(
      data.uuid,
      {
        params: {
          ...values,
          current_step: steps[activeStep + 1]
        }
      },
      (newData) => {
        setData(newData)

        if (
          newData?.funding_solution === FUNDING_SOLUTIONS.CashflowFinance.key ||
          newData?.funding_solution === FUNDING_SOLUTIONS.CorporateFinance.key
        ) {
          const newSteps = Object.entries(APPLICATION_STEPS).map(([k, v]) => v)
          setSteps(newSteps.filter((s) => s !== APPLICATION_STEPS.SecurityDetails))
        } else {
          const newSteps = Object.entries(APPLICATION_STEPS).map(([k, v]) => v)
          if (newSteps.length > steps.length) {
            setSteps(newSteps)
          }
        }

        setActiveStep((prev: number) => prev + 1)
      }
    )
  }

  useEffect(() => {
    form.reset({
      funding_solution: data?.funding_solution
    })
  }, [data, form])

  return (
    <Form {...form}>
      <form className="flex flex-col" onSubmit={form.handleSubmit(onSubmit)}>
        <div className="flex w-full rounded-xl border border-[#ECECEC] bg-white p-8">
          <div className="flex w-full flex-1 flex-col pr-0 xl:pr-[352px]">
            <h3 className="text-main mb-2 text-lg font-semibold">Funding Solution</h3>

            <p className="text-default mb-4 text-sm font-normal">
              Please select the applicable funding solution.
            </p>

            <div className="w-full">
              <FormField
                control={form.control}
                name="funding_solution"
                render={({ field }) => (
                  <FormItem>
                    <div className="grid grid-cols-2 gap-4 md:grid-cols-3 lg:grid-cols-4">
                      {Object.entries(FUNDING_SOLUTIONS)
                        .sort()
                        .map(([k, v]) => (
                          <FundingSolutionItem
                            key={k}
                            disabled={
                              !Object.values(LOAN_TYPES).filter((lt) =>
                                lt.funding_solutions.includes(v.key)
                              ).length
                            }
                            isSelected={v.key === field.value}
                            value={v.key}
                            description={v.description}
                            onClick={() => {
                              field.onChange(v.key)
                              form.handleSubmit(onSubmit)()
                            }}
                          />
                        ))}
                    </div>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          </div>
        </div>

        <div className="flex w-full justify-between gap-2 pb-2 pt-6">
          <Button
            type="button"
            className="text-default h-auto bg-transparent px-0 py-4 font-semibold hover:bg-transparent"
            onClick={handleSaveClick}
          >
            Save and exit
          </Button>

          <div className="flex justify-end gap-2">
            <Button
              type="button"
              className="text-default h-auto border border-[#868194] bg-transparent px-6 py-4 font-semibold hover:bg-transparent"
              onClick={handleBackClick}
            >
              Back
            </Button>

            <LoadingButton
              loading={store.loading}
              type="submit"
              className="flex items-center gap-4 px-6 py-4 md:!w-auto"
            >
              Next
              <IconNext className="text-sm" />
            </LoadingButton>
          </div>
        </div>
      </form>
    </Form>
  )
}
