import React from "react"

interface Props {
  className?: string
}

export default function IconAddLender({ className }: Readonly<Props>) {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.75 8.12461H1.875C1.73901 8.12448 1.60678 8.07999 1.49836 7.9979C1.38994 7.91582 1.31126 7.80061 1.27424 7.66976C1.23722 7.53891 1.24388 7.39955 1.29323 7.27283C1.34257 7.14611 1.4319 7.03894 1.54766 6.96758L9.67266 1.96758C9.77111 1.90705 9.88442 1.875 10 1.875C10.1156 1.875 10.2289 1.90705 10.3273 1.96758L14.1016 4.29018C13.6783 4.47488 13.2986 4.74088 12.9822 5.06844L10 3.23321L4.08281 6.87461H12.0556C12.0191 7.07742 12 7.2863 12 7.49963C12 8.15947 12.1826 8.77666 12.5 9.30343V13.1246H15V10.9642C15.1633 10.9875 15.3302 10.9996 15.5 10.9996C15.7574 10.9996 16.0084 10.9718 16.25 10.9191V13.1246H17.5C17.6658 13.1246 17.8247 13.1905 17.9419 13.3077C18.0592 13.4249 18.125 13.5839 18.125 13.7496C18.125 13.9154 18.0592 14.0743 17.9419 14.1916C17.8247 14.3088 17.6658 14.3746 17.5 14.3746H2.5C2.33424 14.3746 2.17527 14.3088 2.05806 14.1916C1.94085 14.0743 1.875 13.9154 1.875 13.7496C1.875 13.5839 1.94085 13.4249 2.05806 13.3077C2.17527 13.1905 2.33424 13.1246 2.5 13.1246H3.75V8.12461ZM7.5 8.12461H5V13.1246H7.5V8.12461ZM11.25 13.1246V8.12461H8.75V13.1246H11.25ZM19.1919 16.6916C19.3092 16.5743 19.375 16.4154 19.375 16.2496C19.375 16.0839 19.3092 15.9249 19.1919 15.8077C19.0747 15.6905 18.9158 15.6246 18.75 15.6246H1.25C1.08424 15.6246 0.925268 15.6905 0.808058 15.8077C0.690848 15.9249 0.625 16.0839 0.625 16.2496C0.625 16.4154 0.690848 16.5743 0.808058 16.6916C0.925268 16.8088 1.08424 16.8746 1.25 16.8746H18.75C18.9158 16.8746 19.0747 16.8088 19.1919 16.6916Z"
        fill="currentColor"
      />
      <path
        d="M12.875 7.5C12.875 7.32595 12.9441 7.15903 13.0672 7.03596C13.1903 6.91289 13.3572 6.84375 13.5312 6.84375H14.8438V5.53125C14.8438 5.3572 14.9129 5.19028 15.036 5.06721C15.159 4.94414 15.326 4.875 15.5 4.875C15.6741 4.875 15.841 4.94414 15.964 5.06721C16.0871 5.19028 16.1563 5.3572 16.1563 5.53125V6.84375H17.4688C17.6428 6.84375 17.8097 6.91289 17.9328 7.03596C18.0559 7.15903 18.125 7.32595 18.125 7.5C18.125 7.67405 18.0559 7.84097 17.9328 7.96404C17.8097 8.08711 17.6428 8.15625 17.4688 8.15625H16.1563V9.46875C16.1563 9.6428 16.0871 9.80972 15.964 9.93279C15.841 10.0559 15.6741 10.125 15.5 10.125C15.326 10.125 15.159 10.0559 15.036 9.93279C14.9129 9.80972 14.8438 9.6428 14.8438 9.46875V8.15625H13.5312C13.3572 8.15625 13.1903 8.08711 13.0672 7.96404C12.9441 7.84097 12.875 7.67405 12.875 7.5Z"
        fill="currentColor"
      />
    </svg>
  )
}
