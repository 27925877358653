import React from "react"

interface Props {
  className?: string
}

export default function IconDrawerOpen({ className }: Readonly<Props>) {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 22 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1 1H21" stroke="currentColor" strokeWidth={2} strokeLinecap="round" />
      <path d="M7 7L21 7" stroke="currentColor" strokeWidth={2} strokeLinecap="round" />
      <path d="M4 13L21 13" stroke="currentColor" strokeWidth={2} strokeLinecap="round" />
    </svg>
  )
}
