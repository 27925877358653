import React, { Dispatch, SetStateAction, useState } from "react"

import clsx from "clsx"
import { NumericFormat } from "react-number-format"

import { IconFilter } from "@/components/_icons"

import { Button } from "@repo/ui/components/ui/button"
import { Input } from "@repo/ui/components/ui/input"
import { Popover, PopoverContent, PopoverTrigger } from "@repo/ui/components/ui/popover"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "@repo/ui/components/ui/select"
import { LOAN_TERMS, REPAYMENTS, ALL } from "@repo/util/constant"

interface Props {
  params: any
  setParams: Dispatch<SetStateAction<any>>
}

export default function LenderFilterPopover({ params, setParams }: Readonly<Props>) {
  const [open, setOpen] = useState(false)

  const [interestRate, setInterestRate] = useState(params.interest_rate)
  const [loanValueRatio, setLoanValueRatio] = useState(params.loan_value_ratio)
  const [minimumLoanSize, setMinimumLoanSize] = useState(params.minimum_loan_size)
  const [maximumLoanSize, setMaximumLoanSize] = useState(params.maximum_loan_size)
  const [minimumLoanTerm, setMinimumLoanTerm] = useState(params.minimum_loan_term)
  const [maximumLoanTerm, setMaximumLoanTerm] = useState(params.maximum_loan_term)
  const [paymentOption, setPaymentOption] = useState(params.payment_option)

  const handleResetClick = () => {
    setInterestRate("")
    setLoanValueRatio("")
    setMinimumLoanSize("")
    setMaximumLoanSize("")
    setMinimumLoanTerm("")
    setMaximumLoanTerm("")
    setPaymentOption("")

    setParams({
      interest_rate: "",
      loan_value_ratio: "",
      minimum_loan_size: "",
      maximum_loan_size: "",
      minimum_loan_term: "",
      maximum_loan_term: "",
      payment_option: ""
    })
    setOpen(false)
  }

  const handleApplyClick = () => {
    setParams({
      interest_rate: interestRate,
      loan_value_ratio: loanValueRatio,
      minimum_loan_size: minimumLoanSize,
      maximum_loan_size: maximumLoanSize,
      minimum_loan_term: minimumLoanTerm,
      maximum_loan_term: maximumLoanTerm,
      payment_option: paymentOption
    })
    setOpen(false)
  }

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger
        className={clsx(
          "text-default flex h-auto w-full items-center justify-center gap-1.5 rounded-xl border bg-transparent px-4 py-3 text-sm font-semibold hover:bg-transparent md:w-auto",
          open ? "border-primary" : "border-[#868194]"
        )}
      >
        <IconFilter className="text-xl" />
        Filter
      </PopoverTrigger>

      <PopoverContent
        align="end"
        className="flex w-[calc(100vw-32px)] translate-x-4 flex-col gap-4 rounded-xl p-4 md:w-[400px] md:translate-x-0"
      >
        <p className="text-main text-sm">Filter</p>

        <NumericFormat
          customInput={Input}
          thousandSeparator
          prefix="%"
          decimalScale={2}
          fixedDecimalScale
          placeholder="% Interest rate"
          value={interestRate ?? ""}
          onValueChange={(values, sourceInfo) => {
            setInterestRate(values.value)
          }}
        />
        <NumericFormat
          customInput={Input}
          thousandSeparator
          prefix="%"
          decimalScale={2}
          fixedDecimalScale
          placeholder="% Maximum LVR"
          value={loanValueRatio ?? ""}
          onValueChange={(values, sourceInfo) => {
            setLoanValueRatio(values.value)
          }}
        />

        <div className="flex items-center gap-2">
          <NumericFormat
            customInput={Input}
            thousandSeparator
            prefix="$"
            decimalScale={2}
            fixedDecimalScale
            placeholder="$ Min loan size"
            value={minimumLoanSize ?? ""}
            onValueChange={(values, sourceInfo) => {
              setMinimumLoanSize(values.value)
            }}
          />
          <span className="text-default text-sm">-</span>
          <NumericFormat
            customInput={Input}
            thousandSeparator
            prefix="$"
            decimalScale={2}
            fixedDecimalScale
            placeholder="$ Max loan size"
            value={maximumLoanSize ?? ""}
            onValueChange={(values, sourceInfo) => {
              setMaximumLoanSize(values.value)
            }}
          />
        </div>

        <div className="flex items-center gap-2">
          <Select onValueChange={(v) => setMinimumLoanTerm(v)} value={minimumLoanTerm}>
            <SelectTrigger
              className={clsx(
                "h-12 w-full text-sm capitalize",
                minimumLoanTerm ? "text-main" : "text-default"
              )}
            >
              <SelectValue placeholder="Min loan term" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem className="capitalize" value={ALL}>
                {ALL}
              </SelectItem>
              {Object.entries(LOAN_TERMS).map(([k, v]) => (
                <SelectItem key={k} value={v.key.toString()}>
                  {v.text}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
          <span className="text-default text-sm">-</span>
          <Select onValueChange={(v) => setMaximumLoanTerm(v)} value={maximumLoanTerm}>
            <SelectTrigger
              className={clsx(
                "h-12 w-full text-sm capitalize",
                maximumLoanTerm ? "text-main" : "text-default"
              )}
            >
              <SelectValue placeholder="Max loan term" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem className="capitalize" value={ALL}>
                {ALL}
              </SelectItem>
              {Object.entries(LOAN_TERMS).map(([k, v]) => (
                <SelectItem key={k} value={v.key.toString()}>
                  {v.text}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        <Select onValueChange={(v) => setPaymentOption(v)} value={paymentOption}>
          <SelectTrigger
            className={clsx(
              "h-12 w-full text-sm capitalize",
              paymentOption ? "text-main" : "text-default"
            )}
          >
            <SelectValue placeholder="Payment Options" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem className="capitalize" value={ALL}>
              {ALL}
            </SelectItem>
            {Object.entries(REPAYMENTS).map(([k, v]) => (
              <SelectItem key={k} value={v}>
                {v}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>

        <div className="flex justify-end gap-2.5">
          <Button
            className="text-default h-auto border border-[#868194] bg-transparent px-6 py-4 text-sm font-semibold hover:bg-transparent"
            onClick={handleResetClick}
          >
            Reset
          </Button>
          <Button
            className="border-primary h-auto border px-6 py-4 text-sm font-semibold"
            onClick={handleApplyClick}
          >
            Apply filter
          </Button>
        </div>
      </PopoverContent>
    </Popover>
  )
}
