import React, { Dispatch, SetStateAction, useState } from "react"

import clsx from "clsx"

import { IconFilter } from "@/components/_icons"

import { Button } from "@repo/ui/components/ui/button"
import { Input } from "@repo/ui/components/ui/input"
import { Popover, PopoverContent, PopoverTrigger } from "@repo/ui/components/ui/popover"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "@repo/ui/components/ui/select"
import { ALL, USER_ROLES } from "@repo/util/constant"
import { ITableParams } from "@/types/interface"

interface Props {
  tableParams: ITableParams
  setTableParams: Dispatch<SetStateAction<ITableParams>>
}

export default function FilterPopover({ tableParams, setTableParams }: Readonly<Props>) {
  const [open, setOpen] = useState(false)
  const [fullName, setFullName] = useState(tableParams.filter.full_name)
  const [entityName, setEntityName] = useState(tableParams.filter.entity_name)
  const [role, setRole] = useState(tableParams.filter.role)
  const [email, setEmail] = useState(tableParams.filter.email)
  const [mobileNumber, setMobileNumber] = useState(tableParams.filter.mobile_number)

  const handleResetClick = () => {
    setFullName("")
    setEntityName("")
    setRole(ALL)
    setEmail("")
    setMobileNumber("")

    setTableParams((prev: ITableParams) => ({
      ...prev,
      filter: {
        ...prev.filter,
        full_name: "",
        entity_name: "",
        role: ALL,
        email: "",
        mobile_number: ""
      },
      pagination: {
        ...prev.pagination,
        page_index: 1
      }
    }))
    setOpen(false)
  }

  const handleApplyClick = () => {
    setTableParams((prev: ITableParams) => ({
      ...prev,
      filter: {
        ...prev.filter,
        full_name: fullName,
        entity_name: entityName,
        role,
        email,
        mobile_number: mobileNumber
      },
      pagination: {
        ...prev.pagination,
        page_index: 1
      }
    }))
    setOpen(false)
  }

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger
        className={clsx(
          "text-default flex h-auto w-full items-center justify-center gap-1.5 rounded-xl border bg-transparent px-4 py-3 text-sm font-semibold hover:bg-transparent md:w-auto",
          open ? "border-primary" : "border-[#868194]"
        )}
      >
        <IconFilter className="text-xl" />
        Filter
      </PopoverTrigger>

      <PopoverContent
        align="end"
        className="flex w-[calc(100vw-32px)] translate-x-4 flex-col gap-4 rounded-xl p-4 md:w-[400px] md:translate-x-0"
      >
        <p className="text-main text-sm">Filter</p>

        <Input
          autoFocus
          placeholder="Full name"
          value={fullName}
          onChange={(e) => setFullName(e.target.value)}
        />
        <Input
          placeholder="Entity name"
          value={entityName}
          onChange={(e) => setEntityName(e.target.value)}
        />
        <Select
          value={role}
          onValueChange={(value: string) => {
            setRole(value)
          }}
        >
          <SelectTrigger className="px-4 py-1 text-sm capitalize">
            <SelectValue placeholder={role} />
          </SelectTrigger>

          <SelectContent>
            {[
              ALL,
              USER_ROLES.Employee,
              USER_ROLES.Broker,
              USER_ROLES.Introducer,
              USER_ROLES.Enterprise,
              USER_ROLES.Administrator
            ].map((r) => (
              <SelectItem key={r} className="capitalize" value={r}>
                {r}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
        <Input
          placeholder="Email address"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Input
          placeholder="Mobile number"
          value={mobileNumber}
          onChange={(e) => setMobileNumber(e.target.value)}
        />

        <div className="flex justify-end gap-2.5">
          <Button
            className="text-default h-auto border border-[#868194] bg-transparent px-6 py-4 text-sm font-semibold hover:bg-transparent"
            onClick={handleResetClick}
          >
            Reset
          </Button>
          <Button
            className="border-primary h-auto border px-6 py-4 text-sm font-semibold"
            onClick={handleApplyClick}
          >
            Apply filter
          </Button>
        </div>
      </PopoverContent>
    </Popover>
  )
}
