import React from "react"

import { ColumnDef } from "@tanstack/react-table"
import { useNavigate } from "react-router-dom"
import {
  LuArchive,
  LuArchiveRestore,
  LuLock,
  LuUnlock,
  LuUserCheck,
  LuUserX,
  LuZap,
  LuZapOff
} from "react-icons/lu"

import { IconEdit, IconMore, IconView } from "@/components/_icons"
import useAdminUserStore from "@/stores/useAdminUserStore"

import { Avatar, AvatarFallback, AvatarImage } from "@repo/ui/components/ui/avatar"
import { Button } from "@repo/ui/components/ui/button"
import { Checkbox } from "@repo/ui/components/ui/checkbox"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from "@repo/ui/components/ui/dropdown-menu"
import { USER_ROLES } from "@repo/util/constant"

interface ActionColumnProps {
  row: any
}

function ActionColumn({ row }: Readonly<ActionColumnProps>) {
  const navigate = useNavigate()
  const store = useAdminUserStore()

  const handleViewClick = (uuid: string) => () => {
    navigate(`/admin/users/view/${uuid}`)
  }

  const handleEditClick = (uuid: string) => () => {
    navigate(`/admin/users/edit/${uuid}`)
  }

  const handleArchiveClick = (uuid: string) => () => {
    store.archiveAction(uuid)
  }

  const handleApproveClick = (userUuid: string) => () => {
    store.approveAction(userUuid)
  }

  const handleLockClick = (userUuid: string) => () => {
    store.lockAction(userUuid)
  }

  if (row.original.role === USER_ROLES.Administrator) return null

  return (
    <div className="text-center">
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" size="icon">
            <IconMore className="text-2xl" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-32" align="end">
          <DropdownMenuItem
            className="text-default flex gap-2 text-sm"
            onClick={handleViewClick(row.original.uuid)}
          >
            <IconView className="text-base" /> View
          </DropdownMenuItem>

          <DropdownMenuItem
            className="text-default flex gap-2 text-sm"
            onClick={handleEditClick(row.original.uuid)}
          >
            <IconEdit className="text-base" />
            Edit
          </DropdownMenuItem>

          <DropdownMenuItem
            className="text-default flex gap-2 text-sm"
            onClick={handleArchiveClick(row.original.uuid)}
          >
            {!row.original.is_archived ? (
              <>
                <LuArchive className="text-base" />
                Archive
              </>
            ) : (
              <>
                <LuArchiveRestore className="text-base" />
                Restore
              </>
            )}
          </DropdownMenuItem>

          <DropdownMenuItem
            className="text-default flex gap-2 text-sm"
            onClick={handleApproveClick(row.original.uuid)}
          >
            {!row.original.is_approved ? (
              <>
                <LuZap className="text-base" />
                Approve
              </>
            ) : (
              <>
                <LuZapOff className="text-base" />
                Disapprove
              </>
            )}
          </DropdownMenuItem>

          <DropdownMenuItem
            className="text-default flex gap-2 text-sm"
            onClick={handleLockClick(row.original.uuid)}
          >
            {!row.original.is_locked ? (
              <>
                <LuLock className="text-base" />
                Lock
              </>
            ) : (
              <>
                <LuUnlock className="text-base" />
                Unlock
              </>
            )}
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  )
}

const columns: ColumnDef<any>[] = [
  {
    accessorKey: "fullName",
    header: () => <>Full Name</>,
    cell: ({ row }) => (
      <div className="flex items-center justify-start gap-2.5">
        <Avatar>
          <AvatarImage
            className="h-10 w-10 rounded-full object-cover"
            src={row.original.image}
            alt={`${row.original.first_name} ${row.original.last_name}`}
          />
          <AvatarFallback className="uppercase">
            {row.original.first_name[0]}
            {row.original.last_name[0]}
          </AvatarFallback>
        </Avatar>

        <div className="text-sm">{`${row.original.first_name} ${row.original.last_name}`}</div>
      </div>
    )
  },
  {
    accessorKey: "entity_name",
    header: () => <>Entity Name</>,
    cell: ({ row }) => <div className="text-sm">{row.original.entity?.entity_name ?? ""}</div>
  },
  {
    accessorKey: "user_type",
    header: () => <>User Type</>,
    cell: ({ row }) => <div className="text-sm capitalize">{row.original.role ?? ""}</div>
  },
  {
    accessorKey: "email",
    header: () => <>Email Address</>,
    cell: ({ row }) => <div className="text-sm">{row.original.email}</div>
  },
  {
    accessorKey: "mobile_number",
    header: () => <>Mobile Number</>,
    cell: ({ row }) => <div className="text-sm">{row.original.mobile_number}</div>
  },
  {
    accessorKey: "status",
    header: () => <>Status</>,
    cell: ({ row }) => (
      <div className="flex flex-col">
        <div className="flex items-center gap-2">
          <Checkbox disabled checked={row.original.is_archived} className="rounded-none" /> Archived
        </div>

        <div className="flex items-center gap-2">
          <Checkbox disabled checked={row.original.is_approved} className="rounded-none" /> Approved
        </div>

        <div className="flex items-center gap-2">
          <Checkbox disabled checked={row.original.is_locked} className="rounded-none" /> Locked
        </div>
      </div>
    )
  },
  {
    accessorKey: "actions",
    header: () => <div className="text-center">Actions</div>,
    cell: ({ row }) => <ActionColumn row={row} />
  }
]

export default columns
