import React from "react"

import { LuBoxes } from "react-icons/lu"
import { Link } from "react-router-dom"

import {
  IconMyApplications,
  IconUserCircle,
  IconLender,
  IconHome,
  IconMyBorrowers,
  IconApplicationSettled
} from "@/components/_icons"
import { LinkExt } from "@/components/_uiext"
import useAuthStore from "@/stores/useAuthStore"

import { Button } from "@repo/ui/components/ui/button"
import { SITE_TITLE, USER_ROLES } from "@repo/util/constant"

import Logo from "../components/Logo"

export default function AdminSidebar() {
  const authStore = useAuthStore()

  return (
    <aside className="fixed bottom-0 left-0 top-0 hidden max-h-screen min-h-screen min-w-[270px] max-w-[270px] flex-col bg-white px-6 py-[30px] lg:flex">
      <div className="mb-12">
        <Logo />
      </div>

      {[USER_ROLES.Administrator].indexOf(authStore.user?.role) > -1 && (
        <div className="mb-20 flex flex-col">
          <Link className="mb-10 w-full" to="/admin/dashboard">
            <Button className="h-auto w-full rounded-xl py-4 text-sm font-semibold text-white">
              <IconHome className="mr-2 text-base" /> Back to home
            </Button>
          </Link>

          <p className="text-main mb-2.5 text-sm font-semibold">Admin</p>
          <LinkExt
            className="mb-2.5 flex items-center gap-1 py-1 text-sm"
            to="/admin/applications/list"
          >
            <IconMyApplications className="text-xl" />
            Applications
          </LinkExt>
          <LinkExt
            className="mb-2.5 flex items-center gap-1 py-1 text-sm"
            to="/admin/referrals/list"
          >
            <IconApplicationSettled className="text-xl" />
            Referrals
          </LinkExt>
          <LinkExt
            className="mb-2.5 flex items-center gap-1 py-1 text-sm"
            to="/admin/borrowers/list"
          >
            <IconMyBorrowers className="text-xl" />
            Borrowers
          </LinkExt>
          <LinkExt className="mb-2.5 flex items-center gap-1 py-1 text-sm" to="/admin/users/list">
            <IconUserCircle className="text-xl" />
            Users
          </LinkExt>
          <LinkExt className="mb-2.5 flex items-center gap-1 py-1 text-sm" to="/admin/lenders/list">
            <IconLender className="text-xl" />
            Lenders
          </LinkExt>
          <LinkExt className="flex items-center gap-1 py-1 text-sm" to="/admin/leads/list">
            <LuBoxes className="text-xl" />
            Leads
          </LinkExt>
        </div>
      )}

      <p className="text-sm">
        &copy; {new Date().getFullYear()} {SITE_TITLE}
      </p>
    </aside>
  )
}
