import React from "react"

import clsx from "clsx"
import { useFormContext } from "react-hook-form"

import { FormField, FormItem, FormLabel, FormMessage } from "@repo/ui/components/ui/form"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "@repo/ui/components/ui/select"
import { AGGREGATORS } from "@repo/util/constant"

interface Props {
  isView?: boolean
}
export default function Aggregator(props: Readonly<Props>) {
  const { isView = false } = props
  const form = useFormContext()

  return (
    <div className="flex w-full flex-col">
      <h3 className="text-main mb-4 py-4 text-lg font-semibold">Aggregator</h3>

      <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
        <div className="flex-1">
          <FormField
            control={form.control}
            name="aggregator"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-main text-sm font-normal">
                  Which aggregator do you belong to?
                </FormLabel>
                <Select disabled={isView} onValueChange={field.onChange} value={field.value}>
                  <SelectTrigger
                    className={clsx(
                      "h-12 w-full text-sm",
                      field.value ? "text-main" : "text-default"
                    )}
                  >
                    <SelectValue placeholder="Please select" />
                  </SelectTrigger>
                  <SelectContent>
                    {Object.entries(AGGREGATORS).map(([k, v]) => (
                      <SelectItem key={k} value={v.key}>
                        {v.key}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <div className="hidden flex-1 md:block"></div>
      </div>
    </div>
  )
}
