import React, { useCallback, useEffect, useState } from "react"

import json2mq from "json2mq"
import { useMediaQuery } from "react-responsive"

import AdminLayout from "@/components/_layout/AdminLayout"
import useAdminDashboardStore from "@/stores/useAdminDashboardStore"
import { ITableParams } from "@/types/interface"

import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbPage
} from "@repo/ui/components/ui/breadcrumb"

import TotalStatistics from "./sections/TotalStatistics"
import TypeOfEntities from "./sections/TypeOfEntities"
import FundingSolutions from "./sections/FundingSolutions"
import DataTable from "./DataTable"
import columns from "./DataTable/columns"

export default function AdminDashboard() {
  const store = useAdminDashboardStore()

  const [totalStatistics, setTotalStatistics] = useState<any[]>([])
  const [entityTypesCount, setEntityTypesCount] = useState<any[]>([])
  const [fundingSolutionsCount, setFundingSolutionsCount] = useState<any[]>([])
  const [totalCount, setTotalCount] = useState(0)
  const [data, setData] = useState<any[]>([])

  const [tableParams, setTableParams] = useState<ITableParams>({
    isMobile: false,
    filter: {
      full_name: "",
      entity_name: ""
    },
    sort: {
      column: "total_applications",
      direction: "desc"
    },
    pagination: {
      page_index: 1,
      page_size: 10
    }
  })

  const isMobile = useMediaQuery({ query: json2mq({ maxWidth: 767 }) })

  const initialize = useCallback(() => {
    store.getAction({ ...tableParams }, (newData) => {
      setTotalStatistics(newData.totalStatistics)
      setEntityTypesCount(newData.entityTypesCount)
      setFundingSolutionsCount(newData.fundingSolutionsCount)
      setTotalCount(newData.totalCount)
      setData(newData.data)
    })
  }, [tableParams, store.reloadRequired])

  useEffect(() => {
    initialize()
  }, [initialize])

  useEffect(() => {
    if (isMobile !== tableParams.isMobile) {
      setTableParams((prev: ITableParams) => ({
        ...prev,
        isMobile,
        pagination: {
          ...prev.pagination,
          page_size: isMobile ? -1 : 10
        }
      }))
    }
  }, [isMobile])

  return (
    <AdminLayout
      breadcrumb={
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbItem>
              <BreadcrumbPage className="text-main text-sm font-semibold">Dashboard</BreadcrumbPage>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
      }
    >
      <div className="flex flex-col">
        <div className="mb-4">
          <TotalStatistics data={totalStatistics} />
        </div>

        <div className="mb-4 flex flex-col gap-2.5 md:flex-row">
          <TypeOfEntities data={entityTypesCount} />
          <FundingSolutions data={fundingSolutionsCount} />
        </div>

        <div>
          <DataTable
            loading={store.loading}
            columns={columns}
            data={data}
            totalCount={totalCount}
            tableParams={tableParams}
            setTableParams={setTableParams}
          />
        </div>
      </div>
    </AdminLayout>
  )
}
