import React, { Dispatch, SetStateAction, useState } from "react"

import clsx from "clsx"
import { NumericFormat } from "react-number-format"

import { IconFilter } from "@/components/_icons"
import { ITableParams } from "@/types/interface"

import { Popover, PopoverContent, PopoverTrigger } from "@repo/ui/components/ui/popover"
import { Button } from "@repo/ui/components/ui/button"
import { Input } from "@repo/ui/components/ui/input"

interface Props {
  tableParams: ITableParams
  setTableParams: Dispatch<SetStateAction<ITableParams>>
}

export default function FilterPopover({ tableParams, setTableParams }: Readonly<Props>) {
  const [open, setOpen] = useState(false)
  const [createdOn, setCreatedOn] = useState(tableParams.filter.created_on)
  const [applicationId, setApplicationId] = useState(tableParams.filter.application_id)
  const [entityName, setEntityName] = useState(tableParams.filter.entity_name)
  const [loanAmount, setLoanAmount] = useState(tableParams.filter.loan_amount)

  const handleResetClick = () => {
    setCreatedOn("")
    setApplicationId("")
    setEntityName("")
    setLoanAmount("")

    setTableParams((prev: ITableParams) => ({
      ...prev,
      filter: {
        ...prev.filter,
        created_on: "",
        application_id: "",
        entity_name: "",
        loan_amount: ""
      },
      pagination: {
        ...prev.pagination,
        page_index: 1
      }
    }))
    setOpen(false)
  }

  const handleApplyClick = () => {
    setTableParams((prev: ITableParams) => ({
      ...prev,
      filter: {
        ...prev.filter,
        created_on: createdOn,
        application_id: applicationId,
        entity_name: entityName,
        loan_amount: loanAmount
      },
      pagination: {
        ...prev.pagination,
        page_index: 1
      }
    }))
    setOpen(false)
  }

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger
        className={clsx(
          "text-default flex h-auto w-full items-center justify-center gap-1.5 rounded-xl border bg-transparent px-4 py-3 text-sm font-semibold hover:bg-transparent md:w-auto",
          open ? "border-primary" : "border-[#868194]"
        )}
      >
        <IconFilter className="text-xl" />
        Filter
      </PopoverTrigger>

      <PopoverContent
        align="end"
        className="flex w-[calc(100vw-32px)] translate-x-4 flex-col gap-4 rounded-xl p-4 md:w-[400px] md:translate-x-0"
      >
        <p className="text-main text-sm">Filter</p>

        <Input
          autoFocus
          placeholder="Created on"
          value={createdOn}
          onChange={(e) => setCreatedOn(e.target.value)}
        />
        <Input
          placeholder="Application ID"
          value={applicationId}
          onChange={(e) => setApplicationId(e.target.value)}
        />
        <Input
          placeholder="Entity name"
          value={entityName}
          onChange={(e) => setEntityName(e.target.value)}
        />
        <NumericFormat
          customInput={Input}
          thousandSeparator
          prefix="$"
          decimalScale={2}
          fixedDecimalScale
          placeholder="Minimum loan amount"
          value={loanAmount ?? ""}
          onValueChange={(values, sourceInfo) => {
            setLoanAmount(values.value)
          }}
        />

        <div className="flex justify-end gap-2.5">
          <Button
            className="text-default h-auto border border-[#868194] bg-transparent px-6 py-4 text-sm font-semibold hover:bg-transparent"
            onClick={handleResetClick}
          >
            Reset
          </Button>
          <Button
            className="border-primary h-auto border px-6 py-4 text-sm font-semibold"
            onClick={handleApplyClick}
          >
            Apply filter
          </Button>
        </div>
      </PopoverContent>
    </Popover>
  )
}
