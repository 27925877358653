import qs from "qs"

import api from "./api"

// Get
export const getBusinessUsersList = (params: any) =>
  api().get(`/business/users?${qs.stringify(params)}`)

export const getBusinessUserByUuid = (userUuid: string) => api().get(`/business/users/${userUuid}`)

// Create
export const createBusinessUser = (params: any) => api().postForm("/business/users", params)

// Update
export const updateBusinessUser = (userUuid: string, params: any) =>
  api().putForm(`/business/users/${userUuid}`, params)

// Archive
export const archiveBusinessUser = (userUuid: string) =>
  api().patch(`/business/users/${userUuid}/archive`)

export default {
  getBusinessUsersList,
  getBusinessUserByUuid,
  createBusinessUser,
  updateBusinessUser,
  archiveBusinessUser
}
