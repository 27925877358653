import React from "react"

import clsx from "clsx"

import {
  IconAddBorrower,
  IconCreateApplication,
  IconMyApplications,
  IconMyBorrowers,
  IconBusinessSettings,
  IconUserCircle
} from "@/components/_icons"
import { LinkExt } from "@/components/_uiext"
import useAuthStore from "@/stores/useAuthStore"

import { Button } from "@repo/ui/components/ui/button"
import { SITE_TITLE, USER_ROLES } from "@repo/util/constant"

import Logo from "../components/Logo"
import NewLeadDialog from "../components/NewLeadDialog"

export default function Sidebar() {
  const authStore = useAuthStore()

  return (
    <aside className="fixed bottom-0 left-0 top-0 hidden max-h-screen min-h-screen min-w-[270px] max-w-[270px] flex-col bg-white px-6 py-[30px] lg:flex">
      <div className="mb-12">
        <Logo />
      </div>

      <div className="mb-20 flex flex-col">
        {[USER_ROLES.Introducer].indexOf(authStore.user?.role) > -1 && (
          <div className="mb-10">
            <NewLeadDialog type="button" disabled={!authStore.user?.is_approved} />
          </div>
        )}

        {[USER_ROLES.Broker, USER_ROLES.Enterprise, USER_ROLES.Employee].indexOf(
          authStore.user?.role
        ) > -1 && (
          <LinkExt
            className="mb-10 w-full"
            to="/applications/create"
            disabled={!authStore.user?.is_approved}
          >
            <Button className="h-auto w-full rounded-xl py-4 text-sm font-semibold text-white">
              <IconCreateApplication className="mr-1 text-2xl" /> Create application
            </Button>
          </LinkExt>
        )}

        {[USER_ROLES.Broker, USER_ROLES.Enterprise, USER_ROLES.Employee].indexOf(
          authStore.user?.role
        ) > -1 && (
          <>
            <p
              className={clsx(
                "text-main mb-2.5 text-sm font-semibold",
                !authStore.user?.is_approved && "opacity-50"
              )}
            >
              Applications
            </p>
            <LinkExt
              disabled={!authStore.user?.is_approved}
              className="mb-2.5 flex items-center gap-1 py-1 text-sm"
              to="/applications/list"
            >
              <IconMyApplications className="text-xl" />
              My Applications
            </LinkExt>

            <LinkExt
              disabled={!authStore.user?.is_approved}
              className="mb-10 flex items-center gap-1 py-1 text-sm"
              to="/applications/create"
            >
              <IconCreateApplication className="text-xl" />
              Create Application
            </LinkExt>
          </>
        )}

        {[USER_ROLES.Broker, USER_ROLES.Enterprise, USER_ROLES.Employee].indexOf(
          authStore.user?.role
        ) > -1 && (
          <>
            <p
              className={clsx(
                "text-main mb-2.5 text-sm font-semibold",
                !authStore.user?.is_approved && "opacity-50"
              )}
            >
              Borrowers
            </p>
            <LinkExt
              disabled={!authStore.user?.is_approved}
              className="mb-2.5 flex items-center gap-1 py-1 text-sm"
              to="/borrowers/list"
            >
              <IconMyBorrowers className="text-xl" />
              My Borrowers
            </LinkExt>
            <LinkExt
              disabled={!authStore.user?.is_approved}
              className="mb-10 flex items-center gap-1 py-1 text-sm"
              to="/borrowers/add"
            >
              <IconAddBorrower className="text-xl" />
              Add Borrower
            </LinkExt>
          </>
        )}

        {[USER_ROLES.Introducer].indexOf(authStore.user?.role) > -1 && (
          <>
            <p
              className={clsx(
                "text-main mb-2.5 text-sm font-semibold",
                !authStore.user?.is_approved && "opacity-50"
              )}
            >
              Leads
            </p>
            <LinkExt
              disabled={!authStore.user?.is_approved}
              className="mb-2.5 flex items-center gap-1 py-1 text-sm"
              to="/leads/list"
            >
              <IconMyApplications className="text-xl" />
              My Leads
            </LinkExt>

            <div className="mb-10">
              <NewLeadDialog type="link" disabled={!authStore.user?.is_approved} />
            </div>
          </>
        )}

        {[USER_ROLES.Introducer, USER_ROLES.Broker, USER_ROLES.Enterprise].indexOf(
          authStore.user?.role
        ) > -1 && (
          <>
            <p className="text-main mb-2.5 text-sm font-semibold">Business</p>

            {authStore.user?.role === USER_ROLES.Enterprise && (
              <LinkExt
                disabled={!authStore.user?.is_approved}
                className="mb-2.5 flex items-center gap-1 py-1 text-sm"
                to="/business/users/list"
              >
                <IconUserCircle className="text-xl" />
                Users
              </LinkExt>
            )}

            {/* <Link className="mb-2.5 flex items-center gap-1 py-1 text-sm" to="/business/bank">
              <IconBankDetails className="text-xl" />
              Bank Details
            </Link> */}

            <LinkExt className="flex items-center gap-1 py-1 text-sm" to="/business/settings">
              <IconBusinessSettings className="text-xl" />
              Settings
            </LinkExt>
          </>
        )}
      </div>

      <p className="text-sm">
        &copy; {new Date().getFullYear()} {SITE_TITLE}
      </p>
    </aside>
  )
}
