import qs from "qs"

import api from "./api"

// Create
export const apiCreateLead = (params: any) => api().postForm("/leads", params)

// Get
export const apiGetLeadsList = (data: any) => api().get(`/leads?${qs.stringify(data)}`)
export const apiGetLeadByUuid = (uuid: string) => api().get(`/leads/${uuid}`)

// Archive
export const apiArchiveLead = (uuid: string) => api().patch(`/leads/${uuid}/archive`)
