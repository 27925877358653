import React from "react"

import { Swiper, SwiperSlide } from "swiper/react"
import { Pagination } from "swiper/modules"
import { SwiperOptions } from "swiper/types"

import "swiper/css"
import "swiper/css/pagination"
import "@/lib/effect-tinder/effect-tinder.css"

export default function RightSwiper() {
  const swiperOptions: SwiperOptions = {
    style: {
      "--swiper-pagination-color": "#FFFFFF",
      "--swiper-pagination-bullet-inactive-color": "#FFFFFF",
      "--swiper-pagination-bullet-inactive-opacity": "0.6",
      "--swiper-pagination-bullet-size": "8px",
      "--swiper-pagination-bullet-horizontal-gap": "3px"
    },
    grabCursor: true,
    centeredSlides: true,
    modules: [Pagination],
    slidesPerView: 1,
    loop: true,
    pagination: {
      clickable: true
    }
  }

  return (
    <div className="h-full w-full p-6">
      <div className="bg-primary relative flex h-full w-full items-center justify-center rounded-2xl px-[10%] py-[10%]">
        <Swiper className="absolute bottom-0 left-0 right-0 top-0" {...swiperOptions}>
          {[0, 1, 2, 3, 4].map((k) => (
            <SwiperSlide key={k} className="bg-primary h-full pb-8">
              <div className="flex h-full flex-col justify-center">
                <img
                  className="mb-8 w-full object-contain"
                  src="/auth/slider-01.svg"
                  alt="dashboard"
                />

                <div className="mb-8 flex flex-col items-center">
                  <h2 className="mb-4 text-center text-lg font-semibold text-white">
                    Application Submissions
                  </h2>

                  <p className="line-clamp-3 max-w-[400px] px-0 text-center text-sm font-normal text-white">
                    We simplify the application process by guiding users through straightforward
                    steps, prompting them to input relevant financial and operational information.
                  </p>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  )
}
