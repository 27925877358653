import React from "react"

interface Props {
  className?: string
}

export default function IconLender({ className }: Readonly<Props>) {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.875 7.12461H3.75V12.1246H2.5C2.33424 12.1246 2.17527 12.1905 2.05806 12.3077C1.94085 12.4249 1.875 12.5839 1.875 12.7496C1.875 12.9154 1.94085 13.0743 2.05806 13.1916C2.17527 13.3088 2.33424 13.3746 2.5 13.3746H17.5C17.6658 13.3746 17.8247 13.3088 17.9419 13.1916C18.0592 13.0743 18.125 12.9154 18.125 12.7496C18.125 12.5839 18.0592 12.4249 17.9419 12.3077C17.8247 12.1905 17.6658 12.1246 17.5 12.1246H16.25V7.12461H18.125C18.261 7.12447 18.3932 7.07999 18.5016 6.9979C18.6101 6.91582 18.6887 6.80061 18.7258 6.66976C18.7628 6.5389 18.7561 6.39955 18.7068 6.27283C18.6574 6.14611 18.5681 6.03894 18.4523 5.96758L10.3273 0.96758C10.2289 0.907047 10.1156 0.875 10 0.875C9.88442 0.875 9.77111 0.907047 9.67266 0.96758L1.54766 5.96758C1.4319 6.03894 1.34257 6.14611 1.29323 6.27283C1.24388 6.39955 1.23722 6.5389 1.27424 6.66976C1.31126 6.80061 1.38994 6.91582 1.49836 6.9979C1.60678 7.07999 1.73901 7.12447 1.875 7.12461ZM5 7.12461H7.5V12.1246H5V7.12461ZM11.25 7.12461V12.1246H8.75V7.12461H11.25ZM15 12.1246H12.5V7.12461H15V12.1246ZM10 2.2332L15.9172 5.87461H4.08281L10 2.2332ZM19.375 15.2496C19.375 15.4154 19.3092 15.5743 19.1919 15.6916C19.0747 15.8088 18.9158 15.8746 18.75 15.8746H1.25C1.08424 15.8746 0.925268 15.8088 0.808058 15.6916C0.690848 15.5743 0.625 15.4154 0.625 15.2496C0.625 15.0839 0.690848 14.9249 0.808058 14.8077C0.925268 14.6905 1.08424 14.6246 1.25 14.6246H18.75C18.9158 14.6246 19.0747 14.6905 19.1919 14.8077C19.3092 14.9249 19.375 15.0839 19.375 15.2496Z"
        fill="currentColor"
      />
    </svg>
  )
}
