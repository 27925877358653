import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState
} from "react"

interface IAppContext {
  steps: string[]
  setSteps: Dispatch<SetStateAction<string[]>>
  activeStep: number
  setActiveStep: Dispatch<SetStateAction<number>>
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  data: any
  setData: Dispatch<SetStateAction<any>>
}
export const AppContext = createContext<IAppContext | undefined>(undefined)

interface ProviderProps {
  initialSteps: string[]
  initialActiveStep: number
  initialLoading: boolean
  initialData: any
  children: ReactNode
}
export const AppContextProvider = ({
  initialSteps,
  initialActiveStep,
  initialLoading,
  initialData,
  children
}: Readonly<ProviderProps>) => {
  const [steps, setSteps] = useState(initialSteps)
  const [activeStep, setActiveStep] = useState(initialActiveStep)
  const [loading, setLoading] = useState(initialLoading)
  const [data, setData] = useState<any>(initialData)

  useEffect(() => {
    setSteps(initialSteps)
  }, [initialSteps])

  useEffect(() => {
    setActiveStep(initialActiveStep)
  }, [initialActiveStep])

  useEffect(() => {
    setLoading(initialLoading)
  }, [initialLoading])

  useEffect(() => {
    setData(initialData)
  }, [initialData])

  return (
    <AppContext.Provider
      value={{ steps, setSteps, activeStep, setActiveStep, loading, setLoading, data, setData }}
    >
      {children}
    </AppContext.Provider>
  )
}

export function useAppContext() {
  const context = useContext(AppContext)

  if (!context) {
    throw new Error("useAppContext must be used within a AppContextProvider")
  }

  return context
}
