import api from "./api"

// Get invite
export const apiGetApplicationInvite = (inviteUuid: string) =>
  api().get(`/application/invites/${inviteUuid}`)

// Verify invite
export const apiVerifyApplicationInvite = (inviteUuid: string, smsCode: string) =>
  api().postForm(`/application/invites/${inviteUuid}/verify`, { sms_code: smsCode })

// Submit invite
export const apiSubmitApplicationInvite = (inviteUuid: string, params: any) =>
  api().putForm(`/application/invites/${inviteUuid}/submit`, params)
