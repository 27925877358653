import React from "react"

import { Navigate, useLocation } from "react-router-dom"

import useAuthStore from "@/stores/useAuthStore"

import { USER_ROLES } from "@repo/util/constant"

interface Props {
  roles: string[]
  children: React.ReactNode
}

export default function RoleBasedRoute({ roles, children }: Readonly<Props>) {
  const authStore = useAuthStore()
  const location = useLocation()

  if (authStore.user && roles.indexOf(authStore.user.role) === -1) {
    if (authStore.user.role === USER_ROLES.Administrator) {
      return <Navigate to="/admin/dashboard" state={{ from: location }} replace />
    } else {
      return <Navigate to="/dashboard" state={{ from: location }} replace />
    }
  }

  return children
}
