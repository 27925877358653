import React from "react"

import clsx from "clsx"
import { MdCheckCircle, MdInfoOutline } from "react-icons/md"

import { IconApplicantType } from "@/components/_icons"

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from "@repo/ui/components/ui/tooltip"

interface Props {
  isSelected: boolean
  value: string
  description: string
  onClick: () => void
}

export default function LoanTypeItem(props: Readonly<Props>) {
  const { isSelected, value, description, onClick } = props

  return (
    <div
      className={clsx(
        "relative flex min-h-40 flex-1 cursor-pointer flex-col items-center rounded-2xl border px-2.5 py-7 md:px-5",
        isSelected ? "border-primary" : ""
      )}
      onClick={onClick}
    >
      {!isSelected && (
        <TooltipProvider delayDuration={0} skipDelayDuration={0}>
          <Tooltip>
            <TooltipTrigger>
              <div className="absolute right-3 top-3">
                <MdInfoOutline className="text-default" />
              </div>
            </TooltipTrigger>
            <TooltipContent side="bottom" className="w-40 p-3.5" align="start">
              <p className="text-xs font-normal">{description}</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      )}

      {isSelected && (
        <div className="absolute right-3 top-3">
          <MdCheckCircle className="text-primary" />
        </div>
      )}

      <div
        className={clsx(
          "mb-2 flex h-12 w-12 items-center justify-center rounded-full",
          isSelected ? "bg-primary" : "bg-[#CDCDCD]"
        )}
      >
        <IconApplicantType className="text-base text-white" />
      </div>

      <div
        className={clsx(
          "text-center text-base font-normal",
          isSelected ? "text-main" : "text-default"
        )}
      >
        {value}
      </div>
    </div>
  )
}
