import React, { Dispatch, SetStateAction } from "react"

import { useNavigate } from "react-router-dom"

import { IconMyApplications } from "@/components/_icons"
import useAuthStore from "@/stores/useAuthStore"
import { ITableParams } from "@/types/interface"

import { Button } from "@repo/ui/components/ui/button"
import { USER_ROLES } from "@repo/util/constant"

import FilterPopover from "./FilterPopover"

interface Props {
  tableParams: ITableParams
  setTableParams: Dispatch<SetStateAction<ITableParams>>
}

export default function Header({ tableParams, setTableParams }: Readonly<Props>) {
  const authStore = useAuthStore()
  const navigate = useNavigate()

  const handleViewClick = () => {
    navigate("/applications/list")
  }

  return (
    <div className="flex flex-col gap-4 rounded-t-xl border border-[#ECECEC] bg-white p-4 md:flex-row md:items-center md:justify-between">
      <div className="flex items-center justify-between gap-2.5">
        <h3 className="text-main text-lg font-semibold">Applications Settled</h3>
      </div>

      {authStore.user?.role !== USER_ROLES.Introducer && (
        <div className="flex flex-col items-center gap-2 md:flex-row">
          <div className="order-1 flex w-full items-center justify-between gap-2 md:order-1">
            <FilterPopover tableParams={tableParams} setTableParams={setTableParams} />
          </div>

          <Button
            className="border-primary order-2 flex h-auto w-full items-center gap-1.5 border px-4 py-3 font-semibold md:order-2 md:w-auto"
            onClick={handleViewClick}
          >
            <IconMyApplications className="text-lg" />
            View all applications
          </Button>
        </div>
      )}
    </div>
  )
}
