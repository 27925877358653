import { toast } from "sonner"
import { create } from "zustand"

import {
  apiGetApplicationInvite,
  apiSubmitApplicationInvite,
  apiVerifyApplicationInvite
} from "@/api/applicationInvitesApi"

import { CLIENT_MSG } from "@repo/i18n"

interface IApplicationInviteState {
  loading: boolean

  getInviteAction: (inviteUuid: string, onSuccessCallback?: (newData: any) => void) => void
  verifyInviteAction: (
    inviteUuid: string,
    smsCode: string,
    onSuccessCallback?: (newData: any) => void,
    onFailureCallback?: () => void
  ) => void

  submitInviteAction: (
    inviteUuid: string,
    params: any,
    onSuccessCallback?: (newData: any) => void
  ) => void
}

const useApplicationInviteStore = create<IApplicationInviteState>()((set, get) => ({
  loading: false,

  getInviteAction: async (inviteUuid, onSuccessCallback) => {
    try {
      set({ loading: true })

      const response = await apiGetApplicationInvite(inviteUuid)

      if (response?.data) {
        const { data } = response.data

        if (typeof onSuccessCallback === "function") {
          onSuccessCallback(data)
        }
      }
    } catch (err: any) {
      console.error(err)

      if (err?.response?.data?.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error(CLIENT_MSG.SOMETHING_WENT_WRONG)
      }
    } finally {
      set({ loading: false })
    }
  },

  verifyInviteAction: async (inviteUuid, smsCode, onSuccessCallback, onFailureCallback) => {
    try {
      set({ loading: true })

      const response = await apiVerifyApplicationInvite(inviteUuid, smsCode)

      if (response?.data) {
        const { data } = response.data

        if (typeof onSuccessCallback === "function") {
          onSuccessCallback(data)
        }
      }
    } catch (err: any) {
      console.error(err)

      if (err?.response?.data?.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error(CLIENT_MSG.SOMETHING_WENT_WRONG)
      }

      if (typeof onFailureCallback === "function") {
        onFailureCallback()
      }
    } finally {
      set({ loading: false })
    }
  },

  submitInviteAction: async (inviteUuid, params, onSuccessCallback) => {
    try {
      set({ loading: true })

      const response = await apiSubmitApplicationInvite(inviteUuid, params)

      if (response?.data) {
        const { message, data } = response.data
        toast.success(message)

        if (typeof onSuccessCallback === "function") {
          onSuccessCallback(data)
        }
      }
    } catch (err: any) {
      console.error(err)

      if (err?.response?.data?.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error(CLIENT_MSG.SOMETHING_WENT_WRONG)
      }
    } finally {
      set({ loading: false })
    }
  }
}))

export default useApplicationInviteStore
