import React, { useEffect } from "react"

import { zodResolver } from "@hookform/resolvers/zod"
import { FormProvider, useForm } from "react-hook-form"
import { useInView } from "react-intersection-observer"
import { useNavigate } from "react-router-dom"
import * as z from "zod"

import { StickyShortcut } from "@/components/_uiext"
import { INVIEW_THRESHOLD } from "@/utils/constants"

import { Button } from "@repo/ui/components/ui/button"
import { CLIENT_MSG } from "@repo/i18n"

import BasicInformation from "../_sections/BasicInformation"
import Membership from "../_sections/Membership"
import AustralianCreditRepresentative from "../_sections/AustralianCreditRepresentative"
import ProfessionalIndemnityInsurance from "../_sections/ProfessionalIndemnityInsurance"

const FormSchema = z.object({
  image: z.any().optional(),
  first_name: z.string().min(1, { message: CLIENT_MSG.FIELD_REQUIRED }),
  last_name: z.string().min(1, { message: CLIENT_MSG.FIELD_REQUIRED }),
  email: z.string().email({ message: CLIENT_MSG.FIELD_REQUIRED }),
  mobile_number: z.string().min(1, { message: CLIENT_MSG.FIELD_REQUIRED }),

  membership_association: z.string().min(1, { message: CLIENT_MSG.FIELD_REQUIRED }),
  membership_certificate: z.string().min(1, { message: CLIENT_MSG.FIELD_REQUIRED }),
  membership_expiry: z.coerce.date(),
  acr_certificate: z.string().min(1, { message: CLIENT_MSG.FIELD_REQUIRED }),
  acr_expiry: z.coerce.date(),
  pii_certificate: z.string().min(1, { message: CLIENT_MSG.FIELD_REQUIRED }),
  pii_expiry: z.coerce.date()
})

type FormSchemaType = z.infer<typeof FormSchema>

interface Props {
  data: any
}

export default function MainForm({ data }: Readonly<Props>) {
  const navigate = useNavigate()

  const { ref: ref1, inView: inView1 } = useInView({ threshold: INVIEW_THRESHOLD })
  const { ref: ref2, inView: inView2 } = useInView({ threshold: INVIEW_THRESHOLD })
  const { ref: ref3, inView: inView3 } = useInView({ threshold: INVIEW_THRESHOLD })
  const { ref: ref4, inView: inView4 } = useInView({ threshold: INVIEW_THRESHOLD })

  const form = useForm<FormSchemaType>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      image: null,
      first_name: "",
      last_name: "",
      email: "",
      mobile_number: "",

      membership_association: "",
      membership_certificate: "",
      membership_expiry: undefined,
      acr_certificate: "",
      acr_expiry: undefined,
      pii_certificate: "",
      pii_expiry: undefined
    }
  })

  const handleCancelClick = () => {
    navigate("/business/users/list")
  }

  useEffect(() => {
    form.reset({ ...data })
  }, [data])

  return (
    <FormProvider {...form}>
      <form className="flex flex-col">
        <div className="flex w-full rounded-xl border border-[#ECECEC] bg-white p-8">
          <div className="flex w-full flex-1 flex-col gap-5 xl:pr-8">
            <div ref={ref1} id="basic-information">
              <BasicInformation isView={true} />
            </div>

            <div ref={ref2} id="membership">
              <Membership isView={true} />
            </div>

            <div ref={ref3} id="australian-credit-representative">
              <AustralianCreditRepresentative isView={true} />
            </div>

            <div ref={ref4} id="professional-indemnity-insurance">
              <ProfessionalIndemnityInsurance isView={true} />
            </div>
          </div>

          <StickyShortcut
            items={[
              {
                id: "basic-information",
                label: "Basic information",
                inView: inView1
              },
              {
                id: "membership",
                label: "Membership",
                inView: inView2
              },
              {
                id: "australian-credit-representative",
                label: "Australian Credit Representative",
                inView: inView3
              },
              {
                id: "professional-indemnity-insurance",
                label: "Professional indemnity insurance",
                inView: inView4
              }
            ]}
          />
        </div>

        <div className="flex w-full justify-end gap-2 pb-2 pt-6">
          <Button
            type="button"
            className="text-default h-auto w-full border border-[#868194] bg-transparent px-6 py-4 font-semibold hover:bg-transparent md:!w-auto"
            onClick={handleCancelClick}
          >
            Back
          </Button>
        </div>
      </form>
    </FormProvider>
  )
}
