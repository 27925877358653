import React from "react"

interface Props {
  className?: string
}

export default function IconArchive({ className }: Readonly<Props>) {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 16 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.75 0.375H1.25C0.951631 0.375 0.665483 0.493526 0.454505 0.704505C0.243526 0.915483 0.125 1.20163 0.125 1.5V3.1875C0.125 3.48587 0.243526 3.77202 0.454505 3.983C0.474269 4.00276 0.494693 4.02171 0.515724 4.03983C0.850453 4.32822 1.25 4.67067 1.25 5.1125V10.5C1.25 10.7984 1.36853 11.0845 1.5795 11.2955C1.79048 11.5065 2.07663 11.625 2.375 11.625H13.625C13.9234 11.625 14.2095 11.5065 14.4205 11.2955C14.6315 11.0845 14.75 10.7984 14.75 10.5V5.1125C14.75 4.67067 15.1495 4.32822 15.4843 4.03983C15.5053 4.02171 15.5257 4.00276 15.5455 3.983C15.7565 3.77202 15.875 3.48587 15.875 3.1875V1.5C15.875 1.20163 15.7565 0.915483 15.5455 0.704505C15.3345 0.493526 15.0484 0.375 14.75 0.375ZM13.625 9.7C13.625 10.1418 13.2668 10.5 12.825 10.5H3.175C2.73317 10.5 2.375 10.1418 2.375 9.7V5.1125C2.375 4.67067 2.73317 4.3125 3.175 4.3125H12.825C13.2668 4.3125 13.625 4.67067 13.625 5.1125V9.7ZM14.75 2.3875C14.75 2.82933 14.3918 3.1875 13.95 3.1875H2.05C1.60817 3.1875 1.25 2.82933 1.25 2.3875V2.3C1.25 1.85817 1.60817 1.5 2.05 1.5H13.95C14.3918 1.5 14.75 1.85817 14.75 2.3V2.3875ZM5.75 6.5625C5.75 6.41332 5.80926 6.27024 5.91475 6.16475C6.02024 6.05926 6.16332 6 6.3125 6H9.6875C9.83668 6 9.97976 6.05926 10.0852 6.16475C10.1907 6.27024 10.25 6.41332 10.25 6.5625C10.25 6.71168 10.1907 6.85476 10.0852 6.96025C9.97976 7.06574 9.83668 7.125 9.6875 7.125H6.3125C6.16332 7.125 6.02024 7.06574 5.91475 6.96025C5.80926 6.85476 5.75 6.71168 5.75 6.5625Z"
        fill="currentColor"
      />
    </svg>
  )
}
