import React, { useEffect, useRef, useState } from "react"

import SignatureCanvas from "react-signature-canvas"

import { LoadingButton } from "@/components/_uiext"

import { Button } from "@repo/ui/components/ui/button"
import { Dialog, DialogContent, DialogTrigger } from "@repo/ui/components/ui/dialog"

interface Props {
  value?: string
  onConfirm?: (signURI: string) => void
}

export default function SignatureDialog(props: Readonly<Props>) {
  const { value, onConfirm } = props
  const signRef = useRef<SignatureCanvas>(null)
  const uploadRef = useRef<HTMLInputElement>(null)
  const [open, setOpen] = useState(false)

  const handleClearClick = () => {
    signRef?.current?.clear()
  }

  const handleUploadClick = () => {
    uploadRef?.current?.click()
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (file) {
      const reader = new FileReader()
      reader.onload = (e) => {
        const imageUrl = e.target?.result as string
        const img = new Image()
        img.src = imageUrl
        img.onload = () => {
          // Get canvas dimensions
          const canvas = signRef.current?.getCanvas()
          if (!canvas) return

          const ctx = canvas.getContext("2d")
          if (!ctx) return

          const canvasWidth = canvas.width
          const canvasHeight = canvas.height

          // Calculate the new width and height while preserving the aspect ratio
          const aspectRatio = img.width / img.height
          let newWidth, newHeight

          if (img.width > img.height) {
            // Fit to the width of the canvas
            newWidth = canvasWidth
            newHeight = canvasWidth / aspectRatio
          } else {
            // Fit to the height of the canvas
            newHeight = canvasHeight
            newWidth = canvasHeight * aspectRatio
          }

          // Center the image on the canvas
          const xOffset = (canvasWidth - newWidth) / 2
          const yOffset = (canvasHeight - newHeight) / 2

          // Clear the current canvas and draw the resized image
          ctx.clearRect(0, 0, canvasWidth, canvasHeight)
          ctx.drawImage(img, xOffset, yOffset, newWidth, newHeight)
        }
      }
      reader.readAsDataURL(file) // Convert file to data URL
    }
  }

  const handleOkClick = () => {
    if (typeof onConfirm === "function") {
      onConfirm(signRef?.current?.getTrimmedCanvas().toDataURL("image/png") ?? "")
      setOpen(false)
    }
  }

  useEffect(() => {
    if (value?.includes("data:image/") && open) {
      setTimeout(() => {
        signRef?.current?.clear()
        signRef?.current?.fromDataURL(value)
      }, 10)
    }

    if (value?.includes("http") && open) {
      setTimeout(() => {
        signRef?.current?.clear()
        signRef?.current?.fromDataURL(value)
      }, 10)
    }
  }, [value, open])

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger>
        {(!value || typeof value === "undefined") && (
          <div className="h-24 w-full rounded-xl border" />
        )}
        {typeof value === "string" && <img src={value} className="h-24 w-full rounded-xl border" />}
      </DialogTrigger>
      <DialogContent className="flex h-full w-full flex-col gap-0 bg-white p-10 md:h-[80vh] md:max-w-[80vw]">
        <div className="mb-4 h-full w-full">
          <SignatureCanvas
            ref={signRef}
            canvasProps={{
              className: "cursor-pointer bg-background rounded-xl h-full w-full"
            }}
          />
        </div>

        <input
          ref={uploadRef}
          type="file"
          accept="image/*"
          style={{ display: "none" }}
          onChange={handleFileChange}
        />

        <div className="flex justify-end gap-2">
          <Button
            className="text-default h-auto border border-[#868194] bg-transparent px-6 py-4 font-semibold hover:bg-transparent"
            onClick={handleClearClick}
          >
            Clear
          </Button>

          <Button
            className="text-default h-auto border border-[#868194] bg-transparent px-6 py-4 font-semibold hover:bg-transparent"
            onClick={handleUploadClick}
          >
            Upload
          </Button>

          <LoadingButton
            className="flex items-center gap-4 px-6 py-4 md:!w-auto"
            onClick={handleOkClick}
          >
            OK
          </LoadingButton>
        </div>
      </DialogContent>
    </Dialog>
  )
}
