import { toast } from "sonner"
import { create } from "zustand"

import {
  apiArchiveAdminApplication,
  apiGetAdminApplicationByUuid,
  apiGetAdminApplicationsList,
  apiUpdateAdminApplication,
  apiUpdateAdminApplicationEarning,
  apiUpdateAdminApplicationFormalApproval,
  apiUpdateAdminApplicationIndicativeApproval,
  apiUpdateAdminApplicationStatus
} from "@/api/adminApplicationsApi"

import { CLIENT_MSG } from "@repo/i18n"

interface IAdminApplicationState {
  loading: boolean
  reloadRequired: boolean

  getListAction: (params: any, onSuccessCallback?: (newData: any) => void) => void
  getOneByUuidAction: (uuid: string, onSuccessCallback?: (data: any) => void) => void
  archiveAction: (uuid: string) => void

  updateAction: (uuid: string, params: any, onSuccessCallback?: (data: any) => void) => void

  updateStatusAction: (
    uuid: string,
    newStatus: string,
    onSuccessCallback?: (newData: any) => void
  ) => void

  updateEarningAction: (
    uuid: string,
    params: any,
    onSuccessCallback?: (newData: any) => void
  ) => void

  updateIndicativeApprovalAction: (
    uuid: string,
    params: any,
    onSuccessCallback?: (newData: any) => void
  ) => void

  updateFormalApprovalAction: (
    uuid: string,
    params: any,
    onSuccessCallback?: (newData: any) => void
  ) => void
}

const useAdminApplicationStore = create<IAdminApplicationState>()((set, get) => ({
  loading: false,
  reloadRequired: false,

  getListAction: async (params, onSuccessCallback) => {
    try {
      set({ loading: true })

      const response = await apiGetAdminApplicationsList(params)

      if (response?.data) {
        const { data } = response.data

        if (typeof onSuccessCallback === "function") {
          onSuccessCallback(data)
        }
      }
    } catch (err: any) {
      console.error(err)

      if (err?.response?.data?.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error(CLIENT_MSG.SOMETHING_WENT_WRONG)
      }
    } finally {
      set({ loading: false })
    }
  },

  getOneByUuidAction: async (uuid, onSuccessCallback) => {
    try {
      set({ loading: true })

      const response = await apiGetAdminApplicationByUuid(uuid)

      if (response?.data) {
        const { data } = response.data

        if (typeof onSuccessCallback === "function") {
          onSuccessCallback(data)
        }
      }
    } catch (err: any) {
      console.error(err)

      if (err?.response?.data?.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error(CLIENT_MSG.SOMETHING_WENT_WRONG)
      }
    } finally {
      set({ loading: false })
    }
  },

  archiveAction: async (uuid: string) => {
    try {
      const response = await apiArchiveAdminApplication(uuid)

      if (response?.data) {
        const { message } = response.data
        set({ reloadRequired: !get().reloadRequired })
        toast.success(message)
      }
    } catch (err: any) {
      console.error(err)

      if (err?.response?.data?.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error(CLIENT_MSG.SOMETHING_WENT_WRONG)
      }
    }
  },

  updateAction: async (uuid: string, params: any, onSuccessCallback) => {
    try {
      set({ loading: true })

      const response = await apiUpdateAdminApplication(uuid, params)

      if (response?.data) {
        const { data } = response.data

        if (typeof onSuccessCallback === "function") {
          onSuccessCallback(data)
        }
      }
    } catch (err: any) {
      console.error(err)

      if (err?.response?.data?.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error(CLIENT_MSG.SOMETHING_WENT_WRONG)
      }
    } finally {
      set({ loading: false })
    }
  },

  updateStatusAction: async (uuid, newStatus, onSuccessCallback) => {
    try {
      set({ loading: true })

      const response = await apiUpdateAdminApplicationStatus(uuid, { status: newStatus })

      if (response?.data) {
        const { data, message } = response.data

        toast.success(message)

        if (typeof onSuccessCallback === "function") {
          onSuccessCallback(data)
        }
      }
    } catch (err: any) {
      console.error(err)

      if (err?.response?.data?.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error(CLIENT_MSG.SOMETHING_WENT_WRONG)
      }
    } finally {
      set({ loading: false })
    }
  },

  updateEarningAction: async (uuid, params, onSuccessCallback) => {
    try {
      set({ loading: true })

      const response = await apiUpdateAdminApplicationEarning(uuid, params)

      if (response?.data) {
        const { data, message } = response.data

        toast.success(message)

        if (typeof onSuccessCallback === "function") {
          onSuccessCallback(data)
        }
      }
    } catch (err: any) {
      console.error(err)

      if (err?.response?.data?.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error(CLIENT_MSG.SOMETHING_WENT_WRONG)
      }
    } finally {
      set({ loading: false })
    }
  },

  updateIndicativeApprovalAction: async (uuid, params, onSuccessCallback) => {
    try {
      set({ loading: true })

      const response = await apiUpdateAdminApplicationIndicativeApproval(uuid, params)

      if (response?.data) {
        const { data, message } = response.data

        toast.success(message)

        if (typeof onSuccessCallback === "function") {
          onSuccessCallback(data)
        }
      }
    } catch (err: any) {
      console.error(err)

      if (err?.response?.data?.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error(CLIENT_MSG.SOMETHING_WENT_WRONG)
      }
    } finally {
      set({ loading: false })
    }
  },

  updateFormalApprovalAction: async (uuid, params, onSuccessCallback) => {
    try {
      set({ loading: true })

      const response = await apiUpdateAdminApplicationFormalApproval(uuid, params)

      if (response?.data) {
        const { data, message } = response.data

        toast.success(message)

        if (typeof onSuccessCallback === "function") {
          onSuccessCallback(data)
        }
      }
    } catch (err: any) {
      console.error(err)

      if (err?.response?.data?.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error(CLIENT_MSG.SOMETHING_WENT_WRONG)
      }
    } finally {
      set({ loading: false })
    }
  }
}))

export default useAdminApplicationStore
