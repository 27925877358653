import qs from "qs"

import api from "./api"

// Get
export const apiGetAdminLeadsList = (data: any) => api().get(`/admin/leads?${qs.stringify(data)}`)
export const apiGetAdminLeadByUuid = (uuid: string) => api().get(`/admin/leads/${uuid}`)

// Archive
export const apiArchiveAdminLead = (uuid: string) => api().patch(`/admin/leads/${uuid}/archive`)
