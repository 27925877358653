import React, { useEffect } from "react"

import { zodResolver } from "@hookform/resolvers/zod"
import { FormProvider, useForm } from "react-hook-form"
import { useInView } from "react-intersection-observer"
import { useNavigate } from "react-router-dom"
import * as z from "zod"

import { LoadingButton, StickyShortcut } from "@/components/_uiext"
import useAdminUserStore from "@/stores/useAdminUserStore"
import { INVIEW_THRESHOLD } from "@/utils/constants"

import { Button } from "@repo/ui/components/ui/button"
import { ENTITY_TYPES } from "@repo/util/constant"
import { CLIENT_MSG } from "@repo/i18n"

import BasicInformation from "../_sections/BasicInformation"
import BusinessSettingIntroducer from "../_sections/BusinessSettingIntroducer"
// import BusinessBank from "../_sections/BusinessBank"

const FormSchema = z
  .object({
    image: z.any().optional(),
    role: z.string().min(1, { message: "User type is required" }),
    first_name: z.string().min(1, { message: "First name is required" }),
    last_name: z.string().min(1, { message: "Last name is required" }),
    email: z.string().email({
      message: "Please enter a valid email address"
    }),
    mobile_number: z.string().min(1, {
      message: "Please enter a valid mobile number"
    }),
    is_archived: z.boolean(),
    is_approved: z.boolean(),
    is_locked: z.boolean(),

    entity: z.object({
      entity_type: z.string().min(1, { message: CLIENT_MSG.FIELD_REQUIRED }),
      temp_abn: z
        .string({ required_error: CLIENT_MSG.FIELD_REQUIRED })
        .min(1, { message: CLIENT_MSG.FIELD_REQUIRED }),
      entity_name: z.string().min(1, { message: CLIENT_MSG.FIELD_REQUIRED }),
      abn: z.string().min(1, { message: CLIENT_MSG.FIELD_REQUIRED }),
      acn: z.string(),
      trust_type: z.string(),
      trust_type_other: z.string(),
      is_gst_registered: z.boolean().optional().default(false),
      postal_address: z.string(),
      principal_place: z.string().min(1, { message: CLIENT_MSG.FIELD_REQUIRED }),
      registered_place: z.string().min(1, { message: CLIENT_MSG.FIELD_REQUIRED }),
      entity_email: z.string().min(1, { message: CLIENT_MSG.FIELD_REQUIRED }).email(),
      entity_contact_number: z.string().min(1, { message: CLIENT_MSG.FIELD_REQUIRED }),
      primary_business_activity: z.string().min(1, { message: CLIENT_MSG.FIELD_REQUIRED }),
      website: z.string().url().optional(),

      street_address: z.string().min(1, { message: CLIENT_MSG.FIELD_REQUIRED }),
      suburb: z.string().min(1, { message: CLIENT_MSG.FIELD_REQUIRED }),
      state: z.string().min(1, { message: CLIENT_MSG.FIELD_REQUIRED }),
      postcode: z.string().min(1, { message: CLIENT_MSG.FIELD_REQUIRED })
    })

    // bank: z.object({
    //   account_name: z.string().min(1, { message: CLIENT_MSG.FIELD_REQUIRED }),
    //   bank_branch: z.string().min(1, { message: CLIENT_MSG.FIELD_REQUIRED }),
    //   other: z.string(),
    //   bsb: z.string().min(1, { message: CLIENT_MSG.FIELD_REQUIRED }),
    //   account_number: z.string().min(1, { message: CLIENT_MSG.FIELD_REQUIRED }),
    //   statement_proof: z.any().refine((file) => file !== null, CLIENT_MSG.FIELD_REQUIRED)
    // })
  })
  .superRefine((data: any, ctx) => {
    const entityTypesRequiringACN = [
      ENTITY_TYPES.Company.key,
      ENTITY_TYPES.Trust.key,
      ENTITY_TYPES.SMSF.key
    ]
    if (entityTypesRequiringACN.includes(data.entity.entity_type) && !data.entity.acn) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: CLIENT_MSG.FIELD_REQUIRED,
        path: ["entity.acn"]
      })
    }

    // if (data.bank.bank_branch === "Other" && !data.bank.other) {
    //   ctx.addIssue({
    //     code: z.ZodIssueCode.custom,
    //     message: CLIENT_MSG.FIELD_REQUIRED,
    //     path: ["bank.other"]
    //   })
    // }
  })

type FormSchemaType = z.infer<typeof FormSchema>

interface Props {
  data: any
}

export default function IntroducerForm({ data }: Readonly<Props>) {
  const store = useAdminUserStore()
  const navigate = useNavigate()

  const { ref: ref1, inView: inView1 } = useInView({ threshold: INVIEW_THRESHOLD })
  const { ref: ref2, inView: inView2 } = useInView({ threshold: INVIEW_THRESHOLD })
  // const { ref: ref3, inView: inView3 } = useInView({ threshold: INVIEW_THRESHOLD })

  const form = useForm<FormSchemaType>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      image: null,
      role: "",
      first_name: "",
      last_name: "",
      email: "",
      mobile_number: "",
      is_archived: false,
      is_approved: false,
      is_locked: false,

      entity: {
        entity_type: "",
        entity_name: "",
        abn: "",
        acn: "",
        trust_type: "",
        trust_type_other: "",
        is_gst_registered: false,
        postal_address: "",
        principal_place: "",
        registered_place: "",
        entity_email: "",
        entity_contact_number: "",
        primary_business_activity: "",
        website: undefined,

        street_address: "",
        suburb: "",
        state: "",
        postcode: ""
      }

      // bank: {
      //   account_name: "",
      //   bank_branch: "",
      //   other: "",
      //   bsb: "",
      //   account_number: "",
      //   statement_proof: null
      // }
    }
  })

  const handleCancelClick = () => {
    navigate("/admin/users/list")
  }

  const onSubmit = (values: FormSchemaType) => {
    store.updateAction(data.uuid, values, () => {
      navigate("/admin/users/list")
    })
  }

  useEffect(() => {
    form.reset({
      ...data,

      entity: {
        ...data.entity,
        temp_abn: data.entity?.entity_name,
        website: data.entity?.website ?? undefined,
        trust_type_other: data.entity?.trust_type_other ?? ""
      }

      // bank: {
      //   ...data.bank,
      //   other: data.bank?.other ?? ""
      // }
    })
  }, [data])

  return (
    <FormProvider {...form}>
      <form className="flex flex-col" onSubmit={form.handleSubmit(onSubmit)}>
        <div className="flex w-full rounded-xl border border-[#ECECEC] bg-white p-8">
          <div className="flex w-full flex-1 flex-col gap-5 xl:pr-8">
            <div ref={ref1} id="basic-information">
              <BasicInformation isView={true} />
            </div>

            <div ref={ref2} id="business-setting">
              <BusinessSettingIntroducer isView={true} />
            </div>

            {/* <div ref={ref3} id="business-bank">
              <BusinessBank isView={false} />
            </div> */}
          </div>

          <StickyShortcut
            items={[
              {
                id: "basic-information",
                label: "Basic information",
                inView: inView1
              },
              {
                id: "business-setting",
                label: "Business Setting Information",
                inView: inView2
              }
              // {
              //   id: "business-bank",
              //   label: "Business Bank Information",
              //   inView: inView3
              // }
            ]}
          />
        </div>

        <div className="flex w-full justify-end gap-2 pb-2 pt-6">
          <Button
            type="button"
            className="text-default h-auto border border-[#868194] bg-transparent px-6 py-4 font-semibold hover:bg-transparent"
            onClick={handleCancelClick}
          >
            Cancel
          </Button>

          <LoadingButton loading={store.loading} type="submit" className="px-6 py-4 md:!w-auto">
            Update account
          </LoadingButton>
        </div>
      </form>
    </FormProvider>
  )
}
