import React from "react"

interface Props {
  className?: string
}

export default function IconNotification({ className }: Readonly<Props>) {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 19 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.24701 1.5C5.75201 1.5 3.31601 4.238 3.31601 6.695C3.31601 8.774 2.73901 9.735 2.22901 10.583C1.82001 11.264 1.49701 11.802 1.49701 12.971C1.66401 14.857 2.90901 15.848 9.24701 15.848C15.55 15.848 16.834 14.813 17 12.906C16.997 11.802 16.674 11.264 16.265 10.583C15.755 9.735 15.178 8.774 15.178 6.695C15.178 4.238 12.742 1.5 9.24701 1.5ZM9.24701 17.348C4.57101 17.348 0.345013 17.018 1.33547e-05 13.035C-0.00298665 11.387 0.500013 10.549 0.944013 9.811C1.39301 9.063 1.81601 8.358 1.81601 6.695C1.81601 3.462 4.80201 0 9.24701 0C13.692 0 16.678 3.462 16.678 6.695C16.678 8.358 17.101 9.063 17.55 9.811C17.994 10.549 18.497 11.387 18.497 12.971C18.148 17.018 13.923 17.348 9.24701 17.348Z"
        fill="currentColor"
      />
      <path
        transform="translate(6, 18)"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.19803 3.49998H3.19603C2.07503 3.49898 1.01403 3.00498 0.209027 2.10798C-0.0679728 1.80098 -0.0429728 1.32598 0.265027 1.04998C0.573027 0.771984 1.04703 0.796984 1.32403 1.10598C1.84203 1.68298 2.50703 1.99998 3.19703 1.99998H3.19803C3.89103 1.99998 4.55903 1.68298 5.07803 1.10498C5.35603 0.797984 5.83003 0.772984 6.13703 1.04998C6.44503 1.32698 6.47003 1.80198 6.19303 2.10898C5.38503 3.00598 4.32203 3.49998 3.19803 3.49998Z"
        fill="currentColor"
      />
    </svg>
  )
}
