import React from "react"

import { Navigate, useLocation } from "react-router-dom"

import useAuthStore from "@/stores/useAuthStore"

export default function UnauthenticatedRoute({
  children
}: Readonly<{ children: React.ReactNode }>) {
  const authStore = useAuthStore()
  const location = useLocation()

  if (authStore.user?.uuid && authStore.mfaVerified) {
    return <Navigate to="/business/settings" state={{ from: location }} replace />
  }

  return children
}
