import { useState, useEffect } from "react"

import { io } from "socket.io-client"

import useAuthStore from "@/stores/useAuthStore"

import { WS_EVENT, WS_ROOMS } from "@repo/util/constant"

const useNotifications = () => {
  const authStore = useAuthStore()
  const [notifications, setNotifications] = useState<any[]>([])

  useEffect(() => {
    const socket = io(`${import.meta.env.VITE_API_URL}`, {
      path: "/ws",
      auth: {
        user_uuid: authStore.user?.uuid
      }
    })

    socket.on("connect", () => {
      socket.emit("joinRoom", WS_ROOMS.Notification)
    })

    socket.on(WS_EVENT.NotificationListFromServer, (auth, unreadNotifications) => {
      if (auth.user_uuid === authStore.user?.uuid) {
        setNotifications(unreadNotifications)
      }
    })

    socket.on(WS_EVENT.NotificationOneFromServer, (auth, newNotification) => {
      if (auth.user_uuid === authStore.user?.uuid) {
        setNotifications((prev) => [...prev, newNotification])
      }
    })

    return () => {
      socket.emit("leaveRoom", WS_ROOMS.Notification)
      socket.disconnect()
    }
  }, [])

  return { notifications }
}

export { useNotifications }
