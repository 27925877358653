export const LOADING_TIMEOUT = 500

export const CREATE_MOTION_VARIANT = {
  initial: {
    opacity: 0,
    display: "none"
  },
  animate: {
    opacity: 1,
    display: "block",
    transition: {
      delay: 0.3,
      duration: 0.25
    }
  },
  exit: {
    opacity: 0,
    display: "none",
    transition: {
      duration: 0.25
    }
  }
}
