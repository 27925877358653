import React, { useEffect, useState } from "react"

import { useAdminAppContext } from "@/pages/admin/applications/AdminAppContext"

import { FUNDING_SOLUTIONS } from "@repo/util/constant"

export default function LoanDetailsSummary() {
  const { data } = useAdminAppContext()
  const [component, setComponent] = useState<any>(null)

  useEffect(() => {
    const importComponent = async () => {
      if (data?.funding_solution === FUNDING_SOLUTIONS.ConstructionFinance.key) {
        const module = await import("./ConstructionFinance")
        const { LoanDetailsSummaryConstructionFinance } = module
        setComponent(<LoanDetailsSummaryConstructionFinance />)
      }

      if (data?.funding_solution === FUNDING_SOLUTIONS.AgriculturalBusinessFinance.key) {
        const module = await import("./AgriculturalBusinessFinance")
        const { LoanDetailsSummaryAgriculturalBusinessFinance } = module
        setComponent(<LoanDetailsSummaryAgriculturalBusinessFinance />)
      }

      if (data?.funding_solution === FUNDING_SOLUTIONS.CorporateFinance.key) {
        const module = await import("./CorporateFinance")
        const { LoanDetailsSummaryCorporateFinance } = module
        setComponent(<LoanDetailsSummaryCorporateFinance />)
      }

      if (data?.funding_solution === FUNDING_SOLUTIONS.PreDevelopmentFinance.key) {
        const module = await import("./DevelopmentFinance")
        const { LoanDetailsSummaryDevelopmentFinance } = module
        setComponent(<LoanDetailsSummaryDevelopmentFinance />)
      }

      if (data?.funding_solution === FUNDING_SOLUTIONS.PropertyFinance.key) {
        const module = await import("./PropertyFinance")
        const { LoanDetailsSummaryPropertyFinance } = module
        setComponent(<LoanDetailsSummaryPropertyFinance />)
      }

      if (data?.funding_solution === FUNDING_SOLUTIONS.SMEFinance.key) {
        const module = await import("./SMEFinance")
        const { LoanDetailsSummarySMEFinance } = module
        setComponent(<LoanDetailsSummarySMEFinance />)
      }

      if (data?.funding_solution === FUNDING_SOLUTIONS.SMSFFinance.key) {
        const module = await import("./SMSFFinance")
        const { LoanDetailsSummarySMSFFinance } = module
        setComponent(<LoanDetailsSummarySMSFFinance />)
      }

      if (data?.funding_solution === FUNDING_SOLUTIONS.CashflowFinance.key) {
        const module = await import("./CashflowFinance")
        const { LoanDetailsSummaryCashflowFinance } = module
        setComponent(<LoanDetailsSummaryCashflowFinance />)
      }
    }

    importComponent()
  }, [data])

  return <>{component}</>
}
