import api from "./api"

// Login
const login = (params: any) => api().postForm("/auth/login", params)

// Verify TOTP
const verifyTotp = (params: any) => api().postForm("/auth/verify-totp", params)

// Logout
const logout = () => api().post("/auth/logout")

// Refresh Token
const refreshToken = () => api().post("/auth/refresh-token")

// Register
const register = (params: any) => api().postForm("/auth/register", params)

// Verify Email
const verifyEmail = (params: any) => api().postForm("/auth/verify-email", params)

// Resend Email
const resendEmail = (params: any) => api().postForm("/auth/resend-email", params)

// Verify MFA
const verifyMfa = (params: any) => api().postForm("/auth/verify-mfa", params)

/**
 * Reset Password
 */
// Send Password Reset Link
const sendResetLink = (params: any) => api().postForm("/auth/send-reset-link", params)

// Verify Reset Token
const verifyResetToken = (params: any) => api().postForm("/auth/verify-reset-token", params)

// Reset Password
const resetPassword = (params: any) => api().postForm("/auth/reset-password", params)

/**
 * Update user
 */
const updateProfile = (params: any) => api().putForm("/auth/profile", params)

/**
 * Accept invitation
 */
// Verify Invitation
const verifyInvitation = (params: any) => api().postForm("/auth/verify-invitation", params)

// Set Invitee Password
const setInviteePassword = (params: any) => api().postForm("/auth/set-invitee-password", params)

// Verify Invitee MFA
const verifyInviteeMfa = (params: any) => api().postForm("/auth/verify-invitee-mfa", params)

/**
 * Notifications
 */
// Get notifications
const getNotification = () => api().get("/auth/notification")

// Read notification
const readNotification = (id: number) => api().patch(`/auth/notification/${id.toString()}`)

export default {
  login,
  verifyTotp,
  logout,
  refreshToken,

  register,
  verifyEmail,
  resendEmail,
  verifyMfa,

  sendResetLink,
  verifyResetToken,
  resetPassword,

  verifyInvitation,
  setInviteePassword,
  verifyInviteeMfa,

  updateProfile,

  getNotification,
  readNotification
}
