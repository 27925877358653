import api from "./api"

// Get consent
export const apiGetConsentOnConsent = (consentUuid: string) =>
  api().get(`/application/consents/${consentUuid}`)

// Verify consent
export const apiVerifyConsentOnConsent = (consentUuid: string, smsCode: string) =>
  api().postForm(`/application/consents/${consentUuid}/verify`, { sms_code: smsCode })

// Submit consent
export const apiSubmitConsentOnConsent = (consentUuid: string, params: any) =>
  api().putForm(`/application/consents/${consentUuid}/submit`, params)
