import React from "react"

import clsx from "clsx"

import { IStickyShortcutItem } from "@/types/interface"

interface Props {
  width?: number
  unitHeight?: number
  items?: IStickyShortcutItem[]
  hasWidget?: boolean
  widgetBefore?: React.ReactNode
  widgetAfter?: React.ReactNode
}

export default function StickyShortcut(props: Readonly<Props>) {
  const { width = 320, unitHeight = 20, items = [], hasWidget, widgetBefore, widgetAfter } = props

  return (
    <div className="sticky top-24 hidden xl:inline-block" style={{ width, height: 1 }}>
      {hasWidget && widgetBefore}

      {items.length > 0 && (
        <div
          className="bg-background rounded-xl border p-6"
          style={{ height: 50 + unitHeight * items.length + 16 * (items.length - 1) }}
        >
          <ul>
            {items.map((item) => (
              <li key={item.id} className="mb-4">
                <a
                  className={clsx(
                    "flex items-center gap-2 text-sm",
                    item.inView ? "text-main" : "text-default"
                  )}
                  href={`#${item.id}`}
                >
                  <div
                    className={clsx(
                      "h-2 min-h-2 w-2 min-w-2 rounded-full",
                      item.inView ? "bg-primary" : "bg-[#ECECEC]"
                    )}
                  />
                  {item.label}
                </a>
              </li>
            ))}
          </ul>
        </div>
      )}

      {hasWidget && widgetAfter}
    </div>
  )
}
