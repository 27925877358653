import React from "react"

import { ResponsiveContainer, PieChart, Pie, Cell, Tooltip } from "recharts"

interface Props {
  data: any
}

export default function TypeOfEntities(props: Readonly<Props>) {
  const { data } = props

  return (
    <div className="flex w-full flex-col rounded-2xl border border-[#ECECEC] bg-white">
      <div className="border-b border-b-[#ECECEC] px-4 py-5">
        <h3 className="text-main text-lg font-semibold">Type of Entities</h3>
      </div>

      <div className="flex gap-6 p-5">
        <div className="min-h-40 flex-1">
          <ResponsiveContainer>
            <PieChart>
              <Pie
                data={data}
                innerRadius={60}
                outerRadius={80}
                fill="#8884d8"
                paddingAngle={0}
                nameKey="key"
                dataKey="count"
              >
                {data.map((entry: any) => (
                  <Cell key={entry.key} fill={entry.color.graph} stroke={undefined} />
                ))}
              </Pie>
              <Tooltip />
            </PieChart>
          </ResponsiveContainer>
        </div>

        <div className="flex flex-col justify-center gap-2">
          {data.map((d: any) => (
            <div key={d.key} className="flex justify-between gap-4">
              <div className="flex items-center gap-2">
                <div
                  className="max-h-2.5 min-h-2.5 min-w-2.5 max-w-2.5 rounded-full"
                  style={{ backgroundColor: d.color.graph }}
                />
                <span className="text-default text-[10px] font-normal">{d.key}</span>
              </div>
              <span className="text-default text-[10px] font-normal">{d.count}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
