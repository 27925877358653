import React from "react"

import { Page, Text, View, StyleSheet, Image } from "@react-pdf/renderer"

interface Props {
  data: Record<string, any>
}

export default function Cover(props: Readonly<Props>) {
  const { data } = props

  const styles = StyleSheet.create({
    page: {
      width: "100%",
      height: "100%",
      padding: 64,
      display: "flex",
      flexDirection: "column",
      fontFamily: "Poppins"
    }
  })

  return (
    <Page size="A4" style={styles.page}>
      <View
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "center"
        }}
      >
        <Image src="/logo.png" style={{ width: "50%", marginBottom: 64 }} />

        <Text
          style={{
            fontSize: 24,
            fontWeight: 400
          }}
        >
          {data?.funding_solution} application for
        </Text>

        <Text
          style={{
            fontSize: 24,
            fontWeight: 600,
            marginBottom: 32
          }}
        >
          {data?.borrower.entity_name}
        </Text>

        <Text
          style={{
            fontSize: 24,
            fontWeight: 600,
            textAlign: "center"
          }}
        >
          Application ID #{data?.id?.toString().padStart(10, "0")}
        </Text>
      </View>
    </Page>
  )
}
