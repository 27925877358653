import React from "react"

import { InputMask } from "@react-input/mask"
import clsx from "clsx"
import { useFormContext } from "react-hook-form"

import { AvatarUpload } from "@/components/_uiext"

import { Checkbox } from "@repo/ui/components/ui/checkbox"
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from "@repo/ui/components/ui/form"
import { Input } from "@repo/ui/components/ui/input"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "@repo/ui/components/ui/select"
import { USER_ROLES } from "@repo/util/constant"

interface Props {
  isView?: boolean
}

export default function BasicInformation(props: Readonly<Props>) {
  const { isView = false } = props
  const form = useFormContext()

  return (
    <div className="flex w-full flex-col">
      <h2 className="text-main mb-4 py-4 text-xl font-semibold">Basic information</h2>

      <div className="mb-4">
        <FormField
          control={form.control}
          name="image"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="text-main text-sm font-normal">Image</FormLabel>
              <AvatarUpload
                readOnly={isView}
                placeholder={`${form.watch("first_name")?.[0] ?? ""}${form.watch("last_name")?.[0] ?? ""}`}
                {...field}
              />
              <FormMessage />
            </FormItem>
          )}
        />
      </div>

      <div className="flex w-full flex-col gap-4 md:flex-row md:gap-8">
        <div className="flex-1">
          <FormField
            control={form.control}
            name="role"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-main text-sm font-normal">User type</FormLabel>
                <Select onValueChange={field.onChange} value={field.value} disabled>
                  <SelectTrigger
                    className={clsx(
                      "h-12 w-full text-sm capitalize",
                      field.value ? "text-main" : "text-default"
                    )}
                  >
                    <SelectValue placeholder="Please select" />
                  </SelectTrigger>
                  <SelectContent>
                    {[
                      USER_ROLES.Employee,
                      USER_ROLES.Broker,
                      USER_ROLES.Introducer,
                      USER_ROLES.Enterprise,
                      USER_ROLES.Administrator
                    ].map((r) => (
                      <SelectItem key={r} value={r} className="capitalize">
                        {r}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <div className="hidden flex-1 md:block"></div>
      </div>

      <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
        <div className="flex-1">
          <FormField
            control={form.control}
            name="first_name"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-main text-sm font-normal">First name</FormLabel>
                <Input disabled={isView} autoFocus autoComplete="off" {...field} />
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <div className="flex-1">
          <FormField
            control={form.control}
            name="last_name"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-main text-sm font-normal">Last name</FormLabel>
                <Input disabled={isView} autoComplete="off" {...field} />
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
      </div>

      <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
        <div className="flex-1">
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-main text-sm font-normal">Email address</FormLabel>
                <Input disabled={isView} autoComplete="off" type="email" {...field} />
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <div className="flex-1">
          <FormField
            control={form.control}
            name="mobile_number"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-main text-sm font-normal">Mobile number</FormLabel>
                <InputMask
                  disabled={isView}
                  showMask
                  component={Input}
                  mask="04__ ___ ___"
                  placeholder="04__ ___ ___"
                  replacement={{ _: /\d/ }}
                  ref={field.ref}
                  name={field.name}
                  value={field.value}
                  onChange={field.onChange}
                />
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
      </div>

      <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:items-center md:gap-8">
        <div className="flex-1">
          <FormField
            control={form.control}
            name="is_archived"
            render={({ field }) => (
              <FormItem className="flex items-start space-x-2 space-y-0">
                <FormControl>
                  <Checkbox
                    disabled={isView}
                    checked={field.value}
                    onCheckedChange={(checked) => {
                      field.onChange(checked)
                    }}
                    className="data-[state=checked]:border-primary h-5 w-5 rounded-[4px] border-[#CDCDCD]"
                  />
                </FormControl>
                <div className="flex flex-col">
                  <FormLabel className="text-main text-sm font-normal">Archived</FormLabel>
                  <FormMessage />
                </div>
              </FormItem>
            )}
          />
        </div>

        <div className="flex-1">
          <FormField
            control={form.control}
            name="is_approved"
            render={({ field }) => (
              <FormItem className="flex items-start space-x-2 space-y-0">
                <FormControl>
                  <Checkbox
                    disabled={isView}
                    checked={field.value}
                    onCheckedChange={(checked) => {
                      field.onChange(checked)
                    }}
                    className="data-[state=checked]:border-primary h-5 w-5 rounded-[4px] border-[#CDCDCD]"
                  />
                </FormControl>
                <div className="flex flex-col">
                  <FormLabel className="text-main text-sm font-normal">Approved</FormLabel>
                  <FormMessage />
                </div>
              </FormItem>
            )}
          />
        </div>

        <div className="flex-1">
          <FormField
            control={form.control}
            name="is_locked"
            render={({ field }) => (
              <FormItem className="flex items-start space-x-2 space-y-0">
                <FormControl>
                  <Checkbox
                    disabled={isView}
                    checked={field.value}
                    onCheckedChange={(checked) => {
                      field.onChange(checked)
                    }}
                    className="data-[state=checked]:border-primary h-5 w-5 rounded-[4px] border-[#CDCDCD]"
                  />
                </FormControl>
                <div className="flex flex-col">
                  <FormLabel className="text-main text-sm font-normal">Locked</FormLabel>
                  <FormMessage />
                </div>
              </FormItem>
            )}
          />
        </div>
      </div>
    </div>
  )
}
