import React from "react"

import { useFormContext } from "react-hook-form"

import { Checkbox } from "@repo/ui/components/ui/checkbox"
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from "@repo/ui/components/ui/form"
import { BORROWER_INDIVIDUAL_ROLES } from "@repo/util/constant"

interface Props {
  isView?: boolean
  fieldName: string
  index: number
}

export default function IndividualRoleQuestions(props: Readonly<Props>) {
  const { isView = false, fieldName, index } = props
  const form = useFormContext()

  return (
    <div className="flex w-full flex-col">
      {form.watch(`${fieldName}.${index}.role`) &&
        form.watch(`${fieldName}.${index}.role`) === BORROWER_INDIVIDUAL_ROLES.Shareholder.key && (
          <>
            <h3 className="text-main mb-4 py-4 text-lg font-semibold">Questions</h3>

            <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
              <FormField
                control={form.control}
                name={`${fieldName}.${index}.rq_shareholder_is_beneficial_owner`}
                render={({ field }) => (
                  <FormItem className="flex items-start space-x-2 space-y-0">
                    <FormControl>
                      <Checkbox
                        disabled={isView}
                        checked={field.value}
                        onCheckedChange={(checked) => {
                          field.onChange(checked)
                        }}
                        className="data-[state=checked]:border-primary h-5 w-5 rounded-[4px] border-[#CDCDCD]"
                      />
                    </FormControl>
                    <div className="flex flex-col">
                      <FormLabel className="text-main text-sm font-normal">
                        Is this person a Key Beneficial owner (E.g an Individual who ultimately
                        controls/owns 25% or more of the company)?
                      </FormLabel>
                      <FormMessage />
                    </div>
                  </FormItem>
                )}
              />
            </div>

            <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
              <FormField
                control={form.control}
                name={`${fieldName}.${index}.rq_shareholder_is_exposed_person`}
                render={({ field }) => (
                  <FormItem className="flex items-start space-x-2 space-y-0">
                    <FormControl>
                      <Checkbox
                        disabled={isView}
                        checked={field.value}
                        onCheckedChange={(checked) => {
                          field.onChange(checked)
                        }}
                        className="data-[state=checked]:border-primary h-5 w-5 rounded-[4px] border-[#CDCDCD]"
                      />
                    </FormControl>
                    <div className="flex flex-col">
                      <FormLabel className="text-main text-sm font-normal">
                        Is this Shareholder a Politically exposed person? (Do you or does an
                        immediate family member hold a prominent public or function ina government
                        body or international organisation. E.g, a government minister, high-ranking
                        member of the Armed Forces or a Chairman, CEO or CFO of an international
                        organisation?)
                      </FormLabel>
                      <FormMessage />
                    </div>
                  </FormItem>
                )}
              />
            </div>
          </>
        )}

      {form.watch(`${fieldName}.${index}.role`) &&
        form.watch(`${fieldName}.${index}.role`) === BORROWER_INDIVIDUAL_ROLES.Director.key && (
          <>
            <h3 className="text-main mb-4 py-4 text-lg font-semibold">Questions</h3>

            <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
              <FormField
                control={form.control}
                name={`${fieldName}.${index}.rq_director_is_exposed_person`}
                render={({ field }) => (
                  <FormItem className="flex items-start space-x-2 space-y-0">
                    <FormControl>
                      <Checkbox
                        disabled={isView}
                        checked={field.value}
                        onCheckedChange={(checked) => {
                          field.onChange(checked)
                        }}
                        className="data-[state=checked]:border-primary h-5 w-5 rounded-[4px] border-[#CDCDCD]"
                      />
                    </FormControl>
                    <div className="flex flex-col">
                      <FormLabel className="text-main text-sm font-normal">
                        Is this Director a Politically exposed person? (Do you or does an immediate
                        family member hold a prominent public or function ina government body or
                        international organisation. E.g, a government minister, high-ranking member
                        of the Armed Forces or a Chairman, CEO or CFO of an international
                        organisation?)
                      </FormLabel>
                      <FormMessage />
                    </div>
                  </FormItem>
                )}
              />
            </div>
          </>
        )}

      {form.watch(`${fieldName}.${index}.role`) &&
        form.watch(`${fieldName}.${index}.role`) === BORROWER_INDIVIDUAL_ROLES.Beneficiary.key && (
          <>
            <h3 className="text-main mb-4 py-4 text-lg font-semibold">Questions</h3>

            <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
              <FormField
                control={form.control}
                name={`${fieldName}.${index}.rq_beneficiary_is_exposed_person`}
                render={({ field }) => (
                  <FormItem className="flex items-start space-x-2 space-y-0">
                    <FormControl>
                      <Checkbox
                        disabled={isView}
                        checked={field.value}
                        onCheckedChange={(checked) => {
                          field.onChange(checked)
                        }}
                        className="data-[state=checked]:border-primary h-5 w-5 rounded-[4px] border-[#CDCDCD]"
                      />
                    </FormControl>
                    <div className="flex flex-col">
                      <FormLabel className="text-main text-sm font-normal">
                        Is this Beneficiary a Politically exposed person? (Do you or does an
                        immediate family member hold a prominent public or function ina government
                        body or international organisation. E.g, a government minister, high-ranking
                        member of the Armed Forces or a Chairman, CEO or CFO of an international
                        organisation?)
                      </FormLabel>
                      <FormMessage />
                    </div>
                  </FormItem>
                )}
              />
            </div>
          </>
        )}
    </div>
  )
}
