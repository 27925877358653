import qs from "qs"

import api from "./api"

// Get
export const apiGetApplicationAssigneesList = (applicationUuid: string, params: any) =>
  api().get(`/application/assignees/${applicationUuid}?${qs.stringify(params)}`)

// Assign
export const apiAssignApplication = (applicationUuid: string, params: any) =>
  api().putForm(`/application/assignees/${applicationUuid}`, params)
