import React from "react"

import { Font, Document } from "@react-pdf/renderer"

import PoppinsThin from "@/assets/fonts/Poppins-Thin.ttf"
import PoppinsExtraLight from "@/assets/fonts/Poppins-ExtraLight.ttf"
import PoppinsLight from "@/assets/fonts/Poppins-Light.ttf"
import PoppinsRegular from "@/assets/fonts/Poppins-Regular.ttf"
import PoppinsMedium from "@/assets/fonts/Poppins-Medium.ttf"
import PoppinsSemibold from "@/assets/fonts/Poppins-SemiBold.ttf"
import PoppinsBold from "@/assets/fonts/Poppins-Bold.ttf"
import PoppinsExtraBold from "@/assets/fonts/Poppins-ExtraBold.ttf"
import PoppinsBlack from "@/assets/fonts/Poppins-Black.ttf"

import Cover from "./Cover"
import Content from "./Content"

interface Props {
  data: Record<string, any>
}

export default function AdminApplicationsPdf(props: Readonly<Props>) {
  const { data } = props

  Font.register({
    family: "Poppins",
    fonts: [
      {
        src: PoppinsThin,
        fontWeight: 100
      },
      {
        src: PoppinsExtraLight,
        fontWeight: 200
      },
      {
        src: PoppinsLight,
        fontWeight: 300
      },
      {
        src: PoppinsRegular,
        fontWeight: 400
      },
      {
        src: PoppinsMedium,
        fontWeight: 500
      },
      {
        src: PoppinsSemibold,
        fontWeight: 600
      },
      {
        src: PoppinsBold,
        fontWeight: 700
      },
      {
        src: PoppinsExtraBold,
        fontWeight: 800
      },
      {
        src: PoppinsBlack,
        fontWeight: 900
      }
    ]
  })

  return (
    <Document>
      <Cover data={data} />

      <Content data={data} />
    </Document>
  )
}
