import React from "react"

interface Props {
  className?: string
}

export default function IconAccountSettings({ className }: Readonly<Props>) {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.1956 5.44546L15.6769 4.54538C15.2381 3.78378 14.2657 3.52105 13.503 3.95805V3.95805C13.14 4.1719 12.7068 4.23258 12.299 4.12669C11.8912 4.0208 11.5422 3.75705 11.3291 3.39359C11.192 3.16257 11.1183 2.89944 11.1155 2.63081V2.63081C11.1279 2.20013 10.9654 1.78278 10.6651 1.47384C10.3648 1.1649 9.95218 0.990667 9.52132 0.990845H8.47631C8.0542 0.99084 7.64949 1.15904 7.35173 1.45824C7.05397 1.75743 6.88771 2.16294 6.88974 2.58505V2.58505C6.87723 3.45655 6.16713 4.15646 5.29554 4.15637C5.02691 4.15358 4.76378 4.0799 4.53276 3.94279V3.94279C3.77012 3.50579 2.79766 3.76853 2.35885 4.53013L1.80202 5.44546C1.36374 6.20611 1.6229 7.17795 2.38173 7.61937V7.61937C2.87498 7.90415 3.17884 8.43044 3.17884 9C3.17884 9.56956 2.87498 10.0958 2.38173 10.3806V10.3806C1.62386 10.8191 1.36442 11.7886 1.80202 12.5469V12.5469L2.32833 13.4546C2.53394 13.8256 2.8789 14.0994 3.28689 14.2153C3.69488 14.3313 4.13226 14.2799 4.50225 14.0725V14.0725C4.86596 13.8602 5.29939 13.8021 5.70618 13.9109C6.11297 14.0198 6.45943 14.2867 6.66853 14.6522C6.80564 14.8832 6.87932 15.1463 6.88211 15.415V15.415C6.88211 16.2954 7.59586 17.0092 8.47631 17.0092H9.52132C10.3988 17.0092 11.1113 16.3001 11.1155 15.4226V15.4226C11.1135 14.9991 11.2808 14.5925 11.5802 14.2931C11.8796 13.9936 12.2863 13.8263 12.7097 13.8284C12.9777 13.8355 13.2398 13.9089 13.4725 14.042V14.042C14.2331 14.4802 15.205 14.2211 15.6464 13.4622V13.4622L16.1956 12.5469C16.4082 12.182 16.4665 11.7475 16.3577 11.3394C16.2489 10.9314 15.9819 10.5836 15.6159 10.373V10.373C15.2499 10.1624 14.9829 9.8146 14.8741 9.40657C14.7653 8.99854 14.8236 8.56396 15.0362 8.19908C15.1744 7.95773 15.3745 7.75761 15.6159 7.61937V7.61937C16.3702 7.17819 16.6287 6.21203 16.1956 5.45309V5.45309V5.44546Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />

      <ellipse
        cx="9.00246"
        cy="9.00014"
        rx="2.1968"
        ry="2.1968"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
