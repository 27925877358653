import React, { Dispatch, SetStateAction, useEffect, useState } from "react"

import { LoadingButton } from "@/components/_uiext"
import useApplicationAssigneeStore from "@/stores/useApplicationAssigneeStore"

import { Dialog, DialogContent } from "@repo/ui/components/ui/dialog"
import { Button } from "@repo/ui/components/ui/button"

import AssigneeAutoComplete from "./AssigneeAutoComplete"
import useApplicationStore from "@/stores/useApplicationStore"

interface Props {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  data: any
}

export default function AssignDialog(props: Readonly<Props>) {
  const { open, setOpen, data } = props
  const store = useApplicationAssigneeStore()
  const applicationStore = useApplicationStore()
  const [assignees, setAssignees] = useState<any[]>([])

  const handleCancelClick = () => {
    setOpen(false)
  }

  const handleSaveClick = () => {
    store.assignAction(
      data.uuid,
      {
        assignees: assignees.map((a) => a.uuid)
      },
      () => {
        applicationStore.invertReloadRequired()
        setOpen(false)
      }
    )
  }

  useEffect(() => {
    setAssignees(data.assignees.map((a: any) => a.user))
  }, [open, data])

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent
        className="flex h-full flex-col justify-between gap-0 bg-white p-10 md:h-auto"
        onOpenAutoFocus={(e: any) => e.preventDefault()}
      >
        <div className="mb-4 flex flex-col">
          <h1 className="text-main mb-2.5 text-lg font-semibold">Assign</h1>

          <p className="text-default mb-4 text-sm font-normal">
            Select a colleague you wish to assign to application{" "}
            <span className="text-main">
              #
              {Number(data?.id ?? "")
                .toString()
                .padStart(10, "0")}
            </span>
            {""}.
          </p>

          <AssigneeAutoComplete
            applicationUuid={data?.uuid}
            disabled={false}
            value={assignees}
            onValueChange={(value: any) => setAssignees(value)}
          />
        </div>

        <div className="flex justify-center gap-2.5">
          <Button
            type="button"
            className="text-default h-auto border border-[#868194] bg-transparent px-6 py-4 font-semibold hover:bg-transparent"
            onClick={handleCancelClick}
          >
            Cancel
          </Button>

          <LoadingButton
            loading={store.loading}
            className="w-full px-6 py-4 md:w-auto"
            onClick={handleSaveClick}
          >
            Save
          </LoadingButton>
        </div>
      </DialogContent>
    </Dialog>
  )
}
