import React from "react"

import { FUNDING_SOLUTIONS } from "@repo/util/constant"

import { ContentLoanDetailsConstructionFinance } from "./ConstructionFinance"
import { ContentLoanDetailsAgriculturalBusinessFinance } from "./AgriculturalBusinessFinance"
import { ContentLoanDetailsCorporateFinance } from "./CorporateFinance"
import { ContentLoanDetailsDevelopmentFinance } from "./DevelopmentFinance"
import { ContentLoanDetailsPropertyFinance } from "./PropertyFinance"
import { ContentLoanDetailsSMEFinance } from "./SMEFinance"
import { ContentLoanDetailsSMSFFinance } from "./SMSFFinance"
import { ContentLoanDetailsCashflowFinance } from "./CashflowFinance"

interface Props {
  data: Record<string, any>
}

export default function ContentLoanDetails(props: Readonly<Props>) {
  const { data } = props

  if (data?.funding_solution === FUNDING_SOLUTIONS.ConstructionFinance.key) {
    return <ContentLoanDetailsConstructionFinance data={data} />
  }

  if (data?.funding_solution === FUNDING_SOLUTIONS.AgriculturalBusinessFinance.key) {
    return <ContentLoanDetailsAgriculturalBusinessFinance data={data} />
  }

  if (data?.funding_solution === FUNDING_SOLUTIONS.CorporateFinance.key) {
    return <ContentLoanDetailsCorporateFinance data={data} />
  }

  if (data?.funding_solution === FUNDING_SOLUTIONS.PreDevelopmentFinance.key) {
    return <ContentLoanDetailsDevelopmentFinance data={data} />
  }

  if (data?.funding_solution === FUNDING_SOLUTIONS.PropertyFinance.key) {
    return <ContentLoanDetailsPropertyFinance data={data} />
  }

  if (data?.funding_solution === FUNDING_SOLUTIONS.SMEFinance.key) {
    return <ContentLoanDetailsSMEFinance data={data} />
  }

  if (data?.funding_solution === FUNDING_SOLUTIONS.SMSFFinance.key) {
    return <ContentLoanDetailsSMSFFinance data={data} />
  }

  if (data?.funding_solution === FUNDING_SOLUTIONS.CashflowFinance.key) {
    return <ContentLoanDetailsCashflowFinance data={data} />
  }

  return null
}
