import React from "react"

import { Text, View } from "@react-pdf/renderer"

interface Props {
  data: Record<string, any>
}

export function ContentSecurityDetailsCorporateFinance(props: Readonly<Props>) {
  const { data } = props

  return (
    <View style={{ display: "flex", flexDirection: "column" }} wrap={true}>
      <Text
        style={{
          color: "#0E052B",
          fontWeight: 600,
          fontSize: 14,
          borderBottom: "1px solid #CDCDCD"
        }}
      >
        6. Security Details
      </Text>
    </View>
  )
}
