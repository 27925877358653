import React, { useEffect, useRef, useState } from "react"

import { Link, useParams } from "react-router-dom"

import ApplicationLayout from "@/components/_layout/ApplicationLayout"
import useApplicationStore from "@/stores/useApplicationStore"

import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator
} from "@repo/ui/components/ui/breadcrumb"
import { APPLICATION_STEPS, FUNDING_SOLUTIONS } from "@repo/util/constant"

import MainForm from "./MainForm"

import { AppContextProvider } from "../AppContext"

export default function ApplicationsEdit() {
  const { uuid } = useParams()
  const initialized = useRef(false)
  const store = useApplicationStore()

  const [initialSteps, setInitialSteps] = useState(
    Object.entries(APPLICATION_STEPS).map(([k, v]) => {
      return v
    })
  )
  const [initialActiveStep, setInitialActiveStep] = useState(0)
  const [initialLoading, setInitialLoading] = useState(true)
  const [initialData, setInitialData] = useState<any>(null)

  const initialize = () => {
    setInitialLoading(true)
    store.getOneByUuidAction(uuid ?? "", (newData) => {
      setInitialData(newData)

      let newInitialSteps = Object.entries(APPLICATION_STEPS).map(([k, v]) => v)
      if (
        newData?.funding_solution === FUNDING_SOLUTIONS.CashflowFinance.key ||
        newData?.funding_solution === FUNDING_SOLUTIONS.CorporateFinance.key
      ) {
        newInitialSteps = newInitialSteps.filter((s) => s !== APPLICATION_STEPS.SecurityDetails)
      }
      setInitialSteps(newInitialSteps)

      const activeStep = newInitialSteps.indexOf(newData.current_step ?? "")
      setInitialActiveStep(activeStep === -1 ? 0 : activeStep)

      setTimeout(() => {
        setInitialLoading(false)
      }, 1000)
    })
  }

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true
      initialize()
    }
  }, [])

  return (
    <AppContextProvider
      initialSteps={initialSteps}
      initialActiveStep={initialActiveStep}
      initialLoading={initialLoading}
      initialData={initialData}
    >
      <ApplicationLayout
        breadcrumb={
          <Breadcrumb>
            <BreadcrumbList>
              <BreadcrumbItem>
                <Link to="/dashboard" className="text-default text-sm font-normal">
                  Dashboard
                </Link>
              </BreadcrumbItem>
              <BreadcrumbSeparator />
              <BreadcrumbItem>
                <Link to="/applications/list" className="text-default text-sm font-normal">
                  My Applications
                </Link>
              </BreadcrumbItem>
              <BreadcrumbSeparator />
              <BreadcrumbItem>
                <BreadcrumbPage className="text-main text-sm font-semibold">
                  {initialData?.id.toString().padStart(10, "0")}
                </BreadcrumbPage>
              </BreadcrumbItem>
            </BreadcrumbList>
          </Breadcrumb>
        }
      >
        <div className="flex flex-col">
          <div className="mb-4 block w-full rounded-xl border border-[#ECECEC] bg-white p-8 lg:hidden">
            <h3 className="text-main text-lg font-semibold">Create Application</h3>
          </div>

          <MainForm />
        </div>
      </ApplicationLayout>
    </AppContextProvider>
  )
}
