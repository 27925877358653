import React, { forwardRef, useCallback, useEffect, useState } from "react"

import clsx from "clsx"
import { filesize } from "filesize"
import { useDropzone } from "react-dropzone"
import { LuTrash2 } from "react-icons/lu"

import { IconDocument } from "../_icons"

import { Button } from "@repo/ui/components/ui/button"

interface Props {
  title?: string
  name?: string
  value?: File[]
  onChange?: Function
  readOnly?: boolean
  uploadedFiles?: any[]
  onRemoveUploadedFile?: Function
}

function LenderFileUpload(
  { title, name, value, onChange, readOnly, uploadedFiles = [], onRemoveUploadedFile }: Props,
  ref: any
) {
  const [files, setFiles] = useState<File[]>([])

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (typeof onChange === "function") {
        onChange([...files, ...acceptedFiles])
      }

      setFiles((prev) => [...prev, ...acceptedFiles])
    },
    [files, onChange]
  )

  const { getRootProps, getInputProps } = useDropzone({
    maxSize: 5 * 1024 * 1024,
    onDrop
  })

  useEffect(() => {
    setFiles(value ?? [])
  }, [value])

  return (
    <div className="flex flex-col gap-4">
      <input {...getInputProps()} />

      <div className="mb-4 flex w-full items-center justify-between">
        <h3 className="text-main text-lg font-semibold">{title ?? ""}</h3>

        <Button
          type="button"
          className={clsx(
            "text-default h-auto border border-[#868194] bg-transparent px-6 py-4 font-semibold hover:bg-transparent",
            readOnly ? "hidden" : ""
          )}
          {...(readOnly ? {} : getRootProps())}
        >
          Upload file
        </Button>
      </div>

      {files.map((f, index) => (
        <div
          key={f.name}
          className="flex items-center justify-between gap-4 rounded-2xl border p-4"
        >
          <div className="flex min-h-12 min-w-12 items-center justify-center rounded-full bg-[#CDCDCD]">
            <IconDocument className="text-xl text-white" />
          </div>

          <div className="flex flex-1 flex-col gap-1">
            <p className="text-main break-all text-sm">{f.name}</p>
            <p className="text-default text-sm">{filesize(f.size)}</p>
          </div>

          {!readOnly && (
            <LuTrash2
              className="text-destructive cursor-pointer text-2xl"
              onClick={() => {
                if (typeof onChange === "function") {
                  onChange(files.filter((_, i) => i !== index))
                }
                setFiles((prev) => prev.filter((_, i) => i !== index))
              }}
            />
          )}
        </div>
      ))}

      {uploadedFiles.map((uf, index) => (
        <div
          key={uf.uuid}
          className="flex items-center justify-between gap-4 rounded-2xl border p-4"
        >
          <div className="bg-primary flex min-h-12 min-w-12 items-center justify-center rounded-full">
            <IconDocument className="text-xl text-white" />
          </div>

          <div className="flex flex-1 flex-col gap-1">
            <p className="text-main break-all text-sm">{uf.name}</p>
            <p className="text-default text-sm">{filesize(uf.size)}</p>
          </div>

          {!readOnly && (
            <LuTrash2
              className="text-destructive cursor-pointer text-2xl"
              onClick={() => {
                if (typeof onRemoveUploadedFile === "function") {
                  onRemoveUploadedFile(uf.uuid)
                }
              }}
            />
          )}
        </div>
      ))}
    </div>
  )
}

export default forwardRef(LenderFileUpload)
