import React from "react"

interface Props {
  className?: string
}

export default function IconUsers({ className }: Readonly<Props>) {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.59151 13.207C11.2805 13.207 14.4335 13.766 14.4335 15.999C14.4335 18.232 11.3015 18.807 7.59151 18.807C3.90151 18.807 0.749512 18.253 0.749512 16.019C0.749512 13.785 3.88051 13.207 7.59151 13.207Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.59157 10.02C5.16957 10.02 3.20557 8.057 3.20557 5.635C3.20557 3.213 5.16957 1.25 7.59157 1.25C10.0126 1.25 11.9766 3.213 11.9766 5.635C11.9856 8.048 10.0356 10.011 7.62257 10.02H7.59157Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.4829 8.88128C16.0839 8.65628 17.3169 7.28228 17.3199 5.61928C17.3199 3.98028 16.1249 2.62028 14.5579 2.36328"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.5952 12.7324C18.1462 12.9634 19.2292 13.5074 19.2292 14.6274C19.2292 15.3984 18.7192 15.8984 17.8952 16.2114"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
