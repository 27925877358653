import { toast } from "sonner"
import { create } from "zustand"

import { apiGetDashboard } from "@/api/dashboardApi"

import { CLIENT_MSG } from "@repo/i18n"

interface IDashboardState {
  loading: boolean
  reloadRequired: boolean

  invertReloadRequired: () => void

  getAction: (params: any, onSuccessCallback?: (data: any) => void) => void
}

const useDashboardStore = create<IDashboardState>()((set, get) => ({
  loading: false,
  reloadRequired: false,

  invertReloadRequired: () => set({ reloadRequired: !get().reloadRequired }),

  getAction: async (params, onSuccessCallback) => {
    try {
      set({ loading: true })

      const response = await apiGetDashboard(params)

      if (response?.data) {
        const { data } = response.data

        if (typeof onSuccessCallback === "function") {
          onSuccessCallback(data)
        }
      }
    } catch (err: any) {
      console.error(err)

      if (err?.response?.data?.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error(CLIENT_MSG.SOMETHING_WENT_WRONG)
      }
    } finally {
      set({ loading: false })
    }
  }
}))

export default useDashboardStore
