import React from "react"

import { Text, View } from "@react-pdf/renderer"
import { format } from "date-fns"
import numeral from "numeral"

interface Props {
  data: Record<string, any>
}

export function ContentLoanDetailsCashflowFinance(props: Readonly<Props>) {
  const { data } = props

  return (
    <View style={{ display: "flex", flexDirection: "column" }} wrap={true}>
      <Text
        style={{
          color: "#0E052B",
          fontWeight: 600,
          fontSize: 14,
          borderBottom: "1px solid #CDCDCD"
        }}
      >
        5. Loan Details
      </Text>

      <View
        style={{
          paddingLeft: 32,
          paddingVertical: 16,
          display: "flex",
          flexDirection: "column",
          gap: 8
        }}
      >
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 16
          }}
        >
          <Text style={{ color: "#868194", fontWeight: 400, fontSize: 12, flex: 1 }}>
            Annual Turnover Range
          </Text>
          <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 12, flex: 1 }}>
            {numeral(data?.turnover_range?.split("-")?.[0]).format("$ 0,0[.]00")} -{" "}
            {Number(data?.turnover_range?.split("-")?.[1]) !== 0 &&
              numeral(data?.turnover_range?.split("-")?.[1]).format("$ 0,0")}
          </Text>
        </View>

        <View
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 16
          }}
        >
          <Text style={{ color: "#868194", fontWeight: 400, fontSize: 12, flex: 1 }}>
            Industry sector
          </Text>
          <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 12, flex: 1 }}>
            {data?.industry_sector}
          </Text>
        </View>

        <View
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 16
          }}
        >
          <Text style={{ color: "#868194", fontWeight: 400, fontSize: 12, flex: 1 }}>
            When do you require the loan by?
          </Text>
          <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 12, flex: 1 }}>
            {format(data?.loan_by, "dd/MM/yyyy")}
          </Text>
        </View>

        <View
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 16
          }}
        >
          <Text style={{ color: "#868194", fontWeight: 400, fontSize: 12, flex: 1 }}>
            Loan summary
          </Text>
          <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 12, flex: 1 }}>
            {data?.loan_summary}
          </Text>
        </View>
      </View>
    </View>
  )
}
