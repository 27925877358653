import React, { useEffect, useState } from "react"

import { FUNDING_SOLUTIONS } from "@repo/util/constant"

import { useAdminAppContext } from "../../AdminAppContext"

export default function SecurityDetails() {
  const { data } = useAdminAppContext()
  const [component, setComponent] = useState<any>(null)

  useEffect(() => {
    const importComponent = async () => {
      if (data.funding_solution === FUNDING_SOLUTIONS.ConstructionFinance.key) {
        const module = await import("./ConstructionFinance")
        const { SecurityDetailsConstructionFinance } = module
        setComponent(<SecurityDetailsConstructionFinance />)
      }

      if (data.funding_solution === FUNDING_SOLUTIONS.AgriculturalBusinessFinance.key) {
        const module = await import("./AgriculturalBusinessFinance")
        const { SecurityDetailsAgriculturalBusinessFinance } = module
        setComponent(<SecurityDetailsAgriculturalBusinessFinance />)
      }

      if (data.funding_solution === FUNDING_SOLUTIONS.PreDevelopmentFinance.key) {
        const module = await import("./DevelopmentFinance")
        const { SecurityDetailsDevelopmentFinance } = module
        setComponent(<SecurityDetailsDevelopmentFinance />)
      }

      if (data.funding_solution === FUNDING_SOLUTIONS.PropertyFinance.key) {
        const module = await import("./PropertyFinance")
        const { SecurityDetailsPropertyFinance } = module
        setComponent(<SecurityDetailsPropertyFinance />)
      }

      if (data.funding_solution === FUNDING_SOLUTIONS.SMEFinance.key) {
        const module = await import("./SMEFinance")
        const { SecurityDetailsSMEFinance } = module
        setComponent(<SecurityDetailsSMEFinance />)
      }

      if (data.funding_solution === FUNDING_SOLUTIONS.SMSFFinance.key) {
        const module = await import("./SMSFFinance")
        const { SecurityDetailsSMSFFinance } = module
        setComponent(<SecurityDetailsSMSFFinance />)
      }
    }

    importComponent()
  }, [data])

  return <>{component}</>
}
