import React, { useEffect, useRef } from "react"

import { zodResolver } from "@hookform/resolvers/zod"
import { FormProvider, useForm } from "react-hook-form"
import { useInView } from "react-intersection-observer"
import { useNavigate } from "react-router-dom"
import * as z from "zod"

import { LoadingButton, StickyShortcut } from "@/components/_uiext"
import useAuthStore from "@/stores/useAuthStore"
import useBusinessSettingStore from "@/stores/useBusinessSettingStore"
import { INVIEW_THRESHOLD } from "@/utils/constants"

import { Button } from "@repo/ui/components/ui/button"
import { CLIENT_MSG } from "@repo/i18n"
import { ENTITY_TYPES } from "@repo/util/constant"

import EntityDetails from "./sections/EntityDetails"
// import BusinessAddress from "./sections/BusinessAddress"

const FormSchema = z
  .object({
    entity_type: z.string().min(1, { message: CLIENT_MSG.FIELD_REQUIRED }),
    temp_abn: z
      .string({ required_error: CLIENT_MSG.FIELD_REQUIRED })
      .min(1, { message: CLIENT_MSG.FIELD_REQUIRED }),
    entity_name: z.string().min(1, { message: CLIENT_MSG.FIELD_REQUIRED }),
    abn: z.string().min(1, { message: CLIENT_MSG.FIELD_REQUIRED }),
    acn: z.string(),
    trust_type: z.string(),
    trust_type_other: z.string(),
    is_gst_registered: z.boolean().optional().default(false),
    postal_address: z.string(),
    principal_place: z.string().min(1, { message: CLIENT_MSG.FIELD_REQUIRED }),
    registered_place: z.string().min(1, { message: CLIENT_MSG.FIELD_REQUIRED }),
    entity_email: z.string().min(1, { message: CLIENT_MSG.FIELD_REQUIRED }).email(),
    entity_contact_number: z.string().min(1, { message: CLIENT_MSG.FIELD_REQUIRED }),
    primary_business_activity: z.string().min(1, { message: CLIENT_MSG.FIELD_REQUIRED }),
    website: z.string().url().optional()

    // street_address: z.string().min(1, { message: CLIENT_MSG.FIELD_REQUIRED }),
    // suburb: z.string().min(1, { message: CLIENT_MSG.FIELD_REQUIRED }),
    // state: z.string().min(1, { message: CLIENT_MSG.FIELD_REQUIRED }),
    // postcode: z.string().min(1, { message: CLIENT_MSG.FIELD_REQUIRED })
  })
  .superRefine((data: any, ctx) => {
    const entityTypesRequiringACN = [
      ENTITY_TYPES.Company.key,
      ENTITY_TYPES.Trust.key,
      ENTITY_TYPES.SMSF.key
    ]
    if (entityTypesRequiringACN.includes(data.entity_type) && !data.acn) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: CLIENT_MSG.FIELD_REQUIRED,
        path: ["acn"]
      })
    }
  })

type FormSchemaType = z.infer<typeof FormSchema>

export default function IntroducerForm() {
  const authStore = useAuthStore()
  const store = useBusinessSettingStore()

  const navigate = useNavigate()
  const initialized = useRef(false)

  const { ref: ref1, inView: inView1 } = useInView({ threshold: INVIEW_THRESHOLD })
  // const { ref: ref2, inView: inView2 } = useInView({ threshold: INVIEW_THRESHOLD })

  const form = useForm<FormSchemaType>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      entity_type: "",
      entity_name: "",
      abn: "",
      acn: "",
      trust_type: "",
      trust_type_other: "",
      is_gst_registered: false,
      postal_address: "",
      principal_place: "",
      registered_place: "",
      entity_email: "",
      entity_contact_number: "",
      primary_business_activity: "",
      website: undefined

      // street_address: "",
      // suburb: "",
      // state: "",
      // postcode: ""
    }
  })

  const initialize = () => {
    store.getAction((data) => {
      form.reset({
        entity_type: data.entity_type ?? "",
        temp_abn: data.entity_name ?? "",
        entity_name: data.entity_name ?? "",
        abn: data.abn ?? "",
        acn: data.acn ?? "",
        trust_type: data.trust_type ?? "",
        trust_type_other: data.trust_type_other ?? "",
        is_gst_registered: data.is_gst_registered ?? false,
        postal_address: data.postal_address ?? "",
        principal_place: data.principal_place ?? "",
        registered_place: data.registered_place ?? "",
        entity_email: data.entity_email ?? "",
        entity_contact_number: data.entity_contact_number ?? "",
        primary_business_activity: data.primary_business_activity ?? "",
        website: data.website ?? undefined

        // street_address: data.street_address ?? "",
        // suburb: data.suburb ?? "",
        // state: data.state ?? "",
        // postcode: data.postcode ?? ""
      })
    })
  }

  const handleCancelClick = () => {
    navigate("/dashboard")
  }

  const onSubmit = (values: FormSchemaType) => {
    store.updateAction(values, () => {
      authStore.lockAction()
      authStore.approveAction()
    })
  }

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true
      initialize()
    }
  }, [])

  return (
    <div className="flex flex-col">
      <div className="flex w-full rounded-xl border border-[#ECECEC] bg-white p-8">
        <div className="flex w-full flex-1 flex-col gap-5 xl:pr-8">
          <FormProvider {...form}>
            <form className="flex w-full flex-col gap-5" onSubmit={form.handleSubmit(onSubmit)}>
              <div ref={ref1} id="entity-details">
                <EntityDetails isView={authStore.user?.is_locked} />
              </div>

              {/* <div ref={ref2} id="business-address">
                <BusinessAddress isView={authStore.user?.is_locked} />
              </div> */}
            </form>
          </FormProvider>
        </div>

        <StickyShortcut
          items={[
            {
              id: "entity-details",
              label: "Entity Details",
              inView: inView1
            }
            // {
            //   id: "business-address",
            //   label: "Business Address",
            //   inView: inView2
            // }
          ]}
        />
      </div>

      <div className="flex w-full justify-end gap-2 pb-2 pt-6">
        <Button
          type="button"
          className="text-default h-auto border border-[#868194] bg-transparent px-6 py-4 font-semibold hover:bg-transparent"
          onClick={handleCancelClick}
        >
          Cancel
        </Button>

        <LoadingButton
          loading={store.loading}
          type="button"
          className="px-6 py-4 md:!w-auto"
          onClick={form.handleSubmit(onSubmit)}
        >
          Save
        </LoadingButton>
      </div>
    </div>
  )
}
