import { useEffect, useRef } from "react"

import { useNavigate, useParams } from "react-router-dom"

import useSignupStore from "@/stores/useSignupStore"

export default function EmailVerify() {
  const store = useSignupStore()
  const initialized = useRef(false)
  const navigate = useNavigate()
  let { token } = useParams()

  const verifyEmail = () => {
    store.verifyEmailAction(
      { token: token ?? "" },
      () => {
        navigate("/signup")
      },
      () => {
        navigate("/login")
      }
    )
  }

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true
      verifyEmail()
    }
  }, [token])

  return null
}
