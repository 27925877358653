import React, { useState } from "react"

import { zodResolver } from "@hookform/resolvers/zod"
import { InputMask } from "@react-input/mask"
import clsx from "clsx"
import { useForm } from "react-hook-form"
import * as z from "zod"

import { IconCreateApplication } from "@/components/_icons"
import useLeadStore from "@/stores/useLeadStore"
import { LoadingButton } from "@/components/_uiext"

import { Button } from "@repo/ui/components/ui/button"
import { Dialog, DialogContent, DialogTrigger } from "@repo/ui/components/ui/dialog"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from "@repo/ui/components/ui/form"
import { Input } from "@repo/ui/components/ui/input"
import { Textarea } from "@repo/ui/components/ui/textarea"
import { Checkbox } from "@repo/ui/components/ui/checkbox"
import { SITE_TITLE } from "@repo/util/constant"
import { CLIENT_MSG } from "@repo/i18n"

const FormSchema = z.object({
  full_name: z.string().min(1, { message: CLIENT_MSG.FIELD_REQUIRED }),
  mobile_number: z.string().min(1, { message: CLIENT_MSG.FIELD_REQUIRED }),
  email: z.string().email({ message: CLIENT_MSG.FIELD_REQUIRED }),
  requirement: z.string().min(1, { message: CLIENT_MSG.FIELD_REQUIRED }),
  is_agreed: z
    .boolean({
      invalid_type_error: CLIENT_MSG.FIELD_REQUIRED,
      required_error: CLIENT_MSG.FIELD_REQUIRED
    })
    .refine((val) => val === true, {
      message: CLIENT_MSG.FIELD_REQUIRED
    })
})

type FormSchemaType = z.infer<typeof FormSchema>

interface Props {
  type: "button" | "link"
  disabled?: boolean
}

export default function NewLeadDialog(props: Readonly<Props>) {
  const { type, disabled = false } = props
  const store = useLeadStore()
  const [open, setOpen] = useState(false)

  const form = useForm<FormSchemaType>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      full_name: "",
      mobile_number: "",
      email: "",
      requirement: "",
      is_agreed: false
    }
  })

  const onSubmit = (values: FormSchemaType) => {
    store.createAction(values, () => {
      form.reset()
      setOpen(false)
    })
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      {type === "button" && (
        <DialogTrigger disabled={disabled} asChild>
          <Button className="h-auto w-full rounded-xl py-4 text-sm font-semibold text-white">
            <IconCreateApplication className="mr-1 text-2xl" /> Add new lead
          </Button>
        </DialogTrigger>
      )}

      {type === "link" && (
        <DialogTrigger disabled={disabled}>
          <div
            className={clsx(
              "flex cursor-pointer items-center gap-1 py-1 text-sm",
              disabled && "cursor-auto opacity-50"
            )}
          >
            <IconCreateApplication className="text-xl" />
            Add New Lead
          </div>
        </DialogTrigger>
      )}

      <DialogContent className="flex h-full flex-col gap-0 bg-white p-10 md:h-auto">
        <Form {...form}>
          <form className="flex flex-col" onSubmit={form.handleSubmit(onSubmit)}>
            <div className="text-main mb-4 text-center text-lg font-semibold">Add new lead</div>

            <p className="text-default mb-6 text-center text-base font-normal">
              Please enter the details of your lead.
            </p>

            <div className="mb-6">
              <FormField
                control={form.control}
                name="full_name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-main mb-2 text-sm font-normal">Full Name</FormLabel>
                    <Input autoFocus autoComplete="off" {...field} />
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <div className="mb-6">
              <FormField
                control={form.control}
                name="mobile_number"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-main mb-2 text-sm font-normal">
                      Mobile Number
                    </FormLabel>
                    <InputMask
                      showMask
                      component={Input}
                      mask="04__ ___ ___"
                      placeholder="04__ ___ ___"
                      replacement={{ _: /\d/ }}
                      ref={field.ref}
                      name={field.name}
                      value={field.value}
                      onChange={field.onChange}
                    />
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <div className="mb-6">
              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-main mb-2 text-sm font-normal">
                      Email Address
                    </FormLabel>
                    <Input autoComplete="off" type="email" {...field} />
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <div className="mb-6">
              <FormField
                control={form.control}
                name="requirement"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-main mb-2 text-sm font-normal">
                      Tell us about your leads requirement
                    </FormLabel>
                    <Textarea className="text-main" {...field} />
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <div className="mb-6">
              <FormField
                control={form.control}
                name="is_agreed"
                render={({ field }) => (
                  <FormItem className="flex items-start space-x-2 space-y-0">
                    <FormControl>
                      <Checkbox
                        checked={field.value}
                        onCheckedChange={(checked) => {
                          field.onChange(checked)
                        }}
                        className="data-[state=checked]:border-primary h-5 w-5 rounded-[4px] border-[#CDCDCD]"
                      />
                    </FormControl>
                    <div className="flex flex-col">
                      <FormLabel className="text-main text-sm font-normal">
                        I agree to a phone call introduction with the borrower and a {SITE_TITLE}{" "}
                        representative.
                      </FormLabel>
                      <FormMessage />
                    </div>
                  </FormItem>
                )}
              />
            </div>

            <div className="text-center">
              <LoadingButton loading={store.loading} type="submit" className="px-4 py-4 md:!w-auto">
                Submit new lead
              </LoadingButton>
            </div>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
}
