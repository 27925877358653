import * as z from "zod"

import { CLIENT_MSG } from "@repo/i18n"

export const LenderSchema = z.object({
  lender_name: z.string().min(1, { message: CLIENT_MSG.FIELD_REQUIRED }),
  lender_number: z.string().min(1, { message: CLIENT_MSG.FIELD_REQUIRED }),
  bdm_full_name: z.string().min(1, { message: CLIENT_MSG.FIELD_REQUIRED }),
  bdm_email: z.string().email({ message: CLIENT_MSG.FIELD_REQUIRED }),
  bdm_mobile_number: z.string().min(1, { message: CLIENT_MSG.FIELD_REQUIRED }),
  website: z.string().url({ message: CLIENT_MSG.FIELD_REQUIRED }).optional().or(z.literal("")),

  funding_solutions: z.array(z.string()).default([]),

  fs: z.array(
    z.object({
      funding_solution: z.string().min(1, { message: "Funding solution is required" }),

      loan_types: z
        .array(z.string())
        .min(1, { message: "Funding solution should contain at least 1 loan type" }),

      lt: z.array(
        z.object({
          loan_type: z.string().min(1, { message: "Loan type is required" }),

          applicant_types: z
            .array(z.string(), {
              required_error: "Loan type should contain at least 1 applicant type"
            })
            .min(1, { message: "Loan type should contain at least 1 applicant type" }),
          loan_document_types: z
            .array(z.string(), {
              required_error: "Loan type should contain at least 1 loan document type"
            })
            .min(1, { message: "Loan type should contain at least 1 loan document type" }),

          minimum_loan_size: z.coerce.number(),
          maximum_loan_size: z.coerce.number(),
          is_higher_loan_amount: z.boolean().optional().default(false),

          minimum_loan_term: z.string({ required_error: "Minimum loan term is required" }),
          maximum_loan_term: z.string({ required_error: "Maximum loan term is required" }),
          loan_value_ratio: z.coerce.number(),

          // Construction finance
          nsd: z.array(z.string()).optional(),
          sd: z.array(z.string()).optional(),

          // Development finance | Property finance | SME finance | SMSF finance
          securities: z.array(z.string()).optional(),

          // Cashflow finance
          turnover_ranges: z
            .array(
              z.object({
                min: z.coerce.number().optional().default(0),
                max: z.coerce.number().optional().default(0),
                checked: z.coerce.boolean().optional().default(false)
              })
            )
            .optional(),

          // Agricultural business finance
          commodity_types: z.array(z.string()).optional(),

          // Cashflow finance | Corporate finance
          industry_sectors: z.array(z.string()).optional(),

          // Development finance
          loan_purposes: z.array(z.string()).optional(),

          // Cashflow finance
          // max_invoice_financed: z.coerce.number().optional(),

          countries: z
            .array(z.string(), {
              required_error: "Loan type should contain at least 1 country"
            })
            .min(1, { message: "Loan type should contain at least 1 country" }),

          states: z
            .array(z.string(), {
              required_error: "Loan type should contain at least 1 state"
            })
            .min(1, { message: "Loan type should contain at least 1 state" }),

          regions: z
            .array(z.string(), {
              required_error: "Loan type should contain at least 1 region"
            })
            .min(1, { message: "Loan type should contain at least 1 region" }),

          exit_strategies: z
            .array(z.string())
            .min(1, { message: "Loan type should contain at least 1 exit strategy" }),
          repayments: z
            .array(z.string(), {
              required_error: "Loan type should contain at least 1 repayment option"
            })
            .min(1, { message: "Loan type should contain at least 1 repayment option" }),

          establishment_fee: z.coerce.number(),
          interest_rate: z.coerce.number(),

          mortgage_management_fee: z.boolean().optional().default(false),
          line_fee: z.boolean().optional().default(false),
          pre_sales_requirement: z.boolean().optional().default(false),

          // SMSF finance
          net_assets_test: z.boolean().optional().default(false),
          liquidity_test: z.boolean().optional().default(false),
          discharge_fees: z.boolean().optional().default(false),
          loan_administration_fees: z.boolean().optional().default(false),
          monthly_account_keeping_fee: z.boolean().optional().default(false),

          checkitems: z
            .array(
              z.object({
                section: z.string().min(1, { message: "Section is required" }),
                items: z
                  .array(
                    z.object({
                      type: z.string().min(1, { message: "Type is required" }),
                      text: z.string().min(1, { message: "Text is required" })
                    })
                  )
                  .default([])
              })
            )
            .default([])
        })
      )
    })
  ),

  documents: z.array(z.any()).optional(),
  uploaded_documents: z.array(z.any()).optional(),
  removed_documents: z.array(z.string()).optional()
})

export type LenderSchemaType = z.infer<typeof LenderSchema>
