import React from "react"

interface Props {
  className?: string
}

export default function IconLogOut({ className }: Readonly<Props>) {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.5273 7.0804H6.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5762 5.13647L14.5282 7.08047L12.5762 9.02447"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.9067 4.08659C10.6867 1.69992 9.7934 0.833252 6.24007 0.833252C1.50607 0.833252 1.50607 2.37325 1.50607 6.99992C1.50607 11.6266 1.50607 13.1666 6.24007 13.1666C9.7934 13.1666 10.6867 12.2999 10.9067 9.91325"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
