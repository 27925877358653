import React from "react"

interface Props {
  className?: string
}

export default function IconTag({ className }: Readonly<Props>) {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.46938 12.9694C4.32899 12.8288 4.25009 12.6383 4.25 12.4397V3.75H12.9397C13.1383 3.75009 13.3288 3.82899 13.4694 3.96938L22.7806 13.2806C22.9212 13.4213 23.0001 13.612 23.0001 13.8108C23.0001 14.0096 22.9212 14.2003 22.7806 14.3409L14.8438 22.2806C14.7031 22.4212 14.5124 22.5001 14.3136 22.5001C14.1148 22.5001 13.9241 22.4212 13.7834 22.2806L4.46938 12.9694Z"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.375 9C8.99632 9 9.5 8.49632 9.5 7.875C9.5 7.25368 8.99632 6.75 8.375 6.75C7.75368 6.75 7.25 7.25368 7.25 7.875C7.25 8.49632 7.75368 9 8.375 9Z"
        fill="currentColor"
      />
    </svg>
  )
}
