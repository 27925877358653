import React, { Dispatch, SetStateAction } from "react"

import { ITableParams } from "@/types/interface"

import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "@repo/ui/components/ui/select"

import FilterPopover from "./FilterPopover"
import SortPopover from "./SortPopover"

interface Props {
  tableParams: ITableParams
  setTableParams: Dispatch<SetStateAction<ITableParams>>
}

export default function Header({ tableParams, setTableParams }: Readonly<Props>) {
  return (
    <div className="flex flex-col gap-4 rounded-t-xl border border-[#ECECEC] bg-white p-4 md:flex-row md:items-center md:justify-between">
      <div className="flex flex-col justify-between gap-2.5 md:flex-row">
        <h3 className="text-main text-lg font-semibold">Users</h3>

        <div className="flex flex-col gap-2 md:flex-row">
          <Select
            value={tableParams.filter.is_archived?.toString()}
            onValueChange={(value) =>
              setTableParams((prev: ITableParams) => ({
                ...prev,
                filter: {
                  ...prev.filter,
                  is_archived: value
                }
              }))
            }
          >
            <SelectTrigger className="h-auto w-full rounded-full border-[#868194] bg-transparent bg-white px-4 py-1.5 text-sm md:w-32">
              <SelectValue />
            </SelectTrigger>
            <SelectContent className="w-full md:w-32">
              <SelectGroup>
                <SelectItem value="all">All</SelectItem>
                <SelectItem value="false">Restored</SelectItem>
                <SelectItem value="true">Archived</SelectItem>
              </SelectGroup>
            </SelectContent>
          </Select>

          <Select
            value={tableParams.filter.is_approved?.toString()}
            onValueChange={(value) =>
              setTableParams((prev: ITableParams) => ({
                ...prev,
                filter: {
                  ...prev.filter,
                  is_approved: value
                }
              }))
            }
          >
            <SelectTrigger className="h-auto w-full rounded-full border-[#868194] bg-transparent bg-white px-4 py-1.5 text-sm md:w-36">
              <SelectValue />
            </SelectTrigger>
            <SelectContent className="w-full md:w-36">
              <SelectGroup>
                <SelectItem value="all">All</SelectItem>
                <SelectItem value="true">Approved</SelectItem>
                <SelectItem value="false">Disapproved</SelectItem>
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>
      </div>

      <div className="flex items-center justify-between gap-2">
        <FilterPopover tableParams={tableParams} setTableParams={setTableParams} />
        <SortPopover tableParams={tableParams} setTableParams={setTableParams} />
      </div>
    </div>
  )
}
