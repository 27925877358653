import React, { Dispatch, SetStateAction } from "react"

import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import * as z from "zod"

import { LoadingButton } from "@/components/_uiext"
import useApplicationConsentStore from "@/stores/useApplicationConsentStore"

import { Dialog, DialogContent } from "@repo/ui/components/ui/dialog"
import { Form, FormControl, FormField, FormItem, FormMessage } from "@repo/ui/components/ui/form"
import { InputOTP, InputOTPGroup, InputOTPSlot } from "@repo/ui/components/ui/input-otp"
import { CLIENT_MSG } from "@repo/i18n"

const FormSchema = z.object({
  sms_code: z.string().min(6, {
    message: CLIENT_MSG.FIELD_REQUIRED
  })
})

type FormSchemaType = z.infer<typeof FormSchema>

interface Props {
  consentUuid: string
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  onSuccessCallback?: (newAppData: any) => void
}

export default function VerifyDialog(props: Readonly<Props>) {
  const { consentUuid, open, setOpen, onSuccessCallback } = props
  const store = useApplicationConsentStore()

  const form = useForm<FormSchemaType>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      sms_code: ""
    }
  })

  const handleCompletePin = () => {
    form.handleSubmit(onSubmit)()
  }

  const onSubmit = (values: FormSchemaType) => {
    store.verifyConsentAction(
      consentUuid,
      values.sms_code,
      (newData) => {
        if (typeof onSuccessCallback === "function") {
          onSuccessCallback(newData)
        }
        setOpen(false)
      },
      () => {
        form.resetField("sms_code")
      }
    )
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent
        hideClose
        className="flex h-full flex-col gap-0 bg-white p-10 md:h-auto"
        onInteractOutside={(e) => {
          e.preventDefault()
        }}
      >
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <div className="flex h-full flex-col justify-between">
              <div className="flex flex-col">
                <h1 className="text-main mb-4 text-center text-lg font-semibold">
                  Please verify for consent to act request!
                </h1>

                <div className="mb-4 w-full">
                  <FormField
                    control={form.control}
                    name="sms_code"
                    render={({ field }) => (
                      <FormItem>
                        <FormControl>
                          <InputOTP
                            autoFocus
                            maxLength={6}
                            onComplete={handleCompletePin}
                            {...field}
                          >
                            <InputOTPGroup className="flex w-full justify-between gap-2 md:gap-4">
                              <InputOTPSlot
                                className="h-[75px] w-full !rounded-xl border"
                                index={0}
                              />
                              <InputOTPSlot
                                className="h-[75px] w-full rounded-xl border"
                                index={1}
                              />
                              <InputOTPSlot
                                className="h-[75px] w-full rounded-xl border"
                                index={2}
                              />
                              <InputOTPSlot
                                className="h-[75px] w-full rounded-xl border"
                                index={3}
                              />
                              <InputOTPSlot
                                className="h-[75px] w-full rounded-xl border"
                                index={4}
                              />
                              <InputOTPSlot
                                className="h-[75px] w-full !rounded-xl border"
                                index={5}
                              />
                            </InputOTPGroup>
                          </InputOTP>
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </div>

              <div className="flex justify-center">
                <LoadingButton
                  loading={store.loading}
                  type="button"
                  className="w-full px-6 py-4 md:w-auto"
                >
                  Verify
                </LoadingButton>
              </div>
            </div>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
}
