import React, { useState } from "react"

import clsx from "clsx"
import { useFieldArray, useFormContext } from "react-hook-form"
import { NumericFormat } from "react-number-format"

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger
} from "@repo/ui/components/ui/accordion"
import { Button } from "@repo/ui/components/ui/button"
import { FormField, FormItem, FormLabel, FormMessage } from "@repo/ui/components/ui/form"
import { Input } from "@repo/ui/components/ui/input"
import { APPLICATION_LIABILITY_TYPES } from "@repo/util/constant"

interface Props {
  index: number
  item: any
}

export default function IndividualALLiabilities(props: Readonly<Props>) {
  const { index: superIndex, item: superItem } = props

  const form = useFormContext()

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: `individual_al.${superIndex}.individual_liabilities`
  })

  const [open, setOpen] = useState(false)

  return (
    <Accordion type="single" className="w-full" value={open ? "assets" : ""}>
      <AccordionItem className="mb-4 rounded-2xl border p-4" value="assets">
        <AccordionTrigger
          className="h-24 hover:no-underline md:h-12"
          tabIndex={-1}
          onClick={() => {
            if (fields.length > 0) {
              setOpen((prev) => !prev)
            }
          }}
        >
          <div className="flex w-full items-center justify-between gap-4 pr-4">
            <h3 className="text-main relative text-lg font-semibold">
              Liabilities
              <div className="absolute right-[-16px] top-[-4px] flex h-4 w-4 items-center justify-center rounded-full bg-red-500 text-xs font-normal text-white">
                {fields.length}
              </div>
            </h3>
            <span
              className="text-default h-auto rounded-xl border border-[#868194] bg-transparent px-6 py-4 text-sm font-semibold hover:bg-transparent"
              onClick={(e) => {
                e.stopPropagation()
                setOpen(true)
                append({
                  type: APPLICATION_LIABILITY_TYPES.Individual,
                  individual_uuid: superItem.uuid,
                  description: "",
                  amount_owing: 0,
                  lender: "",
                  monthly_repayment: 0
                })
              }}
            >
              Add liability
            </span>
          </div>
        </AccordionTrigger>
        <AccordionContent className="flex flex-col gap-4 p-0">
          {fields.map((item, index) => (
            <div
              key={item.id}
              className={clsx("flex flex-col rounded-xl border p-4", index === 0 && "mt-4")}
            >
              <div className="mb-4 flex w-full justify-end">
                <Button type="button" size="sm" variant="destructive" onClick={() => remove(index)}>
                  Remove
                </Button>
              </div>

              <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
                <div className="flex-1">
                  <FormField
                    control={form.control}
                    name={`individual_al.${superIndex}.individual_liabilities.${index}.description`}
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel className="text-main text-sm font-normal">
                          Description of liability
                        </FormLabel>
                        <Input autoComplete="off" {...field} />
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>

                <div className="flex-1">
                  <FormField
                    control={form.control}
                    name={`individual_al.${superIndex}.individual_liabilities.${index}.amount_owing`}
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel className="text-main text-sm font-normal">
                          Amount owing
                        </FormLabel>
                        <NumericFormat
                          customInput={Input}
                          thousandSeparator
                          prefix="$"
                          placeholder="$"
                          getInputRef={field.ref}
                          name={field.name}
                          value={field.value ?? ""}
                          onBlur={field.onBlur}
                          onValueChange={(values, sourceInfo) => {
                            field.onChange(values.value)
                          }}
                        />
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </div>

              <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
                <div className="flex-1">
                  <FormField
                    control={form.control}
                    name={`individual_al.${superIndex}.individual_liabilities.${index}.lender`}
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel className="text-main text-sm font-normal">
                          Who is the lender?
                        </FormLabel>
                        <Input autoComplete="off" {...field} />
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>

                <div className="flex-1">
                  <FormField
                    control={form.control}
                    name={`individual_al.${superIndex}.individual_liabilities.${index}.monthly_repayment`}
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel className="text-main text-sm font-normal">
                          Monthly repayment / limit
                        </FormLabel>
                        <NumericFormat
                          customInput={Input}
                          thousandSeparator
                          prefix="$"
                          placeholder="$"
                          getInputRef={field.ref}
                          name={field.name}
                          value={field.value ?? ""}
                          onBlur={field.onBlur}
                          onValueChange={(values, sourceInfo) => {
                            field.onChange(values.value)
                          }}
                        />
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </div>
            </div>
          ))}
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  )
}
