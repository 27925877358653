import React, { Dispatch, SetStateAction } from "react"

import { LuChevronLeft, LuChevronRight, LuChevronsLeft, LuChevronsRight } from "react-icons/lu"

import { ITableParams } from "@/types/interface"

import { Button } from "@repo/ui/components/ui/button"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "@repo/ui/components/ui/select"

interface Props {
  loading: boolean
  totalCount: number
  rowCount: number
  tableParams: ITableParams
  setTableParams: Dispatch<SetStateAction<ITableParams>>
}

export default function Pagination({
  loading,
  totalCount,
  rowCount,
  tableParams,
  setTableParams
}: Readonly<Props>) {
  return (
    <div className="flex items-center justify-center rounded-xl bg-white p-4 md:justify-between">
      {!loading && (
        <>
          <div className="text-sm font-normal">
            Showing{" "}
            <span className="font-bold">
              {rowCount === 0
                ? 0
                : tableParams.pagination.page_size * (tableParams.pagination.page_index - 1) +
                  1}{" "}
              -{" "}
              {tableParams.pagination.page_size > 0
                ? tableParams.pagination.page_size * (tableParams.pagination.page_index - 1) +
                  rowCount
                : totalCount}
            </span>{" "}
            of <span className="font-bold">{totalCount}</span> leads
          </div>

          {tableParams.pagination.page_size > 0 && (
            <div className="flex items-center gap-4">
              <Select
                value={tableParams.pagination.page_size.toString()}
                onValueChange={(value) => {
                  setTableParams((prev: ITableParams) => ({
                    ...prev,
                    pagination: {
                      page_index: 1,
                      page_size: Number(value)
                    }
                  }))
                }}
              >
                <SelectTrigger className="rounded-full border-[#868194] bg-transparent bg-white px-4 py-1 text-sm">
                  <SelectValue placeholder={tableParams.pagination.page_size} />
                </SelectTrigger>

                <SelectContent>
                  {[5, 10, 25, 50, 100].map((pageSize) => (
                    <SelectItem key={pageSize} value={pageSize.toString()}>
                      {pageSize}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>

              <Button
                className="text-default flex h-auto items-center gap-1.5 border border-[#868194] bg-transparent p-3 font-semibold hover:bg-transparent"
                onClick={() =>
                  setTableParams((prev: ITableParams) => ({
                    ...prev,
                    pagination: {
                      ...prev.pagination,
                      page_index: 1
                    }
                  }))
                }
                disabled={tableParams.pagination.page_index <= 1}
              >
                <LuChevronsLeft />
              </Button>

              <Button
                className="text-default flex h-auto items-center gap-1.5 border border-[#868194] bg-transparent p-3 font-semibold hover:bg-transparent"
                onClick={() =>
                  setTableParams((prev: ITableParams) => ({
                    ...prev,
                    pagination: {
                      ...prev.pagination,
                      page_index: prev.pagination.page_index - 1
                    }
                  }))
                }
                disabled={tableParams.pagination.page_index <= 1}
              >
                <LuChevronLeft />
              </Button>

              <Button
                className="text-default flex h-auto items-center gap-1.5 border border-[#868194] bg-transparent p-3 font-semibold hover:bg-transparent"
                onClick={() =>
                  setTableParams((prev: ITableParams) => ({
                    ...prev,
                    pagination: {
                      ...prev.pagination,
                      page_index: prev.pagination.page_index + 1
                    }
                  }))
                }
                disabled={
                  tableParams.pagination.page_index >=
                  Math.ceil(totalCount / tableParams.pagination.page_size)
                }
              >
                <LuChevronRight />
              </Button>

              <Button
                className="text-default flex h-auto items-center gap-1.5 border border-[#868194] bg-transparent p-3 font-semibold hover:bg-transparent"
                onClick={() =>
                  setTableParams((prev: ITableParams) => ({
                    ...prev,
                    pagination: {
                      ...prev.pagination,
                      page_index: Math.ceil(totalCount / tableParams.pagination.page_size)
                    }
                  }))
                }
                disabled={
                  tableParams.pagination.page_index >=
                  Math.ceil(totalCount / tableParams.pagination.page_size)
                }
              >
                <LuChevronsRight />
              </Button>
            </div>
          )}
        </>
      )}
    </div>
  )
}
