import React, { useEffect, useState } from "react"

import { FUNDING_SOLUTIONS } from "@repo/util/constant"

import { useAdminAppContext } from "../../AdminAppContext"

export default function LoanDetails() {
  const { data } = useAdminAppContext()
  const [component, setComponent] = useState<any>(null)

  useEffect(() => {
    const importComponent = async () => {
      if (data.funding_solution === FUNDING_SOLUTIONS.ConstructionFinance.key) {
        const module = await import("./ConstructionFinance")
        const { LoanDetailsConstructionFinance } = module
        setComponent(<LoanDetailsConstructionFinance />)
      }

      if (data.funding_solution === FUNDING_SOLUTIONS.AgriculturalBusinessFinance.key) {
        const module = await import("./AgriculturalBusinessFinance")
        const { LoanDetailsAgriculturalBusinessFinance } = module
        setComponent(<LoanDetailsAgriculturalBusinessFinance />)
      }

      if (data.funding_solution === FUNDING_SOLUTIONS.CorporateFinance.key) {
        const module = await import("./CorporateFinance")
        const { LoanDetailsCorporateFinance } = module
        setComponent(<LoanDetailsCorporateFinance />)
      }

      if (data.funding_solution === FUNDING_SOLUTIONS.PreDevelopmentFinance.key) {
        const module = await import("./DevelopmentFinance")
        const { LoanDetailsDevelopmentFinance } = module
        setComponent(<LoanDetailsDevelopmentFinance />)
      }

      if (data.funding_solution === FUNDING_SOLUTIONS.PropertyFinance.key) {
        const module = await import("./PropertyFinance")
        const { LoanDetailsPropertyFinance } = module
        setComponent(<LoanDetailsPropertyFinance />)
      }

      if (data.funding_solution === FUNDING_SOLUTIONS.SMEFinance.key) {
        const module = await import("./SMEFinance")
        const { LoanDetailsSMEFinance } = module
        setComponent(<LoanDetailsSMEFinance />)
      }

      if (data.funding_solution === FUNDING_SOLUTIONS.SMSFFinance.key) {
        const module = await import("./SMSFFinance")
        const { LoanDetailsSMSFFinance } = module
        setComponent(<LoanDetailsSMSFFinance />)
      }

      if (data.funding_solution === FUNDING_SOLUTIONS.CashflowFinance.key) {
        const module = await import("./CashflowFinance")
        const { LoanDetailsCashflowFinance } = module
        setComponent(<LoanDetailsCashflowFinance />)
      }
    }

    importComponent()
  }, [data])

  return <>{component}</>
}
