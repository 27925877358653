import React from "react"

interface Props {
  className?: string
}

export default function IconMore({ className }: Readonly<Props>) {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 6 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.11041 2.70833C5.11041 3.85833 4.16463 4.79167 2.99929 4.79167C1.83396 4.79167 0.888184 3.85833 0.888184 2.70833C0.888184 1.55833 1.83396 0.625 2.99929 0.625C4.16463 0.625 5.11041 1.55833 5.11041 2.70833Z"
        fill="currentColor"
      />
      <path
        d="M2.99929 7.91667C1.83396 7.91667 0.888184 8.85 0.888184 10C0.888184 11.15 1.83396 12.0833 2.99929 12.0833C4.16463 12.0833 5.11041 11.15 5.11041 10C5.11041 8.85 4.16463 7.91667 2.99929 7.91667Z"
        fill="currentColor"
      />
      <path
        d="M2.99929 15.2083C1.83396 15.2083 0.888184 16.1417 0.888184 17.2917C0.888184 18.4417 1.83396 19.375 2.99929 19.375C4.16463 19.375 5.11041 18.4417 5.11041 17.2917C5.11041 16.1417 4.16463 15.2083 2.99929 15.2083Z"
        fill="currentColor"
      />
    </svg>
  )
}
