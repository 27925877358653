import React, { useState } from "react"

import { ColumnDef } from "@tanstack/react-table"
import { format } from "date-fns"
import numeral from "numeral"
import { useNavigate } from "react-router-dom"

import { IconArchive, IconEdit, IconMore, IconUserCircleAdd, IconView } from "@/components/_icons"
import useApplicationStore from "@/stores/useApplicationStore"
import useAuthStore from "@/stores/useAuthStore"

import { Avatar, AvatarFallback, AvatarImage } from "@repo/ui/components/ui/avatar"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from "@repo/ui/components/ui/dropdown-menu"
import { Button } from "@repo/ui/components/ui/button"
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from "@repo/ui/components/ui/tooltip"
import { APPLICATION_STATUS, USER_ROLES } from "@repo/util/constant"

import AssignDialog from "../AssignDialog"

interface ActionColumnProps {
  row: any
}

function ActionColumn({ row }: Readonly<ActionColumnProps>) {
  const navigate = useNavigate()
  const store = useApplicationStore()
  const authStore = useAuthStore()

  const [openAssign, setOpenAssign] = useState(false)

  const handleViewClick = (uuid: string) => () => {
    navigate(`/applications/view/${uuid}`)
  }

  const handleEditClick = (uuid: string) => () => {
    navigate(`/applications/edit/${uuid}`)
  }

  const handleAssignClick = (uuid: string) => () => {
    setOpenAssign(true)
  }

  const handleArchiveClick = (uuid: string) => () => {
    store.archiveAction(uuid)
  }

  return (
    <div className="text-center">
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" size="icon">
            <IconMore className="text-2xl" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-32" align="end">
          {row.original.status !== APPLICATION_STATUS.Incomplete.key && (
            <DropdownMenuItem
              className="text-default flex gap-2 text-sm"
              onClick={handleViewClick(row.original.uuid)}
            >
              <IconView className="text-base" /> View
            </DropdownMenuItem>
          )}

          {row.original.status === APPLICATION_STATUS.Incomplete.key && (
            <>
              <DropdownMenuItem
                className="text-default flex gap-2 text-sm"
                onClick={handleEditClick(row.original.uuid)}
              >
                <IconEdit className="text-base" />
                Edit
              </DropdownMenuItem>

              {(authStore.user?.role === USER_ROLES.Enterprise ||
                (row.original.creator_uuid === authStore.user?.uuid &&
                  authStore.user?.role === USER_ROLES.Employee)) && (
                <DropdownMenuItem
                  className="text-default flex gap-2 text-sm"
                  onClick={handleAssignClick(row.original.uuid)}
                >
                  <IconUserCircleAdd className="text-base" />
                  Assign
                </DropdownMenuItem>
              )}
            </>
          )}

          <DropdownMenuItem
            className="text-default flex gap-2 text-sm"
            onClick={handleArchiveClick(row.original.uuid)}
          >
            <IconArchive className="text-base" />
            {!row.original.is_archived ? "Archive" : "Restore"}
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>

      <AssignDialog open={openAssign} setOpen={setOpenAssign} data={row.original} />
    </div>
  )
}

const columns: ColumnDef<any>[] = [
  {
    accessorKey: "created_at",
    header: () => <>Created On</>,
    cell: ({ row }) => (
      <div className="text-sm">{format(row.original.created_at, "dd/MM/yyyy")}</div>
    )
  },
  {
    accessorKey: "id",
    header: () => <>Application ID</>,
    cell: ({ row }) => (
      <div className="text-sm">{Number(row.original.id).toString().padStart(10, "0")}</div>
    )
  },
  {
    accessorKey: "entity_name",
    header: () => <>Entity Name</>,
    cell: ({ row }) => <div className="text-sm">{row.original.borrower?.entity_name}</div>
  },
  {
    accessorKey: "loan_amount",
    header: () => <>Loan Amount</>,
    cell: ({ row }) => (
      <div className="text-nowrap text-sm">
        {numeral(
          row.original.settled_amount ? row.original.settled_amount : row.original.loan_amount
        ).format("$ 0,0")}
      </div>
    )
  },
  {
    accessorKey: "assigned_to",
    header: () => <>Assigned To</>,
    cell: ({ row }) => (
      <TooltipProvider disableHoverableContent delayDuration={0} skipDelayDuration={0}>
        <div className="flex items-center">
          <Tooltip>
            <TooltipTrigger>
              <Avatar className="hover:border-primary h-10 w-10 border-2 border-white hover:z-50">
                <AvatarImage
                  src={row.original.creator?.image}
                  alt={`${row.original.creator?.first_name} ${row.original.creator?.last_name}`}
                />
                <AvatarFallback className="uppercase">
                  {row.original.creator?.first_name[0]}
                  {row.original.creator?.last_name[0]}
                </AvatarFallback>
              </Avatar>
            </TooltipTrigger>

            <TooltipContent className="grid grid-cols-3 gap-2 p-4">
              <p className="text-default text-sm font-normal">Full Name:</p>
              <p className="text-main col-span-2 text-sm font-semibold">
                {row.original.creator?.first_name} {row.original.creator?.last_name}
              </p>
              <p className="text-default text-sm font-normal">Entity Name:</p>
              <p className="text-main col-span-2 text-sm font-semibold">
                {row.original.creator?.entity?.entity_name}
              </p>
            </TooltipContent>
          </Tooltip>

          {row.original.assignees?.map((a: any) => (
            <Tooltip key={a?.user?.id}>
              <TooltipTrigger>
                <Avatar className="hover:border-primary ml-[-12px] h-10 w-10 border-2 border-white hover:z-50">
                  <AvatarImage
                    src={a?.user?.image}
                    alt={`${a?.user?.first_name} ${a?.user?.last_name}`}
                  />
                  <AvatarFallback className="uppercase">
                    {a?.user?.first_name[0]}
                    {a?.user?.last_name[0]}
                  </AvatarFallback>
                </Avatar>
              </TooltipTrigger>

              <TooltipContent className="grid grid-cols-3 gap-2 p-4">
                <p className="text-default text-sm font-normal">Full Name:</p>
                <p className="text-main col-span-2 text-sm font-semibold">
                  {a?.user?.first_name} {a?.user?.last_name}
                </p>
                <p className="text-default text-sm font-normal">Entity Name:</p>
                <p className="text-main col-span-2 text-sm font-semibold">
                  {a?.user?.entity?.entity_name}
                </p>
              </TooltipContent>
            </Tooltip>
          ))}
        </div>
      </TooltipProvider>
    )
  },
  {
    accessorKey: "status",
    header: () => <>Status</>,
    cell: ({ row }) => (
      <div
        className="rounded-sm px-6 py-1.5 text-center capitalize"
        style={{
          color: Object.entries(APPLICATION_STATUS)
            .map(([k, v]) => ({ ...v }))
            .filter((as) => as.key === row.original.status)?.[0].color.text,
          backgroundColor: Object.entries(APPLICATION_STATUS)
            .map(([k, v]) => ({ ...v }))
            .filter((as) => as.key === row.original.status)?.[0].color.background
        }}
      >
        {row.original.status}
      </div>
    )
  },
  {
    accessorKey: "actions",
    header: () => <div className="text-center">Actions</div>,
    cell: ({ row }) => <ActionColumn row={row} />
  }
]

export default columns
