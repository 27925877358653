import React from "react"

interface Props {
  className?: string
}

export default function IconDocument({ className }: Readonly<Props>) {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 15 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_1447_12004"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="15"
        height="18"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.500488 0.676758H14.7111V17.2213H0.500488V0.676758Z"
          fill="currentColor"
        />
      </mask>

      <g mask="url(#mask0_1447_12004)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.31141 1.92676C2.93057 1.92676 1.78391 3.04509 1.75141 4.42426V13.3368C1.72057 14.7643 2.84557 15.9401 4.25891 15.9718H10.9789C12.3697 15.9143 13.4714 14.7584 13.4614 13.3418V5.95009L9.59891 1.92676H4.32141H4.31141ZM4.32141 17.2218H4.23057C2.12891 17.1743 0.455574 15.4259 0.501408 13.3234V4.40926C0.549741 2.34176 2.25724 0.676758 4.30974 0.676758H4.32391H9.86558C10.0356 0.676758 10.1981 0.745924 10.3164 0.868424L14.5372 5.26592C14.6489 5.38176 14.7114 5.53759 14.7114 5.69842V13.3368C14.7264 15.4276 13.0981 17.1359 11.0039 17.2209L4.32141 17.2218Z"
          fill="currentColor"
        />
      </g>

      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.0823 6.48699H11.7865C10.2615 6.48283 9.02148 5.23949 9.02148 3.71616V1.29199C9.02148 0.946992 9.30148 0.666992 9.64648 0.666992C9.99148 0.666992 10.2715 0.946992 10.2715 1.29199V3.71616C10.2715 4.55283 10.9523 5.23449 11.7882 5.23699H14.0823C14.4273 5.23699 14.7073 5.51699 14.7073 5.86199C14.7073 6.20699 14.4273 6.48699 14.0823 6.48699Z"
        fill="currentColor"
      />

      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.49072 12.4238H4.99072C4.64572 12.4238 4.36572 12.1438 4.36572 11.7988C4.36572 11.4538 4.64572 11.1738 4.99072 11.1738H9.49072C9.83572 11.1738 10.1157 11.4538 10.1157 11.7988C10.1157 12.1438 9.83572 12.4238 9.49072 12.4238Z"
        fill="currentColor"
      />

      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.7869 9.29688H4.99023C4.64523 9.29688 4.36523 9.01687 4.36523 8.67188C4.36523 8.32688 4.64523 8.04688 4.99023 8.04688H7.7869C8.1319 8.04688 8.4119 8.32688 8.4119 8.67188C8.4119 9.01687 8.1319 9.29688 7.7869 9.29688Z"
        fill="currentColor"
      />
    </svg>
  )
}
