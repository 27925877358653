import { toast } from "sonner"
import { create } from "zustand"

import authApi from "@/api/authApi"

import { CLIENT_MSG } from "@repo/i18n"

interface IAuthState {
  loading: boolean
  initialized: boolean

  mfaVerified: boolean
  user: Record<string, any> | null

  lockAction: () => void
  approveAction: () => void

  loginAction: (data: any, onSuccessCallback?: () => void) => void
  verifyTotpAction: (
    token: string,
    onSuccessCallback?: () => void,
    onFailureCallback?: () => void
  ) => void
  logoutAction: (onSuccessCallback?: () => void) => void
  refreshTokenAction: (onSuccessCallback?: () => void) => void

  updateProfileAction: (data: any) => void
  getNotificationAction: (onSuccessCallback?: (newData: any) => void) => void
  readNotificationAction: (notificationId: number, onSuccessCallback?: () => void) => void
}

const useAuthStore = create<IAuthState>()((set, get) => ({
  initialized: false,

  loading: false,
  mfaVerified: false,
  user: null,

  lockAction: () => set({ user: { ...get().user, is_locked: true } }),

  approveAction: () => set({ user: { ...get().user, is_approved: true } }),

  loginAction: async (data: any, onSuccessCallback) => {
    try {
      set({ loading: true })

      const response = await authApi.login(data)

      if (response?.data) {
        const { data } = response.data

        set({ user: data })
        set({ mfaVerified: false })

        if (typeof onSuccessCallback === "function") {
          onSuccessCallback()
        }
      }
    } catch (err: any) {
      console.error(err)

      set({ user: null })
      set({ mfaVerified: false })

      if (err?.response?.data?.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error(CLIENT_MSG.SOMETHING_WENT_WRONG)
      }
    } finally {
      set({ loading: false })
    }
  },

  verifyTotpAction: async (token, onSuccessCallback, onFailureCallback) => {
    try {
      set({ loading: true })

      const response = await authApi.verifyTotp({
        token,
        user_uuid: get().user?.uuid
      })

      if (response?.data) {
        const { message } = response.data

        set({ mfaVerified: true })
        toast.success(message)

        if (typeof onSuccessCallback === "function") {
          onSuccessCallback()
        }
      }
    } catch (err: any) {
      console.error(err)

      set({ mfaVerified: false })

      if (err?.response?.data?.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error(CLIENT_MSG.SOMETHING_WENT_WRONG)
      }
      if (typeof onFailureCallback === "function") {
        onFailureCallback()
      }
    } finally {
      set({ loading: false })
    }
  },

  logoutAction: async (onSuccessCallback) => {
    try {
      set({ loading: true })
      await authApi.logout()

      set({ user: null })
      set({ mfaVerified: false })

      if (typeof onSuccessCallback === "function") {
        onSuccessCallback()
      }
    } catch (err: any) {
      console.error(err)

      if (err?.response?.data?.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error(CLIENT_MSG.SOMETHING_WENT_WRONG)
      }
    } finally {
      set({ loading: false })
    }
  },

  refreshTokenAction: async (onSuccessCallback) => {
    try {
      set({ loading: true })
      set({ initialized: false })

      set({ user: null })
      set({ mfaVerified: false })

      const response = await authApi.refreshToken()

      if (response?.data) {
        const { data } = response.data

        set({ user: data })
        set({ mfaVerified: true })

        if (typeof onSuccessCallback === "function") {
          onSuccessCallback()
        }
      }
    } catch (err: any) {
      console.error(err)
    } finally {
      set({ loading: false })
      set({ initialized: true })
    }
  },

  updateProfileAction: async (data) => {
    try {
      set({ loading: true })

      const response = await authApi.updateProfile(data)

      if (response?.data) {
        const { data, message } = response.data

        set({ user: data })

        toast.success(message)
      }
    } catch (err: any) {
      console.error(err)

      if (err?.response?.data?.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error(CLIENT_MSG.SOMETHING_WENT_WRONG)
      }
    } finally {
      set({ loading: false })
    }
  },

  getNotificationAction: async (onSuccessCallback) => {
    try {
      const user = get().user

      if (user?.uuid) {
        const response = await authApi.getNotification()

        if (response?.data) {
          const { data } = response.data

          if (typeof onSuccessCallback === "function") {
            onSuccessCallback(data)
          }
        }
      }
    } catch (err: any) {
      console.error(err)

      if (err?.response?.data?.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error(CLIENT_MSG.SOMETHING_WENT_WRONG)
      }
    }
  },

  readNotificationAction: async (notificationId, onSuccessCallback) => {
    try {
      const response = await authApi.readNotification(notificationId)

      if (response?.data) {
        const { data } = response.data

        if (typeof onSuccessCallback === "function") {
          onSuccessCallback()
        }
      }
    } catch (err: any) {
      console.error(err)

      if (err?.response?.data?.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error(CLIENT_MSG.SOMETHING_WENT_WRONG)
      }
    }
  }
}))

export default useAuthStore
