import React from "react"

import clsx from "clsx"
import { LuCheck } from "react-icons/lu"

import { useAdminAppContext } from "@/pages/admin/applications/AdminAppContext"

import Logo from "../components/Logo"

export default function AdminApplicationSidebar() {
  const { steps, activeStep, setActiveStep, data } = useAdminAppContext()

  return (
    <aside className="fixed bottom-0 left-0 top-0 hidden max-h-screen min-h-screen min-w-[270px] max-w-[270px] flex-col bg-white px-6 py-[30px] lg:flex">
      <div className="mb-12">
        <Logo />
      </div>

      <div className="mb-20 flex flex-col">
        {steps.slice(0, -1).map((s, index) => (
          <React.Fragment key={s}>
            {index > 0 && <div className="ml-5 h-4 w-[1px] border-l" />}

            <div
              className={clsx(
                "flex items-center gap-4",
                steps.indexOf(data?.max_step) >= index && "cursor-pointer"
              )}
              onClick={() => steps.indexOf(data?.max_step) >= index && setActiveStep(index)}
            >
              {activeStep > index ? (
                <div className="bg-primary border-primary flex min-h-10 min-w-10 cursor-pointer items-center justify-center rounded-full border text-sm font-semibold text-white">
                  <LuCheck className="text-lg font-semibold text-white" />
                </div>
              ) : (
                <div
                  className={clsx(
                    "flex min-h-10 min-w-10 items-center justify-center rounded-full border text-sm font-semibold",
                    activeStep === index ? "border-primary text-primary" : ""
                  )}
                >
                  {index + 1}
                </div>
              )}

              <span
                className={clsx(
                  "text-sm",
                  activeStep >= index ? "text-main font-semibold" : "text-default font-normal"
                )}
              >
                {s}
              </span>
            </div>
          </React.Fragment>
        ))}
      </div>
    </aside>
  )
}
