import React from "react"

import RightSwiper from "./components/RightSwiper"

interface Props {
  children: React.ReactNode
}

export default function AuthLayout({ children }: Readonly<Props>) {
  return (
    <main className="flex min-h-screen flex-row bg-white">
      <section className="flex w-full flex-col px-5 py-8 md:w-1/2 md:px-10 md:py-12 xl:px-20 xl:py-16">
        {children}
      </section>

      <section className="hidden w-1/2 md:inline-block">
        <RightSwiper />
      </section>
    </main>
  )
}
