import axios from "axios"
import QueryString from "qs"

// Search by name
let abortSearchByNameController: any = null

interface ISearchByNameParam {
  name?: string
  maxResults?: number
}

const searchByName = async (params: ISearchByNameParam) => {
  const { name = "", maxResults = 100 } = params
  const newParams = {
    name,
    maxResults,
    guid: import.meta.env.VITE_AUS_ABR_KEY ?? ""
  }

  // Cancel the previous request if it exists
  if (abortSearchByNameController) {
    abortSearchByNameController.abort()
  }

  // Create a new cancel token
  abortSearchByNameController = new AbortController()

  try {
    const res = await axios.get(
      `https://abr.business.gov.au/json/MatchingNames.aspx?${QueryString.stringify(newParams)}`,
      { signal: abortSearchByNameController.signal }
    )

    const { data } = res
    const stringData = data.substring(9, data.length - 1)
    const parsedData = JSON.parse(stringData)
    const { Names } = parsedData

    return Names.filter(
      (value: any, index: number, self: any) =>
        index === self.findIndex((t: any) => t.Abn === value.Abn)
    )
  } catch (e: any) {
    if (axios.isCancel(e)) {
      console.log("ApiAusAbr searchByName - request canceled:", e.message)
    } else if (e.name === "AbortError") {
      console.log("ApiAusAbr searchByName - request aborted:", e.message)
    } else {
      console.error("ApiAusAbr searchByName error: ", e)
    }

    return []
  }
}

// Get by abn
let abortGetByAbnController: any = null

const getByAbn = async (abn: string) => {
  const newParams = {
    abn,
    guid: import.meta.env.VITE_AUS_ABR_KEY ?? ""
  }

  // Cancel the previous request if it exists
  if (abortGetByAbnController) {
    abortGetByAbnController.abort()
  }

  // Create a new cancel token
  abortGetByAbnController = new AbortController()

  try {
    const res = await axios.get(
      `https://abr.business.gov.au/json/AbnDetails.aspx?${QueryString.stringify(newParams)}`,
      { signal: abortGetByAbnController.signal }
    )

    const { data } = res
    const stringData = data.substring(9, data.length - 1)
    const parsedData = JSON.parse(stringData)

    return parsedData
  } catch (e: any) {
    if (axios.isCancel(e)) {
      console.log("ApiAusAbr getByAbn - request canceled:", e.message)
    } else if (e.name === "AbortError") {
      console.log("ApiAusAbr getByAbn - request aborted:", e.message)
    } else {
      console.error("ApiAusAbr getByAbn error: ", e)
    }

    return []
  }
}

const ApiAusAbr = { searchByName, getByAbn }

export default ApiAusAbr
