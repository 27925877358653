import { toast } from "sonner"
import { create } from "zustand"

import authApi from "@/api/authApi"

import { CLIENT_MSG } from "@repo/i18n"

interface IPasswordResetState {
  loading: boolean

  forgotPasswordAction: (data: any, onSuccessCallback?: () => void) => void
  verifyResetTokenAction: (
    data: any,
    onSuccessCallback?: () => void,
    onFailureCallback?: () => void
  ) => void
  resetPasswordAction: (data: any, onSuccessCallback?: () => void) => void
}

const usePasswordResetStore = create<IPasswordResetState>()((set) => ({
  loading: false,

  forgotPasswordAction: async (email: string, onSuccessCallback) => {
    try {
      set({ loading: true })

      const response = await authApi.sendResetLink({ email })

      if (response?.data) {
        const { message } = response.data
        toast.success(message)

        if (typeof onSuccessCallback === "function") {
          onSuccessCallback()
        }
      }
    } catch (err: any) {
      console.error(err)
      if (err?.response?.data?.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error(CLIENT_MSG.SOMETHING_WENT_WRONG)
      }
    } finally {
      set({ loading: false })
    }
  },

  verifyResetTokenAction: async (data, onSuccessCallback, onFailureCallback) => {
    try {
      set({ loading: true })

      const response = await authApi.verifyResetToken(data)

      if (response?.data) {
        const { message } = response.data
        toast.success(message)

        if (typeof onSuccessCallback === "function") {
          onSuccessCallback()
        }
      }
    } catch (err: any) {
      console.error(err)
      if (err?.response?.data?.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error(CLIENT_MSG.SOMETHING_WENT_WRONG)
      }
      if (typeof onFailureCallback === "function") {
        onFailureCallback()
      }
    } finally {
      set({ loading: false })
    }
  },

  resetPasswordAction: async (data: any, onSuccessCallback) => {
    try {
      set({ loading: true })

      const response = await authApi.resetPassword(data)

      if (response?.data) {
        const { message } = response.data
        toast.success(message)

        if (typeof onSuccessCallback === "function") {
          onSuccessCallback()
        }
      }
    } catch (err: any) {
      console.error(err)
      if (err?.response?.data?.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error(CLIENT_MSG.SOMETHING_WENT_WRONG)
      }
    } finally {
      set({ loading: false })
    }
  }
}))

export default usePasswordResetStore
