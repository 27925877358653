import React from "react"

interface Props {
  className?: string
}

export default function IconBusinessSettings({ className }: Readonly<Props>) {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.33838 11.6052C1.33838 11.6052 1.45671 13.0536 1.48421 13.5102C1.52088 14.1227 1.75755 14.8069 2.15255 15.2819C2.71005 15.9552 3.36671 16.1927 4.24338 16.1944C5.27421 16.1961 12.77 16.1961 13.8009 16.1944C14.6775 16.1927 15.3342 15.9552 15.8917 15.2819C16.2867 14.8069 16.5234 14.1227 16.5609 13.5102C16.5875 13.0536 16.7059 11.6052 16.7059 11.6052"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.08203 3.14892V2.83976C6.08203 1.82309 6.90536 0.999756 7.92203 0.999756H10.0737C11.0895 0.999756 11.9137 1.82309 11.9137 2.83976L11.9145 3.14892"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.99772 12.6054V11.5271"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.29346 5.69848V8.58765C2.89179 9.64181 4.80679 10.3801 6.90846 10.6726C7.16012 9.75515 7.98762 9.08265 8.99346 9.08265C9.98346 9.08265 10.8276 9.75515 11.0626 10.681C13.1726 10.3885 15.0951 9.65015 16.7018 8.58765V5.69848C16.7018 4.28681 15.566 3.15015 14.1543 3.15015H3.84929C2.43762 3.15015 1.29346 4.28681 1.29346 5.69848Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
