export const base64ToFile = (base64String: string, fileName: string, mimeType: string) => {
  const byteString = atob(base64String.split(",")[1])
  const byteArray = new Uint8Array(byteString.length)

  for (let i = 0; i < byteString.length; i++) {
    byteArray[i] = byteString.charCodeAt(i)
  }

  return new File([byteArray], fileName, { type: mimeType })
}
