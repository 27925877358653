const CLIENT_MSG_EN = {
  APP_CONSENT_INCOMPLETE: "Consent to act request is incomplete.",
  APP_VERIFICATION_INCOMPLETE: "ID verification is incomplete.",

  BORROWER_INDIVIDUALS_LIMITED:
    "You've reached the maximum number of individuals permitted for each borrower.",

  CHOOSE_ONE_ITEM: "Please choose one item.",

  FIELD_REQUIRED: "This field is required.",

  SOMETHING_WENT_WRONG:
    "Oops! Something went wrong. We're sorry for the inconvenience. Please try again later, or contact our support team if the problem persists."
}

export default CLIENT_MSG_EN
