import api from "./api"

// Get
const getBusinessSetting = () => api().get(`/business/settings`)

// Update
const updateBusinessSetting = (data: any) => api().putForm(`/business/settings`, data)

export default {
  getBusinessSetting,
  updateBusinessSetting
}
