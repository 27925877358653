import React from "react"

interface Props {
  className?: string
}

export default function IconView({ className }: Readonly<Props>) {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.2793 10.488C14.2793 12.8733 12.8733 14.2793 10.488 14.2793H5.3C2.90867 14.2793 1.5 12.8733 1.5 10.488V5.288C1.5 2.906 2.376 1.5 4.762 1.5H6.09533C6.574 1.50067 7.02467 1.72533 7.31133 2.10867L7.92 2.918C8.208 3.30067 8.65867 3.526 9.13733 3.52667H11.024C13.4153 3.52667 14.298 4.744 14.298 7.178L14.2793 10.488Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.9873 9.64193H10.8106"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
