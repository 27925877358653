import React from "react"

import { MdInfoOutline } from "react-icons/md"

export default function NotificationSticky() {
  return (
    <div className="sticky top-20 hidden h-48 w-72 rounded-xl bg-[#E0831626] p-6 xl:inline-block">
      <div className="flex flex-row gap-2">
        <div>
          <MdInfoOutline className="h-6 w-6 text-[#E08316]" />
        </div>
        <div className="text-main text-sm">
          You are one step away from receiving indicative terms for your finance application.
          <br />
          <br />
          Your Broker has requested for you to fill out the following fields.
        </div>
      </div>
    </div>
  )
}
