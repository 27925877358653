import React from "react"

import Logo from "./components/Logo"

interface Props {
  children: React.ReactNode
}

export default function GuestLayout({ children }: Readonly<Props>) {
  return (
    <main className="flex min-h-screen flex-col">
      <header className="fixed left-0 right-0 top-0 flex h-20 w-full items-center bg-white">
        <div className="container">
          <Logo />
        </div>
      </header>

      <section className="flex w-full flex-1 flex-col p-4 pt-[96px]">
        <div className="container flex-1 p-0">{children}</div>
      </section>
    </main>
  )
}
