import React from "react"

interface Props {
  className?: string
}

export default function IconMyBorrowers({ className }: Readonly<Props>) {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 19 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.8832 7.94837H9.9057C12.0057 7.94837 13.7132 6.24004 13.7132 4.14087C13.7132 2.04171 12.0057 0.333374 9.9057 0.333374C7.80653 0.333374 6.09903 2.04171 6.09903 4.13837C6.09487 5.15254 6.4857 6.10754 7.2007 6.82671C7.91403 7.54671 8.86653 7.94504 9.8832 7.94837ZM7.34903 4.14087C7.34903 2.73087 8.4957 1.58337 9.9057 1.58337C11.3157 1.58337 12.4632 2.73087 12.4632 4.14087C12.4632 5.55171 11.3157 6.69837 9.9057 6.69837H9.88486C9.20486 6.69587 8.56653 6.42837 8.08737 5.94587C7.6082 5.46337 7.34653 4.82421 7.34903 4.14087Z"
        fill="currentColor"
      />
      <path
        d="M14.2874 6.5838C14.3316 6.8963 14.5991 7.12213 14.9058 7.12213C14.9341 7.12213 14.9633 7.12046 14.9924 7.1163C16.4716 6.9088 17.5891 5.62546 17.5924 4.12963C17.5924 2.6438 16.5283 1.39046 15.0633 1.14963C14.7208 1.09546 14.4016 1.3238 14.3449 1.66463C14.2891 2.00546 14.5199 2.32713 14.8599 2.38296C15.7191 2.5238 16.3424 3.2588 16.3424 4.12796C16.3408 5.00463 15.6866 5.7563 14.8199 5.87796C14.4774 5.92546 14.2399 6.2413 14.2874 6.5838Z"
        fill="currentColor"
      />
      <path
        d="M16.8004 12.0402C16.8954 12.2893 17.1329 12.4427 17.3846 12.4427C17.4587 12.4427 17.5337 12.4293 17.6071 12.4018C18.7987 11.9477 18.9771 11.1202 18.9771 10.6668C18.9771 9.92434 18.5529 8.976 16.5337 8.6735C16.1896 8.62934 15.8729 8.85684 15.8221 9.1985C15.7712 9.541 16.0071 9.8585 16.3479 9.91017C17.2629 10.0468 17.7271 10.3018 17.7271 10.6668C17.7271 10.781 17.7271 11.0185 17.1621 11.2335C16.8396 11.356 16.6771 11.7177 16.8004 12.0402Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.90567 14.3258C8.52317 14.3258 4.31484 14.3258 4.31484 11.6633C4.31484 9.01166 8.52317 9.01166 9.90567 9.01166C11.2882 9.01166 15.4957 9.01166 15.4957 11.6775C15.4957 14.3258 11.4348 14.3258 9.90567 14.3258ZM9.90567 10.2617C7.92567 10.2617 5.56484 10.505 5.56484 11.6633C5.56484 12.8308 7.92567 13.0758 9.90567 13.0758C11.8857 13.0758 14.2457 12.8333 14.2457 11.6775C14.2457 10.5075 11.8857 10.2617 9.90567 10.2617Z"
        fill="currentColor"
      />
      <path
        d="M4.90483 7.12208C4.87649 7.12208 4.84733 7.12042 4.81816 7.11625C3.33899 6.90875 2.22233 5.62542 2.21899 4.13125C2.21899 2.64375 3.28316 1.39042 4.74816 1.14958C5.09899 1.09458 5.40983 1.32542 5.46649 1.66458C5.52233 2.00542 5.29149 2.32708 4.95149 2.38292C4.09233 2.52375 3.46899 3.25875 3.46899 4.12958C3.47066 5.00458 4.12483 5.75708 4.99066 5.87792C5.33316 5.92542 5.57066 6.24125 5.52316 6.58375C5.47899 6.89625 5.21149 7.12208 4.90483 7.12208Z"
        fill="currentColor"
      />
      <path
        d="M2.20433 12.4019C2.27766 12.4294 2.35266 12.4427 2.42683 12.4427C2.6785 12.4427 2.916 12.2894 3.011 12.0402C3.13433 11.7177 2.97183 11.356 2.64933 11.2335C2.0835 11.0177 2.0835 10.781 2.0835 10.6669C2.0835 10.3019 2.54766 10.0469 3.46266 9.9102C3.8035 9.85853 4.03933 9.54103 3.9885 9.19853C3.93683 8.85687 3.62183 8.6302 3.27766 8.67353C1.25766 8.97603 0.833496 9.9252 0.833496 10.6669C0.833496 11.1194 1.01183 11.9469 2.20433 12.4019Z"
        fill="currentColor"
      />
    </svg>
  )
}
