import React, { useEffect, useState } from "react"

import { Text, View } from "@react-pdf/renderer"
import numeral from "numeral"

interface Props {
  data: Record<string, any>
}

export default function ContentLenderSelection(props: Readonly<Props>) {
  const { data } = props

  const [filteredFslt, setFilteredFslt] = useState<any>(null)

  useEffect(() => {
    const arrFS = data?.lender?.fs?.filter(
      (fs: any) => fs?.funding_solution === data?.funding_solution
    )
    if (!arrFS || !arrFS.length) {
      return
    }

    const arrLT = arrFS?.[0]?.lt?.filter((l: any) => l.loan_type === data?.loan_type)
    if (!arrLT || !arrLT.length) {
      return
    }

    setFilteredFslt(arrLT?.[0])
  }, [data])

  return (
    <View style={{ display: "flex", flexDirection: "column" }} wrap={true}>
      <Text
        style={{
          color: "#0E052B",
          fontWeight: 600,
          fontSize: 14,
          borderBottom: "1px solid #CDCDCD"
        }}
      >
        7. Lender Selection
      </Text>

      <View
        style={{
          paddingLeft: 32,
          paddingVertical: 16,
          display: "flex",
          flexDirection: "column",
          gap: 8
        }}
      >
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 16
          }}
        >
          <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 14 }}>
            {data?.lender?.lender_number}
          </Text>
        </View>

        <View
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 16
          }}
        >
          <Text style={{ color: "#868194", fontWeight: 400, fontSize: 12, flex: 1 }}>
            Interest Rate From
          </Text>
          <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 12, flex: 1 }}>
            {filteredFslt?.interest_rate ?? 0}%
          </Text>
        </View>

        <View
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 16
          }}
        >
          <Text style={{ color: "#868194", fontWeight: 400, fontSize: 12, flex: 1 }}>
            Maximum LVR
          </Text>
          <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 12, flex: 1 }}>
            {filteredFslt?.loan_value_ratio ?? 0}%
          </Text>
        </View>

        <View
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 16
          }}
        >
          <Text style={{ color: "#868194", fontWeight: 400, fontSize: 12, flex: 1 }}>
            Min. Loan Size
          </Text>
          <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 12, flex: 1 }}>
            {numeral(filteredFslt?.minimum_loan_size).format("$ 0[.]00 a")}
          </Text>
        </View>

        <View
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 16
          }}
        >
          <Text style={{ color: "#868194", fontWeight: 400, fontSize: 12, flex: 1 }}>
            Max. Loan Size
          </Text>
          <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 12, flex: 1 }}>
            {numeral(filteredFslt?.maximum_loan_size).format("$ 0[.]00 a")}
          </Text>
        </View>

        <View
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 16
          }}
        >
          <Text style={{ color: "#868194", fontWeight: 400, fontSize: 12, flex: 1 }}>
            Min. Loan Term
          </Text>
          <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 12, flex: 1 }}>
            {filteredFslt?.minimum_loan_term ?? 0} Months
          </Text>
        </View>

        <View
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 16
          }}
        >
          <Text style={{ color: "#868194", fontWeight: 400, fontSize: 12, flex: 1 }}>
            Max. Loan Term
          </Text>
          <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 12, flex: 1 }}>
            {filteredFslt?.maximum_loan_term ?? 0} Months
          </Text>
        </View>

        <View
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 16
          }}
        >
          <Text style={{ color: "#868194", fontWeight: 400, fontSize: 12, flex: 1 }}>
            Payment Options
          </Text>
          <View style={{ flex: 1, display: "flex", flexDirection: "column", gap: 4 }}>
            {filteredFslt?.repayments?.map((r: string) => (
              <Text key={r} style={{ color: "#0E052B", fontWeight: 400, fontSize: 12 }}>
                {r}
              </Text>
            ))}
          </View>
        </View>
      </View>
    </View>
  )
}
