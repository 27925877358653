import { toast } from "sonner"
import { create } from "zustand"

import apiGetAdminReferrals from "@/api/adminReferralsApi"

import { CLIENT_MSG } from "@repo/i18n"

interface IAdminReferralState {
  loading: boolean
  reloadRequired: boolean

  invertReloadRequired: () => void

  getApplicationsListAction: (params: any, onSuccessCallback?: (newData: any) => void) => void
  getIntroducersAction: (params: any, onSuccessCallback?: (newData: any) => void) => void
  assignIntroducersAction: (
    applicationUuid: string,
    params: any,
    onSuccessCallback?: (data: any) => void
  ) => void
}

const useAdminReferralStore = create<IAdminReferralState>()((set, get) => ({
  loading: false,
  reloadRequired: false,

  invertReloadRequired: () => set({ reloadRequired: !get().reloadRequired }),

  getApplicationsListAction: async (params, onSuccessCallback) => {
    try {
      set({ loading: true })

      const response = await apiGetAdminReferrals.getApplicationsList(params)

      if (response?.data) {
        const { data } = response.data

        if (typeof onSuccessCallback === "function") {
          onSuccessCallback(data)
        }
      }
    } catch (err: any) {
      console.error(err)

      if (err?.response?.data?.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error(CLIENT_MSG.SOMETHING_WENT_WRONG)
      }
    } finally {
      set({ loading: false })
    }
  },

  getIntroducersAction: async (params, onSuccessCallback) => {
    try {
      const response = await apiGetAdminReferrals.getIntroducers(params)

      if (response?.data) {
        const { data } = response.data

        if (typeof onSuccessCallback === "function") {
          onSuccessCallback(data)
        }
      }
    } catch (err: any) {
      console.error(err)

      if (err?.response?.data?.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error(CLIENT_MSG.SOMETHING_WENT_WRONG)
      }
    }
  },

  assignIntroducersAction: async (applicationUuid, params, onSuccessCallback) => {
    try {
      const response = await apiGetAdminReferrals.assignIntroducers(applicationUuid, params)

      if (response?.data) {
        const { data } = response.data

        if (typeof onSuccessCallback === "function") {
          onSuccessCallback(data)
        }
      }
    } catch (err: any) {
      console.error(err)

      if (err?.response?.data?.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error(CLIENT_MSG.SOMETHING_WENT_WRONG)
      }
    }
  }
}))

export default useAdminReferralStore
