import React, { useEffect, useRef, useState } from "react"

import { zodResolver } from "@hookform/resolvers/zod"
import { motion } from "framer-motion"
import json2mq from "json2mq"
import { useForm } from "react-hook-form"
import { LuEye, LuEyeOff } from "react-icons/lu"
import { useMediaQuery } from "react-responsive"
import { useNavigate, useParams } from "react-router-dom"
import * as z from "zod"

import AuthLayout from "@/components/_layout/AuthLayout"
import Logo from "@/components/_layout/components/Logo"
import { LoadingButton } from "@/components/_uiext"
import usePasswordResetStore from "@/stores/usePasswordResetStore"
import { ANIMATION_DURATION } from "@/utils/constants"

import { Form, FormField, FormItem, FormLabel, FormMessage } from "@repo/ui/components/ui/form"
import { Input } from "@repo/ui/components/ui/input"

const FormSchema = z
  .object({
    password: z.string().min(8, {
      message: "Password should be at least 8 characters"
    }),
    confirm: z.string().min(8, {
      message: "Confirm password should be at least 8 characters"
    })
  })
  .refine((data) => data.password === data.confirm, {
    message: "Passwords don't match",
    path: ["confirm"]
  })

type FormSchemaType = z.infer<typeof FormSchema>

export default function SetNewPassword() {
  const initialized = useRef(false)
  const [tokenVerified, setTokenVerified] = useState(false)

  const [showNewPassword, setShowNewPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)

  const navigate = useNavigate()
  let { token } = useParams()
  const store = usePasswordResetStore()

  const isMobile = useMediaQuery({ query: json2mq({ maxWidth: 767 }) })

  const variants = {
    initial: {
      opacity: 0,
      y: 20
    },
    animate: (i: number) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: i * ANIMATION_DURATION,
        duration: ANIMATION_DURATION
      }
    })
  }

  const form = useForm<FormSchemaType>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      password: "",
      confirm: ""
    }
  })

  const onSubmit = (values: FormSchemaType) => {
    store.resetPasswordAction({ token, password: values.password }, () => {
      navigate("/login")
    })
  }

  const verifyResetToken = () => {
    store.verifyResetTokenAction(
      { token: token ?? "" },
      () => {
        setTokenVerified(true)
      },
      () => {
        navigate("/login")
      }
    )
  }

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true
      verifyResetToken()
    }
  }, [token])

  if (!tokenVerified) return null

  return (
    <AuthLayout>
      <div className="flex h-full w-full flex-col gap-8">
        <motion.div
          custom={0}
          initial="initial"
          animate="animate"
          variants={variants}
          className="flex flex-wrap items-center justify-center gap-4 md:justify-between"
        >
          <Logo />
        </motion.div>

        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="flex flex-1 flex-col justify-center"
          >
            <motion.h3
              custom={1}
              initial="initial"
              animate="animate"
              variants={variants}
              className="text-main mb-2.5 text-lg font-semibold"
            >
              Set new password
            </motion.h3>

            <motion.p
              custom={2}
              initial="initial"
              animate="animate"
              variants={variants}
              className="mb-8 text-sm font-normal"
            >
              Your password must be at least 8 characters.
            </motion.p>

            <motion.div
              custom={3}
              initial="initial"
              animate="animate"
              variants={variants}
              className="mb-6 w-full"
            >
              <FormField
                control={form.control}
                name="password"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-main text-sm font-normal">New password</FormLabel>
                    <div className="relative w-full">
                      <Input
                        autoFocus={!isMobile}
                        autoComplete="off"
                        type={showNewPassword ? "text" : "password"}
                        {...field}
                      />

                      <div
                        className="absolute right-[4px] top-[50%] translate-x-[-50%] translate-y-[-50%] cursor-pointer"
                        onClick={() => setShowNewPassword((prev) => !prev)}
                      >
                        {showNewPassword ? (
                          <LuEyeOff className="text-[16px]" />
                        ) : (
                          <LuEye className="text-[16px]" />
                        )}
                      </div>
                    </div>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </motion.div>

            <motion.div
              custom={4}
              initial="initial"
              animate="animate"
              variants={variants}
              className="mb-6 w-full"
            >
              <FormField
                control={form.control}
                name="confirm"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-main text-sm font-normal">
                      Confirm new password
                    </FormLabel>
                    <div className="relative w-full">
                      <Input
                        autoComplete="off"
                        type={showConfirmPassword ? "text" : "password"}
                        {...field}
                      />

                      <div
                        className="absolute right-[4px] top-[50%] translate-x-[-50%] translate-y-[-50%] cursor-pointer"
                        onClick={() => setShowConfirmPassword((prev) => !prev)}
                      >
                        {showConfirmPassword ? (
                          <LuEyeOff className="text-[16px]" />
                        ) : (
                          <LuEye className="text-[16px]" />
                        )}
                      </div>
                    </div>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </motion.div>

            <motion.div
              custom={5}
              initial="initial"
              animate="animate"
              variants={variants}
              className="w-full"
            >
              <LoadingButton type="submit" loading={store.loading}>
                Set new password
              </LoadingButton>
            </motion.div>
          </form>
        </Form>
      </div>
    </AuthLayout>
  )
}
