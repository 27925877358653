import React from "react"

import { motion, AnimatePresence } from "framer-motion"

import { LoadingSpinner } from "@/components/_uiext"

import ReviewApplication from "./sections/ReviewApplication"

import { CREATE_MOTION_VARIANT } from "../util"
import { useAppContext } from "../AppContext"

export default function MainForm() {
  const { loading } = useAppContext()

  return (
    <LoadingSpinner loading={loading}>
      <div className="flex flex-col">
        <AnimatePresence>
          <motion.div
            variants={CREATE_MOTION_VARIANT}
            initial="initial"
            animate="animate"
            exit="exit"
          >
            <ReviewApplication />
          </motion.div>
        </AnimatePresence>
      </div>
    </LoadingSpinner>
  )
}
