import React, { useEffect, useState } from "react"

import { zodResolver } from "@hookform/resolvers/zod"
import clsx from "clsx"
import { format } from "date-fns"
import { FormProvider, useFieldArray, useForm } from "react-hook-form"
import { LuCheck, LuMail, LuSendHorizonal, LuX } from "react-icons/lu"
import { InView } from "react-intersection-observer"
import { Oval } from "react-loader-spinner"
import { useNavigate } from "react-router-dom"
import { toast } from "sonner"
import * as z from "zod"

import { IconNext } from "@/components/_icons"
import { HoverIconButton, LoadingButton, ScrollToTop, StickyShortcut } from "@/components/_uiext"
import useAdminApplicationDraftStore from "@/stores/useAdminApplicationDraftStore"

import { Avatar, AvatarFallback, AvatarImage } from "@repo/ui/components/ui/avatar"
import { Button } from "@repo/ui/components/ui/button"
import { Popover, PopoverContent, PopoverTrigger } from "@repo/ui/components/ui/popover"
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from "@repo/ui/components/ui/tooltip"
import { CHECK_ITEM_TYPES, YN } from "@repo/util/constant"
import { CLIENT_MSG } from "@repo/i18n"

import { LOADING_TIMEOUT } from "../util"
import { useAdminAppContext } from "../AdminAppContext"

import SupportingDocumentsItems from "./SupportingDocumentsItems"
import InviteBorrowerDialog from "../_components/InviteBorrowerDialog"

const FormSchema = z.object({
  checkitems: z
    .array(
      z.object({
        section: z.string(),
        items: z
          .array(
            z
              .object({
                type: z.string(),
                text: z.string(),
                choice: z.enum([YN.Yes, YN.No]).default(YN.Yes),
                result: z.union([z.string(), z.instanceof(File)]).optional(),
                modifier_type: z.string().optional(),
                modifier_uuid: z.string().optional(),
                modified_at: z.string().optional()
              })
              .superRefine((data, ctx) => {
                if (data.choice === YN.Yes && !data.result) {
                  ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    path: ["result"],
                    message: CLIENT_MSG.FIELD_REQUIRED
                  })
                }
              })
          )
          .default([])
      })
    )
    .default([])
})

type FormSchemaType = z.infer<typeof FormSchema>

export default function SupportingDocuments() {
  const { steps, activeStep, setActiveStep, setLoading, data, setData } = useAdminAppContext()
  const navigate = useNavigate()
  const store = useAdminApplicationDraftStore()

  const [inviews, setInviews] = useState<boolean[]>([])
  const [openAction, setOpenAction] = useState(false)
  const [openInviteBorrower, setOpenInviteBorrower] = useState(false)

  const [emailingInviteUuid, setEmailingInviteUuid] = useState("")
  const [isConsenting, setIsConsenting] = useState(false)
  const [emailingConsentUuid, setEmailingConsentUuid] = useState("")
  const [isVerifying, setIsVerifying] = useState(false)
  const [emailingVerifyUuid, setEmailingVerifyUuid] = useState("")

  const form = useForm<FormSchemaType>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      checkitems: []
    }
  })

  const { fields } = useFieldArray({
    control: form.control,
    name: "checkitems"
  })

  const initialize = () => {
    const fslt = data?.lender?.fs
      ?.filter((fs: any) => fs.funding_solution === data.funding_solution)?.[0]
      ?.lt?.filter((lt: any) => lt.loan_type === data.loan_type)?.[0]

    if (fslt?.checkitems?.length > 0) {
      const updatedInviews = Array(fslt?.checkitems?.length).fill(false)
      updatedInviews[0] = true
      setInviews(updatedInviews)

      const updatedCheckitems: any[] = []
      fslt?.checkitems.forEach((i: any) => {
        const subitems = i.items
        const items: any[] = []

        subitems.forEach((si: any) => {
          items.push({
            ...si,
            choice: si.type === CHECK_ITEM_TYPES.YesNo ? YN.Yes : YN.No,
            result: ""
          })
        })

        updatedCheckitems.push({
          section: i.section,
          items
        })
      })
      form.setValue("checkitems", data?.checkitems ? data?.checkitems : updatedCheckitems)
    }
  }

  const handleInviteBorrowerClick = () => {
    setOpenAction(false)
    setOpenInviteBorrower(true)
  }

  const handleSendInviteEmail = (inviteUuid: string) => () => {
    setEmailingInviteUuid(inviteUuid)
    store.inviteEmailAction(inviteUuid, () => {
      setEmailingInviteUuid("")
    })
  }

  const handleIDVerificationRequestClick = async () => {
    setOpenAction(false)
    setIsVerifying(true)
    store.idVerifyRequestAction(
      data.uuid,
      (newData) => {
        setData(newData)
        setIsVerifying(false)
      },
      () => {
        setIsVerifying(false)
      }
    )
  }

  const handleSendVerifyEmail = (verifyUuid: string) => () => {
    setEmailingVerifyUuid(verifyUuid)
    store.verifyEmailAction(verifyUuid, () => {
      setEmailingVerifyUuid("")
    })
  }

  const handleConsentToActRequestClick = () => {
    setOpenAction(false)
    setIsConsenting(true)
    store.consentToActRequestAction(data.uuid, (newData) => {
      setData(newData)
      setIsConsenting(false)
    })
  }

  const handleSendConsentEmail = (consentUuid: string) => () => {
    setEmailingConsentUuid(consentUuid)
    store.consentEmailAction(consentUuid, () => {
      setEmailingConsentUuid("")
    })
  }

  const handleBackClick = () => {
    setLoading(true)

    store.updateAction(
      data.uuid,
      {
        params: {
          ...form.getValues(),
          current_step: steps[activeStep - 1]
        }
      },
      (newData) => {
        setData(newData)
        setActiveStep((prev: number) => prev - 1)

        setTimeout(() => {
          setLoading(false)
        }, LOADING_TIMEOUT)
      }
    )
  }

  const handleSaveClick = () => {
    setLoading(true)

    store.updateAction(
      data.uuid,
      {
        params: {
          ...form.getValues(),
          current_step: steps[activeStep]
        }
      },
      (newData) => {
        setData(newData)

        setTimeout(() => {
          setLoading(false)
          navigate("/applications/list")
        }, LOADING_TIMEOUT)
      }
    )
  }

  const onSubmit = (values: FormSchemaType) => {
    const consents = data.consents ?? []
    const verifications = data.verifications ?? []

    if (!consents.length) {
      toast.error(CLIENT_MSG.APP_CONSENT_INCOMPLETE)
      return
    }

    if (!verifications.length) {
      toast.error(CLIENT_MSG.APP_VERIFICATION_INCOMPLETE)
      return
    }

    if (consents.length > consents.filter((c: any) => c.is_completed).length) {
      toast.error(CLIENT_MSG.APP_CONSENT_INCOMPLETE)
      return
    }

    if (verifications.length > verifications.filter((v: any) => v.is_completed).length) {
      toast.error(CLIENT_MSG.APP_VERIFICATION_INCOMPLETE)
      return
    }

    store.updateAction(
      data.uuid,
      {
        params: {
          ...values,
          current_step: steps[activeStep + 1]
        }
      },
      (newData) => {
        setData(newData)
        setActiveStep((prev: number) => prev + 1)
      }
    )
  }

  useEffect(() => {
    initialize()
  }, [data])

  useEffect(() => {
    store.refreshVerificationAction(data?.uuid, (newData) => {
      setData(newData)
    })
  }, [])

  return (
    <FormProvider {...form}>
      <form className="flex flex-col" onSubmit={form.handleSubmit(onSubmit)}>
        <div className="flex w-full rounded-xl border border-[#ECECEC] bg-white p-8">
          <div className="flex w-full flex-1 flex-col pr-0 xl:pr-8">
            <h3 className="text-main mb-2 text-lg font-semibold">Supporting Documents</h3>

            <p className="text-default mb-6 text-sm font-normal">
              Please attach supporting documents.
            </p>

            <div className="bg-background mb-4 grid w-full grid-cols-1 gap-4 rounded-xl border p-6 md:grid-cols-2 lg:grid-cols-3 xl:hidden">
              <p className="text-main col-span-1 text-sm font-semibold md:col-span-2 lg:col-span-3">
                Actions
              </p>

              <Button
                type="button"
                className="text-default h-auto border border-[#868194] bg-transparent px-6 py-4 font-semibold hover:bg-transparent"
                onClick={handleInviteBorrowerClick}
              >
                Invite Borrower
              </Button>

              <LoadingButton
                loading={isVerifying}
                disabled={data?.verifications?.length > 0}
                type="button"
                className="text-default h-auto border border-[#868194] bg-transparent px-6 py-4 font-semibold hover:bg-transparent"
                loadingColor="#000"
                loadingSecondaryColor="#111"
                onClick={handleIDVerificationRequestClick}
              >
                ID Verification Request
              </LoadingButton>

              <LoadingButton
                loading={isConsenting}
                disabled={data?.consents?.length > 0}
                type="button"
                className="text-default h-auto border border-[#868194] bg-transparent px-6 py-4 font-semibold hover:bg-transparent"
                loadingColor="#000"
                loadingSecondaryColor="#111"
                onClick={handleConsentToActRequestClick}
              >
                Consent to Act Request
              </LoadingButton>
            </div>

            <div className="bg-background mb-4 grid w-full grid-cols-1 gap-4 rounded-xl border p-6 md:grid-cols-2 lg:grid-cols-3 xl:hidden">
              <p className="text-main col-span-1 text-sm font-semibold md:col-span-2 lg:col-span-3">
                Created On
              </p>

              <div className="text-main text-sm font-normal">
                Date:{" "}
                <span className="text-default text-sm font-normal">
                  {data?.created_at && format(data?.created_at, "dd/MM/yyyy")}
                </span>
              </div>

              <div className="text-main text-sm font-normal">
                Time:{" "}
                <span className="text-default text-sm font-normal">
                  {data?.created_at && format(data?.created_at, "h:mm aaa")}
                </span>
              </div>

              <div className="flex items-center gap-2">
                <div className="text-main text-sm font-normal">Collaborators:</div>

                <TooltipProvider disableHoverableContent delayDuration={0} skipDelayDuration={0}>
                  <div className="flex items-center">
                    <Tooltip>
                      <TooltipTrigger>
                        <Avatar className="hover:border-primary h-10 w-10 border-2 border-white hover:z-50">
                          <AvatarImage
                            src={data?.creator?.image}
                            alt={`${data?.creator?.first_name} ${data?.creator?.last_name}`}
                          />
                          <AvatarFallback className="uppercase" onClick={(e) => e.preventDefault()}>
                            {data?.creator?.first_name[0]}
                            {data?.creator?.last_name[0]}
                          </AvatarFallback>
                        </Avatar>
                      </TooltipTrigger>

                      <TooltipContent className="grid grid-cols-3 gap-2 p-4">
                        <p className="text-default text-sm font-normal">Full Name:</p>
                        <p className="text-main col-span-2 text-sm font-semibold">
                          {data?.creator?.first_name} {data?.creator?.last_name}
                        </p>
                        <p className="text-default text-sm font-normal">Entity Name:</p>
                        <p className="text-main col-span-2 text-sm font-semibold">
                          {data?.creator?.entity?.entity_name}
                        </p>
                      </TooltipContent>
                    </Tooltip>

                    {data?.assignees?.map((a: any) => (
                      <Tooltip key={a?.user?.id}>
                        <TooltipTrigger>
                          <Avatar className="hover:border-primary ml-[-12px] h-10 w-10 border-2 border-white hover:z-50">
                            <AvatarImage
                              src={a?.user?.image}
                              alt={`${a?.user?.first_name} ${a?.user?.last_name}`}
                            />
                            <AvatarFallback
                              className="uppercase"
                              onClick={(e) => e.preventDefault()}
                            >
                              {a?.user?.first_name[0]}
                              {a?.user?.last_name[0]}
                            </AvatarFallback>
                          </Avatar>
                        </TooltipTrigger>

                        <TooltipContent className="grid grid-cols-3 gap-2 p-4">
                          <p className="text-default text-sm font-normal">Full Name:</p>
                          <p className="text-main col-span-2 text-sm font-semibold">
                            {a?.user?.first_name} {a?.user?.last_name}
                          </p>
                          <p className="text-default text-sm font-normal">Entity Name:</p>
                          <p className="text-main col-span-2 text-sm font-semibold">
                            {a?.user?.entity?.entity_name}
                          </p>
                        </TooltipContent>
                      </Tooltip>
                    ))}
                  </div>
                </TooltipProvider>
              </div>
            </div>

            <div className="bg-background mb-4 grid w-full grid-cols-1 gap-4 rounded-xl border p-6 md:grid-cols-2 xl:hidden">
              <p className="text-main col-span-1 text-sm font-semibold md:col-span-2">
                Authorisations
              </p>

              <div className="flex flex-col gap-4">
                <div className="text-main text-sm font-normal">Invitees:</div>

                {data?.invites?.map((i: any) => (
                  <div key={i.uuid} className="flex items-center gap-2">
                    <HoverIconButton
                      loading={emailingInviteUuid === i.uuid}
                      defaultIcon={<LuMail className="text-default text-lg" />}
                      hoverIcon={<LuSendHorizonal className="text-primary text-lg" />}
                      loadingColor="#aaa"
                      loadingSecondaryColor="#ccc"
                      onClick={handleSendInviteEmail(i.uuid)}
                    />

                    <span className="text-default text-sm font-normal">
                      {`${i?.individual?.first_name} ${i?.individual?.middle_name} ${i?.individual?.last_name}`}
                    </span>
                  </div>
                ))}
              </div>

              <div className="flex flex-col gap-4">
                <div className="text-main text-sm font-normal">Consent to Act:</div>
                {data?.consents?.map((c: any) => (
                  <div key={c.uuid} className="flex items-center gap-2">
                    <div className="flex items-center gap-2">
                      <HoverIconButton
                        loading={emailingConsentUuid === c.uuid}
                        defaultIcon={<LuMail className="text-default text-lg" />}
                        hoverIcon={<LuSendHorizonal className="text-primary text-lg" />}
                        loadingColor="#aaa"
                        loadingSecondaryColor="#ccc"
                        onClick={handleSendConsentEmail(c.uuid)}
                      />
                      <span className="text-default text-sm font-normal">
                        {c?.full_name ||
                          `${c?.individual?.first_name} ${c?.individual?.middle_name} ${c?.individual?.last_name}`}
                      </span>
                    </div>
                    <div
                      className={clsx(
                        "flex h-4 w-4 items-center justify-center rounded-full text-white",
                        c?.is_completed ? "bg-primary" : "bg-[#F59E0B]"
                      )}
                    >
                      {c?.is_completed ? (
                        <LuCheck className="text-xs" />
                      ) : (
                        <LuX className="text-xs" />
                      )}
                    </div>
                  </div>
                ))}
              </div>

              <div className="flex flex-col gap-4">
                <div className="text-main text-sm font-normal">ID Verification:</div>
                {data?.verifications?.map((v: any) => (
                  <div key={v.uuid} className="flex items-center gap-2">
                    <div className="flex items-center gap-2">
                      <HoverIconButton
                        loading={emailingVerifyUuid === v.uuid}
                        defaultIcon={<LuMail className="text-default text-lg" />}
                        hoverIcon={<LuSendHorizonal className="text-primary text-lg" />}
                        loadingColor="#aaa"
                        loadingSecondaryColor="#ccc"
                        onClick={handleSendVerifyEmail(v.uuid)}
                      />
                      <span className="text-default text-sm font-normal">
                        {v?.name ||
                          `${v?.individual?.first_name} ${v?.individual?.middle_name} ${v?.individual?.last_name}`}
                      </span>
                    </div>
                    <div
                      className={clsx(
                        "flex h-4 w-4 items-center justify-center rounded-full text-white",
                        v?.is_completed ? "bg-primary" : "bg-[#F59E0B]"
                      )}
                    >
                      {v?.is_completed ? (
                        <LuCheck className="text-xs" />
                      ) : (
                        <LuX className="text-xs" />
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="flex w-full flex-col">
              {fields.map((item, index) => (
                <InView
                  key={item.id}
                  id={`iv-${item.section.replaceAll(" ", "_")}`}
                  threshold={0.1}
                  className="mb-8 flex flex-col"
                  onChange={(inView, entry) => {
                    setInviews((prev) => {
                      const updatedValue = JSON.parse(JSON.stringify(prev))
                      updatedValue[index] = inView
                      return updatedValue
                    })
                  }}
                >
                  <h3 className="text-main mb-4 text-lg font-semibold">{item.section}</h3>

                  <SupportingDocumentsItems
                    data={data}
                    checkItemIndex={index}
                    modifierType="creator"
                    modifierUuid={data?.creator?.uuid ?? ""}
                  />
                </InView>
              ))}
            </div>
          </div>

          <StickyShortcut
            unitHeight={40}
            hasWidget
            widgetBefore={
              <div className="flex flex-col">
                <Popover open={openAction} onOpenChange={setOpenAction}>
                  <PopoverTrigger
                    disabled={isConsenting || isVerifying}
                    type="button"
                    className={clsx(
                      "border-primary bg-primary hover:bg-primary mb-6 flex h-auto w-full items-center justify-center rounded-xl border px-6 py-3 text-sm font-semibold text-white",
                      (isConsenting || isVerifying) && "opacity-50"
                    )}
                  >
                    {isConsenting || isVerifying ? (
                      <Oval
                        visible={true}
                        height={20}
                        width={20}
                        strokeWidth={8}
                        color="#FFF"
                        secondaryColor="#EEE"
                      />
                    ) : (
                      <>Actions</>
                    )}
                  </PopoverTrigger>

                  <PopoverContent
                    className="bg-background popover-content-width-same-as-its-trigger flex flex-col gap-2 rounded-xl"
                    sideOffset={10}
                  >
                    <Button
                      type="button"
                      className="text-default h-auto border border-[#868194] bg-transparent px-6 py-3 font-semibold hover:bg-transparent"
                      onClick={handleInviteBorrowerClick}
                    >
                      Invite Borrower
                    </Button>

                    <Button
                      disabled={data?.verifications?.length > 0}
                      type="button"
                      className="text-default h-auto border border-[#868194] bg-transparent px-6 py-3 font-semibold hover:bg-transparent"
                      onClick={handleIDVerificationRequestClick}
                    >
                      ID Verification Request
                    </Button>

                    <Button
                      disabled={data?.consents?.length > 0}
                      type="button"
                      className="text-default h-auto border border-[#868194] bg-transparent px-6 py-3 font-semibold hover:bg-transparent"
                      onClick={handleConsentToActRequestClick}
                    >
                      Consent to Act Request
                    </Button>
                  </PopoverContent>
                </Popover>

                <div className="bg-background mb-6 flex flex-col rounded-xl border p-6">
                  <div className="text-main mb-4 text-sm font-semibold">Created On</div>

                  <div className="text-main mb-4 text-sm font-normal">
                    Date:{" "}
                    <span className="text-default text-sm font-normal">
                      {data?.created_at && format(data?.created_at, "dd/MM/yyyy")}
                    </span>
                  </div>

                  <div className="text-main mb-4 text-sm font-normal">
                    Time:{" "}
                    <span className="text-default text-sm font-normal">
                      {data?.created_at && format(data?.created_at, "h:mm aaa")}
                    </span>
                  </div>

                  <div className="flex items-center gap-2">
                    <div className="text-main text-sm font-normal">Collaborators:</div>

                    <TooltipProvider
                      disableHoverableContent
                      delayDuration={0}
                      skipDelayDuration={0}
                    >
                      <div className="flex items-center">
                        <Tooltip>
                          <TooltipTrigger>
                            <Avatar className="hover:border-primary h-10 w-10 border-2 border-white hover:z-50">
                              <AvatarImage
                                src={data?.creator?.image}
                                alt={`${data?.creator?.first_name} ${data?.creator?.last_name}`}
                              />
                              <AvatarFallback
                                className="uppercase"
                                onClick={(e) => e.preventDefault()}
                              >
                                {data?.creator?.first_name[0]}
                                {data?.creator?.last_name[0]}
                              </AvatarFallback>
                            </Avatar>
                          </TooltipTrigger>

                          <TooltipContent className="grid grid-cols-3 gap-2 p-4">
                            <p className="text-default text-sm font-normal">Full Name:</p>
                            <p className="text-main col-span-2 text-sm font-semibold">
                              {data?.creator?.first_name} {data?.creator?.last_name}
                            </p>
                            <p className="text-default text-sm font-normal">Entity Name:</p>
                            <p className="text-main col-span-2 text-sm font-semibold">
                              {data?.creator?.entity?.entity_name}
                            </p>
                          </TooltipContent>
                        </Tooltip>

                        {data?.assignees?.map((a: any) => (
                          <Tooltip key={a?.user?.id}>
                            <TooltipTrigger>
                              <Avatar className="hover:border-primary ml-[-12px] h-10 w-10 border-2 border-white hover:z-50">
                                <AvatarImage
                                  src={a?.user?.image}
                                  alt={`${a?.user?.first_name} ${a?.user?.last_name}`}
                                />
                                <AvatarFallback
                                  className="uppercase"
                                  onClick={(e) => e.preventDefault()}
                                >
                                  {a?.user?.first_name[0]}
                                  {a?.user?.last_name[0]}
                                </AvatarFallback>
                              </Avatar>
                            </TooltipTrigger>

                            <TooltipContent className="grid grid-cols-3 gap-2 p-4">
                              <p className="text-default text-sm font-normal">Full Name:</p>
                              <p className="text-main col-span-2 text-sm font-semibold">
                                {a?.user?.first_name} {a?.user?.last_name}
                              </p>
                              <p className="text-default text-sm font-normal">Entity Name:</p>
                              <p className="text-main col-span-2 text-sm font-semibold">
                                {a?.user?.entity?.entity_name}
                              </p>
                            </TooltipContent>
                          </Tooltip>
                        ))}
                      </div>
                    </TooltipProvider>
                  </div>
                </div>

                <div className="bg-background mb-6 flex flex-col rounded-xl border p-6">
                  <div className="text-main mb-4 text-sm font-semibold">Authorisations</div>

                  <div className="mb-4 flex flex-col gap-4">
                    <div className="text-main text-sm font-normal">Invitees:</div>
                    {data?.invites?.map((i: any) => (
                      <div key={i.uuid} className="flex items-center gap-2">
                        <HoverIconButton
                          loading={emailingInviteUuid === i.uuid}
                          defaultIcon={<LuMail className="text-default text-lg" />}
                          hoverIcon={<LuSendHorizonal className="text-primary text-lg" />}
                          loadingColor="#aaa"
                          loadingSecondaryColor="#ccc"
                          onClick={handleSendInviteEmail(i.uuid)}
                        />

                        <span className="text-default text-sm font-normal">
                          {`${i?.individual?.first_name} ${i?.individual?.middle_name} ${i?.individual?.last_name}`}
                        </span>
                      </div>
                    ))}
                  </div>

                  <div className="mb-4 flex flex-col gap-4">
                    <div className="text-main text-sm font-normal">Consent to Act:</div>
                    {data?.consents?.map((c: any) => (
                      <div key={c.uuid} className="flex items-center gap-2">
                        <div className="flex items-center gap-2">
                          <HoverIconButton
                            loading={emailingConsentUuid === c.uuid}
                            defaultIcon={<LuMail className="text-default text-lg" />}
                            hoverIcon={<LuSendHorizonal className="text-primary text-lg" />}
                            loadingColor="#aaa"
                            loadingSecondaryColor="#ccc"
                            onClick={handleSendConsentEmail(c.uuid)}
                          />

                          <span className="text-default text-sm font-normal">
                            {c?.full_name ||
                              `${c?.individual?.first_name} ${c?.individual?.middle_name} ${c?.individual?.last_name}`}
                          </span>
                        </div>

                        <div
                          className={clsx(
                            "flex h-4 w-4 items-center justify-center rounded-full text-white",
                            c?.is_completed ? "bg-primary" : "bg-[#F59E0B]"
                          )}
                        >
                          {c?.is_completed ? (
                            <LuCheck className="text-xs" />
                          ) : (
                            <LuX className="text-xs" />
                          )}
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="flex flex-col gap-4">
                    <div className="text-main text-sm font-normal">ID Verification:</div>
                    {data?.verifications?.map((v: any) => (
                      <div key={v.uuid} className="flex items-center gap-2">
                        <div className="flex items-center gap-2">
                          <HoverIconButton
                            loading={emailingVerifyUuid === v.uuid}
                            defaultIcon={<LuMail className="text-default text-lg" />}
                            hoverIcon={<LuSendHorizonal className="text-primary text-lg" />}
                            loadingColor="#aaa"
                            loadingSecondaryColor="#ccc"
                            onClick={handleSendVerifyEmail(v.uuid)}
                          />

                          <span className="text-default text-sm font-normal">
                            {v?.name ||
                              `${v?.individual?.first_name} ${v?.individual?.middle_name} ${v?.individual?.last_name}`}
                          </span>
                        </div>

                        <div
                          className={clsx(
                            "flex h-4 w-4 items-center justify-center rounded-full text-white",
                            v?.is_completed ? "bg-primary" : "bg-[#F59E0B]"
                          )}
                        >
                          {v?.is_completed ? (
                            <LuCheck className="text-xs" />
                          ) : (
                            <LuX className="text-xs" />
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            }
          />
        </div>

        <div className="flex w-full justify-between gap-2 pb-2 pt-6">
          <Button
            type="button"
            className="text-default h-auto bg-transparent px-0 py-4 font-semibold hover:bg-transparent"
            onClick={handleSaveClick}
          >
            Save and exit
          </Button>

          <div className="flex justify-end gap-2">
            <Button
              type="button"
              className="text-default h-auto border border-[#868194] bg-transparent px-6 py-4 font-semibold hover:bg-transparent"
              onClick={handleBackClick}
            >
              Back
            </Button>

            <LoadingButton
              loading={store.locked}
              type="submit"
              className="flex items-center gap-4 px-6 py-4 md:!w-auto"
            >
              Next
              <IconNext className="text-sm" />
            </LoadingButton>
          </div>
        </div>
      </form>

      <InviteBorrowerDialog
        open={openInviteBorrower}
        setOpen={setOpenInviteBorrower}
        data={data}
        setData={setData}
      />

      <ScrollToTop />
    </FormProvider>
  )
}
