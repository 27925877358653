import React, { useCallback, useEffect, useState } from "react"

import json2mq from "json2mq"
import { useMediaQuery } from "react-responsive"

import UserLayout from "@/components/_layout/UserLayout"
import useAuthStore from "@/stores/useAuthStore"
import useDashboardStore from "@/stores/useDashboardStore"
import { ITableParams } from "@/types/interface"

import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbPage
} from "@repo/ui/components/ui/breadcrumb"
import { USER_ROLES } from "@repo/util/constant"

import ApplicationStatusCounter from "./sections/ApplicationStatusCounter"
import CommissionsSettlements from "./sections/CommissionsSettlements"
import DataTable from "./DataTable"
import columns from "./DataTable/columns"

export default function Dashboard() {
  const store = useDashboardStore()
  const authStore = useAuthStore()

  const [loading, setLoading] = useState(true)
  const [appStatusCount, setAppStatusCount] = useState<any[]>([])
  const [financeData, setFinanceData] = useState<any[]>([])
  const [totalCount, setTotalCount] = useState(0)
  const [data, setData] = useState<any[]>([])

  const [tableParams, setTableParams] = useState<ITableParams>({
    isMobile: false,
    filter: {
      is_archived: true,
      application_id: "",
      entity_name: ""
    },
    sort: {
      column: "created_at",
      direction: "desc"
    },
    pagination: {
      page_index: 1,
      page_size: 10
    }
  })

  const isMobile = useMediaQuery({ query: json2mq({ maxWidth: 767 }) })

  const initialize = useCallback(() => {
    store.getAction({ ...tableParams }, (newData) => {
      setAppStatusCount(newData.appStatusCount)
      setFinanceData(newData.financeData)
      setTotalCount(newData.totalCount)
      setData(newData.data)

      setTimeout(() => {
        setLoading(false)
      }, 200)
    })
  }, [tableParams, store.reloadRequired])

  useEffect(() => {
    initialize()
  }, [initialize])

  useEffect(() => {
    if (isMobile !== tableParams.isMobile) {
      setTableParams((prev: ITableParams) => ({
        ...prev,
        isMobile,
        pagination: {
          ...prev.pagination,
          page_size: isMobile ? -1 : 10
        }
      }))
    }
  }, [isMobile])

  return (
    <UserLayout
      breadcrumb={
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbItem>
              <BreadcrumbPage className="text-main text-sm font-semibold">Dashboard</BreadcrumbPage>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
      }
    >
      <div className="flex flex-col">
        {authStore.user?.role !== USER_ROLES.Introducer && (
          <div className="mb-4">
            <ApplicationStatusCounter data={appStatusCount} />
          </div>
        )}

        <div className="mb-4">
          <CommissionsSettlements loading={loading} data={financeData} />
        </div>

        <div>
          <DataTable
            loading={loading}
            columns={columns}
            data={data}
            totalCount={totalCount}
            tableParams={tableParams}
            setTableParams={setTableParams}
          />
        </div>
      </div>
    </UserLayout>
  )
}
