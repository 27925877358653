import { toast } from "sonner"
import { create } from "zustand"

import {
  apiGetConsentOnConsent,
  apiSubmitConsentOnConsent,
  apiVerifyConsentOnConsent
} from "@/api/applicationConsentApi"

import { CLIENT_MSG } from "@repo/i18n"

interface IApplicationConsentState {
  loading: boolean

  getConsentAction: (consentUuid: string, onSuccessCallback?: (newData: any) => void) => void
  verifyConsentAction: (
    consentUuid: string,
    smsCode: string,
    onSuccessCallback?: (newData: any) => void,
    onFailureCallback?: () => void
  ) => void

  submitConsentAction: (
    consentUuid: string,
    params: any,
    onSuccessCallback?: (data: any) => void
  ) => void
}

const useApplicationConsentStore = create<IApplicationConsentState>()((set, get) => ({
  loading: false,

  getConsentAction: async (consentUuid, onSuccessCallback) => {
    try {
      set({ loading: true })

      const response = await apiGetConsentOnConsent(consentUuid)

      if (response?.data) {
        const { data } = response.data

        if (typeof onSuccessCallback === "function") {
          onSuccessCallback(data)
        }
      }
    } catch (err: any) {
      console.error(err)

      if (err?.response?.data?.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error(CLIENT_MSG.SOMETHING_WENT_WRONG)
      }
    } finally {
      set({ loading: false })
    }
  },

  verifyConsentAction: async (consentUuid, smsCode, onSuccessCallback, onFailureCallback) => {
    try {
      set({ loading: true })

      const response = await apiVerifyConsentOnConsent(consentUuid, smsCode)

      if (response?.data) {
        const { data } = response.data

        if (typeof onSuccessCallback === "function") {
          onSuccessCallback(data)
        }
      }
    } catch (err: any) {
      console.error(err)

      if (err?.response?.data?.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error(CLIENT_MSG.SOMETHING_WENT_WRONG)
      }

      if (typeof onFailureCallback === "function") {
        onFailureCallback()
      }
    } finally {
      set({ loading: false })
    }
  },

  submitConsentAction: async (consentUuid, params, onSuccessCallback) => {
    try {
      set({ loading: true })

      const response = await apiSubmitConsentOnConsent(consentUuid, params)

      if (response?.data) {
        const { message, data } = response.data
        toast.success(message)

        if (typeof onSuccessCallback === "function") {
          onSuccessCallback(data)
        }
      }
    } catch (err: any) {
      console.error(err)

      if (err?.response?.data?.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error(CLIENT_MSG.SOMETHING_WENT_WRONG)
      }
    } finally {
      set({ loading: false })
    }
  }
}))

export default useApplicationConsentStore
