import qs from "qs"

import api from "./api"

// Get applications list
const getApplicationsList = (params: any) =>
  api().get(`/admin/referrals/get-applications?${qs.stringify(params)}`)

// Get introducers
const getIntroducers = (params: any) =>
  api().get(`/admin/referrals/get-introducers?${qs.stringify(params)}`)

// Assign introducers to an application
export const assignIntroducers = (applicationUuid: string, params: any) =>
  api().postForm(`/admin/referrals/assign-introducers`, {
    application_uuid: applicationUuid,
    ...params
  })

export default { getApplicationsList, getIntroducers, assignIntroducers }
