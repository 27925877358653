import React, { Dispatch, SetStateAction, useState } from "react"

import clsx from "clsx"

import { IconSort } from "@/components/_icons"
import { ITableParams } from "@/types/interface"

import { Popover, PopoverContent, PopoverTrigger } from "@repo/ui/components/ui/popover"
import { Button } from "@repo/ui/components/ui/button"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "@repo/ui/components/ui/select"

interface Props {
  tableParams: ITableParams
  setTableParams: Dispatch<SetStateAction<ITableParams>>
}

export default function SortPopover({ tableParams, setTableParams }: Readonly<Props>) {
  const [open, setOpen] = useState(false)
  const [column, setColumn] = useState(tableParams.sort.column)
  const [direction, setDirection] = useState(tableParams.sort.direction)

  const handleResetClick = () => {
    setColumn("created_at")
    setDirection("desc")

    setTableParams((prev: ITableParams) => ({
      ...prev,
      sort: {
        column: "created_at",
        direction: "desc"
      }
    }))
    setOpen(false)
  }

  const handleApplyClick = () => {
    setTableParams((prev: ITableParams) => ({
      ...prev,
      sort: {
        column,
        direction
      }
    }))
    setOpen(false)
  }

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger
        className={clsx(
          "text-default flex h-auto w-full items-center justify-center gap-1.5 rounded-xl border bg-transparent px-4 py-3 text-sm font-semibold hover:bg-transparent md:w-auto",
          open ? "border-primary" : "border-[#868194]"
        )}
      >
        <IconSort className="text-xl" />
        Sort
      </PopoverTrigger>

      <PopoverContent
        align="end"
        className="flex w-[calc(100vw-32px)] translate-x-4 flex-col gap-4 rounded-xl p-4 md:w-[400px] md:translate-x-0"
      >
        <p className="text-main text-sm">Sort</p>

        <Select
          value={column}
          onValueChange={(value: string) => {
            setColumn(value)
          }}
        >
          <SelectTrigger className="px-4 py-1 text-sm">
            <SelectValue placeholder={column} />
          </SelectTrigger>

          <SelectContent>
            <SelectItem value="created_at">Created On</SelectItem>
            <SelectItem value="id">Application ID</SelectItem>
            <SelectItem value="entity_name">Entity Name</SelectItem>
            <SelectItem value="loan_amount">Loan Amount</SelectItem>
          </SelectContent>
        </Select>

        <Select
          value={direction}
          onValueChange={(value: string) => {
            setDirection(value)
          }}
        >
          <SelectTrigger className="px-4 py-1 text-sm">
            <SelectValue placeholder={direction} />
          </SelectTrigger>

          <SelectContent>
            <SelectItem value="asc">Ascending</SelectItem>
            <SelectItem value="desc">Descending</SelectItem>
          </SelectContent>
        </Select>

        <div className="flex justify-end gap-2.5">
          <Button
            className="text-default h-auto border border-[#868194] bg-transparent px-6 py-4 text-sm font-semibold hover:bg-transparent"
            onClick={handleResetClick}
          >
            Reset
          </Button>
          <Button
            className="border-primary h-auto border px-6 py-4 text-sm font-semibold"
            onClick={handleApplyClick}
          >
            Apply sort
          </Button>
        </div>
      </PopoverContent>
    </Popover>
  )
}
