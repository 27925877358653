import React from "react"

import { APIProvider } from "@vis.gl/react-google-maps"
import { useFormContext } from "react-hook-form"

import { PlacesAutoPredict } from "@/components/_uiext"
import { extractPlace } from "@/utils/google"

import { Checkbox } from "@repo/ui/components/ui/checkbox"
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from "@repo/ui/components/ui/form"
import { Input } from "@repo/ui/components/ui/input"
import { ENTITY_TYPES } from "@repo/util/constant"

interface Props {
  isView?: boolean
}

export default function EntityTypeQuestions(props: Readonly<Props>) {
  const { isView = false } = props
  const form = useFormContext()

  return (
    <div className="flex w-full flex-col">
      {form.watch("entity_type") && form.watch("entity_type") === ENTITY_TYPES.Trust.key && (
        <>
          <h3 className="text-main mb-4 py-4 text-lg font-semibold">Questions</h3>

          <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
            <FormField
              control={form.control}
              name="etq_trust_is_settlor"
              render={({ field }) => (
                <FormItem className="flex items-start space-x-2 space-y-0">
                  <FormControl>
                    <Checkbox
                      disabled={isView}
                      checked={field.value}
                      onCheckedChange={(checked) => {
                        field.onChange(checked)
                      }}
                      className="data-[state=checked]:border-primary h-5 w-5 rounded-[4px] border-[#CDCDCD]"
                    />
                  </FormControl>
                  <div className="flex flex-col">
                    <FormLabel className="text-main text-sm font-normal">
                      If the initial settled sum to establish the Trust is $10,000 or more, please
                      provide name and address of the settler of the Trust:
                    </FormLabel>
                    <FormMessage />
                  </div>
                </FormItem>
              )}
            />
          </div>

          <div className="mb-4 flex w-full flex-col gap-4 px-4 md:flex-row md:gap-8">
            <div className="flex-1">
              <FormField
                control={form.control}
                name="etq_trust_settlor_name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-main text-sm font-normal">Name of settlor</FormLabel>
                    <Input
                      disabled={!form.watch("etq_trust_is_settlor") || isView}
                      autoComplete="off"
                      {...field}
                    />
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <div className="hidden flex-1 md:block" />
          </div>

          <h4 className="text-main mb-4 px-4 text-sm font-semibold">Address of settlor</h4>

          <div className="mb-4 flex w-full flex-col gap-4 px-4 md:flex-row md:gap-8">
            <div className="flex-1">
              <FormField
                control={form.control}
                name="etq_trust_settlor_street_address"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-main text-sm font-normal">Street address</FormLabel>
                    <APIProvider apiKey={import.meta.env.VITE_GOOGLE_API_KEY ?? ""}>
                      <PlacesAutoPredict
                        disabled={!form.watch("etq_trust_is_settlor") || isView}
                        textValue={field.value}
                        onPlaceSelect={(address_components: Array<Record<string, any>> | null) => {
                          if (address_components) {
                            const placeInfo = extractPlace(address_components)

                            form.setValue(`etq_trust_settlor_street_address`, placeInfo.street)
                            form.setValue(`etq_trust_settlor_suburb`, placeInfo.suburb)
                            form.setValue(`etq_trust_settlor_state`, placeInfo.state?.long_name)
                            form.setValue(`etq_trust_settlor_postcode`, placeInfo.postcode)
                          }
                        }}
                      />
                    </APIProvider>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <div className="flex-1">
              <FormField
                control={form.control}
                name="etq_trust_settlor_suburb"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-main text-sm font-normal">Suburb</FormLabel>
                    <Input
                      disabled={!form.watch("etq_trust_is_settlor") || isView}
                      autoComplete="off"
                      {...field}
                    />
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          </div>

          <div className="mb-4 flex w-full flex-col gap-4 px-4 md:flex-row md:gap-8">
            <div className="flex-1">
              <FormField
                control={form.control}
                name="etq_trust_settlor_state"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-main text-sm font-normal">State</FormLabel>
                    <Input
                      disabled={!form.watch("etq_trust_is_settlor") || isView}
                      autoComplete="off"
                      {...field}
                    />
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <div className="flex-1">
              <FormField
                control={form.control}
                name="etq_trust_settlor_postcode"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-main text-sm font-normal">Postcode</FormLabel>
                    <Input
                      disabled={!form.watch("etq_trust_is_settlor") || isView}
                      autoComplete="off"
                      {...field}
                    />
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          </div>

          <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
            <FormField
              control={form.control}
              name="etq_trust_is_exposed_person"
              render={({ field }) => (
                <FormItem className="flex items-start space-x-2 space-y-0">
                  <FormControl>
                    <Checkbox
                      disabled={isView}
                      checked={field.value}
                      onCheckedChange={(checked) => {
                        field.onChange(checked)
                      }}
                      className="data-[state=checked]:border-primary h-5 w-5 rounded-[4px] border-[#CDCDCD]"
                    />
                  </FormControl>
                  <div className="flex flex-col">
                    <FormLabel className="text-main text-sm font-normal">
                      Are any of the individuals listed as a beneficiary of the trust or as trust
                      settlor a Politically Exposed person? (Do you or does an immediate family
                      member hold a prominent public or function ina government body or
                      international organisation. E.g, a government minister, high-ranking member of
                      the Armed Forces or a Chairman, CEO or CFO of an international organisation?)
                    </FormLabel>
                    <FormMessage />
                  </div>
                </FormItem>
              )}
            />
          </div>
        </>
      )}
    </div>
  )
}
