import React from "react"

interface Props {
  className?: string
}

export default function IconUpload({ className }: Readonly<Props>) {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.931885 8.474C0.931885 6.7887 2.25925 5.41318 3.92547 5.33596L4.07334 5.33254H4.73422C5.02762 5.33254 5.26547 5.57039 5.26547 5.86379C5.26547 6.13274 5.06561 6.35501 4.80631 6.39019L4.73422 6.39504H4.07334C2.96629 6.39504 2.06115 7.26063 1.99791 8.35185L1.99438 8.474L1.99438 11.9271C1.99438 13.0336 2.86006 13.9386 3.95121 14.0018L4.07334 14.0054H11.9571C13.0641 14.0054 13.9693 13.1399 14.0325 12.0492L14.0361 11.9271V8.46691C14.0361 7.36378 13.1736 6.46159 12.0866 6.39856L11.9649 6.39504H11.2969C11.0035 6.39504 10.7657 6.15719 10.7657 5.86379C10.7657 5.59484 10.9655 5.37257 11.2248 5.33739L11.2969 5.33254H11.9649C13.6445 5.33254 15.0157 6.65486 15.0949 8.31508L15.0986 8.46691V11.9271C15.0986 13.6119 13.7711 14.9872 12.105 15.0645L11.9571 15.0679H4.07334C2.38818 15.0679 1.01254 13.7406 0.935305 12.0749L0.931885 11.9271L0.931885 8.474ZM7.63186 0.683845C7.72854 0.583152 7.86451 0.520475 8.01512 0.520475C8.16761 0.520475 8.30509 0.584718 8.40197 0.687613L10.457 2.75106C10.664 2.95895 10.6633 3.29532 10.4554 3.50236C10.2664 3.69058 9.97125 3.70712 9.7636 3.55237L9.70412 3.50081L8.54637 2.33911V9.58077C8.54637 9.87417 8.30853 10.112 8.01512 10.112C7.74617 10.112 7.5239 9.91216 7.48872 9.65285L7.48387 9.58077L7.48387 2.3379L6.32673 3.50075C6.13855 3.68977 5.84345 3.70758 5.63513 3.55373L5.57543 3.50242C5.38641 3.31424 5.36861 3.01914 5.52246 2.81082L5.57376 2.75112L7.63186 0.683845Z"
        fill="currentColor"
      />
    </svg>
  )
}
