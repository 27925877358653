import React from "react"

interface Props {
  className?: string
}

export default function IconNext({ className }: Readonly<Props>) {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 10 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.09178 13.8046L1.09177 13.8046C0.937849 13.9515 0.85 14.1524 0.85 14.3633C0.85 14.5743 0.937849 14.7751 1.09177 14.922C1.24546 15.0687 1.45248 15.15 1.66696 15.15C1.88144 15.15 2.08846 15.0687 2.24215 14.922L8.90801 8.55876C8.98424 8.48607 9.04511 8.39937 9.08678 8.30342C9.12845 8.20746 9.15 8.10433 9.15 8C9.15 7.89567 9.12845 7.79254 9.08678 7.69658C9.04511 7.60063 8.98424 7.51393 8.90801 7.44124L2.24215 1.07798C2.166 1.00528 2.07596 0.947964 1.97736 0.908974C1.87876 0.869987 1.77331 0.85 1.66696 0.85C1.56062 0.85 1.45517 0.869987 1.35657 0.908974C1.25797 0.947964 1.16793 1.00528 1.09177 1.07798C1.0156 1.15069 0.954792 1.23739 0.913165 1.33333C0.87153 1.42928 0.85 1.53238 0.85 1.63669C0.85 1.74099 0.87153 1.84409 0.913165 1.94004C0.954792 2.03598 1.0156 2.12268 1.09177 2.19539L1.09178 2.1954L7.17324 8L1.09178 13.8046Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.3"
      />
    </svg>
  )
}
