import React from "react"

import { FUNDING_SOLUTIONS } from "@repo/util/constant"

import { ContentSecurityDetailsConstructionFinance } from "./ConstructionFinance"
import { ContentSecurityDetailsAgriculturalBusinessFinance } from "./AgriculturalBusinessFinance"
import { ContentSecurityDetailsCorporateFinance } from "./CorporateFinance"
import { ContentSecurityDetailsDevelopmentFinance } from "./DevelopmentFinance"
import { ContentSecurityDetailsPropertyFinance } from "./PropertyFinance"
import { ContentSecurityDetailsSMEFinance } from "./SMEFinance"
import { ContentSecurityDetailsSMSFFinance } from "./SMSFFinance"
import { ContentSecurityDetailsCashflowFinance } from "./CashflowFinance"

interface Props {
  data: Record<string, any>
}

export default function ContentSecurityDetails(props: Readonly<Props>) {
  const { data } = props

  if (data?.funding_solution === FUNDING_SOLUTIONS.ConstructionFinance.key) {
    return <ContentSecurityDetailsConstructionFinance data={data} />
  }

  if (data?.funding_solution === FUNDING_SOLUTIONS.AgriculturalBusinessFinance.key) {
    return <ContentSecurityDetailsAgriculturalBusinessFinance data={data} />
  }

  if (data?.funding_solution === FUNDING_SOLUTIONS.CorporateFinance.key) {
    return <ContentSecurityDetailsCorporateFinance data={data} />
  }

  if (data?.funding_solution === FUNDING_SOLUTIONS.PreDevelopmentFinance.key) {
    return <ContentSecurityDetailsDevelopmentFinance data={data} />
  }

  if (data?.funding_solution === FUNDING_SOLUTIONS.PropertyFinance.key) {
    return <ContentSecurityDetailsPropertyFinance data={data} />
  }

  if (data?.funding_solution === FUNDING_SOLUTIONS.SMEFinance.key) {
    return <ContentSecurityDetailsSMEFinance data={data} />
  }

  if (data?.funding_solution === FUNDING_SOLUTIONS.SMSFFinance.key) {
    return <ContentSecurityDetailsSMSFFinance data={data} />
  }

  if (data?.funding_solution === FUNDING_SOLUTIONS.CashflowFinance.key) {
    return <ContentSecurityDetailsCashflowFinance data={data} />
  }

  return null
}
