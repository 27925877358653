import React, { useEffect, useRef, useState } from "react"

import { zodResolver } from "@hookform/resolvers/zod"
import { motion } from "framer-motion"
import json2mq from "json2mq"
import { useForm } from "react-hook-form"
import { LuClipboard } from "react-icons/lu"
import { useMediaQuery } from "react-responsive"
import { useNavigate } from "react-router-dom"
import { toast } from "sonner"
import * as z from "zod"

import Logo from "@/components/_layout/components/Logo"
import { LoadingButton } from "@/components/_uiext"
import { useCopyToClipboard } from "@/hooks"
import useSignupStore from "@/stores/useSignupStore"
import { ANIMATION_DURATION } from "@/utils/constants"

import { Form, FormControl, FormField, FormItem, FormMessage } from "@repo/ui/components/ui/form"
import { InputOTP, InputOTPGroup, InputOTPSlot } from "@repo/ui/components/ui/input-otp"
import { CLIENT_MSG } from "@repo/i18n"

import StageIndicator from "./components/StageIndicator"

const FormSchema = z.object({
  token: z.string().min(6, {
    message: "Your one-time password must be 6 characters."
  })
})

type FormSchemaType = z.infer<typeof FormSchema>

export default function Step04() {
  const navigate = useNavigate()
  const store = useSignupStore()
  const { copy } = useCopyToClipboard()

  const initialized = useRef(false)
  const [showSecret, setShowSecret] = useState(false)

  const isMobile = useMediaQuery({ query: json2mq({ maxWidth: 767 }) })

  const variants = {
    initial: {
      opacity: 0,
      y: 20
    },
    animate: (i: number) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: i * ANIMATION_DURATION,
        duration: ANIMATION_DURATION
      }
    })
  }

  const form = useForm<FormSchemaType>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      token: ""
    }
  })

  const handleCompleteToken = () => {
    form.handleSubmit(onSubmit)()
  }

  const handleShowSecretClick = () => {
    setShowSecret((prev) => !prev)
  }

  const handleCopySecretClick = () => {
    try {
      copy(store.qrCodeKey)
      toast.success("Code copied to clipboard.")
    } catch (e) {
      console.error(e)
      toast.error(CLIENT_MSG.SOMETHING_WENT_WRONG)
    }
  }

  const onSubmit = async (values: FormSchemaType) => {
    store.verifyMfaAction(
      values.token,
      () => {
        navigate("/login")
      },
      () => {
        form.resetField("token")
      }
    )
  }

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true
    }
  }, [])

  return (
    <div className="flex h-full w-full flex-col gap-8">
      <motion.div
        custom={0}
        initial="initial"
        animate="animate"
        variants={variants}
        className="flex flex-wrap items-center justify-center gap-4 md:justify-between"
      >
        <Logo />
      </motion.div>

      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="flex flex-1 flex-col justify-center"
        >
          <motion.h3
            custom={1}
            initial="initial"
            animate="animate"
            variants={variants}
            className="text-main mb-2.5 text-lg font-semibold"
          >
            Set up two factor authenticator (2FA)
          </motion.h3>

          <motion.p
            custom={2}
            initial="initial"
            animate="animate"
            variants={variants}
            className="mb-8 text-sm font-normal"
          >
            To continue, scan the QR code with any authenticator app.
          </motion.p>

          {!showSecret && (
            <motion.div
              custom={!initialized.current ? 3 : 0}
              initial="initial"
              animate="animate"
              variants={variants}
              className="bg-background mb-6 h-36 w-36 rounded-xl p-4"
            >
              <img className="h-full w-full" src={store.qrCodeUri} alt="QRCode" />
            </motion.div>
          )}

          {!showSecret && (
            <motion.p
              custom={!initialized.current ? 4 : 0}
              initial="initial"
              animate="animate"
              variants={variants}
              className="text-primary mb-8 cursor-pointer text-sm font-medium"
              onClick={handleShowSecretClick}
            >
              Can't scan QR code?
            </motion.p>
          )}

          {showSecret && (
            <motion.div
              initial={{ opacity: 0, display: "none" }}
              animate={
                showSecret ? { opacity: 1, display: "block" } : { opacity: 0, display: "none" }
              }
              className="flex flex-col"
            >
              <p className="mb-6 text-sm font-normal">
                If you can't scan the QR code with your camera, enter the following code into the
                authentication app to link it to your account.
              </p>
              <div className="mb-6 flex items-center gap-4">
                <p className="text-main font-medium">
                  {store.qrCodeKey.match(/.{1,4}/g)?.join(" ")}
                </p>

                <LuClipboard
                  className="text-main cursor-pointer font-semibold"
                  onClick={handleCopySecretClick}
                />
              </div>
              <p
                className="text-primary mb-8 cursor-pointer text-sm font-medium"
                onClick={handleShowSecretClick}
              >
                Try to scan the QR code again
              </p>
            </motion.div>
          )}

          <motion.p
            custom={5}
            initial="initial"
            animate="animate"
            variants={variants}
            className="mb-6 text-sm font-normal"
          >
            Enter the 6-digit code
          </motion.p>

          <motion.div
            custom={6}
            initial="initial"
            animate="animate"
            variants={variants}
            className="mb-6"
          >
            <FormField
              control={form.control}
              name="token"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <InputOTP
                      autoFocus={!isMobile}
                      maxLength={6}
                      onComplete={handleCompleteToken}
                      {...field}
                    >
                      <InputOTPGroup className="flex w-full justify-between gap-2 md:gap-4">
                        <InputOTPSlot className="h-[75px] w-full !rounded-xl border" index={0} />
                        <InputOTPSlot className="h-[75px] w-full rounded-xl border" index={1} />
                        <InputOTPSlot className="h-[75px] w-full rounded-xl border" index={2} />
                        <InputOTPSlot className="h-[75px] w-full rounded-xl border" index={3} />
                        <InputOTPSlot className="h-[75px] w-full rounded-xl border" index={4} />
                        <InputOTPSlot className="h-[75px] w-full !rounded-xl border" index={5} />
                      </InputOTPGroup>
                    </InputOTP>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </motion.div>

          <motion.div custom={7} initial="initial" animate="animate" variants={variants}>
            <LoadingButton type="submit" loading={store.loading}>
              Verify
            </LoadingButton>
          </motion.div>
        </form>
      </Form>

      <motion.div custom={8} initial="initial" animate="animate" variants={variants}>
        <StageIndicator />
      </motion.div>
    </div>
  )
}
