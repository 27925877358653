import React from "react"

import { useFormContext } from "react-hook-form"

import { LenderFileUpload } from "@/components/_uiext"

import { FormField, FormItem, FormMessage } from "@repo/ui/components/ui/form"

interface Props {
  isView?: boolean
}

export default function FilesAndAttachments(props: Readonly<Props>) {
  const { isView = false } = props
  const form = useFormContext()

  return (
    <div className="flex w-full flex-col">
      <div className="w-full">
        <FormField
          control={form.control}
          name="documents"
          render={({ field }) => (
            <FormItem>
              <LenderFileUpload
                {...field}
                readOnly={isView}
                title="Files and Attachments"
                uploadedFiles={form.watch("uploaded_documents")}
                onRemoveUploadedFile={(uuid: string) => {
                  form.setValue("removed_documents", [...form.getValues("removed_documents"), uuid])
                  form.setValue("uploaded_documents", [
                    ...form.getValues("uploaded_documents").filter((ud: any) => ud.uuid !== uuid)
                  ])
                }}
              />
              <FormMessage />
            </FormItem>
          )}
        />
      </div>
    </div>
  )
}
