import React, { useEffect, useState } from "react"

import { Text, View } from "@react-pdf/renderer"
import { format } from "date-fns"
import numeral from "numeral"

import { APPLICATION_ASSET_TYPES, APPLICATION_LIABILITY_TYPES, YN } from "@repo/util/constant"

interface Props {
  data: Record<string, any>
}

export default function ContentIndividualAL(props: Readonly<Props>) {
  const { data } = props

  const [individualAL, setIndividualAL] = useState<any[]>([])

  useEffect(() => {
    if (Object.keys(data ?? {}).length) {
      const borrowerIndividuals = data?.borrower?.individuals ?? []
      let tempIndividualAL = borrowerIndividuals.map((bi: any) => {
        return {
          ...bi,
          birth_date: new Date(bi.birth_date),
          expiry_date: new Date(bi.expiry_date),
          individual_assets:
            data?.assets?.filter(
              (a: any) =>
                a.type === APPLICATION_ASSET_TYPES.Individual && a.individual_uuid === bi.uuid
            ) ?? [],
          individual_liabilities:
            data?.liabilities?.filter(
              (a: any) =>
                a.type === APPLICATION_LIABILITY_TYPES.Individual && a.individual_uuid === bi.uuid
            ) ?? []
        }
      })
      setIndividualAL(tempIndividualAL)
    } else {
      setIndividualAL([])
    }
  }, [data])

  return (
    <View style={{ display: "flex", flexDirection: "column" }} wrap={true}>
      <Text
        style={{
          color: "#0E052B",
          fontWeight: 600,
          fontSize: 14,
          borderBottom: "1px solid #CDCDCD"
        }}
      >
        9. Individual's A & L
      </Text>

      {individualAL.map((item, index) => (
        <View
          key={item.id}
          style={{
            paddingLeft: 32,
            paddingVertical: 16,
            display: "flex",
            flexDirection: "column",
            gap: 8
          }}
        >
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 16
            }}
          >
            <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 14 }}>
              {item.title}. {item.first_name} {item.middle_name} {item.last_name}
            </Text>
          </View>

          <View
            key={item.id}
            style={{
              paddingLeft: 32,
              paddingVertical: 16,
              display: "flex",
              flexDirection: "column",
              gap: 8
            }}
          >
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 16
              }}
            >
              <Text style={{ color: "#0E052B", fontWeight: 600, fontSize: 14 }}>Details</Text>
            </View>

            {item.role && (
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 16
                }}
              >
                <Text style={{ color: "#868194", fontWeight: 400, fontSize: 12, flex: 1 }}>
                  Role
                </Text>
                <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 12, flex: 1 }}>
                  {item?.role}
                </Text>
              </View>
            )}

            <View
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 16
              }}
            >
              <Text style={{ color: "#868194", fontWeight: 400, fontSize: 12, flex: 1 }}>
                Title
              </Text>
              <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 12, flex: 1 }}>
                {item.title}
              </Text>
            </View>

            <View
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 16
              }}
            >
              <Text style={{ color: "#868194", fontWeight: 400, fontSize: 12, flex: 1 }}>
                Gender
              </Text>
              <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 12, flex: 1 }}>
                {item.gender}
              </Text>
            </View>

            <View
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 16
              }}
            >
              <Text style={{ color: "#868194", fontWeight: 400, fontSize: 12, flex: 1 }}>
                First name
              </Text>
              <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 12, flex: 1 }}>
                {item.first_name}
              </Text>
            </View>

            <View
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 16
              }}
            >
              <Text style={{ color: "#868194", fontWeight: 400, fontSize: 12, flex: 1 }}>
                Middle name
              </Text>
              <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 12, flex: 1 }}>
                {item.middle_name}
              </Text>
            </View>

            <View
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 16
              }}
            >
              <Text style={{ color: "#868194", fontWeight: 400, fontSize: 12, flex: 1 }}>
                Last name
              </Text>
              <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 12, flex: 1 }}>
                {item.last_name}
              </Text>
            </View>

            <View
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 16
              }}
            >
              <Text style={{ color: "#868194", fontWeight: 400, fontSize: 12, flex: 1 }}>
                Marital Status
              </Text>
              <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 12, flex: 1 }}>
                {item.marital_status}
              </Text>
            </View>

            <View
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 16
              }}
            >
              <Text style={{ color: "#868194", fontWeight: 400, fontSize: 12, flex: 1 }}>
                Birth date
              </Text>
              <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 12, flex: 1 }}>
                {format(item.birth_date, "dd/MM/yyyy")}
              </Text>
            </View>

            <View
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 16
              }}
            >
              <Text style={{ color: "#868194", fontWeight: 400, fontSize: 12, flex: 1 }}>
                Country of birth
              </Text>
              <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 12, flex: 1 }}>
                {item.birth_country}
              </Text>
            </View>

            <View
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 16
              }}
            >
              <Text style={{ color: "#868194", fontWeight: 400, fontSize: 12, flex: 1 }}>
                Place of birth
              </Text>
              <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 12, flex: 1 }}>
                {item.birth_place}
              </Text>
            </View>

            <View
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 16
              }}
            >
              <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 12, flex: 1 }}>
                Residential Address
              </Text>
            </View>

            <View
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 16
              }}
            >
              <Text style={{ color: "#868194", fontWeight: 400, fontSize: 12, flex: 1 }}>
                Street address
              </Text>
              <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 12, flex: 1 }}>
                {item.residential_street_address}
              </Text>
            </View>

            <View
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 16
              }}
            >
              <Text style={{ color: "#868194", fontWeight: 400, fontSize: 12, flex: 1 }}>
                Suburb
              </Text>
              <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 12, flex: 1 }}>
                {item.residential_suburb}
              </Text>
            </View>

            <View
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 16
              }}
            >
              <Text style={{ color: "#868194", fontWeight: 400, fontSize: 12, flex: 1 }}>
                State
              </Text>
              <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 12, flex: 1 }}>
                {item.residential_state}
              </Text>
            </View>

            <View
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 16
              }}
            >
              <Text style={{ color: "#868194", fontWeight: 400, fontSize: 12, flex: 1 }}>
                Postcode
              </Text>
              <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 12, flex: 1 }}>
                {item.residential_postcode}
              </Text>
            </View>

            <View
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 16
              }}
            >
              <Text style={{ color: "#868194", fontWeight: 400, fontSize: 12, flex: 1 }}>
                Residential years
              </Text>
              <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 12, flex: 1 }}>
                {item.residential_years}
              </Text>
            </View>

            <View
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 16
              }}
            >
              <Text style={{ color: "#868194", fontWeight: 400, fontSize: 12, flex: 1 }}>
                Residential months
              </Text>
              <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 12, flex: 1 }}>
                {item.residential_months}
              </Text>
            </View>

            <View
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 16
              }}
            >
              <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 12, flex: 1 }}>
                Previous Address (if less than 2 years at current address)
              </Text>
            </View>

            <View
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 16
              }}
            >
              <Text style={{ color: "#868194", fontWeight: 400, fontSize: 12, flex: 1 }}>
                Street address
              </Text>
              <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 12, flex: 1 }}>
                {item.previous_street_address}
              </Text>
            </View>

            <View
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 16
              }}
            >
              <Text style={{ color: "#868194", fontWeight: 400, fontSize: 12, flex: 1 }}>
                Suburb
              </Text>
              <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 12, flex: 1 }}>
                {item.previous_suburb}
              </Text>
            </View>

            <View
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 16
              }}
            >
              <Text style={{ color: "#868194", fontWeight: 400, fontSize: 12, flex: 1 }}>
                State
              </Text>
              <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 12, flex: 1 }}>
                {item.previous_state}
              </Text>
            </View>

            <View
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 16
              }}
            >
              <Text style={{ color: "#868194", fontWeight: 400, fontSize: 12, flex: 1 }}>
                Postcode
              </Text>
              <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 12, flex: 1 }}>
                {item.previous_postcode}
              </Text>
            </View>

            <View
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 16
              }}
            >
              <Text style={{ color: "#868194", fontWeight: 400, fontSize: 12, flex: 1 }}>
                Australian resident
              </Text>
              <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 12, flex: 1 }}>
                {item.is_australian_resident ? YN.Yes : YN.No}
              </Text>
            </View>

            <View
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 16
              }}
            >
              <Text style={{ color: "#868194", fontWeight: 400, fontSize: 12, flex: 1 }}>
                Current residential Status
              </Text>
              <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 12, flex: 1 }}>
                {item.current_residential_status}
              </Text>
            </View>

            <View
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 16
              }}
            >
              <Text style={{ color: "#868194", fontWeight: 400, fontSize: 12, flex: 1 }}>
                Postal address
              </Text>
              <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 12, flex: 1 }}>
                {item.postal_address}
              </Text>
            </View>

            <View
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 16
              }}
            >
              <Text style={{ color: "#868194", fontWeight: 400, fontSize: 12, flex: 1 }}>
                Email address
              </Text>
              <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 12, flex: 1 }}>
                {item.email}
              </Text>
            </View>

            <View
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 16
              }}
            >
              <Text style={{ color: "#868194", fontWeight: 400, fontSize: 12, flex: 1 }}>
                Mobile number
              </Text>
              <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 12, flex: 1 }}>
                {item.mobile_number}
              </Text>
            </View>

            <View
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 16
              }}
            >
              <Text style={{ color: "#868194", fontWeight: 400, fontSize: 12, flex: 1 }}>
                Drivers license number
              </Text>
              <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 12, flex: 1 }}>
                {item.drivers_license_number}
              </Text>
            </View>

            <View
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 16
              }}
            >
              <Text style={{ color: "#868194", fontWeight: 400, fontSize: 12, flex: 1 }}>
                Card number
              </Text>
              <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 12, flex: 1 }}>
                {item.card_number}
              </Text>
            </View>

            <View
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 16
              }}
            >
              <Text style={{ color: "#868194", fontWeight: 400, fontSize: 12, flex: 1 }}>
                Expiry date
              </Text>
              <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 12, flex: 1 }}>
                {format(item.expiry_date, "dd/MM/yyyy")}
              </Text>
            </View>

            <View
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 16
              }}
            >
              <Text style={{ color: "#0E052B", fontWeight: 600, fontSize: 14 }}>Assets</Text>
            </View>

            {item.individual_assets.map((subItem: any) => (
              <React.Fragment key={subItem.id}>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 16
                  }}
                >
                  <Text style={{ color: "#868194", fontWeight: 400, fontSize: 12, flex: 1 }}>
                    Description of asset
                  </Text>
                  <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 12, flex: 1 }}>
                    {subItem.description}
                  </Text>
                </View>

                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 16
                  }}
                >
                  <Text style={{ color: "#868194", fontWeight: 400, fontSize: 12, flex: 1 }}>
                    Value of asset
                  </Text>
                  <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 12, flex: 1 }}>
                    {numeral(subItem.value).format("$ 0,0[.]00")}
                  </Text>
                </View>

                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 16
                  }}
                >
                  <Text style={{ color: "#868194", fontWeight: 400, fontSize: 12, flex: 1 }}>
                    Does your asset have an associated debt?
                  </Text>
                  <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 12, flex: 1 }}>
                    {subItem.associated_debt}
                  </Text>
                </View>

                {subItem.associated_debt === YN.Yes && (
                  <>
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: 16
                      }}
                    >
                      <Text style={{ color: "#868194", fontWeight: 400, fontSize: 12, flex: 1 }}>
                        Who is the lender?
                      </Text>
                      <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 12, flex: 1 }}>
                        {subItem.lender}
                      </Text>
                    </View>

                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: 16
                      }}
                    >
                      <Text style={{ color: "#868194", fontWeight: 400, fontSize: 12, flex: 1 }}>
                        Monthly repayment / limit
                      </Text>
                      <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 12, flex: 1 }}>
                        {numeral(subItem.monthly_repayment).format("$ 0,0[.]00")}
                      </Text>
                    </View>
                  </>
                )}

                <View style={{ borderBottom: "1px dashed #CDCDCD" }} />
              </React.Fragment>
            ))}

            <View
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 16
              }}
            >
              <Text style={{ color: "#0E052B", fontWeight: 600, fontSize: 14 }}>Liabilities</Text>
            </View>

            {item.individual_liabilities.map((subItem: any) => (
              <React.Fragment key={subItem.id}>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 16
                  }}
                >
                  <Text style={{ color: "#868194", fontWeight: 400, fontSize: 12, flex: 1 }}>
                    Description of liability
                  </Text>
                  <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 12, flex: 1 }}>
                    {subItem.description}
                  </Text>
                </View>

                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 16
                  }}
                >
                  <Text style={{ color: "#868194", fontWeight: 400, fontSize: 12, flex: 1 }}>
                    Amount owing
                  </Text>
                  <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 12, flex: 1 }}>
                    {numeral(subItem.amount_owing).format("$ 0,0[.]00")}
                  </Text>
                </View>

                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 16
                  }}
                >
                  <Text style={{ color: "#868194", fontWeight: 400, fontSize: 12, flex: 1 }}>
                    Who is the lender?
                  </Text>
                  <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 12, flex: 1 }}>
                    {subItem.lender}
                  </Text>
                </View>

                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 16
                  }}
                >
                  <Text style={{ color: "#868194", fontWeight: 400, fontSize: 12, flex: 1 }}>
                    Monthly repayment / limit
                  </Text>
                  <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 12, flex: 1 }}>
                    {numeral(subItem.monthly_repayment).format("$ 0,0[.]00")}
                  </Text>
                </View>

                <View style={{ borderBottom: "1px dashed #CDCDCD" }} />
              </React.Fragment>
            ))}
          </View>
        </View>
      ))}
    </View>
  )
}
