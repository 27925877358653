import React from "react"

interface Props {
  className?: string
}

export default function IconApplicationDeclined({ className }: Readonly<Props>) {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.8328 16.4935C22.1812 14.2537 20.7055 12.88 18.4155 12.3917C18.3439 12.3764 18.2718 12.3607 18.183 12.3419V12.0723C18.183 9.18475 18.1591 6.29627 18.192 3.40869C18.2105 1.78477 17.0696 0.682832 15.8606 0.529648C15.8399 0.526953 15.8209 0.510332 15.8011 0.5H3.34464C2.87556 0.607363 2.4227 0.750664 2.03698 1.05568C1.34936 1.59924 0.96635 2.30631 0.964998 3.18273C0.957789 8.4067 0.957789 13.6307 0.964097 18.8546C0.965899 20.3402 2.18478 21.5288 3.68935 21.5306C6.76066 21.5342 9.83242 21.5333 12.9042 21.5275C13.0867 21.527 13.2038 21.5863 13.3264 21.7171C14.2623 22.717 15.4127 23.301 16.7762 23.4609C16.8267 23.4672 16.8758 23.4865 16.9254 23.5H17.9144C18.2646 23.4268 18.6201 23.3729 18.9635 23.2763C21.2268 22.6415 22.8621 20.6048 23.0045 18.2603C23.0099 18.1646 23.0283 18.0698 23.041 17.9746V17.8398C22.9743 17.3902 22.9585 16.9257 22.8328 16.4935ZM12.5712 15.1095C11.6321 16.7307 11.5893 18.4297 12.2896 20.1839H12.0323C9.2899 20.1839 6.54752 20.1686 3.80515 20.1929C2.91881 20.2005 2.30014 19.5383 2.30239 18.6925C2.31816 13.5732 2.31005 8.45342 2.3105 3.33367C2.3105 2.41457 2.87556 1.84811 3.79208 1.84811C7.64339 1.84721 11.4947 1.84721 15.346 1.84766C16.2652 1.84811 16.833 2.41322 16.8334 3.32918C16.8343 6.2482 16.8339 9.16723 16.8339 12.0863V12.3198C14.9643 12.5799 13.5139 13.4824 12.5712 15.1095ZM17.4026 22.1519C15.0621 22.1438 13.1529 20.2279 13.1633 17.8978C13.1741 15.5394 15.0738 13.65 17.4328 13.6513C19.7732 13.6531 21.6774 15.5632 21.6707 17.9027C21.6639 20.2553 19.7507 22.1604 17.4026 22.1519Z"
        fill="currentColor"
      />
      <path
        d="M9.10852 10.9825C9.11393 11.3801 8.82104 11.6694 8.38305 11.6712C7.079 11.6766 5.77541 11.6757 4.47181 11.6716C4.09015 11.6703 3.81033 11.4457 3.7522 11.1191C3.69407 10.7934 3.87071 10.4767 4.18388 10.3694C4.28031 10.3366 4.3889 10.328 4.49209 10.3276C5.13645 10.324 5.78082 10.3258 6.42518 10.3258C7.0772 10.3258 7.72923 10.324 8.38125 10.3267C8.81293 10.3285 9.10266 10.5935 9.10852 10.9825Z"
        fill="currentColor"
      />
      <path
        d="M9.10838 13.6789C9.11379 14.076 8.82045 14.3649 8.38246 14.3667C7.07887 14.3721 5.77482 14.3712 4.47123 14.3671C4.09002 14.3658 3.8102 14.1412 3.75207 13.8142C3.69439 13.4885 3.87058 13.1713 4.1842 13.0653C4.28738 13.0303 4.40409 13.024 4.51494 13.0231C5.1593 13.0195 5.80366 13.0213 6.44802 13.0213C7.09239 13.0213 7.73675 13.019 8.38156 13.0222C8.81324 13.024 9.10298 13.289 9.10838 13.6789Z"
        fill="currentColor"
      />
      <path
        d="M9.10822 16.4205C9.11317 16.8185 8.81938 17.1056 8.38139 17.1074C7.07735 17.1119 5.77375 17.1114 4.46971 17.1074C4.0894 17.106 3.80912 16.881 3.7519 16.5539C3.69467 16.2283 3.87131 15.9116 4.18493 15.8055C4.28857 15.7705 4.40527 15.7642 4.51567 15.7633C5.15282 15.7597 5.78953 15.7615 6.42668 15.7615C7.07825 15.7615 7.73027 15.7593 8.3823 15.7624C8.81352 15.7647 9.10326 16.0311 9.10822 16.4205Z"
        fill="currentColor"
      />
      <path
        d="M9.10814 5.59236C9.11355 5.98138 8.82877 6.27652 8.40475 6.27877C7.08583 6.28685 5.76692 6.28685 4.44845 6.27877C4.02443 6.27652 3.7392 5.98183 3.7437 5.59326C3.74866 5.20469 4.0402 4.93785 4.46963 4.93606C5.12931 4.93336 5.78855 4.93516 6.44778 4.93516C7.09214 4.93516 7.73696 4.93336 8.38132 4.93606C8.81165 4.9374 9.10274 5.20334 9.10814 5.59236Z"
        fill="currentColor"
      />
      <path
        d="M9.10812 8.28543C9.11443 8.68299 8.82244 8.97364 8.38445 8.97588C7.08041 8.98172 5.77681 8.98127 4.47276 8.97588C4.03342 8.97409 3.74008 8.68703 3.74369 8.29082C3.74774 7.89371 4.04063 7.63227 4.48989 7.63092C5.1347 7.62912 5.77906 7.63047 6.42343 7.63047C7.075 7.63047 7.72702 7.62867 8.37905 7.63137C8.81073 7.63272 9.10182 7.89686 9.10812 8.28543Z"
        fill="currentColor"
      />
      <path
        d="M15.3996 5.58967C15.4063 5.97734 15.1202 6.27608 14.698 6.27877C13.3795 6.28731 12.0606 6.28686 10.7417 6.27922C10.3168 6.27697 10.0311 5.98498 10.0347 5.59596C10.0383 5.20828 10.3307 4.93785 10.7579 4.93606C11.4099 4.93336 12.0615 4.93516 12.7135 4.93516C13.3656 4.93516 14.0176 4.93336 14.6692 4.93606C15.1008 4.9374 15.3928 5.20199 15.3996 5.58967Z"
        fill="currentColor"
      />
      <path
        d="M15.4 8.28716C15.4063 8.69101 15.1066 8.97626 14.6529 8.97761C13.3565 8.9821 12.0601 8.98165 10.7637 8.97761C10.4127 8.97671 10.1657 8.80241 10.0675 8.51267C9.9783 8.24988 10.049 7.94576 10.2834 7.79033C10.4176 7.70093 10.5979 7.64298 10.7592 7.63894C11.4108 7.62187 12.0632 7.63175 12.7148 7.63175C13.3592 7.63175 14.0035 7.6304 14.6483 7.6322C15.0981 7.63355 15.3936 7.89275 15.4 8.28716Z"
        fill="currentColor"
      />
      <path
        d="M13.3201 10.9792C13.3314 11.3301 13.0615 11.6508 12.6843 11.6625C12.012 11.684 11.3379 11.6845 10.6656 11.6629C10.2749 11.6499 10.019 11.3314 10.037 10.9563C10.0546 10.5974 10.3367 10.3346 10.7215 10.3278C11.036 10.3225 11.3501 10.3269 11.6642 10.3269C11.9859 10.3269 12.3076 10.3225 12.6293 10.3278C13.0209 10.3346 13.3084 10.61 13.3201 10.9792Z"
        fill="currentColor"
      />
      <path
        d="M19.3019 18.7981C19.4816 18.9819 19.5727 19.1939 19.5141 19.45C19.3947 19.972 18.7782 20.1615 18.388 19.7824C18.0717 19.4756 17.7869 19.1369 17.4882 18.8125C17.4395 18.8116 17.3908 18.8112 17.3426 18.8107C17.0448 19.1369 16.7622 19.4792 16.445 19.7851C16.0602 20.157 15.4555 19.9755 15.3271 19.467C15.2608 19.2033 15.3487 18.9828 15.5393 18.7959C15.6506 18.6867 15.7619 18.5771 15.8728 18.4675C16.0341 18.308 16.1949 18.1486 16.3562 17.9891C16.3752 17.9702 16.3946 17.9514 16.4135 17.9325C16.1111 17.6118 15.8146 17.2982 15.5181 16.9846C15.1356 16.5808 15.3036 15.9609 15.8331 15.8373C16.089 15.7776 16.3089 15.862 16.486 16.0467C16.7807 16.3548 17.0682 16.6697 17.376 16.9999C17.7396 16.6437 18.0483 16.3306 18.3682 16.0296C18.7778 15.6451 19.3902 15.8189 19.5136 16.3508C19.5731 16.6073 19.483 16.8216 19.3014 17.0022C19.1527 17.15 18.9999 17.2937 18.8526 17.4428C18.7057 17.5915 18.5633 17.7443 18.4254 17.8889C18.7138 18.1886 19.0058 18.4954 19.3019 18.7981Z"
        fill="currentColor"
      />
    </svg>
  )
}
