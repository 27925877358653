import React from "react"

interface Props {
  className?: string
}

export default function IconMyApplications({ className }: Readonly<Props>) {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.571 1.66675H16.2932C17.4195 1.66675 18.3331 2.58833 18.3331 3.72523V6.47017C18.3331 7.60621 17.4195 8.52865 16.2932 8.52865H13.571C12.4439 8.52865 11.5303 7.60621 11.5303 6.47017V3.72523C11.5303 2.58833 12.4439 1.66675 13.571 1.66675Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.70727 1.66675H6.42858C7.5557 1.66675 8.46935 2.58833 8.46935 3.72523V6.47017C8.46935 7.60621 7.5557 8.52865 6.42858 8.52865H3.70727C2.58015 8.52865 1.6665 7.60621 1.6665 6.47017V3.72523C1.6665 2.58833 2.58015 1.66675 3.70727 1.66675Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.70727 11.4714H6.42858C7.5557 11.4714 8.46935 12.393 8.46935 13.5308V16.2749C8.46935 17.4118 7.5557 18.3333 6.42858 18.3333H3.70727C2.58015 18.3333 1.6665 17.4118 1.6665 16.2749V13.5308C1.6665 12.393 2.58015 11.4714 3.70727 11.4714Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.571 11.4714H16.2932C17.4195 11.4714 18.3331 12.393 18.3331 13.5308V16.2749C18.3331 17.4118 17.4195 18.3333 16.2932 18.3333H13.571C12.4439 18.3333 11.5303 17.4118 11.5303 16.2749V13.5308C11.5303 12.393 12.4439 11.4714 13.571 11.4714Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
