import qs from "qs"

import api from "./api"

// Get
export const getAdminUserList = (data: any) => api().get(`/admin/users?${qs.stringify(data)}`)
export const getAdminUserByUuid = (uuid: string) => api().get(`/admin/users/${uuid}`)

// Update
export const updateAdminUser = (uuid: string, data: any) =>
  api().putForm(`/admin/users/${uuid}`, data)

// Archive
export const archiveAdminUser = (userUuid: string) =>
  api().patch(`/admin/users/${userUuid}/archive`)

// Approve
export const approveAdminUser = (userUuid: string) =>
  api().patch(`/admin/users/${userUuid}/approve`)

// Lock
export const lockAdminUser = (userUuid: string) => api().patch(`/admin/users/${userUuid}/lock`)

export default {
  getAdminUserList,
  getAdminUserByUuid,
  updateAdminUser,
  archiveAdminUser,
  approveAdminUser,
  lockAdminUser
}
