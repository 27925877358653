import React from "react"

import { Link } from "react-router-dom"

import UserLayout from "@/components/_layout/UserLayout"
import useAuthStore from "@/stores/useAuthStore"

import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator
} from "@repo/ui/components/ui/breadcrumb"
import { USER_ROLES } from "@repo/util/constant"

import MainForm from "./MainForm"
import IntroducerForm from "./IntroducerForm"

export default function BusinessSettings() {
  const authStore = useAuthStore()

  return (
    <UserLayout
      breadcrumb={
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbItem>
              <Link to="/dashboard" className="text-default text-sm font-normal">
                Dashboard
              </Link>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <Link to="" className="text-default text-sm font-normal">
                Business
              </Link>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbPage className="text-main text-sm font-semibold">Settings</BreadcrumbPage>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
      }
    >
      <div className="flex flex-col">
        <div className="mb-4 block w-full rounded-xl border border-[#ECECEC] bg-white p-8 md:hidden">
          <h3 className="text-main text-lg font-semibold">Business Settings</h3>
        </div>

        {authStore.user?.role === USER_ROLES.Introducer && <IntroducerForm />}

        {(authStore.user?.role === USER_ROLES.Broker ||
          authStore.user?.role === USER_ROLES.Enterprise) && <MainForm />}
      </div>
    </UserLayout>
  )
}
