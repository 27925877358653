import React from "react"

interface Props {
  className?: string
}

export default function IconApplicationSettled({ className }: Readonly<Props>) {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 29 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.59594 4.28428C4.59344 3.14072 3.69729 2.20189 2.55359 2.0963C1.4304 1.9927 0.390719 2.75706 0.149177 3.86344C0.125116 3.97176 0.097168 4.0791 0.097168 4.19006V15.656C0.097168 16.1895 0.203851 16.7176 0.411367 17.2091C0.813577 18.1617 1.21643 19.1142 1.61393 20.0685C1.75445 20.4056 1.97799 20.6242 2.35856 20.6222C2.72712 20.6197 2.94966 20.4081 3.08618 20.0799C3.55476 18.9522 4.03235 17.828 4.49943 16.6998C4.55444 16.5655 4.59594 16.4143 4.59644 16.271C4.60095 12.2753 4.60495 8.27954 4.59594 4.28428ZM2.58216 17.4006C2.49837 17.6002 2.20718 17.6016 2.12312 17.4021C2.05523 17.241 2.1775 17.0721 2.35235 17.0721C2.52705 17.0721 2.64977 17.2395 2.58216 17.4006ZM3.07968 14.8298C3.07968 15.2338 2.75223 15.5612 2.34831 15.5612C1.94438 15.5612 1.61693 15.2338 1.61693 14.8298V7.19817C1.61693 6.79425 1.94438 6.4668 2.34831 6.4668C2.75223 6.4668 3.07968 6.79425 3.07968 7.19817V14.8298ZM3.09669 4.2818C3.11438 4.65183 2.79838 4.95247 2.42792 4.95247H2.36668C1.9446 4.95247 1.56306 4.59163 1.63525 4.17577C1.63918 4.15318 1.64355 4.13069 1.64844 4.10831C1.72545 3.7524 2.09151 3.53529 2.44307 3.58139C2.80113 3.62798 3.07968 3.91995 3.09669 4.2818Z"
        fill="currentColor"
      />
      <path
        d="M25.8124 16.7755C24.8682 17.982 23.9201 19.1856 22.9629 20.3822C22.6614 20.7589 22.1503 20.7708 21.8272 20.4129C21.2996 19.8285 20.783 19.2342 20.2749 18.6329C20.0089 18.3176 20.0579 17.8636 20.3555 17.6068C20.665 17.3396 21.1231 17.3609 21.4101 17.6742C21.9126 18.2216 22.7778 18.2105 23.2383 17.6274C23.5233 17.2664 23.8067 16.9077 24.0901 16.549C24.2696 16.3215 24.4457 16.0905 24.6312 15.8679C24.9157 15.5264 25.3668 15.4729 25.6974 15.7331C26.0159 15.9839 26.0774 16.4365 25.8124 16.7755Z"
        fill="currentColor"
      />
      <path
        d="M20.121 5.95191C19.5299 5.96133 18.9388 5.95439 18.3472 5.95439H15.2747C13.6839 5.95439 12.0937 5.95687 10.5029 5.9529C9.92779 5.95142 9.57523 5.49191 9.73976 4.9764C9.83877 4.66659 10.1008 4.47476 10.4599 4.46931C10.926 4.46286 11.3925 4.46732 11.8586 4.46732C14.5816 4.46732 17.3051 4.46881 20.028 4.46584C20.3921 4.46534 20.6871 4.56993 20.8352 4.92435C21.0457 5.43045 20.6921 5.94299 20.121 5.95191Z"
        fill="currentColor"
      />
      <path
        d="M20.1151 8.92553C19.3324 8.93445 18.5493 8.92801 17.7667 8.92801H15.3183C13.7195 8.92801 12.1207 8.92999 10.5219 8.92702C9.92982 8.92553 9.56876 8.46553 9.74329 7.94357C9.84231 7.64665 10.0628 7.491 10.3684 7.44738C10.4339 7.43797 10.5014 7.44094 10.5679 7.44094C13.724 7.44094 16.88 7.44193 20.0361 7.43945C20.3676 7.43945 20.6462 7.5257 20.8072 7.83749C21.0742 8.35449 20.7202 8.91908 20.1151 8.92553Z"
        fill="currentColor"
      />
      <path
        d="M20.1135 11.9006C19.2894 11.9091 18.4647 11.9031 17.6406 11.9031H15.2922C13.7014 11.9031 12.1111 11.9051 10.5204 11.9021C9.92926 11.9006 9.5682 11.4391 9.74373 10.9172C9.84325 10.6208 10.0638 10.4651 10.3698 10.4225C10.4348 10.4131 10.5024 10.416 10.5694 10.416C13.7254 10.416 16.8815 10.417 20.0375 10.415C20.3691 10.4145 20.6471 10.5018 20.8076 10.8136C21.0742 11.3311 20.7191 11.8947 20.1135 11.9006Z"
        fill="currentColor"
      />
      <path
        d="M13.5856 14.1214C13.5941 14.5388 13.2525 14.8699 12.7994 14.8759C12.4084 14.8808 12.0178 14.8769 11.6267 14.8769C11.2527 14.8769 10.8786 14.8803 10.5045 14.8759C10.0215 14.8704 9.6904 14.5566 9.6994 14.1199C9.7084 13.6902 10.0305 13.3933 10.5055 13.3908C11.2707 13.3868 12.0353 13.3863 12.8004 13.3913C13.2435 13.3938 13.5776 13.7105 13.5856 14.1214Z"
        fill="currentColor"
      />
      <path
        d="M28.6912 16.4935C28.0301 14.2537 26.5328 12.88 24.2094 12.3917C24.0719 12.3628 23.9735 12.2412 23.9735 12.1007V12.0723C23.9735 9.18475 23.9493 6.29627 23.9827 3.40869C24.0014 1.78477 22.8438 0.682832 21.6172 0.529648C21.5731 0.523994 21.5337 0.5 21.4892 0.5H9.3641C9.06796 0.5 8.7706 0.525638 8.48744 0.612345C8.1676 0.710285 7.86329 0.844062 7.59178 1.05568C6.89412 1.59924 6.50551 2.30631 6.50414 3.18273C6.49683 8.4067 6.49683 13.6307 6.50323 18.8546C6.50506 20.3402 7.74173 21.5288 9.26826 21.5306C12.3844 21.5342 15.501 21.5333 18.6176 21.5275C18.8028 21.527 18.9217 21.5863 19.046 21.7171C19.9956 22.717 21.1628 23.301 22.5462 23.4609C22.6493 23.4736 22.7501 23.5 22.854 23.5H23.2931C23.5643 23.5 23.835 23.473 24.1016 23.4226C24.3248 23.3804 24.5475 23.3367 24.7654 23.2763C27.0618 22.6415 28.7209 20.6048 28.8654 18.2603C28.8709 18.1646 28.8896 18.0698 28.9024 17.9746C28.9024 17.8849 28.8953 17.7953 28.8837 17.7064C28.8305 17.2988 28.8062 16.8831 28.6912 16.4935ZM18.2798 15.1095C17.3712 16.6555 17.2896 18.2722 17.9 19.9401C17.9429 20.0573 17.8578 20.1839 17.733 20.1839C14.9506 20.1839 12.1682 20.1686 9.38576 20.1929C8.48648 20.2005 7.85877 19.5383 7.86106 18.6925C7.87706 13.5732 7.86883 8.45342 7.86929 3.33367C7.86929 2.41457 8.44259 1.84811 9.3725 1.84811C13.28 1.84721 17.1876 1.84721 21.0951 1.84766C22.0278 1.84811 22.6038 2.41322 22.6043 3.32918C22.6052 6.2482 22.6047 9.16723 22.6047 12.0863C22.6047 12.2201 22.5056 12.3329 22.3736 12.3551C20.5881 12.6551 19.197 13.5491 18.2798 15.1095ZM23.1817 22.1519C20.8071 22.1438 18.87 20.2279 18.8805 17.8978C18.8915 15.5394 20.819 13.65 23.2123 13.6513C25.5869 13.6531 27.519 15.5632 27.5121 17.9027C27.5053 20.2553 25.5641 22.1604 23.1817 22.1519Z"
        fill="currentColor"
      />
    </svg>
  )
}
