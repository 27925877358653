import React from "react"

import { ColumnDef } from "@tanstack/react-table"
import { useNavigate } from "react-router-dom"

import { IconArchive, IconEdit, IconMore, IconView } from "@/components/_icons"
import useBorrowerStore from "@/stores/useBorrowerStore"

import { Avatar, AvatarFallback, AvatarImage } from "@repo/ui/components/ui/avatar"
import { Button } from "@repo/ui/components/ui/button"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from "@repo/ui/components/ui/dropdown-menu"
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from "@repo/ui/components/ui/tooltip"

import { ENTITY_TYPES } from "@repo/util/constant"

interface ActionColumnProps {
  row: any
}

function ActionColumn({ row }: Readonly<ActionColumnProps>) {
  const navigate = useNavigate()
  const store = useBorrowerStore()

  const handleViewClick = (uuid: string) => () => {
    navigate(`/borrowers/view/${uuid}`)
  }

  const handleEditClick = (uuid: string) => () => {
    navigate(`/borrowers/edit/${uuid}`)
  }

  const handleArchiveClick = (uuid: string) => () => {
    store.archiveAction(uuid)
  }

  return (
    <div className="text-center">
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" size="icon">
            <IconMore className="text-2xl" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-32" align="end">
          <DropdownMenuItem
            className="text-default flex gap-2 text-sm"
            onClick={handleViewClick(row.original.uuid)}
          >
            <IconView className="text-base" /> View
          </DropdownMenuItem>
          <DropdownMenuItem
            className="text-default flex gap-2 text-sm"
            onClick={handleEditClick(row.original.uuid)}
          >
            <IconEdit className="text-base" />
            Edit
          </DropdownMenuItem>
          <DropdownMenuItem
            className="text-default flex gap-2 text-sm"
            onClick={handleArchiveClick(row.original.uuid)}
          >
            <IconArchive className="text-base" />
            {!row.original.is_archived ? "Archive" : "Restore"}
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  )
}

const columns: ColumnDef<any>[] = [
  {
    accessorKey: "belongs_to",
    header: () => <>Added By</>,
    cell: ({ row }) => (
      <TooltipProvider disableHoverableContent delayDuration={0} skipDelayDuration={0}>
        <Tooltip>
          <TooltipTrigger>
            <Avatar className="hover:border-primary h-10 w-10 border-2 border-white hover:z-50">
              <AvatarImage
                src={row.original.holder?.image}
                alt={`${row.original.holder?.first_name} ${row.original.holder?.last_name}`}
              />
              <AvatarFallback className="uppercase">
                {row.original.holder?.first_name[0]}
                {row.original.holder?.last_name[0]}
              </AvatarFallback>
            </Avatar>
          </TooltipTrigger>

          <TooltipContent className="grid grid-cols-3 gap-2 p-4">
            <p className="text-default text-sm font-normal">Full Name:</p>
            <p className="text-main col-span-2 text-sm font-semibold">
              {row.original.holder?.first_name} {row.original.holder?.last_name}
            </p>
            <p className="text-default text-sm font-normal">Entity Name:</p>
            <p className="text-main col-span-2 text-sm font-semibold">
              {row.original.holder?.entity?.entity_name}
            </p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    )
  },
  {
    accessorKey: "entity_name",
    header: () => <>Entity Name</>,
    cell: ({ row }) => <div className="text-sm">{row.original.entity_name}</div>
  },
  {
    accessorKey: "entity_type",
    header: () => <>Entity Type</>,
    cell: ({ row }) => (
      <div
        className="rounded-sm px-6 py-1.5 text-center capitalize"
        style={{
          color: Object.entries(ENTITY_TYPES)
            .map(([k, v]) => ({ ...v }))
            .filter((as) => as.key === row.original.entity_type)?.[0].color.text,
          backgroundColor: Object.entries(ENTITY_TYPES)
            .map(([k, v]) => ({ ...v }))
            .filter((as) => as.key === row.original.entity_type)?.[0].color.background
        }}
      >
        {row.original.entity_type}
      </div>
    )
  },
  {
    accessorKey: "abn",
    header: () => <>ABN</>,
    cell: ({ row }) => <div className="whitespace-nowrap text-sm">{row.original.abn}</div>
  },
  {
    accessorKey: "contact",
    header: () => <>Contact</>,
    cell: ({ row }) => (
      <div className="text-sm">
        {row.original.mcp_first_name} {row.original.mcp_middle_name} {row.original.mcp_last_name}
      </div>
    )
  },
  {
    accessorKey: "email",
    header: () => <>Email Address</>,
    cell: ({ row }) => <div className="text-sm">{row.original.mcp_email}</div>
  },
  {
    accessorKey: "mobile_number",
    header: () => <>Mobile Number</>,
    cell: ({ row }) => <div className="text-sm">{row.original.mcp_mobile_number}</div>
  },
  {
    accessorKey: "actions",
    header: () => <div className="text-center">Actions</div>,
    cell: ({ row }) => <ActionColumn row={row} />
  }
]

export default columns
