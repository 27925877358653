import React from "react"

interface Props {
  className?: string
}

export default function IconDownload({ className }: Readonly<Props>) {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 26 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.666687 15.2794C0.666687 12.1071 3.16527 9.51788 6.30167 9.37251L6.58002 9.36607H7.82402C8.37631 9.36607 8.82402 9.81379 8.82402 10.3661C8.82402 10.8723 8.44782 11.2907 7.95971 11.3569L7.82402 11.3661H6.58002C4.49615 11.3661 2.79237 12.9954 2.67333 15.0495L2.66669 15.2794L2.66669 21.7794C2.66669 23.8621 4.2962 25.5657 6.35012 25.6848L6.58002 25.6914H21.42C23.5037 25.6914 25.2077 24.0622 25.3267 22.0092L25.3334 21.7794V15.2661C25.3334 13.1896 23.7099 11.4914 21.6637 11.3727L21.4347 11.3661H20.1774C19.6251 11.3661 19.1774 10.9184 19.1774 10.3661C19.1774 9.85981 19.5536 9.44142 20.0417 9.3752L20.1774 9.36607H21.4347C24.5963 9.36607 27.1775 11.8551 27.3266 14.9803L27.3334 15.2661V21.7794C27.3334 24.9507 24.8345 27.5396 21.6984 27.685L21.42 27.6914H6.58002C3.40795 27.6914 0.818503 25.1931 0.673124 22.0577L0.666687 21.7794L0.666687 15.2794ZM12.9999 1.30812C12.9999 0.75584 13.4477 0.308125 13.9999 0.308125C14.5062 0.308125 14.9246 0.68433 14.9908 1.17243L14.9999 1.30812L14.9999 17.3628C14.9999 17.4054 14.9973 17.4474 14.9921 17.4886L17.1791 15.2941L17.291 15.197C17.6819 14.9058 18.2375 14.9369 18.5933 15.2912C18.9846 15.6809 18.9859 16.3141 18.5962 16.7054L14.7082 20.6094L14.5959 20.7067C14.2038 20.9987 13.6463 20.9662 13.291 20.6093L9.4043 16.7053L9.30772 16.5929C9.01812 16.2008 9.05163 15.6453 9.40744 15.2911L9.51982 15.1945C9.91194 14.9049 10.4674 14.9384 10.8217 15.2942L13.0086 17.492L12.9999 17.3628L12.9999 1.30812ZM13.8664 18.3539L13.9979 18.4862L14.1291 18.3545C14.0868 18.36 14.0437 18.3628 13.9999 18.3628C13.9546 18.3628 13.9101 18.3598 13.8664 18.3539Z"
        fill="currentColor"
      />
    </svg>
  )
}
