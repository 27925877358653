import React, { useEffect, useState } from "react"

import clsx from "clsx"
import { BiArrowFromBottom } from "react-icons/bi"

export default function ScrollToTop() {
  const [isVisible, setIsVisible] = useState(false)

  const toggleVisibility = () => {
    if (window.scrollY > 300) {
      setIsVisible(true)
    } else {
      setIsVisible(false)
    }
  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    })
  }

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility)

    return () => {
      window.removeEventListener("scroll", toggleVisibility)
    }
  }, [])

  return (
    <div className="fixed bottom-4 left-4">
      <button
        type="button"
        onClick={scrollToTop}
        className={clsx(
          "bg-primary inline-flex items-center rounded-full p-4 text-white shadow-sm transition-opacity hover:bg-emerald-400 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2",
          isVisible ? "opacity-100" : "opacity-0"
        )}
      >
        <BiArrowFromBottom className="h-6 w-6" aria-hidden="true" />
      </button>
    </div>
  )
}
