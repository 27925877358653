import React from "react"

import clsx from "clsx"
import { Link } from "react-router-dom"

interface Props {
  disabled?: boolean
  className?: string
  to?: string
  children?: React.ReactNode
}

export default function LinkExt(props: Readonly<Props>) {
  const { disabled = false, className = "", to = "", children = null } = props

  return (
    <>
      {disabled ? (
        <span className={clsx(className, "opacity-50")}>{children}</span>
      ) : (
        <Link className={className} to={to}>
          {children}
        </Link>
      )}
    </>
  )
}
