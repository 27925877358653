import React, { useEffect, useState } from "react"

import { InputMask } from "@react-input/mask"
import { APIProvider } from "@vis.gl/react-google-maps"
import clsx from "clsx"
import { getNames } from "country-list"
import { format } from "date-fns"
import pluralize from "pluralize"
import { NumericFormat } from "react-number-format"

import { IconCalendar } from "@/components/_icons"
import { PlacesAutoPredict } from "@/components/_uiext"
import { useAppContext } from "@/pages/applications/AppContext"

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger
} from "@repo/ui/components/ui/accordion"
import { Button } from "@repo/ui/components/ui/button"
import { Checkbox } from "@repo/ui/components/ui/checkbox"
import { FormControl, FormItem, FormLabel } from "@repo/ui/components/ui/form"
import { Input } from "@repo/ui/components/ui/input"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "@repo/ui/components/ui/select"
import {
  APPLICATION_ASSET_TYPES,
  APPLICATION_LIABILITY_TYPES,
  BORROWER_INDIVIDUAL_ROLES,
  GENDERS,
  MARITAL_STATUSES,
  RESIDENTIAL_STATUSES,
  TITLES,
  YN
} from "@repo/util/constant"

export default function IndividualALSummary() {
  const { data } = useAppContext()

  const [individualAL, setIndividualAL] = useState<any[]>([])

  useEffect(() => {
    if (Object.keys(data ?? {}).length) {
      const borrowerIndividuals = data?.borrower?.individuals ?? []
      let tempIndividualAL = borrowerIndividuals.map((bi: any) => {
        return {
          ...bi,
          birth_date: new Date(bi.birth_date),
          expiry_date: new Date(bi.expiry_date),
          individual_assets:
            data?.assets?.filter(
              (a: any) =>
                a.type === APPLICATION_ASSET_TYPES.Individual && a.individual_uuid === bi.uuid
            ) ?? [],
          individual_liabilities:
            data?.liabilities?.filter(
              (a: any) =>
                a.type === APPLICATION_LIABILITY_TYPES.Individual && a.individual_uuid === bi.uuid
            ) ?? []
        }
      })
      setIndividualAL(tempIndividualAL)
    } else {
      setIndividualAL([])
    }
  }, [data])

  return (
    <div className="flex w-full flex-col gap-4 rounded-2xl border p-4 md:p-6">
      {individualAL.map((item, index) => (
        <Accordion key={item.id} type="multiple" className="w-full">
          <AccordionItem className="mb-4 rounded-2xl border p-4" value="entity-details">
            <AccordionTrigger className="h-24 hover:no-underline md:h-12" tabIndex={-1}>
              <div className="flex items-center justify-between gap-4">
                <h3 className="text-main text-lg font-semibold">
                  {item.title}. {item.first_name} {item.middle_name} {item.last_name}
                </h3>
              </div>
            </AccordionTrigger>
            <AccordionContent className="flex flex-col p-0">
              <div className="flex flex-col py-4">
                <div
                  className={clsx(
                    "mb-4 w-full",
                    Object.entries(BORROWER_INDIVIDUAL_ROLES).filter((bir) =>
                      bir[1].entity_types.includes(data?.borrower?.entity_type)
                    )?.length > 0
                      ? ""
                      : "hidden"
                  )}
                >
                  <FormItem>
                    <FormLabel className="text-main text-sm font-normal">Role</FormLabel>
                    <FormControl>
                      <div className="flex flex-wrap items-center gap-2.5">
                        {Object.entries(BORROWER_INDIVIDUAL_ROLES).map(([k, v]) => {
                          if (!v.entity_types.includes(data?.borrower?.entity_type)) return null

                          return (
                            <Button
                              key={k}
                              disabled
                              type="button"
                              className={clsx(
                                "h-auto border px-6 py-4 font-semibold capitalize",
                                item.role === v.key
                                  ? "border-primary"
                                  : "text-default border-[#868194] bg-transparent hover:bg-transparent"
                              )}
                            >
                              {v.key}
                            </Button>
                          )
                        })}
                      </div>
                    </FormControl>
                  </FormItem>
                </div>

                <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
                  <div className="flex-1">
                    <FormItem>
                      <FormLabel className="text-main text-sm font-normal">Title</FormLabel>
                      <Select disabled value={item.title}>
                        <SelectTrigger
                          className={clsx(
                            "h-12 w-full text-sm",
                            item.title ? "text-main" : "text-default"
                          )}
                        >
                          <SelectValue placeholder="Please select" />
                        </SelectTrigger>
                        <SelectContent>
                          {Object.entries(TITLES).map(([k, v]) => (
                            <SelectItem key={k} value={v}>
                              {v}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </FormItem>
                  </div>

                  <div className="flex-1">
                    <FormItem>
                      <FormLabel className="text-main text-sm font-normal">Gender</FormLabel>
                      <Select disabled value={item.gender}>
                        <SelectTrigger
                          className={clsx(
                            "h-12 w-full text-sm",
                            item.gender ? "text-main" : "text-default"
                          )}
                        >
                          <SelectValue placeholder="Please select" />
                        </SelectTrigger>
                        <SelectContent>
                          {Object.entries(GENDERS).map(([k, v]) => (
                            <SelectItem key={k} value={v}>
                              {v}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </FormItem>
                  </div>
                </div>

                <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
                  <div className="flex-1">
                    <FormItem>
                      <FormLabel className="text-main text-sm font-normal">First name</FormLabel>
                      <Input disabled autoComplete="off" value={item.first_name} />
                    </FormItem>
                  </div>

                  <div className="flex-1">
                    <FormItem>
                      <FormLabel className="text-main text-sm font-normal">Middle name</FormLabel>
                      <Input disabled autoComplete="off" value={item.middle_name} />
                    </FormItem>
                  </div>

                  <div className="flex-1">
                    <FormItem>
                      <FormLabel className="text-main text-sm font-normal">Last name</FormLabel>
                      <Input disabled autoComplete="off" value={item.last_name} />
                    </FormItem>
                  </div>
                </div>

                <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
                  <div className="flex-1">
                    <FormItem>
                      <FormLabel className="text-main text-sm font-normal">
                        Marital Status
                      </FormLabel>
                      <Select disabled value={item.marital_status}>
                        <SelectTrigger
                          className={clsx(
                            "h-12 w-full text-sm",
                            item.marital_status ? "text-main" : "text-default"
                          )}
                        >
                          <SelectValue placeholder="Please select" />
                        </SelectTrigger>
                        <SelectContent>
                          {Object.entries(MARITAL_STATUSES).map(([k, v]) => (
                            <SelectItem key={k} value={v}>
                              {v}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </FormItem>
                  </div>

                  <div className="flex-1">
                    <FormItem className="flex flex-col">
                      <FormLabel className="text-main mb-1 text-sm font-normal">
                        Birth date
                      </FormLabel>
                      <FormControl>
                        <div className="relative">
                          <Input
                            readOnly
                            disabled
                            className="cursor-pointer"
                            value={format(item.birth_date, "dd/MM/yyyy")}
                          />
                          <div className="absolute right-[17.5px] top-[50%] translate-y-[-50%]">
                            <IconCalendar className="text-default text-lg" />
                          </div>
                        </div>
                      </FormControl>
                    </FormItem>
                  </div>
                </div>

                <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
                  <div className="flex-1">
                    <FormItem className="flex flex-col">
                      <FormLabel className="text-main text-sm font-normal">
                        Country of birth
                      </FormLabel>
                      <Select disabled value={item.birth_country}>
                        <SelectTrigger
                          className={clsx(
                            "h-12 w-full text-sm",
                            item.birth_country ? "text-main" : "text-default"
                          )}
                        >
                          <SelectValue placeholder="Please select" />
                        </SelectTrigger>
                        <SelectContent>
                          {getNames()
                            .sort((a, b) => a.localeCompare(b))
                            .map((as) => (
                              <SelectItem key={as} value={as}>
                                {as}
                              </SelectItem>
                            ))}
                        </SelectContent>
                      </Select>
                    </FormItem>
                  </div>

                  <div className="flex-1">
                    <FormItem>
                      <FormLabel className="text-main text-sm font-normal">
                        Place of birth
                      </FormLabel>
                      <Input disabled autoComplete="off" value={item.birth_place} />
                    </FormItem>
                  </div>
                </div>

                <h4 className="text-main my-4 text-base font-semibold">Residential Address</h4>

                <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
                  <div className="flex-1">
                    <FormItem>
                      <FormLabel className="text-main text-sm font-normal">
                        Street address
                      </FormLabel>
                      <APIProvider apiKey={import.meta.env.VITE_GOOGLE_API_KEY ?? ""}>
                        <PlacesAutoPredict
                          disabled
                          textValue={item.residential_street_address}
                          onPlaceSelect={() => {}}
                        />
                      </APIProvider>
                    </FormItem>
                  </div>

                  <div className="flex-1">
                    <FormItem>
                      <FormLabel className="text-main text-sm font-normal">Suburb</FormLabel>
                      <Input disabled autoComplete="off" value={item.residential_suburb} />
                    </FormItem>
                  </div>
                </div>

                <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
                  <div className="flex-1">
                    <FormItem>
                      <FormLabel className="text-main text-sm font-normal">State</FormLabel>
                      <Input disabled autoComplete="off" value={item.residential_state} />
                    </FormItem>
                  </div>

                  <div className="flex-1">
                    <FormItem>
                      <FormLabel className="text-main text-sm font-normal">Postcode</FormLabel>
                      <Input disabled autoComplete="off" value={item.residential_postcode} />
                    </FormItem>
                  </div>
                </div>

                <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
                  <div className="flex-1">
                    <FormItem className="flex flex-col">
                      <FormLabel className="text-main text-sm font-normal">
                        Residential years
                      </FormLabel>
                      <Select disabled value={item.residential_years.toString()}>
                        <SelectTrigger
                          className={clsx(
                            "h-12 w-full text-sm",
                            item.residential_years ? "text-main" : "text-default"
                          )}
                        >
                          <SelectValue placeholder="Please select" />
                        </SelectTrigger>
                        <SelectContent>
                          {Array.from({ length: 50 }, (_, i) => i + 1).map((ry) => (
                            <SelectItem key={ry} value={ry.toString()}>
                              {ry} {pluralize("year", ry)}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </FormItem>
                  </div>

                  <div className="flex-1">
                    <FormItem className="flex flex-col">
                      <FormLabel className="text-main text-sm font-normal">
                        Residential months
                      </FormLabel>
                      <Select disabled value={item.residential_months.toString()}>
                        <SelectTrigger
                          className={clsx(
                            "h-12 w-full text-sm",
                            item.residential_months ? "text-main" : "text-default"
                          )}
                        >
                          <SelectValue placeholder="Please select" />
                        </SelectTrigger>
                        <SelectContent>
                          {Array.from({ length: 12 }, (_, i) => i + 1).map((rm) => (
                            <SelectItem key={rm} value={rm.toString()}>
                              {rm} {pluralize("month", rm)}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </FormItem>
                  </div>
                </div>

                <h4 className="text-main my-4 text-base font-semibold">
                  Previous Address (if less than 2 years at current address)
                </h4>

                <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
                  <div className="flex-1">
                    <FormItem>
                      <FormLabel className="text-main text-sm font-normal">
                        Street address
                      </FormLabel>
                      <APIProvider apiKey={import.meta.env.VITE_GOOGLE_API_KEY ?? ""}>
                        <PlacesAutoPredict
                          disabled
                          textValue={item.previous_street_address}
                          onPlaceSelect={() => {}}
                        />
                      </APIProvider>
                    </FormItem>
                  </div>

                  <div className="flex-1">
                    <FormItem>
                      <FormLabel className="text-main text-sm font-normal">Suburb</FormLabel>
                      <Input disabled autoComplete="off" value={item.previous_suburb} />
                    </FormItem>
                  </div>
                </div>

                <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
                  <div className="flex-1">
                    <FormItem>
                      <FormLabel className="text-main text-sm font-normal">State</FormLabel>
                      <Input disabled autoComplete="off" value={item.previous_state} />
                    </FormItem>
                  </div>

                  <div className="flex-1">
                    <FormItem>
                      <FormLabel className="text-main text-sm font-normal">Postcode</FormLabel>
                      <Input disabled autoComplete="off" value={item.previous_postcode} />
                    </FormItem>
                  </div>
                </div>

                <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:items-center md:gap-8">
                  <div className="flex-1">
                    <FormItem className="flex items-start space-x-2 space-y-0">
                      <FormControl>
                        <Checkbox
                          disabled
                          checked={item.is_australian_resident}
                          className="data-[state=checked]:border-primary h-5 w-5 rounded-[4px] border-[#CDCDCD]"
                        />
                      </FormControl>
                      <div className="flex flex-col">
                        <FormLabel className="text-main text-sm font-normal">
                          Australian resident
                        </FormLabel>
                      </div>
                    </FormItem>
                  </div>

                  <div className="flex-1">
                    <FormItem>
                      <FormLabel className="text-main text-sm font-normal">
                        Current residential Status
                      </FormLabel>
                      <Select disabled value={item.current_residential_status}>
                        <SelectTrigger
                          className={clsx(
                            "h-12 w-full text-sm",
                            item.current_residential_status ? "text-main" : "text-default"
                          )}
                        >
                          <SelectValue placeholder="Please select" />
                        </SelectTrigger>
                        <SelectContent>
                          {Object.entries(RESIDENTIAL_STATUSES).map(([k, v]) => (
                            <SelectItem key={k} value={v}>
                              {v}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </FormItem>
                  </div>
                </div>

                <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
                  <div className="flex-1">
                    <FormItem>
                      <FormLabel className="text-main text-sm font-normal">
                        Postal address
                      </FormLabel>
                      <Input disabled autoComplete="off" value={item.postal_address} />
                    </FormItem>
                  </div>

                  <div className="hidden flex-1 md:block" />
                </div>

                <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
                  <div className="flex-1">
                    <FormItem>
                      <FormLabel className="text-main text-sm font-normal">Email address</FormLabel>
                      <Input disabled type="email" autoComplete="off" value={item.email} />
                    </FormItem>
                  </div>

                  <div className="flex-1">
                    <FormItem>
                      <FormLabel className="text-main text-sm font-normal">Mobile number</FormLabel>
                      <InputMask
                        showMask
                        disabled
                        component={Input}
                        mask="04__ ___ ___"
                        placeholder="04__ ___ ___"
                        replacement={{ _: /\d/ }}
                        value={item.mobile_number}
                      />
                    </FormItem>
                  </div>
                </div>

                <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
                  <div className="flex-1">
                    <FormItem>
                      <FormLabel className="text-main text-sm font-normal">
                        Drivers license number
                      </FormLabel>
                      <Input disabled autoComplete="off" value={item.drivers_license_number} />
                    </FormItem>
                  </div>

                  <div className="flex-1">
                    <FormItem>
                      <FormLabel className="text-main text-sm font-normal">Card number</FormLabel>
                      <Input disabled autoComplete="off" value={item.card_number} />
                    </FormItem>
                  </div>
                </div>

                <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
                  <div className="flex-1">
                    <FormItem className="flex flex-col">
                      <FormLabel className="text-main mb-1 text-sm font-normal">
                        Expiry date
                      </FormLabel>
                      <FormControl>
                        <div className="relative">
                          <Input
                            readOnly
                            disabled
                            className="cursor-pointer"
                            value={format(item.expiry_date, "dd/MM/yyyy")}
                          />
                          <div className="absolute right-[17.5px] top-[50%] translate-y-[-50%]">
                            <IconCalendar className="text-default text-lg" />
                          </div>
                        </div>
                      </FormControl>
                    </FormItem>
                  </div>

                  <div className="hidden flex-1 md:block" />
                </div>
              </div>

              <Accordion type="multiple" className="w-full">
                <AccordionItem className="mb-4 rounded-2xl border p-4" value="assets">
                  <AccordionTrigger className="h-24 hover:no-underline md:h-12" tabIndex={-1}>
                    <div className="flex w-full items-center justify-between gap-4 pr-4">
                      <h3 className="text-main relative text-lg font-semibold">
                        Assets
                        <div className="absolute right-[-16px] top-[-4px] flex h-4 w-4 items-center justify-center rounded-full bg-red-500 text-xs font-normal text-white">
                          {item.individual_assets.length}
                        </div>
                      </h3>
                    </div>
                  </AccordionTrigger>
                  <AccordionContent className="flex flex-col gap-4 p-0">
                    {item.individual_assets.map((subItem: any) => (
                      <div
                        key={subItem.id}
                        className={clsx(
                          "flex flex-col rounded-xl border p-4",
                          index === 0 && "mt-4"
                        )}
                      >
                        <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
                          <div className="flex-1">
                            <FormItem>
                              <FormLabel className="text-main text-sm font-normal">
                                Description of asset
                              </FormLabel>
                              <Input disabled autoComplete="off" value={subItem.description} />
                            </FormItem>
                          </div>

                          <div className="flex-1">
                            <FormItem>
                              <FormLabel className="text-main text-sm font-normal">
                                Value of asset
                              </FormLabel>
                              <NumericFormat
                                disabled
                                customInput={Input}
                                thousandSeparator
                                prefix="$"
                                placeholder="$"
                                value={subItem.value ?? ""}
                              />
                            </FormItem>
                          </div>
                        </div>

                        <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
                          <div className="flex-1">
                            <FormItem>
                              <FormLabel className="text-main text-sm font-normal">
                                Does your asset have an associated debt?
                              </FormLabel>
                              <Select disabled value={subItem.associated_debt}>
                                <SelectTrigger
                                  className={clsx(
                                    "h-12 w-full text-sm",
                                    subItem.associated_debt ? "text-main" : "text-default"
                                  )}
                                >
                                  <SelectValue placeholder="Please select" />
                                </SelectTrigger>
                                <SelectContent>
                                  {Object.entries(YN).map(([k, v]) => (
                                    <SelectItem key={k} value={v}>
                                      {v}
                                    </SelectItem>
                                  ))}
                                </SelectContent>
                              </Select>
                            </FormItem>
                          </div>

                          <div className="hidden flex-1 md:block" />
                        </div>

                        <div
                          className={clsx(
                            "mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8",
                            subItem.associated_debt === YN.Yes ? "block" : "hidden"
                          )}
                        >
                          <div className="flex-1">
                            <FormItem>
                              <FormLabel className="text-main text-sm font-normal">
                                Who is the lender?
                              </FormLabel>
                              <Input disabled autoComplete="off" value={subItem.lender} />
                            </FormItem>
                          </div>

                          <div className="flex-1">
                            <FormItem>
                              <FormLabel className="text-main text-sm font-normal">
                                Monthly repayment / limit
                              </FormLabel>
                              <NumericFormat
                                disabled
                                customInput={Input}
                                thousandSeparator
                                prefix="$"
                                placeholder="$"
                                value={subItem.monthly_repayment ?? ""}
                              />
                            </FormItem>
                          </div>
                        </div>
                      </div>
                    ))}
                  </AccordionContent>
                </AccordionItem>
              </Accordion>

              <Accordion type="multiple" className="w-full">
                <AccordionItem className="mb-4 rounded-2xl border p-4" value="assets">
                  <AccordionTrigger className="h-24 hover:no-underline md:h-12" tabIndex={-1}>
                    <div className="flex w-full items-center justify-between gap-4 pr-4">
                      <h3 className="text-main relative text-lg font-semibold">
                        Liabilities
                        <div className="absolute right-[-16px] top-[-4px] flex h-4 w-4 items-center justify-center rounded-full bg-red-500 text-xs font-normal text-white">
                          {item.individual_liabilities.length}
                        </div>
                      </h3>
                    </div>
                  </AccordionTrigger>
                  <AccordionContent className="flex flex-col gap-4 p-0">
                    {item.individual_liabilities.map((subItem: any) => (
                      <div
                        key={subItem.id}
                        className={clsx(
                          "flex flex-col rounded-xl border p-4",
                          index === 0 && "mt-4"
                        )}
                      >
                        <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
                          <div className="flex-1">
                            <FormItem>
                              <FormLabel className="text-main text-sm font-normal">
                                Description of liability
                              </FormLabel>
                              <Input disabled autoComplete="off" value={subItem.description} />
                            </FormItem>
                          </div>

                          <div className="flex-1">
                            <FormItem>
                              <FormLabel className="text-main text-sm font-normal">
                                Amount owing
                              </FormLabel>
                              <NumericFormat
                                disabled
                                customInput={Input}
                                thousandSeparator
                                prefix="$"
                                placeholder="$"
                                value={subItem.amount_owing ?? ""}
                              />
                            </FormItem>
                          </div>
                        </div>

                        <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
                          <div className="flex-1">
                            <FormItem>
                              <FormLabel className="text-main text-sm font-normal">
                                Who is the lender?
                              </FormLabel>
                              <Input disabled autoComplete="off" value={subItem.lender} />
                            </FormItem>
                          </div>

                          <div className="flex-1">
                            <FormItem>
                              <FormLabel className="text-main text-sm font-normal">
                                Monthly repayment / limit
                              </FormLabel>
                              <NumericFormat
                                disabled
                                customInput={Input}
                                thousandSeparator
                                prefix="$"
                                placeholder="$"
                                value={subItem.monthly_repayment ?? ""}
                              />
                            </FormItem>
                          </div>
                        </div>
                      </div>
                    ))}
                  </AccordionContent>
                </AccordionItem>
              </Accordion>
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      ))}
    </div>
  )
}
