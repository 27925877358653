import qs from "qs"

import api from "./api"

// Get
export const getAdminLendersList = (params: any) =>
  api().get(`/admin/lenders?${qs.stringify(params)}`)

export const getAdminLenderByUuid = (lenderUuid: string) =>
  api().get(`/admin/lenders/${lenderUuid}`)

export const getMaxId = () => api().get(`/admin/lenders/max-id`)

// Create
export const createAdminLender = (params: any) => api().postForm("/admin/lenders", params)

// Update
export const updateAdminLender = (lenderUuid: string, params: any) =>
  api().putForm(`/admin/lenders/${lenderUuid}`, params)

// Archive
export const archiveAdminLender = (lenderUuid: string) =>
  api().patch(`/admin/lenders/${lenderUuid}/archive`)

// Duplicate
export const duplicateAdminLender = (lenderUuid: string) =>
  api().put(`/admin/lenders/${lenderUuid}/duplicate`)

export default {
  getAdminLendersList,
  getAdminLenderByUuid,
  getMaxId,
  createAdminLender,
  updateAdminLender,
  archiveAdminLender,
  duplicateAdminLender
}
