import React from "react"

import { Image, StyleSheet, Text, View } from "@react-pdf/renderer"

interface Props {
  data: Record<string, any>
}

export default function Header(props: Readonly<Props>) {
  const { data } = props

  const styles = StyleSheet.create({
    header: {
      top: 0,
      left: 0,
      right: 0,
      width: "100%",
      height: 80,
      paddingHorizontal: 32,
      paddingTop: 32,
      paddingBottom: 16,
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center"
    }
  })

  return (
    <View style={styles.header} fixed>
      <Image
        style={{
          width: 162,
          height: 18
        }}
        src="/logo.png"
      />

      <View
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
          gap: 4
        }}
      >
        <Text
          style={{
            color: "#868194",
            fontSize: 10,
            fontWeight: 400,
            textAlign: "right"
          }}
        >
          Application ID
        </Text>

        <Text
          style={{
            color: "#0E052B",
            fontSize: 10,
            fontWeight: 400,
            textAlign: "right"
          }}
        >
          #{data?.id?.toString().padStart(10, "0")}
        </Text>
      </View>
    </View>
  )
}
