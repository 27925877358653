export const SITE_TITLE = "FundsConnect"

/* ---------------------------------------------------------------- */

export const ALL = "all"

export const YN = {
  Yes: "Yes",
  No: "No"
}

export const TITLES = {
  Mr: "Mr",
  Mrs: "Mrs",
  Miss: "Miss",
  Ms: "Ms",
  Dr: "Dr",
  Other: "Other"
}

export const GENDERS = {
  Male: "Male",
  Female: "Female",
  NonBinary: "Non-Binary"
}

export const MARITAL_STATUSES = {
  Single: "Single",
  Married: "Married",
  Defacto: "De facto",
  Divorced: "Divorced",
  Widowed: "Widowed",
  Other: "Other"
}

export const RESIDENTIAL_STATUSES = {
  Owner: "Owner",
  Buying: "Buying",
  Boarding: "Boarding",
  Renting: "Renting",
  WithParents: "With Parents"
}

/* ---------------------------------------------------------------- */

export const CRON_JOB_TYPES = {
  ENTITY_MEMBERSHIP_EXPIRY: "Entity Membership Expiry",
  ENTITY_ACL_EXPIRY: "Entity ACL Expiry",
  ENTITY_PII_EXPIRY: "Entity PII Expiry",

  BUSINESS_USER_MEMBERSHIP_EXPIRY: "Business User Membership Expiry",
  BUSINESS_USER_ACR_EXPIRY: "Business User ACR Expiry",
  BUSINESS_USER_PII_EXPIRY: "Business User PII Expiry",

  APPLICATION_INVISIBLE_DELETE: "Application Invisible Delete"
}

/* ---------------------------------------------------------------- */

export const NOTIFICATION_TYPES = {
  APPLICATION: "Application",
  IntroducerLead: "Introducer Lead"
}

export const APPLICATION_STEPS = {
  BorrowerSelection: "Borrower Selection",
  CommissionSplit: "Commission Split",
  FundingSolution: "Funding Solution",
  LoanType: "Loan Type",
  LoanDetails: "Loan Details",
  SecurityDetails: "Security Details",
  LenderSelection: "Lender Selection",
  EntityAL: "Entity's A & L",
  IndividualAL: "Individual's A & L",
  SupportingDocuments: "Supporting Documents",
  ReviewApplication: "Review Application"
}

export const USER_ROLES = {
  Employee: "employee",
  Broker: "broker",
  Introducer: "introducer",
  Enterprise: "enterprise",
  Administrator: "administrator"
}

export const BUSINESS_ROLE = {
  Manager: "manager",
  Employee: "employee"
}

export const ENTITY_TYPES = {
  Company: {
    key: "Company",
    description:
      "This is just for Company text. Don’t be alarmed, this is just here to fill up space since your finalized copy isn’t ready yet.",
    color: {
      text: "#7459BB",
      background: "#7459BB26",
      graph: "#7459BB"
    }
  },
  Trust: {
    key: "Trust (With Corporate Trustee Only)",
    description:
      "This is just for Trust text. Don’t be alarmed, this is just here to fill up space since your finalized copy isn’t ready yet.",
    color: {
      text: "#5FCB6A",
      background: "#5FCB6A26",
      graph: "#5FCB6A"
    }
  },
  SMSF: {
    key: "SMSF (With Corporate Trustee Only) ",
    description:
      "This is just for SMSF text. Don’t be alarmed, this is just here to fill up space since your finalized copy isn’t ready yet.",
    color: {
      text: "#4B8CEE",
      background: "#4B8CEE26",
      graph: "#4B8CEE"
    }
  },

  Partnership: {
    key: "Partnership",
    description:
      "This is just for Partnership text. Don’t be alarmed, this is just here to fill up space since your finalized copy isn’t ready yet.",
    color: {
      text: "#52DAE3",
      background: "#52DAE326",
      graph: "#52DAE3"
    }
  },
  SoleTrader: {
    key: "Sole Trader",
    description:
      "This is just for Sole Trader text. Don’t be alarmed, this is just here to fill up space since your finalized copy isn’t ready yet.",
    color: {
      text: "#D366E4",
      background: "#D366E426",
      graph: "#D366E4"
    }
  },

  Individual: {
    key: "Individual",
    description:
      "This is just for Individual text. Don’t be alarmed, this is just here to fill up space since your finalized copy isn’t ready yet.",
    color: {
      text: "#48E3A4",
      background: "#48E3A426",
      graph: "#48E3A4"
    }
  }
}

export const TRUST_TYPES = {
  Family: {
    key: "Family"
  },
  Discretionary: {
    key: "Discretionary"
  },
  Testamentary: {
    key: "Testamentary"
  },
  UnitTrust: {
    key: "Unit Trust"
  },
  Other: {
    key: "Other"
  }
}

export const MAX_BORROWER_INDIVIDUAL_COUNT = 100

export const BORROWER_INDIVIDUAL_ROLES = {
  Director: {
    key: "director",
    entity_types: [ENTITY_TYPES.Company.key, ENTITY_TYPES.Trust.key, ENTITY_TYPES.SMSF.key]
  },
  Guarantor: {
    key: "guarantor",
    entity_types: [ENTITY_TYPES.Company.key, ENTITY_TYPES.Trust.key, ENTITY_TYPES.SMSF.key]
  },
  Shareholder: {
    key: "shareholder",
    entity_types: [ENTITY_TYPES.Company.key, ENTITY_TYPES.Trust.key, ENTITY_TYPES.SMSF.key]
  },
  Beneficiary: {
    key: "beneficiary",
    entity_types: [ENTITY_TYPES.Trust.key]
  },
  Member: {
    key: "member",
    entity_types: [ENTITY_TYPES.SMSF.key]
  }
}

/* ---------------------------------------------------------------- */
export const APPLICATION_STATUS = {
  Incomplete: {
    key: "Incomplete",
    color: {
      text: "#F59E0B",
      background: "#F59E0B26"
    }
  },
  Received: {
    key: "Received",
    color: {
      text: "#379FFF",
      background: "#379FFF26"
    }
  },
  Credit: {
    key: "Credit",
    color: {
      text: "#8BCCEE",
      background: "#8BCCEE26"
    }
  },
  FurtherInformationRequestedFromCredit: {
    key: "Further Information Requested from Credit",
    color: {
      text: "#000000",
      background: "#00000026"
    }
  },
  Approved: {
    key: "Approved",
    color: {
      text: "#F59E0B",
      background: "#F59E0B26"
    }
  },
  Declined: {
    key: "Declined",
    color: {
      text: "#EF4444",
      background: "#EF444426"
    }
  },
  Withdrawn: {
    key: "Withdrawn",
    color: {
      text: "#483CD0",
      background: "#483CD026"
    }
  },
  Settled: {
    key: "Settled",
    color: {
      text: "#10B981",
      background: "#10B98126"
    }
  },
  Indicative: {
    key: "Indicative",
    color: {
      text: "#E5B24E",
      background: "#E5B24E26"
    }
  },
  IndicativeSigned: {
    key: "Indicative Signed",
    color: {
      text: "#E5B24E",
      background: "#E5B24E26"
    }
  },
  Formal: {
    key: "Formal",
    color: {
      text: "#C10BC5",
      background: "#C10BC526"
    }
  },
  FormalSigned: {
    key: "Formal Signed",
    color: {
      text: "#C10BC5",
      background: "#C10BC526"
    }
  }
}

/* ---------------------------------------------------------------- */

export const AGGREGATORS = {
  LoanMarketGroup: {
    key: "Loan Market Group (LMG)"
  },
  AustralianFinanceGroup: {
    key: "Australian Finance Group (AFG)"
  },
  Finsure: {
    key: "Finsure"
  },
  Connective: {
    key: "Connective"
  },
  SpecialistFinanceGroup: {
    key: "Specialist Finance Group (SFG)"
  },
  VowFinancial: {
    key: "Vow Financial"
  },
  NationalMortgageBrokers: {
    key: "National Mortgage Brokers (nMB)"
  },
  OutsourceFinancial: {
    key: "Outsource Financial"
  },
  MoneyQuest: {
    key: "MoneyQuest"
  },
  Other: {
    key: "Other"
  }
}

export const ENTITY_INDUSTRIES = [
  "Mining and Resources",
  "Agriculture",
  "Finance and Insurance",
  "Healthcare and Social Assistance",
  "Education",
  "Tourism",
  "Construction",
  "Manufacturing",
  "Technology and Innovation",
  "Retail Trade"
]

export const FUNDING_SOLUTIONS = {
  ConstructionFinance: {
    key: "Construction Finance",
    description:
      "Finances building projects, secured by the project’s underlying property value. The full capital stack may apply for complex developments."
  },
  AgriculturalBusinessFinance: {
    key: "Agri Business Finance",
    description:
      "Funding for farming operations, including land, equipment, livestock, and working capital. Secured by agricultural assets like property and commodities."
  },
  CashflowFinance: {
    key: "Cashflow Finance",
    description:
      "Provides liquidity by advancing funds against invoices or projected sales. Includes Debtor, Trade, and Supply Chain Finance, often without real estate security."
  },
  CorporateFinance: {
    key: "Corporate Finance",
    description:
      "Funding for acquisitions or growth, secured by property, assets, or cash flow. A flexible solution utilising the full capital stack where needed."
  },
  PreDevelopmentFinance: {
    key: "Pre-Development Finance",
    description:
      "Covers pre-construction costs, secured by land or other property. Helps developers reduce upfront capital commitments."
  },
  PropertyFinance: {
    key: "Property Finance",
    description:
      "Funding for property purchases or refinancing, secured by residential, commercial, industrial, land, or specialised property."
  },
  SMEFinance: {
    key: "SME Finance",
    description:
      "Provides capital for SMEs to grow, acquire equipment, or manage costs, bypassing traditional bank lending hurdles."
  },
  SMSFFinance: {
    key: "SMSF Finance",
    description:
      "Enables SMSFs to purchase or refinance residential or commercial property, typically under a LRB arrangement."
  }
}

/* ---------------------------------------------------------------- */

export const LOAN_TYPES = {
  // Construction
  SeniorDebt: {
    funding_solutions: [FUNDING_SOLUTIONS.ConstructionFinance.key],
    key: "Senior Debt",
    description: "Funding to secure Primary Debt with first claim on project assets."
  },
  StretchedSeniorDebt: {
    funding_solutions: [FUNDING_SOLUTIONS.ConstructionFinance.key],
    key: "Stretched Senior Debt",
    description: "Funding that provides an up lift in the LVR of Senior debt."
  },
  MezzanineDebt: {
    funding_solutions: [FUNDING_SOLUTIONS.ConstructionFinance.key],
    key: "Mezzanine Debt",
    description: "Funding to secure “Junior Debt”  that bridges equity and senior loans."
  },
  PreferredEquity: {
    funding_solutions: [FUNDING_SOLUTIONS.ConstructionFinance.key],
    key: "Preferred Equity",
    description: "Funding as equity-like capital with preference in payouts."
  },
  JointVenture: {
    funding_solutions: [FUNDING_SOLUTIONS.ConstructionFinance.key],
    key: "Joint Venture",
    description: "Funding for co-investment partnerships in shared project ownership."
  },

  // Cashflow
  DebtorFinance: {
    funding_solutions: [FUNDING_SOLUTIONS.CashflowFinance.key],
    key: "Debtor Finance",
    description: "Funding to leverage unpaid invoices to secure immediate funds."
  },
  TradeFinance: {
    funding_solutions: [FUNDING_SOLUTIONS.CashflowFinance.key],
    key: "Trade Finance",
    description: "Funding for international and domestic trade transactions."
  },
  SupplyChainFinance: {
    funding_solutions: [FUNDING_SOLUTIONS.CashflowFinance.key],
    key: "Supply Chain Finance",
    description: "Funding to support supply chain operational cash flow."
  },

  // PreDevelopment
  SubDividedLandLots: {
    funding_solutions: [FUNDING_SOLUTIONS.PreDevelopmentFinance.key],
    key: "Sub Divided Land Lots",
    description: "Funding for land lots ready for immediate construction."
  },
  VacantLand: {
    funding_solutions: [FUNDING_SOLUTIONS.PreDevelopmentFinance.key],
    key: "Vacant Land (Englobo)",
    description: "Funding for undeveloped, large parcels of land."
  },
  ZonedLand: {
    funding_solutions: [FUNDING_SOLUTIONS.PreDevelopmentFinance.key],
    key: "Zoned Land (DA Approved)",
    description: "Funding for land with development approval."
  },

  // Agriculture Business
  GrowthCapital: {
    funding_solutions: [FUNDING_SOLUTIONS.AgriculturalBusinessFinance.key],
    key: "Growth Capital (incl. CapEx)",
    description: "Funding to expand agribusiness operations."
  },
  WorkingCapital: {
    funding_solutions: [FUNDING_SOLUTIONS.AgriculturalBusinessFinance.key],
    key: "Working Capital",
    description: "Funding for daily business expenses."
  },
  SeasonalFluctuations: {
    funding_solutions: [FUNDING_SOLUTIONS.AgriculturalBusinessFinance.key],
    key: "Seasonal Fluctuations",
    description: "Funding to manage cash flow in off-peak seasons."
  },
  InventoryStockManagement: {
    funding_solutions: [FUNDING_SOLUTIONS.AgriculturalBusinessFinance.key],
    key: "Inventory & Stock Management",
    description: "Funding for stock purchases."
  },

  // Corporate finance
  GrowthCapital2: {
    funding_solutions: [FUNDING_SOLUTIONS.CorporateFinance.key],
    key: "Growth Capital",
    description: "Funding for scaling corporate operations."
  },
  MergersAcquisitions: {
    funding_solutions: [FUNDING_SOLUTIONS.CorporateFinance.key],
    key: "Mergers & Acquisitions",
    description: "Funding for business purchases or mergers."
  },
  AssetBasedFinance: {
    funding_solutions: [FUNDING_SOLUTIONS.CorporateFinance.key],
    key: "Asset Based Finance",
    description: "Funding secured using business assets."
  },
  ShareBuyBack: {
    funding_solutions: [FUNDING_SOLUTIONS.CorporateFinance.key],
    key: "Share Buy-Back",
    description: "Funding to repurchase corporate shares."
  },
  WholesaleWarehousingFunding: {
    funding_solutions: [FUNDING_SOLUTIONS.CorporateFinance.key],
    key: "Wholesale/Warehousing Funding",
    description: "Funding for large stock purchases."
  },
  TurnaroundFinance: {
    funding_solutions: [FUNDING_SOLUTIONS.CorporateFinance.key],
    key: "Turnaround Finance",
    description: "Funding to stabilise struggling businesses."
  },
  DebtRestructure: {
    funding_solutions: [FUNDING_SOLUTIONS.CorporateFinance.key],
    key: "Debt Restructure",
    description: "Funding to adjust debt terms for improved financial health."
  },
  CashflowBasedFinance: {
    funding_solutions: [FUNDING_SOLUTIONS.CorporateFinance.key],
    key: "Cashflow Based Finance",
    description: "Funding based on company cash flow."
  },
  LargeScaleInfrustructureProjectFinance: {
    funding_solutions: [FUNDING_SOLUTIONS.CorporateFinance.key],
    key: "Large Scale Infrustructure & Project Finance",
    description: "Funding for major projects."
  },
  OwnershipSuccessionBuyouts: {
    funding_solutions: [FUNDING_SOLUTIONS.CorporateFinance.key],
    key: "Ownership Succession & Buyouts",
    description: "Funding for ownership transitions."
  },
  PortfolioAcquistion: {
    funding_solutions: [FUNDING_SOLUTIONS.CorporateFinance.key],
    key: "Portfolio Acquistion",
    description: "Funding for purchasing asset portfolios."
  },

  // Property
  Refinance: {
    funding_solutions: [FUNDING_SOLUTIONS.PropertyFinance.key],
    key: "Refinance",
    description: "Funding to restructure existing property loans for better terms."
  },
  Purchase: {
    funding_solutions: [FUNDING_SOLUTIONS.PropertyFinance.key],
    key: "Purchase",
    description: "Funding to acquire residential or commercial property assets."
  },
  EquityRelease: {
    funding_solutions: [FUNDING_SOLUTIONS.PropertyFinance.key],
    key: "Equity Release",
    description: "Funding to unlock property equity for investment or cash flow."
  },
  BridgingLoan: {
    funding_solutions: [FUNDING_SOLUTIONS.PropertyFinance.key],
    key: "Bridging Loan",
    description: "Funding for short-term needs to cover property transaction gaps."
  },
  SecondMortgage: {
    funding_solutions: [FUNDING_SOLUTIONS.PropertyFinance.key],
    key: "2nd Mortgage",
    description: "Funding as an additional 2nd loan secured against existing property."
  },
  ResidualStock: {
    funding_solutions: [FUNDING_SOLUTIONS.PropertyFinance.key],
    key: "Residual Stock ",
    description: "Funding for unsold units in completed developments."
  },

  // SME
  Refinance2: {
    funding_solutions: [FUNDING_SOLUTIONS.SMEFinance.key],
    key: "Refinance",
    description: "Funding to optimise debt for cash flow improvements."
  },
  WorkingCapital2: {
    funding_solutions: [FUNDING_SOLUTIONS.SMEFinance.key],
    key: "Working Capital",
    description: "Funding for daily business expenses."
  },
  InventoryStockManagement2: {
    funding_solutions: [FUNDING_SOLUTIONS.SMEFinance.key],
    key: "Inventory & Stock Management",
    description: "Funding for stock purchases."
  },
  Acquisition: {
    funding_solutions: [FUNDING_SOLUTIONS.SMEFinance.key],
    key: "Acquisition",
    description: "Funding for business acquisitions."
  },
  EquityRelease2: {
    funding_solutions: [FUNDING_SOLUTIONS.SMEFinance.key],
    key: "Equity Release",
    description: "Funding to unlock business equity for growth."
  },
  Bridging: {
    funding_solutions: [FUNDING_SOLUTIONS.SMEFinance.key],
    key: "Bridging",
    description: "Funding for short-term immediate capital needs."
  },
  TaxDebts: {
    funding_solutions: [FUNDING_SOLUTIONS.SMEFinance.key],
    key: "Tax Debts",
    description: "Funding to settle tax obligations."
  },
  ExpansionGrowth: {
    funding_solutions: [FUNDING_SOLUTIONS.SMEFinance.key],
    key: "Expansion & Growth",
    description: "Funding to scale business operations."
  },
  TechnologyUpgrades: {
    funding_solutions: [FUNDING_SOLUTIONS.SMEFinance.key],
    key: "Technology Upgrades",
    description: "Funding for tech investments to enhance efficiency."
  },
  HumanResourcing: {
    funding_solutions: [FUNDING_SOLUTIONS.SMEFinance.key],
    key: "Human Resourcing",
    description: "Funding for hiring and payroll."
  },
  AdvertisingMarketing: {
    funding_solutions: [FUNDING_SOLUTIONS.SMEFinance.key],
    key: "Advertising & Marketing",
    description: "Funding for marketing  and promotional activities."
  },
  RenovationsUpgrades: {
    funding_solutions: [FUNDING_SOLUTIONS.SMEFinance.key],
    key: "Renovations & Upgrades",
    description: "Funding for physical improvements."
  },
  DebtConsolidation: {
    funding_solutions: [FUNDING_SOLUTIONS.SMEFinance.key],
    key: "Debt Consolidation",
    description: "Funding to combine and restructure business debts."
  },
  RestoreCreditRating: {
    funding_solutions: [FUNDING_SOLUTIONS.SMEFinance.key],
    key: "Restore Credit Rating",
    description: "Funding to improve credit standings."
  },
  SeasonalFluctuations2: {
    funding_solutions: [FUNDING_SOLUTIONS.SMEFinance.key],
    key: "Seasonal Fluctuations",
    description: "Funding to manage cash flow in off-peak seasons."
  },

  // SMSF
  Refinance3: {
    funding_solutions: [FUNDING_SOLUTIONS.SMSFFinance.key],
    key: "Refinance",
    description: "Funding to restructure SMSF loan terms."
  },
  Purchase3: {
    funding_solutions: [FUNDING_SOLUTIONS.SMSFFinance.key],
    key: "Purchase",
    description: "Funding for SMSF property acquisitions."
  }
}

/* ---------------------------------------------------------------- */

export const LOAN_DOCUMENT_TYPES = {
  FullDoc: {
    key: "Full Doc",
    description:
      "This is just for Full Doc text. Don’t be alarmed, this is just here to fill up space since your finalized copy isn’t ready yet.",
    enabled: false
  },
  LowDoc: {
    key: "Low Doc",
    description:
      "This is just for Low Doc text. Don’t be alarmed, this is just here to fill up space since your finalized copy isn’t ready yet.",
    enabled: false
  },
  AltDoc: {
    key: "Alt Doc",
    description:
      "This is just for Alt Doc text. Don’t be alarmed, this is just here to fill up space since your finalized copy isn’t ready yet.",
    enabled: false
  },
  LeaseDoc: {
    key: "Lease Doc",
    description:
      "This is just for Lease Doc text. Don’t be alarmed, this is just here to fill up space since your finalized copy isn’t ready yet.",
    enabled: false
  },
  PrivateCredit: {
    key: "Private Credit",
    description:
      "This is just for Private Credit text. Don’t be alarmed, this is just here to fill up space since your finalized copy isn’t ready yet.",
    enabled: true
  }
}

/* ---------------------------------------------------------------- */
// For construction finance
export const EXIT_STRATEGIES = {
  Refinance: "Refinance",
  SaleOfAsset: "Sale of Asset",
  Other: "Other"
}

export const SPECIALISED_DEVELOPMENTS = {
  AffordableSocialHousing: "Affordable & Social Housing",
  AgedCare: "Aged Care",
  BrR: "BrR - Build-to-Rent",
  Childcare: "Childcare",
  DataCentres: "Data Centres",
  DisabilityHousing: "Disability Housing",
  Education: "Education",
  Healthcare: "Healthcare",
  HotelAccommodation: "Hotel (Accommodation)",
  HospitalityVenue: "Hospitality Venue",
  Infrastructure: "Infrastructure",
  LandLeaseCommunities: "Land Lease Communities",
  LifeSciences: "Life Sciences",
  LuxuryHousing: "Luxury Housing",
  NDISHousing: "NDIS Housing",
  Retirement: "Retirement",
  SDAHousing: "SDA Housing",
  SelfStorage: "Self Storage",
  StudentAccommodation: "Student Accommodation"
}

export const NON_SPECIALISED_DEVELOPMENTS = {
  CommercialDevelopment: "Commercial Development",
  ResidentialDevelopment: "Residential Development",
  IndustrialDevelopment: "Industrial Development",
  RetailDevelopment: "Retail Development",
  MixedUseDevelopment: "Mixed-Use Development"
}
/* ---------------------------------------------------------------- */
// For agricultural business finance
export const AGRICULTURAL_COMMODITY_TYPES = {
  Dairy: "Dairy",
  Horticulture: "Horticulture",
  Protein: "Protein",
  Cropping: "Cropping",
  Forestry: "Forestry",
  FoodProcessing: "Food Processing",
  Fibre: "Fibre"
}
/* ---------------------------------------------------------------- */
// For cashflow finance
export const CASHFLOW_TURNOVER_RANGES = [
  {
    key: 1,
    min: 1000000,
    max: 2000000
  },
  {
    key: 2,
    min: 2000001,
    max: 3000000
  },
  {
    key: 3,
    min: 3000001,
    max: 4000000
  },
  {
    key: 4,
    min: 4000001,
    max: 5000000
  },
  {
    key: 5,
    min: 5000001,
    max: 10000000
  },
  {
    key: 6,
    min: 10000001,
    max: 20000000
  },
  {
    key: 7,
    min: 20000001,
    max: 0
  }
]

export const CASHFLOW_INDUSTRY_SECTORS = {
  ImportWholesale: {
    loan_types: [LOAN_TYPES.DebtorFinance.key],
    key: "Import & Wholesale"
  },
  LabourHire: {
    loan_types: [LOAN_TYPES.DebtorFinance.key],
    key: "Labour Hire"
  },
  TrafficManagement: {
    loan_types: [LOAN_TYPES.DebtorFinance.key],
    key: "Traffic Management"
  },
  Manufacturing: {
    loan_types: [LOAN_TYPES.DebtorFinance.key],
    key: "Manufacturing"
  },
  Transport: {
    loan_types: [LOAN_TYPES.DebtorFinance.key],
    key: "Transport"
  },
  Printing: {
    loan_types: [LOAN_TYPES.DebtorFinance.key],
    key: "Printing"
  },
  MiningServices: {
    loan_types: [LOAN_TYPES.DebtorFinance.key],
    key: "Mining Services"
  },
  FoodBeverage: {
    loan_types: [LOAN_TYPES.DebtorFinance.key, LOAN_TYPES.SupplyChainFinance.key],
    key: "Food & Beverage"
  },
  Pharmaceuticals: {
    loan_types: [LOAN_TYPES.DebtorFinance.key],
    key: "Pharmaceuticals"
  },
  Healthcare: {
    loan_types: [LOAN_TYPES.DebtorFinance.key],
    key: "Healthcare"
  },
  ITServices: {
    loan_types: [LOAN_TYPES.DebtorFinance.key],
    key: "IT Services"
  },
  MediaTelecommunications: {
    loan_types: [LOAN_TYPES.DebtorFinance.key],
    key: "Media & Telecommunications"
  },

  Agriculture: {
    loan_types: [LOAN_TYPES.TradeFinance.key],
    key: "Agriculture (Food Products & Raw Materials)"
  },
  ImportExport: {
    loan_types: [LOAN_TYPES.TradeFinance.key],
    key: "Import/Export - Importers & Exporters"
  },
  Construction: {
    loan_types: [LOAN_TYPES.TradeFinance.key],
    key: "Construction (Building Materials & Heavy Machinery)"
  },
  Retailers: {
    loan_types: [LOAN_TYPES.TradeFinance.key],
    key: "Retailers (Apparel, Electronics, Furniture etc)"
  },
  Pharmaceutical: {
    loan_types: [LOAN_TYPES.TradeFinance.key],
    key: "Pharmaceutical (Medical Supplies; Drugs & Medicines)"
  },
  Manufacturers: {
    loan_types: [LOAN_TYPES.TradeFinance.key],
    key: "Manufacturers (Industrial Equipment, Consumer Goods & Automotive Parts)"
  },
  EnergyCompanies: {
    loan_types: [LOAN_TYPES.TradeFinance.key],
    key: "Energy Companies (Oil & Gas & Renewable Energy Components)"
  },
  Wholesalers: {
    loan_types: [LOAN_TYPES.TradeFinance.key],
    key: "Wholesalers (Bulk Products & Intermediate Goods)"
  },

  Agriculture3: {
    loan_types: [LOAN_TYPES.SupplyChainFinance.key],
    key: "Agriculture"
  },
  Automotive: {
    loan_types: [LOAN_TYPES.SupplyChainFinance.key],
    key: "Automotive"
  },
  Construction3: {
    loan_types: [LOAN_TYPES.SupplyChainFinance.key],
    key: "Construction"
  },
  ElectronicsTechnology: {
    loan_types: [LOAN_TYPES.SupplyChainFinance.key],
    key: "Electronics & Technology"
  },
  TextileApparel: {
    loan_types: [LOAN_TYPES.SupplyChainFinance.key],
    key: "Textile & Apparel"
  },
  FurnitureHomeGoods: {
    loan_types: [LOAN_TYPES.SupplyChainFinance.key],
    key: "Furniture & Home Goods"
  },
  Retail: {
    loan_types: [LOAN_TYPES.SupplyChainFinance.key],
    key: "Retail"
  },
  Manufacturers3: {
    loan_types: [LOAN_TYPES.SupplyChainFinance.key],
    key: "Manufacturers"
  }
}
/* ---------------------------------------------------------------- */
// For corporate finance
export const CORPORATE_INDUSTRY_SECTORS = {
  ManufacturingIndustrial:
    "Manufacturing & Industrial (Automotive; Aerospace & Defence; Heavy Machinery; Textiles; Chemicals & Plastics)",
  EnergyUtilities: "Energy & Utilities (Oil & Ga; Renewable Energy; Utilites & Mining)",
  ConstructionRealEstate:
    "Construction & Real Estate (Large Scale Commercial & Residential Real Estate Projects)",
  TransportationLogistics:
    "Transportation & Logistics (Shipping & Maritime; Aviation; Rail & Public Transport; Trucking & Freight)",
  TechnologyTelecommunications:
    "Technology & Telecommunications ( IT; Telco Providers; ISP and Data Centres)",
  HealthcarePharmaceuticals:
    "Healthcare & Pharmaceuticals (Healthcare Providers; Pharmaceuticals; Medical Devices & Biotechnology)",
  RetailConsumerGoods:
    "Retail & Consumer Goods (Retail Chains; Consumer Goods & Food and Beverage)",
  FinancialServicesInsurance:
    "Financial Services & Insurance (Private Equity & Venture Capital for growth, expansion and M&A's)",
  HospitalityLeisure:
    "Hospitality & Leisure (Hotels & Resorts; Travel & Tourism; Restaurants & Food Service)",
  AgricultureAgribusiness:
    "Agriculture & Agribusiness (Farming & Livestock; Food Processin; AgTech)",
  MediaEntertainment:
    "Media & Entertainment (Film & Televison Production; Music Industry; Publishing & Print Media)",
  Education: "Education (Private Educational Institutions; Universities & EdTech)",
  EnvironmentalSustainableIndustries:
    "Environmental & Sustainable Industries ( Sustainable Agriculture; Recyclying & Waste Management & Green Technology)",
  PublicSectorInfrustructure:
    "Public Sector & Infrustructure (Governemnet Contract & Public Infrustructure Projects)",
  NonProfitSocialEnterprises:
    "Non-Profit & Social Enterprises (Non-Profit Organisations & Social Impact Ventures)"
}
/* ---------------------------------------------------------------- */
// For development finance
export const DEVELOPMENT_LOAN_PURPOSES = {
  Purchase: "Purchase",
  Refinance: "Refinance",
  EquityRelease: "Equity Release"
}

export const DEVELOPMENT_SECURITY_TYPES = {
  CommercialLandLots: {
    loan_types: [LOAN_TYPES.SubDividedLandLots.key],
    key: "Commercial Land Lots"
  },
  ResidentialLandLots: {
    loan_types: [LOAN_TYPES.SubDividedLandLots.key],
    key: "Residential Land Lots"
  },
  IndustrialLandLots: {
    loan_types: [LOAN_TYPES.SubDividedLandLots.key],
    key: "Industrial Land Lots"
  },
  RuralLandLots: {
    loan_types: [LOAN_TYPES.SubDividedLandLots.key],
    key: "Rural Land Lots (Excluding Agricultural Land)"
  },

  VacantCommercialLand: {
    loan_types: [LOAN_TYPES.VacantLand.key],
    key: "Vacant Commercial Land"
  },
  VacantResidentialLand: {
    loan_types: [LOAN_TYPES.VacantLand.key],
    key: "Vacant Residential Land"
  },
  VacantIndustrialLand: {
    loan_types: [LOAN_TYPES.VacantLand.key],
    key: "Vacant Industrial Land"
  },
  VacantRuralLand: {
    loan_types: [LOAN_TYPES.VacantLand.key],
    key: "Vacant Rural Land (Excluding Agricultural Land)"
  },

  ZonedCommercialLand: {
    loan_types: [LOAN_TYPES.ZonedLand.key],
    key: "Zoned Commercial Land"
  },
  ZonedResidentialLand: {
    loan_types: [LOAN_TYPES.ZonedLand.key],
    key: "Zoned Residential Land"
  },
  ZonedIndustrialLand: {
    loan_types: [LOAN_TYPES.ZonedLand.key],
    key: "Zoned Industrial Land"
  },
  ZonedRuralLand: {
    loan_types: [LOAN_TYPES.ZonedLand.key],
    key: "Zoned Rural Land (Excluding Agricultural Land)"
  }
}
/* ---------------------------------------------------------------- */
// For property finance
export const PROPERTY_SECURITY_TYPES = {
  ResidentialProperty: "Residential Property",
  CommercialProperty: "Commercial Property",
  IndustrialProperty: "Industrial Property",
  LandOnly: "Land Only",
  MixedUseProperty: "Mixed Use Property",
  RetailProperty: "Retail Property",
  SpecialisedProperty: "Specialised Property"
}
/* ---------------------------------------------------------------- */
// For sme finance
export const SME_SECURITY_TYPES = {
  ResidentialProperty: "Residential Property",
  CommercialProperty: "Commercial Property",
  IndustrialProperty: "Industrial Property",
  LandOnly: "Land Only",
  MixedUseProperty: "Mixed Use Property",
  RetailProperty: "Retail Property",
  SpecialisedProperty: "Specialised Property"
}
/* ---------------------------------------------------------------- */
// For smsf finance
export const SMSF_SECURITY_TYPES = {
  ResidentialProperty: "Residential Property",
  CommercialProperty: "Commercial Property",
  IndustrialProperty: "Industrial Property",
  MixedUseProperty: "Mixed Use Property",
  RetailProperty: "Retail Property"
}
/* ---------------------------------------------------------------- */
export const LOAN_TERMS = {
  M1: {
    key: 1,
    text: "1 month"
  },
  M2: {
    key: 2,
    text: "2 months"
  },
  M3: {
    key: 3,
    text: "3 months"
  },
  M6: {
    key: 6,
    text: "6 months"
  },
  M12: {
    key: 12,
    text: "12 months"
  },
  M18: {
    key: 18,
    text: "18 months"
  },
  M24: {
    key: 24,
    text: "24 months"
  },
  M30: {
    key: 30,
    text: "30 months"
  },
  M36: {
    key: 36,
    text: "36 months"
  },
  M48: {
    key: 48,
    text: "48 months"
  },
  M60: {
    key: 60,
    text: "60 months"
  },
  M300: {
    key: 300,
    text: "25 years"
  },
  M360: {
    key: 360,
    text: "30 years"
  }
}

export const REPAYMENTS = {
  CapitalisedInterest: "Capitalised Interest (Into the Facility)",
  InterestOnly: "Interest Only (Paid Monthly)",
  PrepaidInterest: "Prepaid Interest (From Funds Advanced)"
}

export const REGIONS = {
  CapitalCities: "Capital Cities",
  Metropolitan: "Metropolitan - within 15 km Radius of Capital City Centre",
  MajorRegional: "Major Regional - Population > 20,000"
}

export const COUNTRIES = {
  Australia: "Australia",
  NewZealand: "New Zealand"
}

export const AUSTRALIA_STATES = [
  "New South Wales",
  "Queensland",
  "South Australia",
  "Tasmania",
  "Victoria",
  "Western Australia",
  "Australian Capital Territory",
  "Northern Territory"
]

export const NEWZEALAND_STATES = ["North Island", "South Island"]

export const CHECK_ITEM_TYPES = {
  YesNo: "Yes/No",
  Input: "Input",
  Upload: "Upload"
}

/* ---------------------------------------------------------------- */

export const APPLICATION_ASSET_TYPES = {
  Entity: "entity",
  Individual: "individual"
}

export const APPLICATION_LIABILITY_TYPES = {
  Entity: "entity",
  Individual: "individual"
}

export const ASSETS_TYPES = {
  RealEstate: "Real Estate",
  Cash: "Cash",
  Stocks: "Stocks",
  Bonds: "Bonds",
  Vehicles: "Vehicles",
  Jewelry: "Jewelry",
  Livestock: "Livestock"
}

export const LIABILITIES_TYPES = {
  Mortgage: "Mortgage",
  Loans: "Loans",
  Overdrafts: "Overdrafts",
  Alimony: "Alimony"
}

/* ---------------------------------------------------------------- */

export const MEMBERSHIP_ASSOCIATIONS = ["CAFBA", "FBAA", "MFAA", "Other"]

/* ---------------------------------------------------------------- */

export const BANK_BRANCHES = [
  "Commonwealth Bank of Australia (CBA)",
  "Westpac Banking Corporation",
  "National Australia Bank (NAB)",
  "Australia and New Zealand Banking Group (ANZ)",
  "Bendigo and Adelaide Bank",
  "Macquarie Bank",
  "St. George Bank",
  "Bank of Queensland",
  "Suncorp Bank",
  "Other"
]

/* ---------------------------------------------------------------- */

export const DOCUMENT_TYPES = {
  Primary: "primary",
  Secondary: "secondary"
}

export const DOCUMENTS = [
  {
    type: DOCUMENT_TYPES.Primary,
    name: "Foreign Passport (current)",
    points: 70
  },
  {
    type: DOCUMENT_TYPES.Primary,
    name: "Australian Passport (current or expired within last 2 years but not cancelled)",
    points: 70
  },
  {
    type: DOCUMENT_TYPES.Primary,
    name: "Australian Citizenship Certificate",
    points: 70
  },
  {
    type: DOCUMENT_TYPES.Primary,
    name: "Full Birth certificate (not birth certificate extract)",
    points: 70
  },
  {
    type: DOCUMENT_TYPES.Primary,
    name: "Certificate of Identity issued by the Australian Government to refugees and non Australian citizens forentry to Australia",
    points: 70
  },
  {
    type: DOCUMENT_TYPES.Primary,
    name: "Australian Driver Licence/Learner’s Permit",
    points: 40
  },
  {
    type: DOCUMENT_TYPES.Primary,
    name: "Current (Australian) Tertiary Student Identification Card",
    points: 40
  },
  {
    type: DOCUMENT_TYPES.Primary,
    name: "AustraliaPhoto identification card issued for Australian regulatory purposes (e.g. Aviation/Maritime Security identification, security industry etc.)",
    points: 40
  },
  {
    type: DOCUMENT_TYPES.Primary,
    name: "Government employee ID (Australian Federal/State/Territory)",
    points: 40
  },
  {
    type: DOCUMENT_TYPES.Primary,
    name: "Defence Force Identity Card (with photo or signature)",
    points: 40
  },

  {
    type: DOCUMENT_TYPES.Secondary,
    name: "Department of Veterans Affairs (DVA) card",
    points: 40
  },
  {
    type: DOCUMENT_TYPES.Secondary,
    name: "Centrelink card (with reference number)",
    points: 40
  },
  {
    type: DOCUMENT_TYPES.Secondary,
    name: "Birth Certificate Extract",
    points: 25
  },
  {
    type: DOCUMENT_TYPES.Secondary,
    name: "Birth card (NSW Births, Deaths, Marriages issue only)",
    points: 25
  },
  {
    type: DOCUMENT_TYPES.Secondary,
    name: "Medicare card",
    points: 25
  },
  {
    type: DOCUMENT_TYPES.Secondary,
    name: "Credit card or account card",
    points: 25
  },
  {
    type: DOCUMENT_TYPES.Secondary,
    name: "Australian Marriage certificate (Australian Registry issue only)",
    points: 25
  },
  {
    type: DOCUMENT_TYPES.Secondary,
    name: "Decree Nisi / Decree Absolute (Australian Registry issue only)",
    points: 25
  },
  {
    type: DOCUMENT_TYPES.Secondary,
    name: "Change of name certificate (Australian Registry issue only)",
    points: 25
  },
  {
    type: DOCUMENT_TYPES.Secondary,
    name: "Bank statement (showing transactions)",
    points: 25
  },
  {
    type: DOCUMENT_TYPES.Secondary,
    name: "Property lease agreement - current address",
    points: 25
  },
  {
    type: DOCUMENT_TYPES.Secondary,
    name: "Taxation assessment notice",
    points: 25
  },
  {
    type: DOCUMENT_TYPES.Secondary,
    name: "Australian Mortgage Documents - Current address",
    points: 25
  },
  {
    type: DOCUMENT_TYPES.Secondary,
    name: "Rating Authority - Current address eg Land Rates",
    points: 25
  },
  {
    type: DOCUMENT_TYPES.Secondary,
    name: "Utility Bill - electricity, gas, telephone - Current address (less than 12 months old)",
    points: 20
  },
  {
    type: DOCUMENT_TYPES.Secondary,
    name: "Reference from Indigenous Organisation",
    points: 20
  },
  {
    type: DOCUMENT_TYPES.Secondary,
    name: "Documents issued outside Australia (equivalent to Australian documents). Must have official translation attachedForeign",
    points: 20
  }
]

/* ---------------------------------------------------------------- */

export const PRIVY_USER_LEVEL = {
  Bronze: "bronze",
  Silver: "silver",
  Gold: "gold"
}

export const PRIVY_STATUS = {
  Pending: "pending",
  WaitingVerification: "waiting_verification",
  Verified: "verified",
  Rejected: "rejected"
}

/* ---------------------------------------------------------------- */

export const WS_ROOMS = {
  Notification: "FC-ROOM-NOTIFICATION"
}

export const WS_EVENT = {
  NotificationListFromServer: "FC-EVENT-NOTIFICATION-LIST-FROM-SERVER",
  NotificationOneFromServer: "FC-EVENT-NOTIFICATION-ONE-FROM-SERVER"
}

/* ---------------------------------------------------------------- */

export const abnEntity2fcEntity = (abnEntity: string) => {
  const entityMap: Record<string, any> = {
    "Approved Deposit Fund": null,
    "APRA Regulated Non-Public Offer Fund": null,
    "APRA Regulated Public Offer Fund": null,
    "APRA Regulated Fund (Fund Type Unknown)": null,
    "ATO Regulated Self-Managed Superannuation Fund": ENTITY_TYPES.Company.key,
    "Australian Private Company": ENTITY_TYPES.Company.key,
    "Australian Public Company": ENTITY_TYPES.Company.key,
    "Cash Management Trust": null,
    "Commonwealth Government APRA Regulated Public Sector Fund": null,
    "Commonwealth Government APRA Regulated Public Sector Scheme": null,
    "Commonwealth Government Cash Management Trust": null,
    "Commonwealth Government Company": null,
    "Commonwealth Government Co-operative": null,
    "Commonwealth Government Corporate Unit Trust": null,
    "Commonwealth Government Discretionary Investment Trust": null,
    "Commonwealth Government Discretionary Services Management Trust": null,
    "Commonwealth Government Discretionary Trading Trust": null,
    "Commonwealth Government Entity": null,
    "Commonwealth Government Fixed Trust": null,
    "Commonwealth Government Fixed Unit Trust": null,
    "Commonwealth Government Hybrid Trust": null,
    "Commonwealth Government Limited Partnership": null,
    "Commonwealth Government Listed Public Unit Trust": null,
    "Commonwealth Government Non-Regulated Super Fund": null,
    "Commonwealth Government Other Incorporated Entity": null,
    "Commonwealth Government Other Unincorporated Entity": null,
    "Commonwealth Government Partnership": null,
    "Commonwealth Government Pooled Development Fund": null,
    "Commonwealth Government Private Company": null,
    "Commonwealth Government Public Company": null,
    "Commonwealth Government Public Trading Trust": null,
    "Commonwealth Government Statutory Authority": null,
    "Commonwealth Government Strata Title": null,
    "Commonwealth Government Super Fund": null,
    "Commonwealth Government Trust": null,
    "Commonwealth Government Unlisted Public Unit Trust": null,
    // "Co-operative": ENTITY_TYPES.CoOperative.key,
    "Co-operative": null,
    "Corporate Collective Investment Vehicle (CCIV) Sub-Fund": null,
    "Corporate Unit Trust": ENTITY_TYPES.Trust.key,
    "Deceased Estate": null,
    "Diplomatic/Consulate Body or High Commission": null,
    "Discretionary Investment Trust": ENTITY_TYPES.Trust.key,
    "Discretionary Services Management Trust": ENTITY_TYPES.Trust.key,
    "Discretionary Trading Trust": ENTITY_TYPES.Trust.key,
    "Family Partnership": ENTITY_TYPES.Partnership.key,
    "First Home Saver Accounts (FHSA) Trust": null,
    "Fixed Trust": ENTITY_TYPES.Trust.key,
    "Fixed Unit Trust": ENTITY_TYPES.Trust.key,
    "Hybrid Trust": ENTITY_TYPES.Trust.key,
    "Individual/Sole Trader": ENTITY_TYPES.Individual.key,
    "Limited Partnership": ENTITY_TYPES.Partnership.key,
    "Listed Public Unit Trust": ENTITY_TYPES.Trust.key,
    "Local Government APRA Regulated Public Sector Fund": null,
    "Local Government APRA Regulated Public Sector Scheme": null,
    "Local Government Cash Management Trust": null,
    "Local Government Company": null,
    "Local Government Co-operative": null,
    "Local Government Corporate Unit Trust": null,
    "Local Government Discretionary Investment Trust": null,
    "Local Government Discretionary Services Management Trust": null,
    "Local Government Discretionary Trading Trust": null,
    "Local Government Entity": null,
    "Local Government Fixed Trust": null,
    "Local Government Fixed Unit Trust": null,
    "Local Government Hybrid Trust": null,
    "Local Government Limited Partnership": null,
    "Local Government Listed Public Unit Trust": null,
    "Local Government Non-Regulated Super Fund": null,
    "Local Government Other Incorporated Entity": null,
    "Local Government Other Unincorporated Entity": null,
    "Local Government Partnership": null,
    "Local Government Pooled Development Fund": null,
    "Local Government Private Company": null,
    "Local Government Public Company": null,
    "Local Government Public Trading Trust": null,
    "Local Government Statutory Authority": null,
    "Local Government Strata Title": null,
    "Local Government Trust": null,
    "Local Government Unlisted Public Unit Trust": null,
    "Non-Regulated Superannuation Fund": ENTITY_TYPES.Company.key,
    "Other Incorporated Entity": null,
    "Other Partnership": ENTITY_TYPES.Partnership.key,
    "Other Trust": ENTITY_TYPES.Trust.key,
    // "Other Unincorporated Entity": ENTITY_TYPES.Association.key,
    "Other Unincorporated Entity": null,
    "Pooled Development Fund": null,
    "Pooled Superannuation Trust": null,
    "Public Trading Trust": ENTITY_TYPES.Trust.key,
    "Small APRA Fund": ENTITY_TYPES.Company.key,
    "State Government APRA Regulated Public Sector Scheme": null,
    "State Government Cash Management Trust": null,
    "State Government Company": null,
    "State Government Co-operative": null,
    "State Government Corporate Unit Trust": null,
    "State Government Discretionary Investment Trust": null,
    "State Government Discretionary Services Management Trust": null,
    "State Government Discretionary Trading Trust": null,
    "State Government Entity": null,
    "State Government Fixed Trust": null,
    "State Government Fixed Unit Trust": null,
    "State Government Hybrid Trust": null,
    "State Government Limited Partnership": null,
    "State Government Listed Public Unit Trust": null,
    "State Government Non-Regulated Super Fund": null,
    "State Government Other Incorporated Entity": null,
    "State Government Other Unincorporated Entity": null,
    "State Government Partnership": null,
    "State Government Pooled Development Fund": null,
    "State Government Private Company": null,
    "State Government Public Company": null,
    "State Government Public Trading Trust": null,
    "State Government Statutory Authority": null,
    "State Government Strata Title": null,
    "State Government Trust": null,
    "State Government Unlisted Public Unit Trust": null,
    "State Government APRA Regulated Public Sector Fund": null,
    "Strata-title": null,
    "Super fund": ENTITY_TYPES.Company.key,
    "Territory Government APRA Regulated Public Sector Fund": null,
    "Territory Government APRA Regulated Public Sector Scheme": null,
    "Territory Government Cash Management Trust": null,
    "Territory Government Co-operative": null,
    "Territory Government Corporate Unit Trust": null,
    "Territory Government Discretionary Investment Trust": null,
    "Territory Government Discretionary Services Management Trust": null,
    "Territory Government Discretionary Trading Trust": null,
    "Territory Government Entity": null,
    "Territory Government Fixed Trust": null,
    "Territory Government Fixed Unit Trust": null,
    "Territory Government Hybrid Trust": null,
    "Territory Government Limited Partnership": null,
    "Territory Government Listed Public Unit Trust": null,
    "Territory Government Non-Regulated Super Fund": null,
    "Territory Government Other Incorporated Entity": null,
    "Territory Government Other Unincorporated Entity": null,
    "Territory Government Partnership": null,
    "Territory Government Pooled Development Fund": null,
    "Territory Government Private Company": null,
    "Territory Government Public Company": null,
    "Territory Government Public Trading Trust": null,
    "Territory Government Statutory Authority": null,
    "Territory Government Strata Title": null,
    "Territory Government Trust": null,
    "Territory Government Unlisted Public Unit Trust": null,
    "Unlisted Public Unit Trust": ENTITY_TYPES.Trust.key
  }

  return entityMap[abnEntity]
}
