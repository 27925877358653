import React from "react"

interface Props {
  className?: string
}

export default function IconUserBroker({ className }: Readonly<Props>) {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5 3.5C10.8431 3.5 9.5 4.84315 9.5 6.5C9.5 8.15685 10.8431 9.5 12.5 9.5C14.1569 9.5 15.5 8.15685 15.5 6.5C15.5 4.84315 14.1569 3.5 12.5 3.5ZM7.5 6.5C7.5 3.73858 9.73858 1.5 12.5 1.5C15.2614 1.5 17.5 3.73858 17.5 6.5C17.5 9.26142 15.2614 11.5 12.5 11.5C9.73858 11.5 7.5 9.26142 7.5 6.5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.8571 12.7794C14.1013 12.5968 13.3119 12.5 12.5 12.5C6.97722 12.5 2.5 16.9772 2.5 22.5C2.5 23.0523 2.94772 23.5 3.5 23.5C4.05228 23.5 4.5 23.0523 4.5 22.5C4.5 18.0818 8.08178 14.5 12.5 14.5C12.9163 14.5 13.3252 14.5318 13.7243 14.5931C13.9527 13.8985 14.3457 13.2785 14.8571 12.7794ZM20.096 19.9831C20.3581 20.7745 20.5 21.6207 20.5 22.5C20.5 23.0523 20.9477 23.5 21.5 23.5C22.0523 23.5 22.5 23.0523 22.5 22.5C22.5 21.1036 22.2138 19.774 21.6967 18.5667C21.2857 19.1576 20.7365 19.6453 20.096 19.9831Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.6653 13.4158L18.5661 13.9097C19.2852 14.1151 19.7264 14.6599 19.6164 15.3282L19.0083 15.206C19.0443 14.83 18.8241 14.5638 18.4592 14.4419L18.2143 15.661L18.3477 15.7242C19.0809 16.0571 19.4384 16.4575 19.3133 17.0801C19.1695 17.7965 18.5031 18.0853 17.7469 17.9883L17.6483 18.4793L17.3179 18.413L17.4166 17.922C16.6851 17.7171 16.1815 17.1811 16.302 16.4753L16.9072 16.5969C16.8911 16.9495 17.0628 17.2456 17.5234 17.39L17.7795 16.1149L17.6655 16.0614C16.9575 15.74 16.624 15.3263 16.7432 14.7328C16.8748 14.0778 17.4878 13.7509 18.2358 13.8432L18.335 13.3492L18.6653 13.4158ZM17.8532 17.4591L18.0945 16.2574C18.6144 16.5018 18.7422 16.713 18.6817 17.0141C18.6189 17.3269 18.3608 17.5214 17.8532 17.4591ZM17.3729 14.8078C17.4263 14.5416 17.6973 14.3436 18.1288 14.3755L17.9 15.5159C17.4557 15.3048 17.3194 15.0738 17.3729 14.8078Z"
        fill="currentColor"
      />
      <path
        d="M18.7143 13.4256L18.7241 13.3766L18.6751 13.3667L18.3448 13.3002L18.2958 13.2903L18.2859 13.3394L18.1958 13.7884C17.8313 13.7513 17.4968 13.8123 17.2333 13.9644C16.9579 14.1236 16.7629 14.3811 16.6942 14.7229C16.6321 15.0323 16.6879 15.2985 16.8536 15.5284C17.0178 15.7562 17.287 15.9445 17.6446 16.1068L17.7227 16.1435L17.4855 17.3246C17.292 17.2553 17.1604 17.1574 17.077 17.0422C16.9864 16.917 16.9495 16.7665 16.9571 16.5991L16.9591 16.5563L16.917 16.5478L16.3119 16.4263L16.2614 16.4161L16.2527 16.4669C16.1899 16.8346 16.2901 17.1596 16.5011 17.4181C16.7028 17.6653 17.0044 17.8503 17.3585 17.9571L17.2689 18.4031L17.2591 18.4522L17.3081 18.462L17.6384 18.5283L17.6875 18.5382L17.6973 18.4892L17.7868 18.0434C18.1554 18.0833 18.5045 18.0328 18.7834 17.8833C19.0753 17.7268 19.2874 17.4631 19.3624 17.09C19.4275 16.7659 19.367 16.4953 19.1923 16.2634C19.0195 16.0341 18.7381 15.8466 18.3688 15.6788L18.271 15.6326L18.4968 14.5087C18.6465 14.5672 18.7645 14.6521 18.844 14.7586C18.9322 14.8767 18.9754 15.0248 18.9586 15.2013L18.9543 15.2462L18.9985 15.2551L19.6066 15.3772L19.6574 15.3874L19.6658 15.3363C19.7787 14.65 19.3343 14.0947 18.6241 13.8748L18.7143 13.4256ZM18.4089 17.3342C18.2948 17.4013 18.1327 17.4347 17.913 17.4152L18.1309 16.3304C18.3542 16.4407 18.49 16.542 18.5655 16.6442C18.6447 16.7513 18.6608 16.8641 18.6327 17.0042C18.6037 17.1489 18.5307 17.2625 18.4089 17.3342ZM17.4219 14.8176C17.4666 14.5951 17.6876 14.4123 18.0684 14.4225L17.8638 15.4423C17.6786 15.3476 17.5596 15.2512 17.4906 15.1536C17.4162 15.0483 17.3977 14.9382 17.4219 14.8176Z"
        stroke="currentColor"
        strokeWidth="0.1"
      />
      <path
        d="M22.5589 16.9157C23.0646 14.3979 21.4334 11.9468 18.9156 11.4411C16.3977 10.9354 13.9467 12.5666 13.4409 15.0845C12.9352 17.6023 14.5664 20.0534 17.0843 20.5591C19.6021 21.0648 22.0532 19.4336 22.5589 16.9157ZM14.4704 15.2912C14.8619 13.3419 16.7594 12.0791 18.7088 12.4706C20.6581 12.8621 21.9209 14.7596 21.5294 16.709C21.1379 18.6583 19.2404 19.9211 17.291 19.5296C15.3417 19.1381 14.0789 17.2406 14.4704 15.2912Z"
        fill="white"
        stroke="currentColor"
        strokeWidth="0.3"
      />
    </svg>
  )
}
