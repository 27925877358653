import React from "react"

interface Props {
  className?: string
}

export default function IconEdit({ className }: Readonly<Props>) {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.89014 11.0052H11.1415"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.70503 1.23934V1.23934C7.80903 0.567341 6.53836 0.748674 5.86636 1.64401C5.86636 1.64401 2.52436 6.09601 1.36503 7.64067C0.205698 9.18601 1.30236 11.1007 1.30236 11.1007C1.30236 11.1007 3.46503 11.598 4.6077 10.0747C5.75103 8.55201 9.10903 4.07801 9.10903 4.07801C9.78103 3.18267 9.60036 1.91134 8.70503 1.23934Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.00244 2.80762L8.24511 5.24162"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
