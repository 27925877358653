import { toast } from "sonner"
import { create } from "zustand"

import adminLendersApi from "@/api/adminLendersApi"

import { CLIENT_MSG } from "@repo/i18n"

interface IAdminLenderState {
  loading: boolean
  reloadRequired: boolean

  getListAction: (params: any, onSuccessCallback?: (data: any, totalCount: number) => void) => void
  getOneByUuidAction: (lenderUuid: string, onSuccessCallback?: (data: any) => void) => void
  getMaxIdAction: (onSuccessCallback?: (data: any) => void) => void
  createAction: (params: any, onSuccessCallback?: () => void) => void
  updateAction: (lenderUuid: string, params: any, onSuccessCallback?: () => void) => void
  archiveAction: (lenderUuid: string) => void
  duplicateAction: (lenderUuid: string, onSuccessCallback?: () => void) => void
}

const useAdminLenderStore = create<IAdminLenderState>()((set, get) => ({
  loading: false,
  reloadRequired: false,

  getListAction: async (params, onSuccessCallback) => {
    try {
      set({ loading: true })

      const response = await adminLendersApi.getAdminLendersList(params)

      if (response?.data) {
        const { data } = response.data

        if (typeof onSuccessCallback === "function") {
          onSuccessCallback(data.data, data.totalCount)
        }
      }
    } catch (err: any) {
      console.error(err)

      if (err?.response?.data?.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error(CLIENT_MSG.SOMETHING_WENT_WRONG)
      }
    } finally {
      set({ loading: false })
    }
  },

  getOneByUuidAction: async (lenderUuid, onSuccessCallback) => {
    try {
      set({ loading: true })

      const response = await adminLendersApi.getAdminLenderByUuid(lenderUuid)

      if (response?.data) {
        const { data } = response.data

        if (typeof onSuccessCallback === "function") {
          onSuccessCallback(data)
        }
      }
    } catch (err: any) {
      console.error(err)

      if (err?.response?.data?.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error(CLIENT_MSG.SOMETHING_WENT_WRONG)
      }
    } finally {
      set({ loading: false })
    }
  },

  getMaxIdAction: async (onSuccessCallback) => {
    try {
      set({ loading: true })

      const response = await adminLendersApi.getMaxId()

      if (response?.data) {
        const { data } = response.data

        if (typeof onSuccessCallback === "function") {
          onSuccessCallback(data)
        }
      }
    } catch (err: any) {
      console.error(err)

      if (err?.response?.data?.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error(CLIENT_MSG.SOMETHING_WENT_WRONG)
      }
    } finally {
      set({ loading: false })
    }
  },

  createAction: async (params, onSuccessCallback) => {
    try {
      set({ loading: true })

      const response = await adminLendersApi.createAdminLender(params)

      if (response?.data) {
        const { message } = response.data

        toast.success(message)

        if (typeof onSuccessCallback === "function") {
          onSuccessCallback()
        }
      }
    } catch (err: any) {
      console.error(err)

      if (err?.response?.data?.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error(CLIENT_MSG.SOMETHING_WENT_WRONG)
      }
    } finally {
      set({ loading: false })
    }
  },

  updateAction: async (lenderUuid, params, onSuccessCallback) => {
    try {
      set({ loading: true })

      const response = await adminLendersApi.updateAdminLender(lenderUuid, params)

      if (response?.data) {
        const { message } = response.data

        toast.success(message)

        if (typeof onSuccessCallback === "function") {
          onSuccessCallback()
        }
      }
    } catch (err: any) {
      console.error(err)

      if (err?.response?.data?.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error(CLIENT_MSG.SOMETHING_WENT_WRONG)
      }
    } finally {
      set({ loading: false })
    }
  },

  archiveAction: async (lenderUuid) => {
    try {
      const response = await adminLendersApi.archiveAdminLender(lenderUuid)

      if (response?.data) {
        const { message } = response.data
        set({ reloadRequired: !get().reloadRequired })
        toast.success(message)
      }
    } catch (err: any) {
      console.error(err)

      if (err?.response?.data?.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error(CLIENT_MSG.SOMETHING_WENT_WRONG)
      }
    }
  },

  duplicateAction: async (lenderUuid, onSuccessCallback) => {
    try {
      const response = await adminLendersApi.duplicateAdminLender(lenderUuid)

      if (response?.data) {
        const { message } = response.data
        set({ reloadRequired: !get().reloadRequired })
        toast.success(message)

        if (typeof onSuccessCallback === "function") {
          onSuccessCallback()
        }
      }
    } catch (err: any) {
      console.error(err)

      if (err?.response?.data?.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error(CLIENT_MSG.SOMETHING_WENT_WRONG)
      }
    }
  }
}))

export default useAdminLenderStore
