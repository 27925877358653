import React, { forwardRef, useCallback, useEffect, useState } from "react"

import clsx from "clsx"
import { FileRejection, useDropzone } from "react-dropzone"

import { IconDownload, IconUpload } from "../_icons"

interface Props {
  readOnly?: boolean
  text?: string
  name?: string
  value?: File | string | null
  onChange?: Function
}

function ApprovalUpload({ readOnly, text, name, value, onChange }: Props, ref: any) {
  const [file, setFile] = useState<File | string | null>(value ?? null)
  const [error, setError] = useState("")

  const onDropAccepted = useCallback(
    (acceptedFiles: File[]) => {
      setFile(acceptedFiles[0])
      setError("")

      if (typeof onChange === "function") {
        onChange(acceptedFiles[0])
      }
    },
    [file, onChange]
  )

  const onDropRejected = (fileRejections: FileRejection[]) => {
    if (fileRejections.length > 0) {
      setFile(null)
      setError(fileRejections[0].errors[0].message)

      if (typeof onChange === "function") {
        onChange(null)
      }
    }
  }

  const { getRootProps, getInputProps } = useDropzone({
    maxSize: 5 * 1024 * 1024,
    maxFiles: 1,
    multiple: false,
    onDropAccepted,
    onDropRejected
  })

  useEffect(() => {
    setFile(value ?? null)
  }, [value])

  return (
    <div className="flex w-full flex-col gap-2">
      <input {...getInputProps()} />

      {!readOnly && (
        <div
          className="bg-background flex min-h-52 w-full cursor-pointer flex-col items-center rounded-xl border p-8"
          {...(readOnly ? {} : getRootProps())}
        >
          <div
            className={clsx(
              "flex max-h-16 min-h-16 min-w-16 max-w-16 items-center justify-center rounded-full",
              file ? "bg-primary" : "bg-[#CDCDCD]"
            )}
          >
            <IconUpload className="text-[32px] text-white" />
          </div>

          {text && (
            <div className="text-default mt-4 text-center text-base font-normal">{text}</div>
          )}
        </div>
      )}

      {readOnly && (
        <div
          className={clsx(
            "bg-background flex min-h-52 w-full flex-col items-center rounded-xl border p-8",
            file ? "cursor-pointer" : "cursor-not-allowed"
          )}
          onClick={() => {
            if (file) {
              window.open(typeof file === "string" ? file : "", "_blank")
            }
          }}
          // {...(readOnly ? {} : getRootProps())}
        >
          <div
            className={clsx(
              "flex max-h-16 min-h-16 min-w-16 max-w-16 items-center justify-center rounded-full",
              file ? "bg-primary" : "bg-[#CDCDCD]"
            )}
          >
            <IconDownload className="text-[32px] text-white" />
          </div>

          {text && (
            <div className="text-default mt-4 text-center text-base font-normal">{text}</div>
          )}
        </div>
      )}

      {error && <p className="text-sm font-normal text-red-600">{error}</p>}
    </div>
  )
}

export default forwardRef(ApprovalUpload)
