import { toast } from "sonner"
import { create } from "zustand"

import {
  apiGetApplicationsList,
  apiGetApplicationByUuid,
  apiArchiveApplication,
  apiUpdateApplication,
  apiUpdateApplicationIndicativeApproval,
  apiUpdateApplicationFormalApproval
} from "@/api/applicationsApi"

import { CLIENT_MSG } from "@repo/i18n"
interface IApplicationState {
  loading: boolean
  reloadRequired: boolean

  invertReloadRequired: () => void

  getListAction: (
    params: any,
    onSuccessCallback?: (data: any, totalCount: number, statusCount: any) => void
  ) => void

  getOneByUuidAction: (uuid: string, onSuccessCallback?: (data: any) => void) => void
  archiveAction: (uuid: string) => void
  updateAction: (uuid: string, params: any, onSuccessCallback?: (data: any) => void) => void

  updateIndicativeApprovalAction: (
    uuid: string,
    params: any,
    onSuccessCallback?: (newData: any) => void
  ) => void

  updateFormalApprovalAction: (
    uuid: string,
    params: any,
    onSuccessCallback?: (newData: any) => void
  ) => void
}

const useApplicationStore = create<IApplicationState>()((set, get) => ({
  loading: false,
  reloadRequired: false,

  invertReloadRequired: () => set({ reloadRequired: !get().reloadRequired }),

  getListAction: async (params, onSuccessCallback) => {
    try {
      set({ loading: true })

      const response = await apiGetApplicationsList(params)

      if (response?.data) {
        const { data } = response.data

        if (typeof onSuccessCallback === "function") {
          onSuccessCallback(data.data, data.totalCount, data.statusCount)
        }
      }
    } catch (err: any) {
      console.error(err)

      if (err?.response?.data?.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error(CLIENT_MSG.SOMETHING_WENT_WRONG)
      }
    } finally {
      set({ loading: false })
    }
  },

  getOneByUuidAction: async (uuid, onSuccessCallback) => {
    try {
      set({ loading: true })

      const response = await apiGetApplicationByUuid(uuid)

      if (response?.data) {
        const { data } = response.data

        if (typeof onSuccessCallback === "function") {
          onSuccessCallback(data)
        }
      }
    } catch (err: any) {
      console.error(err)

      if (err?.response?.data?.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error(CLIENT_MSG.SOMETHING_WENT_WRONG)
      }
    } finally {
      set({ loading: false })
    }
  },

  archiveAction: async (uuid: string) => {
    try {
      const response = await apiArchiveApplication(uuid)

      if (response?.data) {
        const { message } = response.data
        set({ reloadRequired: !get().reloadRequired })
        toast.success(message)
      }
    } catch (err: any) {
      console.error(err)

      if (err?.response?.data?.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error(CLIENT_MSG.SOMETHING_WENT_WRONG)
      }
    }
  },

  updateAction: async (uuid: string, params: any, onSuccessCallback) => {
    try {
      set({ loading: true })

      const response = await apiUpdateApplication(uuid, params)

      if (response?.data) {
        const { data } = response.data

        if (typeof onSuccessCallback === "function") {
          onSuccessCallback(data)
        }
      }
    } catch (err: any) {
      console.error(err)

      if (err?.response?.data?.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error(CLIENT_MSG.SOMETHING_WENT_WRONG)
      }
    } finally {
      set({ loading: false })
    }
  },

  updateIndicativeApprovalAction: async (uuid, params, onSuccessCallback) => {
    try {
      set({ loading: true })

      const response = await apiUpdateApplicationIndicativeApproval(uuid, params)

      if (response?.data) {
        const { data, message } = response.data

        toast.success(message)

        if (typeof onSuccessCallback === "function") {
          onSuccessCallback(data)
        }
      }
    } catch (err: any) {
      console.error(err)

      if (err?.response?.data?.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error(CLIENT_MSG.SOMETHING_WENT_WRONG)
      }
    } finally {
      set({ loading: false })
    }
  },

  updateFormalApprovalAction: async (uuid, params, onSuccessCallback) => {
    try {
      set({ loading: true })

      const response = await apiUpdateApplicationFormalApproval(uuid, params)

      if (response?.data) {
        const { data, message } = response.data

        toast.success(message)

        if (typeof onSuccessCallback === "function") {
          onSuccessCallback(data)
        }
      }
    } catch (err: any) {
      console.error(err)

      if (err?.response?.data?.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error(CLIENT_MSG.SOMETHING_WENT_WRONG)
      }
    } finally {
      set({ loading: false })
    }
  }
}))

export default useApplicationStore
