import React from "react"

import EntityDetails from "./bs/EntityDetails"
import BusinessAddress from "./bs/BusinessAddress"

interface Props {
  isView?: boolean
}

export default function BusinessSettingIntroducer(props: Readonly<Props>) {
  const { isView = false } = props

  return (
    <div className="flex w-full flex-col">
      <h2 className="text-main py-4 text-xl font-semibold">Business Setting Information</h2>

      <div id="entity-details">
        <EntityDetails isView={isView} />
      </div>

      <div id="business-address">
        <BusinessAddress isView={isView} />
      </div>
    </div>
  )
}
