import React, { useEffect, useState } from "react"

import { useAppContext } from "@/pages/applications/AppContext"

import { FUNDING_SOLUTIONS } from "@repo/util/constant"

export default function SecurityDetailsSummary() {
  const { data } = useAppContext()
  const [component, setComponent] = useState<any>(null)

  useEffect(() => {
    const importComponent = async () => {
      if (data?.funding_solution === FUNDING_SOLUTIONS.ConstructionFinance.key) {
        const module = await import("./ConstructionFinance")
        const { SecurityDetailsSummaryConstructionFinance } = module
        setComponent(<SecurityDetailsSummaryConstructionFinance />)
      }

      if (data?.funding_solution === FUNDING_SOLUTIONS.AgriculturalBusinessFinance.key) {
        const module = await import("./AgriculturalBusinessFinance")
        const { SecurityDetailsSummaryAgriculturalBusinessFinance } = module
        setComponent(<SecurityDetailsSummaryAgriculturalBusinessFinance />)
      }

      if (data?.funding_solution === FUNDING_SOLUTIONS.PreDevelopmentFinance.key) {
        const module = await import("./DevelopmentFinance")
        const { SecurityDetailsSummaryDevelopmentFinance } = module
        setComponent(<SecurityDetailsSummaryDevelopmentFinance />)
      }

      if (data?.funding_solution === FUNDING_SOLUTIONS.PropertyFinance.key) {
        const module = await import("./PropertyFinance")
        const { SecurityDetailsSummaryPropertyFinance } = module
        setComponent(<SecurityDetailsSummaryPropertyFinance />)
      }

      if (data?.funding_solution === FUNDING_SOLUTIONS.SMEFinance.key) {
        const module = await import("./SMEFinance")
        const { SecurityDetailsSummarySMEFinance } = module
        setComponent(<SecurityDetailsSummarySMEFinance />)
      }

      if (data?.funding_solution === FUNDING_SOLUTIONS.SMSFFinance.key) {
        const module = await import("./SMSFFinance")
        const { SecurityDetailsSummarySMSFFinance } = module
        setComponent(<SecurityDetailsSummarySMSFFinance />)
      }
    }

    importComponent()
  }, [data])

  return <>{component}</>
}
