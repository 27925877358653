import React from "react"

import AuthLayout from "@/components/_layout/AuthLayout"
import useSignupStore from "@/stores/useSignupStore"

import Step01 from "./Step01"
import Step02 from "./Step02"
import Step03 from "./Step03"
import Step04 from "./Step04"

export default function SignUp() {
  const store = useSignupStore()

  return (
    <AuthLayout>
      {store.stage === 1 && <Step01 />}
      {store.stage === 2 && <Step02 />}
      {store.stage === 3 && <Step03 />}
      {store.stage === 4 && <Step04 />}
    </AuthLayout>
  )
}
