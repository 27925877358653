import axios from "axios"

const baseURL = import.meta.env.VITE_API_URL || ""

const api = () => {
  const instance = axios.create({
    baseURL,
    withCredentials: true,
    timeout: 2000000,
    timeoutErrorMessage: "Timeout error occurred. Please try again later.",
    maxContentLength: Infinity,
    maxBodyLength: Infinity
  })

  instance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config

      if (error.response?.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true
        try {
          await instance.post("/auth/refresh-token")
          return instance(originalRequest)
        } catch (refreshError) {
          return Promise.reject(refreshError)
        }
      }

      return Promise.reject(error)
    }
  )

  return instance
}

export default api
