import React from "react"

import clsx from "clsx"
import numeral from "numeral"
import { useFieldArray, useFormContext } from "react-hook-form"
import { NumericFormat } from "react-number-format"

import { Checkbox } from "@repo/ui/components/ui/checkbox"
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from "@repo/ui/components/ui/form"
import { Input } from "@repo/ui/components/ui/input"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "@repo/ui/components/ui/select"
import {
  AGRICULTURAL_COMMODITY_TYPES,
  AUSTRALIA_STATES,
  COUNTRIES,
  DEVELOPMENT_SECURITY_TYPES,
  ENTITY_TYPES,
  EXIT_STRATEGIES,
  FUNDING_SOLUTIONS,
  CORPORATE_INDUSTRY_SECTORS,
  LOAN_DOCUMENT_TYPES,
  DEVELOPMENT_LOAN_PURPOSES,
  LOAN_TERMS,
  NEWZEALAND_STATES,
  NON_SPECIALISED_DEVELOPMENTS,
  REGIONS,
  REPAYMENTS,
  SPECIALISED_DEVELOPMENTS,
  PROPERTY_SECURITY_TYPES,
  SME_SECURITY_TYPES,
  SMSF_SECURITY_TYPES,
  CASHFLOW_TURNOVER_RANGES,
  CASHFLOW_INDUSTRY_SECTORS
} from "@repo/util/constant"

import CheckItems from "./CheckItems"

interface Props {
  fsIndex: number
  isView?: boolean
}

export default function LoanTypes(props: Readonly<Props>) {
  const { fsIndex, isView = false } = props
  const form = useFormContext()

  const { fields } = useFieldArray({
    control: form.control,
    name: `fs.${fsIndex}.lt`
  })

  return (
    <div className="flex flex-col gap-4">
      {fields.map((item: any, index) => (
        <div key={item.id} className="rounded-xl border p-4">
          <h4 className="text-main mb-4 text-base font-semibold">
            Loan Type: <span className="text-primary">{item.loan_type}</span>
          </h4>

          <div className="mb-4 w-full">
            <FormField
              control={form.control}
              name={`fs.${fsIndex}.lt.${index}.applicant_types`}
              render={({ field }) => (
                <div className="flex flex-col gap-2">
                  <FormLabel className="text-main text-sm font-medium">Applicant Type</FormLabel>
                  <div className="grid grid-cols-1 gap-2 md:grid-cols-2 2xl:grid-cols-3">
                    {Object.entries(ENTITY_TYPES).map(([k, v]) => (
                      <FormItem key={k} className="flex items-center space-x-2 space-y-0">
                        <FormControl>
                          <Checkbox
                            disabled={isView}
                            checked={field.value?.includes(v.key)}
                            onCheckedChange={(checked) => {
                              const updatedValue = field.value || []
                              if (checked) {
                                field.onChange([...updatedValue, v.key])
                              } else {
                                field.onChange(
                                  updatedValue.filter((item: string) => item !== v.key)
                                )
                              }
                            }}
                            className="data-[state=checked]:border-primary h-5 w-5 rounded-[4px] border-[#CDCDCD]"
                          />
                        </FormControl>
                        <FormLabel className="text-main font-normal">{v.key}</FormLabel>
                      </FormItem>
                    ))}
                  </div>
                  <FormMessage />
                </div>
              )}
            />
          </div>

          <div className="mb-4 w-full">
            <FormField
              control={form.control}
              name={`fs.${fsIndex}.lt.${index}.loan_document_types`}
              render={({ field }) => (
                <div className="flex flex-col gap-2">
                  <FormLabel className="text-main text-sm font-medium">
                    Loan Document Type
                  </FormLabel>
                  <div className="grid grid-cols-1 gap-2 md:grid-cols-2 2xl:grid-cols-3">
                    {Object.entries(LOAN_DOCUMENT_TYPES).map(([k, v]) => (
                      <FormItem key={k} className="flex items-center space-x-2 space-y-0">
                        <FormControl>
                          <Checkbox
                            disabled={!v.enabled || isView}
                            checked={field.value?.includes(v.key)}
                            onCheckedChange={(checked) => {
                              const updatedValue = field.value || []
                              if (checked) {
                                field.onChange([...updatedValue, v.key])
                              } else {
                                field.onChange(
                                  updatedValue.filter((item: string) => item !== v.key)
                                )
                              }
                            }}
                            className="data-[state=checked]:border-primary h-5 w-5 rounded-[4px] border-[#CDCDCD]"
                          />
                        </FormControl>
                        <FormLabel className="text-main font-normal">{v.key}</FormLabel>
                      </FormItem>
                    ))}
                  </div>
                  <FormMessage />
                </div>
              )}
            />
          </div>

          {form.watch(`fs.${fsIndex}.funding_solution`) !==
            FUNDING_SOLUTIONS.CashflowFinance.key && (
            <>
              <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
                <div className="flex-1">
                  <FormField
                    control={form.control}
                    name={`fs.${fsIndex}.lt.${index}.minimum_loan_size`}
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel className="text-main text-sm font-medium">
                          Minimum loan size
                        </FormLabel>
                        <NumericFormat
                          disabled={isView}
                          customInput={Input}
                          thousandSeparator
                          prefix="$"
                          placeholder="$"
                          getInputRef={field.ref}
                          name={field.name}
                          value={field.value ?? ""}
                          onBlur={field.onBlur}
                          onValueChange={(values, sourceInfo) => {
                            field.onChange(values.value)
                          }}
                        />
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>

                <div className="flex-1">
                  <FormField
                    control={form.control}
                    name={`fs.${fsIndex}.lt.${index}.maximum_loan_size`}
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel className="text-main text-sm font-medium">
                          Maximum loan size
                        </FormLabel>
                        <NumericFormat
                          disabled={isView}
                          customInput={Input}
                          thousandSeparator
                          prefix="$"
                          placeholder="$"
                          getInputRef={field.ref}
                          name={field.name}
                          value={field.value ?? ""}
                          onBlur={field.onBlur}
                          onValueChange={(values, sourceInfo) => {
                            field.onChange(values.value)
                          }}
                        />
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </div>

              <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
                <div className="flex-1">
                  <FormField
                    control={form.control}
                    name={`fs.${fsIndex}.lt.${index}.is_higher_loan_amount`}
                    render={({ field }) => (
                      <FormItem className="flex items-start space-x-2 space-y-0">
                        <FormControl>
                          <Checkbox
                            disabled={isView}
                            checked={field.value}
                            onCheckedChange={(checked) => {
                              field.onChange(checked)
                            }}
                            className="data-[state=checked]:border-primary h-5 w-5 rounded-[4px] border-[#CDCDCD]"
                          />
                        </FormControl>
                        <div className="flex flex-col">
                          <FormLabel className="text-main text-sm font-medium">
                            Higher loan amount
                          </FormLabel>
                          <FormMessage />
                        </div>
                      </FormItem>
                    )}
                  />
                </div>

                <div className="hidden flex-1 md:block"></div>
              </div>
            </>
          )}

          <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
            <div className="flex-1">
              <FormField
                control={form.control}
                name={`fs.${fsIndex}.lt.${index}.minimum_loan_term`}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-main text-sm font-medium">
                      Minimum loan term
                    </FormLabel>
                    <Select disabled={isView} onValueChange={field.onChange} value={field.value}>
                      <SelectTrigger
                        className={clsx(
                          "h-12 w-full text-sm",
                          field.value ? "text-main" : "text-default"
                        )}
                      >
                        <SelectValue placeholder="Please select" />
                      </SelectTrigger>
                      <SelectContent>
                        {Object.entries(LOAN_TERMS).map(([k, v]) => (
                          <SelectItem key={k} value={v.key.toString()}>
                            {v.text}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <div className="flex-1">
              <FormField
                control={form.control}
                name={`fs.${fsIndex}.lt.${index}.maximum_loan_term`}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-main text-sm font-medium">
                      Maximum loan term
                    </FormLabel>
                    <Select disabled={isView} onValueChange={field.onChange} value={field.value}>
                      <SelectTrigger
                        className={clsx(
                          "h-12 w-full text-sm",
                          field.value ? "text-main" : "text-default"
                        )}
                      >
                        <SelectValue placeholder="Please select" />
                      </SelectTrigger>
                      <SelectContent>
                        {Object.entries(LOAN_TERMS).map(([k, v]) => (
                          <SelectItem key={k} value={v.key.toString()}>
                            {v.text}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          </div>

          <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
            <div className="flex-1">
              <FormField
                control={form.control}
                name={`fs.${fsIndex}.lt.${index}.loan_value_ratio`}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-main text-sm font-medium">
                      Loan value ratio
                    </FormLabel>
                    <NumericFormat
                      disabled={isView}
                      customInput={Input}
                      thousandSeparator
                      prefix="%"
                      placeholder="%"
                      decimalScale={2}
                      fixedDecimalScale
                      getInputRef={field.ref}
                      name={field.name}
                      value={field.value ?? ""}
                      onBlur={field.onBlur}
                      onValueChange={(values, sourceInfo) => {
                        field.onChange(values.value)
                      }}
                    />
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <div className="hidden flex-1 md:block"></div>
          </div>

          {form.watch(`fs.${fsIndex}.funding_solution`) ===
            FUNDING_SOLUTIONS.ConstructionFinance.key && (
            <>
              <div className="mb-4 w-full">
                <FormField
                  control={form.control}
                  name={`fs.${fsIndex}.lt.${index}.nsd`}
                  render={({ field }) => (
                    <div className="flex flex-col gap-2">
                      <FormLabel className="text-main text-sm font-medium">
                        Non-Specialised Development
                      </FormLabel>
                      <div className="grid grid-cols-1 gap-2 md:grid-cols-2 2xl:grid-cols-3">
                        {Object.entries(NON_SPECIALISED_DEVELOPMENTS).map(([k, v]) => (
                          <FormItem key={k} className="flex items-center space-x-2 space-y-0">
                            <FormControl>
                              <Checkbox
                                disabled={isView}
                                checked={field.value?.includes(v)}
                                onCheckedChange={(checked) => {
                                  const updatedValue = field.value || []
                                  if (checked) {
                                    field.onChange([...updatedValue, v])
                                  } else {
                                    field.onChange(
                                      updatedValue.filter((item: string) => item !== v)
                                    )
                                  }
                                }}
                                className="data-[state=checked]:border-primary h-5 w-5 rounded-[4px] border-[#CDCDCD]"
                              />
                            </FormControl>
                            <FormLabel className="text-main font-normal">{v}</FormLabel>
                          </FormItem>
                        ))}
                      </div>
                      <FormMessage />
                    </div>
                  )}
                />
              </div>

              <div className="mb-4 w-full">
                <FormField
                  control={form.control}
                  name={`fs.${fsIndex}.lt.${index}.sd`}
                  render={({ field }) => (
                    <div className="flex flex-col gap-2">
                      <FormLabel className="text-main text-sm font-medium">
                        Specialised Development
                      </FormLabel>
                      <div className="grid grid-cols-1 gap-2 md:grid-cols-2 2xl:grid-cols-3">
                        {Object.entries(SPECIALISED_DEVELOPMENTS).map(([k, v]) => (
                          <FormItem key={k} className="flex items-center space-x-2 space-y-0">
                            <FormControl>
                              <Checkbox
                                disabled={isView}
                                checked={field.value?.includes(v)}
                                onCheckedChange={(checked) => {
                                  const updatedValue = field.value || []
                                  if (checked) {
                                    field.onChange([...updatedValue, v])
                                  } else {
                                    field.onChange(
                                      updatedValue.filter((item: string) => item !== v)
                                    )
                                  }
                                }}
                                className="data-[state=checked]:border-primary h-5 w-5 rounded-[4px] border-[#CDCDCD]"
                              />
                            </FormControl>
                            <FormLabel className="text-main font-normal">{v}</FormLabel>
                          </FormItem>
                        ))}
                      </div>
                      <FormMessage />
                    </div>
                  )}
                />
              </div>
            </>
          )}

          {form.watch(`fs.${fsIndex}.funding_solution`) ===
            FUNDING_SOLUTIONS.AgriculturalBusinessFinance.key && (
            <>
              <div className="mb-4 w-full">
                <FormField
                  control={form.control}
                  name={`fs.${fsIndex}.lt.${index}.commodity_types`}
                  render={({ field }) => (
                    <div className="flex flex-col gap-2">
                      <FormLabel className="text-main text-sm font-medium">
                        Commodity Types
                      </FormLabel>
                      <div className="grid grid-cols-1 gap-2 md:grid-cols-2 2xl:grid-cols-3">
                        {Object.entries(AGRICULTURAL_COMMODITY_TYPES).map(([k, v]) => (
                          <FormItem key={k} className="flex items-center space-x-2 space-y-0">
                            <FormControl>
                              <Checkbox
                                disabled={isView}
                                checked={field.value?.includes(v)}
                                onCheckedChange={(checked) => {
                                  const updatedValue = field.value || []
                                  if (checked) {
                                    field.onChange([...updatedValue, v])
                                  } else {
                                    field.onChange(
                                      updatedValue.filter((item: string) => item !== v)
                                    )
                                  }
                                }}
                                className="data-[state=checked]:border-primary h-5 w-5 rounded-[4px] border-[#CDCDCD]"
                              />
                            </FormControl>
                            <FormLabel className="text-main font-normal">{v}</FormLabel>
                          </FormItem>
                        ))}
                      </div>
                      <FormMessage />
                    </div>
                  )}
                />
              </div>
            </>
          )}

          {form.watch(`fs.${fsIndex}.funding_solution`) ===
            FUNDING_SOLUTIONS.CashflowFinance.key && (
            <>
              <div className="mb-4 w-full">
                <FormField
                  control={form.control}
                  name={`fs.${fsIndex}.lt.${index}.turnover_ranges`}
                  render={({ field }) => (
                    <div className="flex flex-col gap-2">
                      <FormLabel className="text-main text-sm font-medium">
                        Annual Turnover Ranges
                      </FormLabel>
                      <div className="grid grid-cols-1 gap-2">
                        {CASHFLOW_TURNOVER_RANGES.map((ctr) => (
                          <FormItem key={ctr.key} className="flex items-center space-x-2 space-y-0">
                            <FormControl>
                              <Checkbox
                                disabled={isView}
                                className="data-[state=checked]:border-primary h-5 w-5 rounded-[4px] border-[#CDCDCD]"
                                checked={
                                  !!field.value?.some(
                                    (v: any) =>
                                      Number(v.min) === Number(ctr.min) &&
                                      Number(v.max) === Number(ctr.max) &&
                                      (typeof v.checked === "boolean"
                                        ? v.checked
                                        : v.checked === "true")
                                  )
                                }
                                onCheckedChange={(checked) => {
                                  let updatedValue = field.value || []
                                  updatedValue = updatedValue.map((v: any) =>
                                    Number(v.min) === Number(ctr.min) &&
                                    Number(v.max) === Number(ctr.max)
                                      ? { ...v, checked: checked }
                                      : v
                                  )
                                  field.onChange(updatedValue)
                                }}
                              />
                            </FormControl>
                            <FormLabel className="text-main font-normal">
                              {numeral(ctr.min).format("$ 0,0")} -{" "}
                              {Number(ctr.max) !== 0 && numeral(ctr.max).format("$ 0,0")}
                            </FormLabel>
                          </FormItem>
                        ))}
                      </div>
                      <FormMessage />
                    </div>
                  )}
                />
              </div>

              <div className="mb-4 w-full">
                <FormField
                  control={form.control}
                  name={`fs.${fsIndex}.lt.${index}.industry_sectors`}
                  render={({ field }) => (
                    <div className="flex flex-col gap-2">
                      <FormLabel className="text-main text-sm font-medium">
                        Industry Sectors
                      </FormLabel>
                      <div className="grid grid-cols-1 gap-2">
                        {Object.values(CASHFLOW_INDUSTRY_SECTORS)
                          .filter((cis) => cis.loan_types.includes(item.loan_type))
                          .map((cis) => (
                            <FormItem
                              key={cis.key}
                              className="flex items-center space-x-2 space-y-0"
                            >
                              <FormControl>
                                <Checkbox
                                  disabled={isView}
                                  checked={field.value?.includes(cis.key)}
                                  onCheckedChange={(checked) => {
                                    const updatedValue = field.value || []
                                    if (checked) {
                                      field.onChange([...updatedValue, cis.key])
                                    } else {
                                      field.onChange(
                                        updatedValue.filter((item: string) => item !== cis.key)
                                      )
                                    }
                                  }}
                                  className="data-[state=checked]:border-primary h-5 w-5 rounded-[4px] border-[#CDCDCD]"
                                />
                              </FormControl>
                              <FormLabel className="text-main font-normal">{cis.key}</FormLabel>
                            </FormItem>
                          ))}
                      </div>
                      <FormMessage />
                    </div>
                  )}
                />
              </div>

              {/* <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
                <div className="flex-1">
                  <FormField
                    control={form.control}
                    name={`fs.${fsIndex}.lt.${index}.max_invoice_financed`}
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel className="text-main text-sm font-medium">
                          Max Invoice Financed
                        </FormLabel>
                        <NumericFormat
                          disabled={isView}
                          customInput={Input}
                          thousandSeparator
                          prefix="%"
                          placeholder="%"
                          decimalScale={2}
                          fixedDecimalScale
                          getInputRef={field.ref}
                          name={field.name}
                          value={field.value ?? ""}
                          onBlur={field.onBlur}
                          onValueChange={(values, sourceInfo) => {
                            field.onChange(values.value)
                          }}
                        />
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                <div className="hidden flex-1 md:block"></div>
              </div> */}
            </>
          )}

          {form.watch(`fs.${fsIndex}.funding_solution`) ===
            FUNDING_SOLUTIONS.CorporateFinance.key && (
            <>
              <div className="mb-4 w-full">
                <FormField
                  control={form.control}
                  name={`fs.${fsIndex}.lt.${index}.industry_sectors`}
                  render={({ field }) => (
                    <div className="flex flex-col gap-2">
                      <FormLabel className="text-main text-sm font-medium">
                        Industry Sectors
                      </FormLabel>
                      <div className="grid grid-cols-1 gap-2">
                        {Object.entries(CORPORATE_INDUSTRY_SECTORS).map(([k, v]) => (
                          <FormItem key={k} className="flex items-center space-x-2 space-y-0">
                            <FormControl>
                              <Checkbox
                                disabled={isView}
                                checked={field.value?.includes(v)}
                                onCheckedChange={(checked) => {
                                  const updatedValue = field.value || []
                                  if (checked) {
                                    field.onChange([...updatedValue, v])
                                  } else {
                                    field.onChange(
                                      updatedValue.filter((item: string) => item !== v)
                                    )
                                  }
                                }}
                                className="data-[state=checked]:border-primary h-5 w-5 rounded-[4px] border-[#CDCDCD]"
                              />
                            </FormControl>
                            <FormLabel className="text-main font-normal">{v}</FormLabel>
                          </FormItem>
                        ))}
                      </div>
                      <FormMessage />
                    </div>
                  )}
                />
              </div>
            </>
          )}

          {form.watch(`fs.${fsIndex}.funding_solution`) ===
            FUNDING_SOLUTIONS.PreDevelopmentFinance.key && (
            <>
              <div className="mb-4 w-full">
                <FormField
                  control={form.control}
                  name={`fs.${fsIndex}.lt.${index}.loan_purposes`}
                  render={({ field }) => (
                    <div className="flex flex-col gap-2">
                      <FormLabel className="text-main text-sm font-medium">Loan Purposes</FormLabel>
                      <div className="grid grid-cols-1 gap-2">
                        {Object.entries(DEVELOPMENT_LOAN_PURPOSES).map(([k, v]) => (
                          <FormItem key={k} className="flex items-center space-x-2 space-y-0">
                            <FormControl>
                              <Checkbox
                                disabled={isView}
                                checked={field.value?.includes(v)}
                                onCheckedChange={(checked) => {
                                  const updatedValue = field.value || []
                                  if (checked) {
                                    field.onChange([...updatedValue, v])
                                  } else {
                                    field.onChange(
                                      updatedValue.filter((item: string) => item !== v)
                                    )
                                  }
                                }}
                                className="data-[state=checked]:border-primary h-5 w-5 rounded-[4px] border-[#CDCDCD]"
                              />
                            </FormControl>
                            <FormLabel className="text-main font-normal">{v}</FormLabel>
                          </FormItem>
                        ))}
                      </div>
                      <FormMessage />
                    </div>
                  )}
                />
              </div>

              <div className="mb-4 w-full">
                <FormField
                  control={form.control}
                  name={`fs.${fsIndex}.lt.${index}.securities`}
                  render={({ field }) => (
                    <div className="flex flex-col gap-2">
                      <FormLabel className="text-main text-sm font-medium">
                        Security Types
                      </FormLabel>
                      <div className="grid grid-cols-1 gap-2">
                        {Object.values(DEVELOPMENT_SECURITY_TYPES)
                          .filter((dst) => dst.loan_types.includes(item.loan_type))
                          .map((dst) => (
                            <FormItem
                              key={dst.key}
                              className="flex items-center space-x-2 space-y-0"
                            >
                              <FormControl>
                                <Checkbox
                                  disabled={isView}
                                  checked={field.value?.includes(dst.key)}
                                  onCheckedChange={(checked) => {
                                    const updatedValue = field.value || []
                                    if (checked) {
                                      field.onChange([...updatedValue, dst.key])
                                    } else {
                                      field.onChange(
                                        updatedValue.filter((item: string) => item !== dst.key)
                                      )
                                    }
                                  }}
                                  className="data-[state=checked]:border-primary h-5 w-5 rounded-[4px] border-[#CDCDCD]"
                                />
                              </FormControl>
                              <FormLabel className="text-main font-normal">{dst.key}</FormLabel>
                            </FormItem>
                          ))}
                      </div>
                      <FormMessage />
                    </div>
                  )}
                />
              </div>
            </>
          )}

          {form.watch(`fs.${fsIndex}.funding_solution`) ===
            FUNDING_SOLUTIONS.PropertyFinance.key && (
            <>
              <div className="mb-4 w-full">
                <FormField
                  control={form.control}
                  name={`fs.${fsIndex}.lt.${index}.securities`}
                  render={({ field }) => (
                    <div className="flex flex-col gap-2">
                      <FormLabel className="text-main text-sm font-medium">
                        Security Types
                      </FormLabel>
                      <div className="grid grid-cols-1 gap-2">
                        {Object.entries(PROPERTY_SECURITY_TYPES).map(([k, v]) => (
                          <FormItem key={k} className="flex items-center space-x-2 space-y-0">
                            <FormControl>
                              <Checkbox
                                disabled={isView}
                                checked={field.value?.includes(v)}
                                onCheckedChange={(checked) => {
                                  const updatedValue = field.value || []
                                  if (checked) {
                                    field.onChange([...updatedValue, v])
                                  } else {
                                    field.onChange(
                                      updatedValue.filter((item: string) => item !== v)
                                    )
                                  }
                                }}
                                className="data-[state=checked]:border-primary h-5 w-5 rounded-[4px] border-[#CDCDCD]"
                              />
                            </FormControl>
                            <FormLabel className="text-main font-normal">{v}</FormLabel>
                          </FormItem>
                        ))}
                      </div>
                      <FormMessage />
                    </div>
                  )}
                />
              </div>
            </>
          )}

          {form.watch(`fs.${fsIndex}.funding_solution`) === FUNDING_SOLUTIONS.SMEFinance.key && (
            <>
              <div className="mb-4 w-full">
                <FormField
                  control={form.control}
                  name={`fs.${fsIndex}.lt.${index}.securities`}
                  render={({ field }) => (
                    <div className="flex flex-col gap-2">
                      <FormLabel className="text-main text-sm font-medium">
                        Security Types
                      </FormLabel>
                      <div className="grid grid-cols-1 gap-2">
                        {Object.entries(SME_SECURITY_TYPES).map(([k, v]) => (
                          <FormItem key={k} className="flex items-center space-x-2 space-y-0">
                            <FormControl>
                              <Checkbox
                                disabled={isView}
                                checked={field.value?.includes(v)}
                                onCheckedChange={(checked) => {
                                  const updatedValue = field.value || []
                                  if (checked) {
                                    field.onChange([...updatedValue, v])
                                  } else {
                                    field.onChange(
                                      updatedValue.filter((item: string) => item !== v)
                                    )
                                  }
                                }}
                                className="data-[state=checked]:border-primary h-5 w-5 rounded-[4px] border-[#CDCDCD]"
                              />
                            </FormControl>
                            <FormLabel className="text-main font-normal">{v}</FormLabel>
                          </FormItem>
                        ))}
                      </div>
                      <FormMessage />
                    </div>
                  )}
                />
              </div>
            </>
          )}

          {form.watch(`fs.${fsIndex}.funding_solution`) === FUNDING_SOLUTIONS.SMSFFinance.key && (
            <>
              <div className="mb-4 w-full">
                <FormField
                  control={form.control}
                  name={`fs.${fsIndex}.lt.${index}.securities`}
                  render={({ field }) => (
                    <div className="flex flex-col gap-2">
                      <FormLabel className="text-main text-sm font-medium">
                        Security Types
                      </FormLabel>
                      <div className="grid grid-cols-1 gap-2">
                        {Object.entries(SMSF_SECURITY_TYPES).map(([k, v]) => (
                          <FormItem key={k} className="flex items-center space-x-2 space-y-0">
                            <FormControl>
                              <Checkbox
                                disabled={isView}
                                checked={field.value?.includes(v)}
                                onCheckedChange={(checked) => {
                                  const updatedValue = field.value || []
                                  if (checked) {
                                    field.onChange([...updatedValue, v])
                                  } else {
                                    field.onChange(
                                      updatedValue.filter((item: string) => item !== v)
                                    )
                                  }
                                }}
                                className="data-[state=checked]:border-primary h-5 w-5 rounded-[4px] border-[#CDCDCD]"
                              />
                            </FormControl>
                            <FormLabel className="text-main font-normal">{v}</FormLabel>
                          </FormItem>
                        ))}
                      </div>
                      <FormMessage />
                    </div>
                  )}
                />
              </div>
            </>
          )}

          <div className="mb-4 w-full">
            <FormField
              control={form.control}
              name={`fs.${fsIndex}.lt.${index}.countries`}
              render={({ field }) => (
                <div className="flex flex-col gap-2">
                  <FormLabel className="text-main text-sm font-medium">Country</FormLabel>
                  <div className="grid grid-cols-1 gap-2 md:grid-cols-2 2xl:grid-cols-3">
                    {Object.entries(COUNTRIES).map(([k, v]) => (
                      <FormItem key={k} className="flex items-center space-x-2 space-y-0">
                        <FormControl>
                          <Checkbox
                            disabled={isView}
                            checked={field.value?.includes(v)}
                            onCheckedChange={(checked) => {
                              const updatedValue = field.value || []
                              if (checked) {
                                field.onChange([...updatedValue, v])
                              } else {
                                field.onChange(updatedValue.filter((item: string) => item !== v))
                              }
                            }}
                            className="data-[state=checked]:border-primary h-5 w-5 rounded-[4px] border-[#CDCDCD]"
                          />
                        </FormControl>
                        <FormLabel className="text-main font-normal">{v}</FormLabel>
                      </FormItem>
                    ))}
                  </div>
                  <FormMessage />
                </div>
              )}
            />
          </div>

          <div className="mb-4 w-full">
            <FormField
              control={form.control}
              name={`fs.${fsIndex}.lt.${index}.states`}
              render={({ field }) => (
                <div className="flex flex-col gap-2">
                  <FormLabel className="text-main text-sm font-medium">States</FormLabel>
                  <div className="grid grid-cols-1 gap-2 md:grid-cols-2 2xl:grid-cols-3">
                    <div className="flex flex-col gap-1">
                      {AUSTRALIA_STATES.map((v) => (
                        <FormItem key={v} className="flex items-center space-x-2 space-y-0">
                          <FormControl>
                            <Checkbox
                              disabled={
                                !form
                                  .watch(`fs.${fsIndex}.lt.${index}.countries`)
                                  ?.includes(COUNTRIES.Australia) || isView
                              }
                              checked={field.value?.includes(v)}
                              onCheckedChange={(checked) => {
                                const updatedValue = field.value || []
                                if (checked) {
                                  field.onChange([...updatedValue, v])
                                } else {
                                  field.onChange(updatedValue.filter((item: string) => item !== v))
                                }
                              }}
                              className="data-[state=checked]:border-primary h-5 w-5 rounded-[4px] border-[#CDCDCD]"
                            />
                          </FormControl>
                          <FormLabel className="text-main font-normal">{v}</FormLabel>
                        </FormItem>
                      ))}
                    </div>

                    <div className="flex flex-col gap-1">
                      {NEWZEALAND_STATES.map((v) => (
                        <FormItem key={v} className="flex items-center space-x-2 space-y-0">
                          <FormControl>
                            <Checkbox
                              disabled={
                                !form
                                  .watch(`fs.${fsIndex}.lt.${index}.countries`)
                                  ?.includes(COUNTRIES.NewZealand) || isView
                              }
                              checked={field.value?.includes(v)}
                              onCheckedChange={(checked) => {
                                const updatedValue = field.value || []
                                if (checked) {
                                  field.onChange([...updatedValue, v])
                                } else {
                                  field.onChange(updatedValue.filter((item: string) => item !== v))
                                }
                              }}
                              className="data-[state=checked]:border-primary h-5 w-5 rounded-[4px] border-[#CDCDCD]"
                            />
                          </FormControl>
                          <FormLabel className="text-main font-normal">{v}</FormLabel>
                        </FormItem>
                      ))}
                    </div>
                  </div>
                  <FormMessage />
                </div>
              )}
            />
          </div>

          <div className="mb-4 w-full">
            <FormField
              control={form.control}
              name={`fs.${fsIndex}.lt.${index}.regions`}
              render={({ field }) => (
                <div className="flex flex-col gap-2">
                  <FormLabel className="text-main text-sm font-medium">Region</FormLabel>
                  <div className="grid grid-cols-1 gap-2">
                    {Object.entries(REGIONS).map(([k, v]) => (
                      <FormItem key={k} className="flex items-center space-x-2 space-y-0">
                        <FormControl>
                          <Checkbox
                            disabled={isView}
                            checked={field.value?.includes(v)}
                            onCheckedChange={(checked) => {
                              const updatedValue = field.value || []
                              if (checked) {
                                field.onChange([...updatedValue, v])
                              } else {
                                field.onChange(updatedValue.filter((item: string) => item !== v))
                              }
                            }}
                            className="data-[state=checked]:border-primary h-5 w-5 rounded-[4px] border-[#CDCDCD]"
                          />
                        </FormControl>
                        <FormLabel className="text-main font-normal">{v}</FormLabel>
                      </FormItem>
                    ))}
                  </div>
                  <FormMessage />
                </div>
              )}
            />
          </div>

          <div className="mb-4 w-full">
            <FormField
              control={form.control}
              name={`fs.${fsIndex}.lt.${index}.exit_strategies`}
              render={({ field }) => (
                <div className="flex flex-col gap-2">
                  <FormLabel className="text-main text-sm font-medium">Exit Strategy</FormLabel>
                  <div className="grid grid-cols-1 gap-2">
                    {Object.entries(EXIT_STRATEGIES).map(([k, v]) => (
                      <FormItem key={k} className="flex items-center space-x-2 space-y-0">
                        <FormControl>
                          <Checkbox
                            disabled={isView}
                            checked={field.value?.includes(v)}
                            onCheckedChange={(checked) => {
                              const updatedValue = field.value || []
                              if (checked) {
                                field.onChange([...updatedValue, v])
                              } else {
                                field.onChange(updatedValue.filter((item: string) => item !== v))
                              }
                            }}
                            className="data-[state=checked]:border-primary h-5 w-5 rounded-[4px] border-[#CDCDCD]"
                          />
                        </FormControl>
                        <FormLabel className="text-main font-normal">{v}</FormLabel>
                      </FormItem>
                    ))}
                  </div>
                  <FormMessage />
                </div>
              )}
            />
          </div>

          <div className="mb-4 w-full">
            <FormField
              control={form.control}
              name={`fs.${fsIndex}.lt.${index}.repayments`}
              render={({ field }) => (
                <div className="flex flex-col gap-2">
                  <FormLabel className="text-main text-sm font-medium">Repayment</FormLabel>
                  <div className="grid grid-cols-1 gap-2">
                    {Object.entries(REPAYMENTS).map(([k, v]) => (
                      <FormItem key={k} className="flex items-center space-x-2 space-y-0">
                        <FormControl>
                          <Checkbox
                            disabled={isView}
                            checked={field.value?.includes(v)}
                            onCheckedChange={(checked) => {
                              const updatedValue = field.value || []
                              if (checked) {
                                field.onChange([...updatedValue, v])
                              } else {
                                field.onChange(updatedValue.filter((item: string) => item !== v))
                              }
                            }}
                            className="data-[state=checked]:border-primary h-5 w-5 rounded-[4px] border-[#CDCDCD]"
                          />
                        </FormControl>
                        <FormLabel className="text-main font-normal">{v}</FormLabel>
                      </FormItem>
                    ))}
                  </div>
                  <FormMessage />
                </div>
              )}
            />
          </div>

          <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
            <div className="flex-1">
              <FormField
                control={form.control}
                name={`fs.${fsIndex}.lt.${index}.establishment_fee`}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-main text-sm font-medium">
                      Establishment Fee
                    </FormLabel>
                    <NumericFormat
                      disabled={isView}
                      customInput={Input}
                      thousandSeparator
                      prefix="%"
                      placeholder="%"
                      decimalScale={2}
                      fixedDecimalScale
                      getInputRef={field.ref}
                      name={field.name}
                      value={field.value ?? ""}
                      onBlur={field.onBlur}
                      onValueChange={(values, sourceInfo) => {
                        field.onChange(values.value)
                      }}
                    />
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <div className="flex-1">
              <FormField
                control={form.control}
                name={`fs.${fsIndex}.lt.${index}.interest_rate`}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-main text-sm font-medium">Interest Rate</FormLabel>
                    <NumericFormat
                      disabled={isView}
                      customInput={Input}
                      thousandSeparator
                      prefix="%"
                      placeholder="%"
                      decimalScale={2}
                      fixedDecimalScale
                      getInputRef={field.ref}
                      name={field.name}
                      value={field.value ?? ""}
                      onBlur={field.onBlur}
                      onValueChange={(values, sourceInfo) => {
                        field.onChange(values.value)
                      }}
                    />
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          </div>

          <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
            <FormField
              control={form.control}
              name={`fs.${fsIndex}.lt.${index}.mortgage_management_fee`}
              render={({ field }) => (
                <FormItem className="flex items-start space-x-2 space-y-0">
                  <FormControl>
                    <Checkbox
                      disabled={isView}
                      checked={field.value}
                      onCheckedChange={(checked) => {
                        field.onChange(checked)
                      }}
                      className="data-[state=checked]:border-primary h-5 w-5 rounded-[4px] border-[#CDCDCD]"
                    />
                  </FormControl>
                  <div className="flex flex-col">
                    <FormLabel className="text-main text-sm font-medium">
                      Mortgage Management Fee
                    </FormLabel>
                    <FormMessage />
                  </div>
                </FormItem>
              )}
            />
          </div>

          <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
            <FormField
              control={form.control}
              name={`fs.${fsIndex}.lt.${index}.line_fee`}
              render={({ field }) => (
                <FormItem className="flex items-start space-x-2 space-y-0">
                  <FormControl>
                    <Checkbox
                      disabled={isView}
                      checked={field.value}
                      onCheckedChange={(checked) => {
                        field.onChange(checked)
                      }}
                      className="data-[state=checked]:border-primary h-5 w-5 rounded-[4px] border-[#CDCDCD]"
                    />
                  </FormControl>
                  <div className="flex flex-col">
                    <FormLabel className="text-main text-sm font-medium">Line Fee</FormLabel>
                    <FormMessage />
                  </div>
                </FormItem>
              )}
            />
          </div>

          <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
            <FormField
              control={form.control}
              name={`fs.${fsIndex}.lt.${index}.pre_sales_requirement`}
              render={({ field }) => (
                <FormItem className="flex items-start space-x-2 space-y-0">
                  <FormControl>
                    <Checkbox
                      disabled={isView}
                      checked={field.value}
                      onCheckedChange={(checked) => {
                        field.onChange(checked)
                      }}
                      className="data-[state=checked]:border-primary h-5 w-5 rounded-[4px] border-[#CDCDCD]"
                    />
                  </FormControl>
                  <div className="flex flex-col">
                    <FormLabel className="text-main text-sm font-medium">
                      Pre-Sales Requirement
                    </FormLabel>
                    <FormMessage />
                  </div>
                </FormItem>
              )}
            />
          </div>

          {form.watch(`fs.${fsIndex}.funding_solution`) === FUNDING_SOLUTIONS.SMSFFinance.key && (
            <>
              <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
                <FormField
                  control={form.control}
                  name={`fs.${fsIndex}.lt.${index}.net_assets_test`}
                  render={({ field }) => (
                    <FormItem className="flex items-start space-x-2 space-y-0">
                      <FormControl>
                        <Checkbox
                          disabled={isView}
                          checked={field.value}
                          onCheckedChange={(checked) => {
                            field.onChange(checked)
                          }}
                          className="data-[state=checked]:border-primary h-5 w-5 rounded-[4px] border-[#CDCDCD]"
                        />
                      </FormControl>
                      <div className="flex flex-col">
                        <FormLabel className="text-main text-sm font-medium">
                          $0 Net Assets Test
                        </FormLabel>
                        <FormMessage />
                      </div>
                    </FormItem>
                  )}
                />
              </div>

              <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
                <FormField
                  control={form.control}
                  name={`fs.${fsIndex}.lt.${index}.liquidity_test`}
                  render={({ field }) => (
                    <FormItem className="flex items-start space-x-2 space-y-0">
                      <FormControl>
                        <Checkbox
                          disabled={isView}
                          checked={field.value}
                          onCheckedChange={(checked) => {
                            field.onChange(checked)
                          }}
                          className="data-[state=checked]:border-primary h-5 w-5 rounded-[4px] border-[#CDCDCD]"
                        />
                      </FormControl>
                      <div className="flex flex-col">
                        <FormLabel className="text-main text-sm font-medium">
                          0% Liquidity Test
                        </FormLabel>
                        <FormMessage />
                      </div>
                    </FormItem>
                  )}
                />
              </div>

              <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
                <FormField
                  control={form.control}
                  name={`fs.${fsIndex}.lt.${index}.discharge_fees`}
                  render={({ field }) => (
                    <FormItem className="flex items-start space-x-2 space-y-0">
                      <FormControl>
                        <Checkbox
                          disabled={isView}
                          checked={field.value}
                          onCheckedChange={(checked) => {
                            field.onChange(checked)
                          }}
                          className="data-[state=checked]:border-primary h-5 w-5 rounded-[4px] border-[#CDCDCD]"
                        />
                      </FormControl>
                      <div className="flex flex-col">
                        <FormLabel className="text-main text-sm font-medium">
                          Discharge Fees
                        </FormLabel>
                        <FormMessage />
                      </div>
                    </FormItem>
                  )}
                />
              </div>

              <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
                <FormField
                  control={form.control}
                  name={`fs.${fsIndex}.lt.${index}.loan_administration_fees`}
                  render={({ field }) => (
                    <FormItem className="flex items-start space-x-2 space-y-0">
                      <FormControl>
                        <Checkbox
                          disabled={isView}
                          checked={field.value}
                          onCheckedChange={(checked) => {
                            field.onChange(checked)
                          }}
                          className="data-[state=checked]:border-primary h-5 w-5 rounded-[4px] border-[#CDCDCD]"
                        />
                      </FormControl>
                      <div className="flex flex-col">
                        <FormLabel className="text-main text-sm font-medium">
                          Loan Administration Fees
                        </FormLabel>
                        <FormMessage />
                      </div>
                    </FormItem>
                  )}
                />
              </div>

              <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
                <FormField
                  control={form.control}
                  name={`fs.${fsIndex}.lt.${index}.monthly_account_keeping_fee`}
                  render={({ field }) => (
                    <FormItem className="flex items-start space-x-2 space-y-0">
                      <FormControl>
                        <Checkbox
                          disabled={isView}
                          checked={field.value}
                          onCheckedChange={(checked) => {
                            field.onChange(checked)
                          }}
                          className="data-[state=checked]:border-primary h-5 w-5 rounded-[4px] border-[#CDCDCD]"
                        />
                      </FormControl>
                      <div className="flex flex-col">
                        <FormLabel className="text-main text-sm font-medium">
                          Monthly Account Keeping Fee
                        </FormLabel>
                        <FormMessage />
                      </div>
                    </FormItem>
                  )}
                />
              </div>
            </>
          )}

          <CheckItems fsIndex={fsIndex} ltIndex={index} isView={isView} />
        </div>
      ))}
    </div>
  )
}
