import { toast } from "sonner"
import { create } from "zustand"

import { apiGetAdminDashboard } from "@/api/adminDashboardApi"

import { CLIENT_MSG } from "@repo/i18n"

interface IAdminDashboardState {
  loading: boolean
  reloadRequired: boolean

  getAction: (params: any, onSuccessCallback?: (data: any) => void) => void
}

const useAdminDashboardStore = create<IAdminDashboardState>()((set, get) => ({
  loading: false,
  reloadRequired: false,

  getAction: async (params, onSuccessCallback) => {
    try {
      set({ loading: true })

      const response = await apiGetAdminDashboard(params)

      if (response?.data) {
        const { data } = response.data

        if (typeof onSuccessCallback === "function") {
          onSuccessCallback(data)
        }
      }
    } catch (err: any) {
      console.error(err)

      if (err?.response?.data?.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error(CLIENT_MSG.SOMETHING_WENT_WRONG)
      }
    } finally {
      set({ loading: false })
    }
  }
}))

export default useAdminDashboardStore
