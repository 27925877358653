import React from "react"

import useSignupStore from "@/stores/useSignupStore"

import { Progress } from "@repo/ui/components/ui/progress"

export default function StageIndicator() {
  const store = useSignupStore()

  return (
    <div className="flex gap-2.5 p-2.5">
      <Progress className="h-2" value={store.stage >= 1 ? 100 : 0} />
      <Progress className="h-2" value={store.stage >= 2 ? 100 : 0} />
      <Progress className="h-2" value={store.stage >= 3 ? 100 : 0} />
      <Progress className="h-2" value={store.stage >= 4 ? 100 : 0} />
    </div>
  )
}
