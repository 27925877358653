import React from "react"

import { Page, StyleSheet, View } from "@react-pdf/renderer"

import ContentBorrowerSelection from "./sections/ContentBorrowerSelection"
import ContentCommissionSplit from "./sections/ContentCommissionSplit"
import ContentFundingSolution from "./sections/ContentFundingSolution"
import ContentLoanType from "./sections/ContentLoanType"
import ContentLoanDetails from "./sections/ContentLoanDetails"
import ContentSecurityDetails from "./sections/ContentSecurityDetails"
import ContentLenderSelection from "./sections/ContentLenderSelection"
import ContentSupportingDocumentation from "./sections/ContentSupportingDocumentation"

import Header from "./components/Header"
import Footer from "./components/Footer"
import ContentEntityAL from "./sections/ContentEntityAL"
import ContentIndividualAL from "./sections/ContentIndividualAL"

interface Props {
  data: Record<string, any>
}

export default function Content(props: Readonly<Props>) {
  const { data } = props

  const styles = StyleSheet.create({
    page: {
      width: "100%",
      height: "100%",
      fontFamily: "Poppins",
      display: "flex",
      flexDirection: "column"
    }
  })

  return (
    <Page wrap size="A4" style={styles.page}>
      <Header data={data} />

      <View
        style={{
          paddingHorizontal: 32,
          display: "flex",
          flexDirection: "column",
          gap: 16,
          flex: 1
        }}
      >
        <ContentBorrowerSelection data={data} />

        <ContentCommissionSplit data={data} />

        <ContentFundingSolution data={data} />

        <ContentLoanType data={data} />

        <ContentLoanDetails data={data} />

        <ContentSecurityDetails data={data} />

        <ContentLenderSelection data={data} />

        <ContentEntityAL data={data} />

        <ContentIndividualAL data={data} />

        <ContentSupportingDocumentation data={data} />
      </View>

      <Footer />
    </Page>
  )
}
