import React from "react"

interface Props {
  className?: string
}

export default function IconUserIntroducer({ className }: Readonly<Props>) {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5 3.5C10.8431 3.5 9.5 4.84315 9.5 6.5C9.5 8.15685 10.8431 9.5 12.5 9.5C14.1569 9.5 15.5 8.15685 15.5 6.5C15.5 4.84315 14.1569 3.5 12.5 3.5ZM7.5 6.5C7.5 3.73858 9.73858 1.5 12.5 1.5C15.2614 1.5 17.5 3.73858 17.5 6.5C17.5 9.26142 15.2614 11.5 12.5 11.5C9.73858 11.5 7.5 9.26142 7.5 6.5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.5 22.5C2.5 16.9772 6.97722 12.5 12.5 12.5C18.0228 12.5 22.5 16.9772 22.5 22.5C22.5 23.0523 22.0523 23.5 21.5 23.5C20.9477 23.5 20.5 23.0523 20.5 22.5C20.5 18.0818 16.9182 14.5 12.5 14.5C8.08178 14.5 4.5 18.0818 4.5 22.5C4.5 23.0523 4.05228 23.5 3.5 23.5C2.94772 23.5 2.5 23.0523 2.5 22.5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5 12.5C12.939 12.5 13.3267 12.7863 13.4558 13.2059L15.4558 19.7059C15.5531 20.0222 15.4876 20.3663 15.2809 20.6247L13.2809 23.1247C13.0911 23.3619 12.8038 23.5 12.5 23.5C12.1962 23.5 11.9089 23.3619 11.7191 23.1247L9.71914 20.6247C9.51239 20.3663 9.4469 20.0222 9.54423 19.7059L11.5442 13.2059C11.6733 12.7863 12.061 12.5 12.5 12.5ZM11.6114 19.7884L12.5 20.8992L13.3886 19.7884L12.5 16.9004L11.6114 19.7884Z"
        fill="currentColor"
      />
    </svg>
  )
}
