import React from "react"

import { Text, View } from "@react-pdf/renderer"

interface Props {
  data: Record<string, any>
}

export default function ContentBorrowerSelection(props: Readonly<Props>) {
  const { data } = props

  return (
    <View
      style={{
        display: "flex",
        flexDirection: "column"
      }}
      wrap={true}
    >
      <Text
        style={{
          color: "#0E052B",
          fontWeight: 600,
          fontSize: 14,
          borderBottom: "1px solid #CDCDCD"
        }}
      >
        1. Borrower Selection
      </Text>

      <View
        style={{
          paddingLeft: 32,
          paddingVertical: 16,
          display: "flex",
          flexDirection: "column",
          gap: 8
        }}
      >
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 16
          }}
        >
          <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 14 }}>
            {data?.borrower?.entity_name}
          </Text>
        </View>

        <View
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 16
          }}
        >
          <Text style={{ color: "#868194", fontWeight: 400, fontSize: 12, flex: 1 }}>ABN</Text>
          <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 12, flex: 1 }}>
            {data?.borrower?.abn}
          </Text>
        </View>

        <View
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 16
          }}
        >
          <Text style={{ color: "#868194", fontWeight: 400, fontSize: 12, flex: 1 }}>
            Email address
          </Text>
          <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 12, flex: 1 }}>
            {data?.borrower?.entity_email}
          </Text>
        </View>

        <View
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 16
          }}
        >
          <Text style={{ color: "#868194", fontWeight: 400, fontSize: 12, flex: 1 }}>
            Mobile number
          </Text>
          <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 12, flex: 1 }}>
            {data?.borrower?.entity_contact_number}
          </Text>
        </View>
      </View>
    </View>
  )
}
