import React, { useEffect } from "react"

import { zodResolver } from "@hookform/resolvers/zod"
import { motion } from "framer-motion"
import json2mq from "json2mq"
import { useForm } from "react-hook-form"
import { useMediaQuery } from "react-responsive"
import { useNavigate } from "react-router-dom"
import * as z from "zod"

import AuthLayout from "@/components/_layout/AuthLayout"
import Logo from "@/components/_layout/components/Logo"
import { LoadingButton } from "@/components/_uiext"
import useAuthStore from "@/stores/useAuthStore"
import { ANIMATION_DURATION } from "@/utils/constants"

import { Form, FormControl, FormField, FormItem, FormMessage } from "@repo/ui/components/ui/form"
import { InputOTP, InputOTPGroup, InputOTPSlot } from "@repo/ui/components/ui/input-otp"

const FormSchema = z.object({
  token: z.string().min(6, {
    message: "Your one-time password must be 6 characters."
  })
})

type FormSchemaType = z.infer<typeof FormSchema>

export default function Verify() {
  const navigate = useNavigate()
  const store = useAuthStore()

  const isMobile = useMediaQuery({ query: json2mq({ maxWidth: 767 }) })

  const variants = {
    initial: {
      opacity: 0,
      y: 20
    },
    animate: (i: number) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: i * ANIMATION_DURATION,
        duration: ANIMATION_DURATION
      }
    })
  }

  const form = useForm<FormSchemaType>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      token: ""
    }
  })

  const handleCompletePin = () => {
    form.handleSubmit(onSubmit)()
  }

  const onSubmit = (values: FormSchemaType) => {
    store.verifyTotpAction(
      values.token,
      () => {
        navigate("/dashboard")
      },
      () => {
        form.resetField("token")
      }
    )
  }

  useEffect(() => {
    if (store.user?.uuid) {
      if (store.mfaVerified) {
        navigate("/dashboard")
      }
    } else {
      navigate("/login")
    }
  }, [])

  return (
    <AuthLayout>
      <div className="flex h-full w-full flex-col gap-8">
        <motion.div
          custom={0}
          initial="initial"
          animate="animate"
          variants={variants}
          className="flex flex-wrap items-center justify-center gap-4 md:justify-between"
        >
          <Logo />
        </motion.div>

        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="flex flex-1 flex-col justify-center"
          >
            <motion.h3
              custom={1}
              initial="initial"
              animate="animate"
              variants={variants}
              className="text-main mb-2.5 text-lg font-semibold"
            >
              Verify it's you with your authenticator app
            </motion.h3>

            <motion.p
              custom={2}
              initial="initial"
              animate="animate"
              variants={variants}
              className="mb-8 text-sm font-normal"
            >
              To continue, enter the 6-digit code generated by your authenticator app.
            </motion.p>

            <motion.div
              custom={3}
              initial="initial"
              animate="animate"
              variants={variants}
              className="mb-6 w-full"
            >
              <FormField
                control={form.control}
                name="token"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <InputOTP
                        autoFocus={!isMobile}
                        maxLength={6}
                        onComplete={handleCompletePin}
                        {...field}
                      >
                        <InputOTPGroup className="flex w-full justify-between gap-2 md:gap-4">
                          <InputOTPSlot className="h-[75px] w-full !rounded-xl border" index={0} />
                          <InputOTPSlot className="h-[75px] w-full rounded-xl border" index={1} />
                          <InputOTPSlot className="h-[75px] w-full rounded-xl border" index={2} />
                          <InputOTPSlot className="h-[75px] w-full rounded-xl border" index={3} />
                          <InputOTPSlot className="h-[75px] w-full rounded-xl border" index={4} />
                          <InputOTPSlot className="h-[75px] w-full !rounded-xl border" index={5} />
                        </InputOTPGroup>
                      </InputOTP>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </motion.div>

            <motion.div
              custom={4}
              initial="initial"
              animate="animate"
              variants={variants}
              className="w-full"
            >
              <LoadingButton type="submit" loading={store.loading}>
                Verify
              </LoadingButton>
            </motion.div>
          </form>
        </Form>
      </div>
    </AuthLayout>
  )
}
