import { toast } from "sonner"
import { create } from "zustand"

import businessUsersApi from "@/api/businessUsersApi"

import { CLIENT_MSG } from "@repo/i18n"

interface IBusinessUserState {
  loading: boolean
  reloadRequired: boolean

  getListAction: (params: any, onSuccessCallback?: (data: any, totalCount: number) => void) => void
  getOneByUuidAction: (userUuid: string, onSuccessCallback?: (data: any) => void) => void
  createAction: (params: any, onSuccessCallback?: () => void) => void
  updateAction: (userUuid: string, params: any, onSuccessCallback?: () => void) => void
  archiveAction: (userUuid: string) => void
}

const useBusinessUserStore = create<IBusinessUserState>()((set, get) => ({
  loading: false,
  reloadRequired: false,

  getListAction: async (params, onSuccessCallback) => {
    try {
      const response = await businessUsersApi.getBusinessUsersList(params)

      if (response?.data) {
        const { data } = response.data

        if (typeof onSuccessCallback === "function") {
          onSuccessCallback(data.data, data.totalCount)
        }
      }
    } catch (err: any) {
      console.error(err)

      if (err?.response?.data?.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error(CLIENT_MSG.SOMETHING_WENT_WRONG)
      }
    }
  },

  getOneByUuidAction: async (userUuid, onSuccessCallback) => {
    try {
      set({ loading: true })

      const response = await businessUsersApi.getBusinessUserByUuid(userUuid)

      if (response?.data) {
        const { data } = response.data

        if (typeof onSuccessCallback === "function") {
          onSuccessCallback(data)
        }
      }
    } catch (err: any) {
      console.error(err)

      if (err?.response?.data?.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error(CLIENT_MSG.SOMETHING_WENT_WRONG)
      }
    } finally {
      set({ loading: false })
    }
  },

  createAction: async (params, onSuccessCallback) => {
    try {
      set({ loading: true })

      const response = await businessUsersApi.createBusinessUser(params)

      if (response?.data) {
        const { message } = response.data

        toast.success(message)

        if (typeof onSuccessCallback === "function") {
          onSuccessCallback()
        }
      }
    } catch (err: any) {
      console.error(err)

      if (err?.response?.data?.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error(CLIENT_MSG.SOMETHING_WENT_WRONG)
      }
    } finally {
      set({ loading: false })
    }
  },

  updateAction: async (userUuid, params, onSuccessCallback) => {
    try {
      set({ loading: true })

      const response = await businessUsersApi.updateBusinessUser(userUuid, params)

      if (response?.data) {
        const { message } = response.data

        toast.success(message)

        if (typeof onSuccessCallback === "function") {
          onSuccessCallback()
        }
      }
    } catch (err: any) {
      console.error(err)

      if (err?.response?.data?.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error(CLIENT_MSG.SOMETHING_WENT_WRONG)
      }
    } finally {
      set({ loading: false })
    }
  },

  archiveAction: async (userUuid: string) => {
    try {
      const response = await businessUsersApi.archiveBusinessUser(userUuid)

      if (response?.data) {
        const { message } = response.data
        set({ reloadRequired: !get().reloadRequired })
        toast.success(message)
      }
    } catch (err: any) {
      console.error(err)

      if (err?.response?.data?.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error(CLIENT_MSG.SOMETHING_WENT_WRONG)
      }
    }
  }
}))

export default useBusinessUserStore
