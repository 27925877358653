import React, { useEffect, useRef, useState } from "react"

import { useNavigate, useParams } from "react-router-dom"

import useAcceptInvitationStore from "@/stores/useAcceptInvitationStore"
import AuthLayout from "@/components/_layout/AuthLayout"
import Step01 from "./Step01"
import Step02 from "./Step02"

export default function AcceptInvitation() {
  const initialized = useRef(false)
  const [verified, setVerified] = useState(false)

  const store = useAcceptInvitationStore()
  const navigate = useNavigate()
  let { token } = useParams()

  const verifyInvitation = () => {
    store.verifyInvitationAction(
      { token: token ?? "" },
      () => {
        setVerified(true)
      },
      () => {
        navigate("/login")
      }
    )
  }

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true
      verifyInvitation()
    }
  }, [token])

  if (!verified) return null

  return (
    <AuthLayout>
      {store.stage === 1 && <Step01 token={token ?? ""} />}
      {store.stage === 2 && <Step02 />}
    </AuthLayout>
  )
}
