import React, { Dispatch, SetStateAction, useEffect, useState } from "react"

import { LoadingButton } from "@/components/_uiext"
import useAdminReferralStore from "@/stores/useAdminReferralStore"

import { Dialog, DialogContent } from "@repo/ui/components/ui/dialog"
import { Button } from "@repo/ui/components/ui/button"

import ReferrerAutoComplete from "./ReferrerAutoComplete"

interface Props {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  data: any
}

export default function ReferrerDialog(props: Readonly<Props>) {
  const { open, setOpen, data } = props
  const store = useAdminReferralStore()

  const [referrers, setReferrers] = useState<any[]>([])
  const [loading, setLoading] = useState(false)

  const handleCancelClick = () => {
    setOpen(false)
  }

  const handleSaveClick = () => {
    setLoading(true)
    store.assignIntroducersAction(
      data.uuid,
      {
        introducers: referrers.map((r: any) => r.uuid)
      },
      () => {
        store.invertReloadRequired()
        setLoading(false)
        setOpen(false)
      }
    )
  }

  useEffect(() => {
    setReferrers(data?.referrals?.map((r: any) => r.introducer))
  }, [open, data])

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent
        className="flex h-full flex-col justify-between gap-0 bg-white p-10 md:h-auto"
        onOpenAutoFocus={(e: any) => e.preventDefault()}
      >
        <div className="mb-4 flex flex-col">
          <h1 className="text-main mb-2.5 text-lg font-semibold">Add Referrers</h1>

          <p className="text-default mb-4 text-sm font-normal">
            Please select introducers whom you intend to lead to application{" "}
            <span className="text-main">
              #
              {Number(data?.id ?? "")
                .toString()
                .padStart(10, "0")}
            </span>
            {""}.
          </p>

          <ReferrerAutoComplete
            disabled={false}
            value={referrers}
            onValueChange={(value: any) => setReferrers(value)}
          />
        </div>

        <div className="flex justify-center gap-2.5">
          <Button
            type="button"
            className="text-default h-auto border border-[#868194] bg-transparent px-6 py-4 font-semibold hover:bg-transparent"
            onClick={handleCancelClick}
          >
            Cancel
          </Button>

          <LoadingButton
            loading={loading}
            className="w-full px-6 py-4 md:w-auto"
            onClick={handleSaveClick}
          >
            Save
          </LoadingButton>
        </div>
      </DialogContent>
    </Dialog>
  )
}
