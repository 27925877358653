import React from "react"

import { useFieldArray, useFormContext } from "react-hook-form"

import { Checkbox } from "@repo/ui/components/ui/checkbox"
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from "@repo/ui/components/ui/form"
import {
  CASHFLOW_TURNOVER_RANGES,
  LOAN_DOCUMENT_TYPES,
  LOAN_TERMS,
  LOAN_TYPES
} from "@repo/util/constant"

import LoanTypes from "./LoanTypes"

interface Props {
  isView?: boolean
}

export default function FundingSolutions(props: Readonly<Props>) {
  const { isView = false } = props
  const form = useFormContext()

  const { fields } = useFieldArray({
    control: form.control,
    name: "fs"
  })

  return (
    <div className="flex flex-col gap-4">
      {fields.map((item: any, index) => (
        <div key={item.id} className="border-primary rounded-xl border p-4">
          <h4 className="text-main mb-4 text-base font-semibold">
            Funding Solution: <span className="text-primary">{item.funding_solution}</span>
          </h4>

          <div className="mb-8 w-full">
            <FormField
              control={form.control}
              name={`fs.${index}.loan_types`}
              render={({ field }) => (
                <div className="flex flex-col gap-2">
                  <FormLabel className="text-main text-base font-medium">Loan Types</FormLabel>
                  <div className="grid grid-cols-1 gap-2 md:grid-cols-2 2xl:grid-cols-3">
                    {Object.values(LOAN_TYPES)
                      .filter((lt) => lt.funding_solutions.includes(item.funding_solution))
                      .sort((a, b) => a.key.localeCompare(b.key))
                      .map((lt) => (
                        <FormItem key={lt.key} className="flex items-center space-x-2 space-y-0">
                          <FormControl>
                            <Checkbox
                              disabled={isView}
                              checked={field.value?.includes(lt.key)}
                              onCheckedChange={(checked) => {
                                const updatedValue = field.value || []
                                if (checked) {
                                  field.onChange([...updatedValue, lt.key])
                                  form.setValue(`fs.${index}.lt`, [
                                    ...form.getValues()["fs"][index]["lt"],
                                    {
                                      loan_type: lt.key,
                                      applicant_types: [],
                                      loan_document_types: [LOAN_DOCUMENT_TYPES.PrivateCredit.key],

                                      minimum_loan_size: 0,
                                      maximum_loan_size: 0,
                                      is_higher_loan_amount: false,

                                      minimum_loan_term: LOAN_TERMS.M1.key.toString(),
                                      maximum_loan_term: LOAN_TERMS.M12.key.toString(),
                                      loan_value_ratio: 0,

                                      // Construction finance
                                      nsd: [],
                                      sd: [],

                                      // Development finance | Property finance | SME finance | SMSF finance
                                      securities: [],

                                      // Cashflow finance
                                      turnover_ranges: CASHFLOW_TURNOVER_RANGES.map((ctr) => ({
                                        ...ctr,
                                        checked: false
                                      })),

                                      // Agricultural business finance
                                      commodity_types: [],

                                      // Cashflow finance | Corporate finance
                                      industry_sectors: [],

                                      // Development finance
                                      loan_purposes: [],

                                      // Cashflow finance
                                      // max_invoice_financed: 0,

                                      countries: [],
                                      states: [],
                                      regions: [],

                                      exit_strategies: [],
                                      repayments: [],

                                      establishment_fee: 0,
                                      interest_rate: 0,

                                      mortgage_management_fee: false,
                                      line_fee: false,
                                      pre_sales_requirement: false,

                                      // SMSF finance
                                      net_assets_test: false,
                                      liquidity_test: false,
                                      discharge_fees: false,
                                      loan_administration_fees: false,
                                      monthly_account_keeping_fee: false,

                                      checkitems: []
                                    }
                                  ])
                                } else {
                                  field.onChange(
                                    updatedValue.filter((item: string) => item !== lt.key)
                                  )
                                  form.setValue(
                                    `fs.${index}.lt`,
                                    form
                                      .getValues()
                                      [
                                        "fs"
                                      ][index]["lt"].filter((item: any) => item.loan_type !== lt.key)
                                  )
                                }
                              }}
                              className="data-[state=checked]:border-primary h-5 w-5 rounded-[4px] border-[#CDCDCD]"
                            />
                          </FormControl>
                          <FormLabel className="text-main font-normal">{lt.key}</FormLabel>
                        </FormItem>
                      ))}
                  </div>
                  <FormMessage />
                </div>
              )}
            />
          </div>

          <LoanTypes fsIndex={index} isView={isView} />
        </div>
      ))}
    </div>
  )
}
