import React from "react"

import clsx from "clsx"

import { FileUpload } from "@/components/_uiext"
import { useAdminAppContext } from "@/pages/admin/applications/AdminAppContext"

import { FormControl, FormItem } from "@repo/ui/components/ui/form"
import { Button } from "@repo/ui/components/ui/button"
import { Input } from "@repo/ui/components/ui/input"
import { CHECK_ITEM_TYPES, YN } from "@repo/util/constant"

export default function SupportingDocumentsSummary() {
  const { data } = useAdminAppContext()

  return (
    <div className="flex w-full flex-col gap-4 rounded-2xl border p-4 md:p-6">
      {data?.checkitems?.map((ci: any) => (
        <div key={ci.section}>
          <h3 className="text-main mb-4 text-lg font-semibold">{ci.section}</h3>

          {ci.items.map((item: any) => (
            <div key={item.text} className="mb-4 flex w-full flex-col">
              <div className="flex flex-col gap-2 md:flex-row md:items-center md:justify-between">
                <div className="text-main text-sm font-normal">{item.text}</div>

                <FormItem>
                  <FormControl>
                    <>
                      {(item.type === CHECK_ITEM_TYPES.Input ||
                        item.type === CHECK_ITEM_TYPES.Upload) && (
                        <div className="flex gap-2.5">
                          {[YN.Yes, YN.No].map((option) => (
                            <Button
                              key={option}
                              disabled
                              type="button"
                              size="sm"
                              className={clsx(
                                "h-auto border px-6 py-4 font-semibold capitalize",
                                item.choice === option
                                  ? "border-primary"
                                  : "text-default hover:bg-primary hover:border-primary border-[#868194] bg-transparent hover:text-white"
                              )}
                            >
                              {option}
                            </Button>
                          ))}
                        </div>
                      )}
                    </>
                  </FormControl>
                </FormItem>
              </div>

              <div className="flex w-full md:justify-end">
                <FormItem className="flex w-full flex-col md:w-auto">
                  <FormControl>
                    <>
                      {item.type === CHECK_ITEM_TYPES.YesNo && (
                        <div className="flex gap-2.5">
                          {[YN.Yes, YN.No].map((option) => (
                            <Button
                              key={option}
                              type="button"
                              size="sm"
                              className={clsx(
                                "h-auto border px-6 py-4 font-semibold capitalize",
                                item.result === option
                                  ? "border-primary"
                                  : "text-default hover:bg-primary hover:border-primary border-[#868194] bg-transparent hover:text-white"
                              )}
                            >
                              {option}
                            </Button>
                          ))}
                        </div>
                      )}

                      {item.choice === YN.Yes && item.type === CHECK_ITEM_TYPES.Input && (
                        <Input
                          disabled
                          className="w-full md:min-w-40 md:max-w-40"
                          autoComplete="off"
                          value={item.result}
                        />
                      )}

                      {item.choice === YN.Yes && item.type === CHECK_ITEM_TYPES.Upload && (
                        <div className="w-full pt-8 md:min-w-40 md:max-w-40">
                          <FileUpload readOnly value={item.result} />
                        </div>
                      )}
                    </>
                  </FormControl>
                </FormItem>
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  )
}
