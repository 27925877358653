import React, { useEffect, useState } from "react"

import { zodResolver } from "@hookform/resolvers/zod"
import { format } from "date-fns"
import { useForm } from "react-hook-form"
import { InView } from "react-intersection-observer"
import { useNavigate } from "react-router-dom"
import * as z from "zod"

import { ScrollToTop, StickyShortcut } from "@/components/_uiext"

import { Avatar, AvatarFallback, AvatarImage } from "@repo/ui/components/ui/avatar"
import { Button } from "@repo/ui/components/ui/button"
import { Form } from "@repo/ui/components/ui/form"
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from "@repo/ui/components/ui/tooltip"
import { APPLICATION_STEPS, FUNDING_SOLUTIONS } from "@repo/util/constant"

import BorrowerSummary from "../../_components/summary/BorrowerSummary"
import CommissionSplitSummary from "../../_components/summary/CommissionSplitSummary"
import FundingSolutionSummary from "../../_components/summary/FundingSolutionSummary"
import LoanTypeSummary from "../../_components/summary/LoanTypeSummary"
import LoanDetailsSummary from "../../_components/summary/LoanDetailsSummary"
import SecurityDetailsSummary from "../../_components/summary/SecurityDetailsSummary"
import LenderSelectionSummary from "../../_components/summary/LenderSelectionSummary"
import EntityALSummary from "../../_components/summary/EntityALSummary"
import IndividualALSummary from "../../_components/summary/IndividualALSummary"
import SupportingDocumentsSummary from "../../_components/summary/SupportingDocumentsSummary"

import { useAppContext } from "../../AppContext"

import IndicativeDialog from "../components/IndicativeDialog"
import FormalDialog from "../components/FormalDialog"

const FormSchema = z.object({})

type FormSchemaType = z.infer<typeof FormSchema>

export default function ReviewApplication() {
  const navigate = useNavigate()
  const { steps, setSteps, data } = useAppContext()

  const [openIndicative, setOpenIndicative] = useState(false)
  const [openFormal, setOpenFormal] = useState(false)

  const [inviews, setInviews] = useState<boolean[]>(Array(steps.length - 1).fill(false))
  const [summaryItems, setSummaryItems] = useState([
    {
      step: APPLICATION_STEPS.BorrowerSelection,
      threshold: 0.1,
      component: <BorrowerSummary />
    },
    {
      step: APPLICATION_STEPS.CommissionSplit,
      threshold: 0.1,
      component: <CommissionSplitSummary />
    },
    {
      step: APPLICATION_STEPS.FundingSolution,
      threshold: 0.1,
      component: <FundingSolutionSummary />
    },
    {
      step: APPLICATION_STEPS.LoanType,
      threshold: 0.1,
      component: <LoanTypeSummary />
    },
    {
      step: APPLICATION_STEPS.LoanDetails,
      threshold: 0.1,
      component: <LoanDetailsSummary />
    },
    {
      step: APPLICATION_STEPS.SecurityDetails,
      threshold: 0.1,
      component: <SecurityDetailsSummary />
    },
    {
      step: APPLICATION_STEPS.LenderSelection,
      threshold: 0.1,
      component: <LenderSelectionSummary />
    },
    {
      step: APPLICATION_STEPS.EntityAL,
      threshold: 0.1,
      component: <EntityALSummary />
    },
    {
      step: APPLICATION_STEPS.IndividualAL,
      threshold: 0.1,
      component: <IndividualALSummary />
    },
    {
      step: APPLICATION_STEPS.SupportingDocuments,
      threshold: 0.01,
      component: <SupportingDocumentsSummary />
    }
  ])

  const form = useForm<FormSchemaType>({
    resolver: zodResolver(FormSchema),
    defaultValues: {}
  })

  const initialize = () => {
    if (
      data?.funding_solution === FUNDING_SOLUTIONS.CashflowFinance.key ||
      data?.funding_solution === FUNDING_SOLUTIONS.CorporateFinance.key
    ) {
      setSummaryItems((prev) => prev.filter((s) => s.step !== APPLICATION_STEPS.SecurityDetails))
      setSteps((prev) => prev.filter((s) => s !== APPLICATION_STEPS.SecurityDetails))
    }
  }

  const handleBackClick = () => {
    navigate("/applications/list")
  }

  const handleIndicativeClick = () => {
    setOpenIndicative(true)
  }

  const handleFormalClick = () => {
    setOpenFormal(true)
  }

  const onSubmit = () => {}

  useEffect(() => {
    initialize()
  }, [data])

  return (
    <Form {...form}>
      <form className="flex flex-col" onSubmit={form.handleSubmit(onSubmit)}>
        <div className="flex w-full rounded-xl border border-[#ECECEC] bg-white p-8">
          <div className="flex w-full flex-1 flex-col pr-0 xl:pr-8">
            <div className="mb-8 flex flex-col gap-4 md:flex-row xl:hidden">
              <div className="bg-background flex flex-1 flex-col rounded-xl border p-6">
                <div className="text-main mb-4 text-sm font-semibold">Created On</div>

                <div className="text-main mb-4 text-sm font-normal">
                  Date:{" "}
                  <span className="text-default text-sm font-normal">
                    {data?.created_at && format(data?.created_at, "dd/MM/yyyy")}
                  </span>
                </div>

                <div className="text-main mb-4 text-sm font-normal">
                  Time:{" "}
                  <span className="text-default text-sm font-normal">
                    {data?.created_at && format(data?.created_at, "h:mm aaa")}
                  </span>
                </div>

                <div className="flex items-center gap-2">
                  <div className="text-main text-sm font-normal">Collaborators:</div>

                  <TooltipProvider disableHoverableContent delayDuration={0} skipDelayDuration={0}>
                    <div className="flex items-center">
                      <Tooltip>
                        <TooltipTrigger>
                          <Avatar className="hover:border-primary h-10 w-10 border-2 border-white hover:z-50">
                            <AvatarImage
                              src={data?.creator?.image}
                              alt={`${data?.creator?.first_name} ${data?.creator?.last_name}`}
                            />
                            <AvatarFallback className="uppercase">
                              {data?.creator?.first_name[0]}
                              {data?.creator?.last_name[0]}
                            </AvatarFallback>
                          </Avatar>
                        </TooltipTrigger>

                        <TooltipContent className="grid grid-cols-3 gap-2 p-4">
                          <p className="text-default text-sm font-normal">Full Name:</p>
                          <p className="text-main col-span-2 text-sm font-semibold">
                            {data?.creator?.first_name} {data?.creator?.last_name}
                          </p>
                          <p className="text-default text-sm font-normal">Entity Name:</p>
                          <p className="text-main col-span-2 text-sm font-semibold">
                            {data?.creator?.entity?.entity_name}
                          </p>
                        </TooltipContent>
                      </Tooltip>

                      {data?.assignees?.map((a: any) => (
                        <Tooltip key={a?.user?.id}>
                          <TooltipTrigger>
                            <Avatar className="hover:border-primary ml-[-12px] h-10 w-10 border-2 border-white hover:z-50">
                              <AvatarImage
                                src={a?.user?.image}
                                alt={`${a?.user?.first_name} ${a?.user?.last_name}`}
                              />
                              <AvatarFallback className="uppercase">
                                {a?.user?.first_name[0]}
                                {a?.user?.last_name[0]}
                              </AvatarFallback>
                            </Avatar>
                          </TooltipTrigger>

                          <TooltipContent className="grid grid-cols-3 gap-2 p-4">
                            <p className="text-default text-sm font-normal">Full Name:</p>
                            <p className="text-main col-span-2 text-sm font-semibold">
                              {a?.user?.first_name} {a?.user?.last_name}
                            </p>
                            <p className="text-default text-sm font-normal">Entity Name:</p>
                            <p className="text-main col-span-2 text-sm font-semibold">
                              {a?.user?.entity?.entity_name}
                            </p>
                          </TooltipContent>
                        </Tooltip>
                      ))}
                    </div>
                  </TooltipProvider>
                </div>
              </div>

              <div className="bg-background flex flex-1 flex-col gap-4 rounded-xl border p-6">
                <p className="text-main text-sm font-semibold">Funding Approvals</p>

                <Button
                  disabled={!data?.indicative_approval || !data?.indicative_approval_signed}
                  type="button"
                  className="text-default h-auto border border-[#868194] bg-transparent px-6 py-3 font-semibold hover:bg-transparent"
                  onClick={handleIndicativeClick}
                >
                  Indicative Approval
                </Button>

                <Button
                  disabled={!data?.formal_approval || !data?.formal_approval_signed}
                  type="button"
                  className="text-default h-auto border border-[#868194] bg-transparent px-6 py-3 font-semibold hover:bg-transparent"
                  onClick={handleFormalClick}
                >
                  Formal Approval
                </Button>
              </div>
            </div>

            {summaryItems.map((item: any, index: number) => (
              <InView
                key={item.step}
                className="mb-8"
                threshold={item.threshold}
                onChange={(inView, entry) => {
                  setInviews((prev) => {
                    const updatedValues = JSON.parse(JSON.stringify(prev))
                    updatedValues[index] = inView
                    return updatedValues
                  })
                }}
                id={steps[index]}
              >
                <div className="mb-6 flex flex-col gap-2.5 md:flex-row md:items-center md:justify-between">
                  <h3 className="text-main text-lg font-semibold">{steps[index]}</h3>
                </div>

                {item.component}
              </InView>
            ))}
          </div>

          <StickyShortcut
            // items={steps.slice(0, -1)?.map((s: string, index: number) => ({
            //   id: s,
            //   label: s,
            //   inView: inviews[index]
            // }))}
            hasWidget
            widgetBefore={
              <div className="bg-background mb-4 flex flex-col rounded-xl border p-6">
                <div className="text-main mb-4 text-sm font-semibold">Created On</div>

                <div className="text-main mb-4 text-sm font-normal">
                  Date:{" "}
                  <span className="text-default text-sm font-normal">
                    {data?.created_at && format(data?.created_at, "dd/MM/yyyy")}
                  </span>
                </div>

                <div className="text-main mb-4 text-sm font-normal">
                  Time:{" "}
                  <span className="text-default text-sm font-normal">
                    {data?.created_at && format(data?.created_at, "h:mm aaa")}
                  </span>
                </div>

                <div className="flex items-center gap-2">
                  <div className="text-main text-sm font-normal">Collaborators:</div>

                  <TooltipProvider disableHoverableContent delayDuration={0} skipDelayDuration={0}>
                    <div className="flex items-center">
                      <Tooltip>
                        <TooltipTrigger>
                          <Avatar className="hover:border-primary h-10 w-10 border-2 border-white hover:z-50">
                            <AvatarImage
                              src={data?.creator?.image}
                              alt={`${data?.creator?.first_name} ${data?.creator?.last_name}`}
                            />
                            <AvatarFallback className="uppercase">
                              {data?.creator?.first_name[0]}
                              {data?.creator?.last_name[0]}
                            </AvatarFallback>
                          </Avatar>
                        </TooltipTrigger>

                        <TooltipContent className="grid grid-cols-3 gap-2 p-4">
                          <p className="text-default text-sm font-normal">Full Name:</p>
                          <p className="text-main col-span-2 text-sm font-semibold">
                            {data?.creator?.first_name} {data?.creator?.last_name}
                          </p>
                          <p className="text-default text-sm font-normal">Entity Name:</p>
                          <p className="text-main col-span-2 text-sm font-semibold">
                            {data?.creator?.entity?.entity_name}
                          </p>
                        </TooltipContent>
                      </Tooltip>

                      {data?.assignees?.map((a: any) => (
                        <Tooltip key={a?.user?.id}>
                          <TooltipTrigger>
                            <Avatar className="hover:border-primary ml-[-12px] h-10 w-10 border-2 border-white hover:z-50">
                              <AvatarImage
                                src={a?.user?.image}
                                alt={`${a?.user?.first_name} ${a?.user?.last_name}`}
                              />
                              <AvatarFallback className="uppercase">
                                {a?.user?.first_name[0]}
                                {a?.user?.last_name[0]}
                              </AvatarFallback>
                            </Avatar>
                          </TooltipTrigger>

                          <TooltipContent className="grid grid-cols-3 gap-2 p-4">
                            <p className="text-default text-sm font-normal">Full Name:</p>
                            <p className="text-main col-span-2 text-sm font-semibold">
                              {a?.user?.first_name} {a?.user?.last_name}
                            </p>
                            <p className="text-default text-sm font-normal">Entity Name:</p>
                            <p className="text-main col-span-2 text-sm font-semibold">
                              {a?.user?.entity?.entity_name}
                            </p>
                          </TooltipContent>
                        </Tooltip>
                      ))}
                    </div>
                  </TooltipProvider>
                </div>
              </div>
            }
            widgetAfter={
              <div className="bg-background mt-4 flex flex-col rounded-xl border p-6">
                <div className="text-main mb-4 text-sm font-semibold">Funding Approvals</div>

                <Button
                  disabled={!data?.indicative_approval}
                  type="button"
                  className="text-default mb-4 h-auto border border-[#868194] bg-transparent px-6 py-3 font-semibold hover:bg-transparent"
                  onClick={handleIndicativeClick}
                >
                  Indicative Approval
                </Button>

                <Button
                  disabled={!data?.formal_approval}
                  type="button"
                  className="text-default h-auto border border-[#868194] bg-transparent px-6 py-3 font-semibold hover:bg-transparent"
                  onClick={handleFormalClick}
                >
                  Formal Approval
                </Button>
              </div>
            }
          />
        </div>

        <div className="flex w-full justify-end gap-2 pb-2 pt-6">
          <Button
            type="button"
            className="text-default h-auto border border-[#868194] bg-transparent px-6 py-4 font-semibold hover:bg-transparent"
            onClick={handleBackClick}
          >
            Back
          </Button>
        </div>

        <ScrollToTop />
        <IndicativeDialog open={openIndicative} setOpen={setOpenIndicative} />

        <FormalDialog open={openFormal} setOpen={setOpenFormal} />
      </form>
    </Form>
  )
}
