import React from "react"

import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from "recharts"

interface Props {
  data: any
}

export default function FundingSolutions(props: Readonly<Props>) {
  const { data } = props

  return (
    <div className="flex w-full flex-col rounded-2xl border border-[#ECECEC] bg-white">
      <div className="border-b border-b-[#ECECEC] px-4 py-5">
        <h3 className="text-main text-lg font-semibold">Funding Solutions</h3>
      </div>

      <div className="flex h-full flex-col p-5">
        <div className="flex-1">
          <ResponsiveContainer>
            <BarChart width={600} height={300} data={data} margin={{ bottom: 20 }}>
              <XAxis
                dataKey="key"
                tickSize={0}
                includeHidden={false}
                tick={{
                  fontSize: 8,
                  width: 60,
                  dy: 20
                }}
                angle={30}
              />
              <YAxis
                axisLine={false}
                tickLine={false}
                allowDecimals={false}
                tick={{ fontSize: 10 }}
                width={40}
              />
              <Bar dataKey="count" fill="#08ECB3" />
              <Tooltip />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  )
}
