import React, { useState } from "react"

import clsx from "clsx"
import { Oval } from "react-loader-spinner"

interface Props {
  className?: string
  loading?: boolean

  defaultIcon: React.ReactNode
  hoverIcon: React.ReactNode

  loadingColor?: string
  loadingSecondaryColor?: string

  onClick?: React.MouseEventHandler
}

export default function HoverIconButton(props: Readonly<Props>) {
  const {
    className,
    loading = false,
    defaultIcon,
    hoverIcon,
    onClick,
    loadingColor = "#FFF",
    loadingSecondaryColor = "#EEE"
  } = props

  const [isHover, setIsHover] = useState(false)

  return (
    <div
      className={clsx("h-auto w-auto cursor-pointer", className)}
      onClick={onClick}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      {loading && (
        <Oval
          visible={true}
          height={20}
          width={20}
          strokeWidth={8}
          color={loadingColor}
          secondaryColor={loadingSecondaryColor}
        />
      )}

      {!loading && (
        <div className={clsx(isHover ? "animate-bounce" : "")}>
          {!isHover ? defaultIcon : hoverIcon}
        </div>
      )}
    </div>
  )
}
