import React, { Dispatch, SetStateAction, useState } from "react"

import clsx from "clsx"

import { IconFilter } from "@/components/_icons"
import { ITableParams } from "@/types/interface"

import { Popover, PopoverContent, PopoverTrigger } from "@repo/ui/components/ui/popover"
import { Button } from "@repo/ui/components/ui/button"
import { Input } from "@repo/ui/components/ui/input"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "@repo/ui/components/ui/select"
import { ALL, APPLICATION_STATUS } from "@repo/util/constant"
import { NumericFormat } from "react-number-format"

interface Props {
  tableParams: ITableParams
  setTableParams: Dispatch<SetStateAction<ITableParams>>
}

export default function FilterPopover({ tableParams, setTableParams }: Readonly<Props>) {
  const [open, setOpen] = useState(false)
  const [isArchived, setIsArchived] = useState(tableParams.filter.is_archived)
  const [createdOn, setCreatedOn] = useState(tableParams.filter.created_on)
  const [applicationId, setApplicationId] = useState(tableParams.filter.application_id)
  const [entityName, setEntityName] = useState(tableParams.filter.entity_name)
  const [loanAmount, setLoanAmount] = useState(tableParams.filter.loan_amount)
  const [status, setStatus] = useState(tableParams.filter.status)

  const handleResetClick = () => {
    setIsArchived(false)
    setCreatedOn("")
    setApplicationId("")
    setEntityName("")
    setLoanAmount("")
    setStatus(ALL)

    setTableParams((prev: ITableParams) => ({
      ...prev,
      filter: {
        ...prev.filter,
        is_archived: false,
        created_on: "",
        application_id: "",
        entity_name: "",
        loan_amount: "",
        status: ALL
      },
      pagination: {
        ...prev.pagination,
        page_index: 1
      }
    }))
    setOpen(false)
  }

  const handleApplyClick = () => {
    setTableParams((prev: ITableParams) => ({
      ...prev,
      filter: {
        ...prev.filter,
        is_archived: isArchived,
        created_on: createdOn,
        application_id: applicationId,
        entity_name: entityName,
        loan_amount: loanAmount,
        status
      },
      pagination: {
        ...prev.pagination,
        page_index: 1
      }
    }))
    setOpen(false)
  }

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger
        className={clsx(
          "text-default flex h-auto w-full items-center justify-center gap-1.5 rounded-xl border bg-transparent px-4 py-3 text-sm font-semibold hover:bg-transparent md:w-auto",
          open ? "border-primary" : "border-[#868194]"
        )}
      >
        <IconFilter className="text-xl" />
        Filter
      </PopoverTrigger>

      <PopoverContent
        align="end"
        className="flex w-[calc(100vw-32px)] translate-x-4 flex-col gap-4 rounded-xl p-4 md:w-[400px] md:translate-x-0"
      >
        <p className="text-main text-sm">Filter</p>

        <Select
          value={isArchived.toString()}
          onValueChange={(value) => setIsArchived(value === "true")}
        >
          <SelectTrigger className="px-4 py-1 text-sm capitalize">
            <SelectValue placeholder={isArchived.toString()} />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="false">Active</SelectItem>
            <SelectItem value="true">Archived</SelectItem>
          </SelectContent>
        </Select>
        <Input
          autoFocus
          placeholder="Created on"
          value={createdOn}
          onChange={(e) => setCreatedOn(e.target.value)}
        />
        <Input
          placeholder="Application ID"
          value={applicationId}
          onChange={(e) => setApplicationId(e.target.value)}
        />
        <Input
          placeholder="Entity name"
          value={entityName}
          onChange={(e) => setEntityName(e.target.value)}
        />
        <NumericFormat
          customInput={Input}
          thousandSeparator
          prefix="$"
          decimalScale={2}
          fixedDecimalScale
          placeholder="Minimum loan amount"
          value={loanAmount ?? ""}
          onValueChange={(values, sourceInfo) => {
            setLoanAmount(values.value)
          }}
        />
        <Select
          value={status}
          onValueChange={(value: string) => {
            setStatus(value)
          }}
        >
          <SelectTrigger className="px-4 py-1 text-sm capitalize">
            <SelectValue placeholder={status} />
          </SelectTrigger>

          <SelectContent>
            <SelectItem className="capitalize" value={ALL}>
              {ALL}
            </SelectItem>
            {Object.entries(APPLICATION_STATUS).map(([k, v]) => (
              <SelectItem key={k} className="capitalize" value={v.key}>
                {v.key}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>

        <div className="flex justify-end gap-2.5">
          <Button
            className="text-default h-auto border border-[#868194] bg-transparent px-6 py-4 text-sm font-semibold hover:bg-transparent"
            onClick={handleResetClick}
          >
            Reset
          </Button>
          <Button
            className="border-primary h-auto border px-6 py-4 text-sm font-semibold"
            onClick={handleApplyClick}
          >
            Apply filter
          </Button>
        </div>
      </PopoverContent>
    </Popover>
  )
}
