import React from "react"

interface Props {
  className?: string
}

export default function IconApplicationIncomplete({ className }: Readonly<Props>) {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 22 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.0141 15.8442C20.3925 13.7164 18.9849 12.4114 16.8005 11.9475C16.7322 11.933 16.6634 11.9181 16.5787 11.9002V11.6441C16.5787 8.9009 16.5559 6.15685 16.5873 3.41365C16.6049 1.87092 15.5166 0.824081 14.3634 0.678557C14.3436 0.675996 14.3256 0.660206 14.3067 0.650391H2.42459C1.97714 0.752386 1.54517 0.888521 1.17724 1.17829C0.521325 1.69467 0.155974 2.36638 0.154685 3.19899C0.147808 8.16176 0.147808 13.1245 0.153825 18.0873C0.155544 19.4986 1.31822 20.6278 2.7534 20.6295C5.68309 20.6329 8.6132 20.632 11.5433 20.6265C11.7174 20.6261 11.8292 20.6824 11.9461 20.8066C12.8388 21.7566 13.9362 22.3113 15.2368 22.4633C15.2849 22.4692 15.3318 22.4876 15.3791 22.5004H16.3225C16.6565 22.4308 16.9956 22.3796 17.3232 22.2879C19.4822 21.6849 21.042 19.7499 21.1778 17.5227C21.183 17.4318 21.2006 17.3417 21.2127 17.2513V17.1232C21.149 16.6961 21.134 16.2548 21.0141 15.8442ZM11.2257 14.5294C10.3299 16.0696 10.2891 17.6836 10.957 19.3501H10.7116C8.0957 19.3501 5.47978 19.3356 2.86387 19.3586C2.0184 19.3659 1.42826 18.7368 1.4304 17.9332C1.44545 13.0699 1.43771 8.20614 1.43814 3.34238C1.43814 2.46923 1.97714 1.93109 2.8514 1.93109C6.52512 1.93024 10.1988 1.93024 13.8725 1.93066C14.7494 1.93109 15.291 2.46795 15.2914 3.33811C15.2922 6.11118 15.2918 8.88426 15.2918 11.6573V11.8792C13.5085 12.1263 12.1249 12.9837 11.2257 14.5294ZM15.8343 21.2197C13.6017 21.212 11.7806 19.3919 11.7905 17.1783C11.8008 14.9378 13.6129 13.1429 15.8631 13.1442C18.0956 13.1459 19.912 14.9604 19.9056 17.183C19.8991 19.4179 18.0741 21.2278 15.8343 21.2197Z"
        fill="currentColor"
      />
      <path
        d="M7.92254 10.6089C7.9277 10.9865 7.64831 11.2614 7.23052 11.2631C5.98661 11.2682 4.74313 11.2673 3.49965 11.2635C3.13558 11.2622 2.86866 11.0488 2.81322 10.7386C2.75777 10.4292 2.92626 10.1283 3.22499 10.0263C3.31697 9.99517 3.42056 9.98707 3.51899 9.98664C4.13364 9.98322 4.74829 9.98493 5.36294 9.98493C5.98489 9.98493 6.60685 9.98323 7.22881 9.98579C7.64058 9.98749 7.91695 10.2393 7.92254 10.6089Z"
        fill="currentColor"
      />
      <path
        d="M7.92241 13.1704C7.92757 13.5477 7.64775 13.8221 7.22996 13.8238C5.98648 13.8289 4.74257 13.8281 3.49909 13.8242C3.13546 13.8229 2.86854 13.6096 2.81309 13.2989C2.75807 12.9895 2.92613 12.6882 3.22529 12.5875C3.32372 12.5542 3.43505 12.5482 3.54078 12.5474C4.15543 12.544 4.77008 12.5457 5.38473 12.5457C5.99938 12.5457 6.61402 12.5435 7.2291 12.5465C7.64087 12.5482 7.91725 12.8 7.92241 13.1704Z"
        fill="currentColor"
      />
      <path
        d="M7.92225 15.7762C7.92698 16.1543 7.64673 16.427 7.22894 16.4287C5.98503 16.433 4.74155 16.4326 3.49764 16.4287C3.13487 16.4274 2.86752 16.2136 2.81293 15.903C2.75834 15.5936 2.92683 15.2927 3.22599 15.192C3.32485 15.1587 3.43617 15.1527 3.54148 15.1519C4.14925 15.1484 4.75659 15.1502 5.36437 15.1502C5.98589 15.1502 6.60785 15.148 7.2298 15.151C7.64115 15.1531 7.91752 15.4062 7.92225 15.7762Z"
        fill="currentColor"
      />
      <path
        d="M7.92278 5.48818C7.92794 5.85775 7.65629 6.13813 7.25183 6.14027C5.99373 6.14795 4.73563 6.14795 3.47796 6.14027C3.0735 6.13813 2.80142 5.85818 2.80571 5.48904C2.81044 5.11989 3.08854 4.8664 3.49816 4.86469C4.12743 4.86213 4.75626 4.86384 5.3851 4.86384C5.99975 4.86384 6.61483 4.86213 7.22948 4.86469C7.63996 4.86597 7.91763 5.11861 7.92278 5.48818Z"
        fill="currentColor"
      />
      <path
        d="M7.92276 8.0466C7.92878 8.42428 7.65026 8.70039 7.23246 8.70253C5.98855 8.70808 4.74507 8.70765 3.50115 8.70253C3.08207 8.70082 2.80226 8.42812 2.8057 8.05172C2.80956 7.67447 3.08895 7.42609 3.51749 7.42481C4.13257 7.4231 4.74722 7.42439 5.36187 7.42439C5.98339 7.42439 6.60535 7.42268 7.22731 7.42524C7.63908 7.42652 7.91675 7.67745 7.92276 8.0466Z"
        fill="currentColor"
      />
      <path
        d="M13.9237 5.48562C13.9301 5.85392 13.6572 6.13771 13.2544 6.14027C11.9968 6.14838 10.7387 6.14795 9.48058 6.1407C9.07525 6.13856 8.80275 5.86117 8.80618 5.4916C8.80962 5.12331 9.08858 4.8664 9.49605 4.86469C10.118 4.86213 10.7395 4.86384 11.3615 4.86384C11.9834 4.86384 12.6054 4.86213 13.2269 4.86469C13.6387 4.86597 13.9172 5.11733 13.9237 5.48562Z"
        fill="currentColor"
      />
      <path
        d="M13.9243 8.04819C13.9303 8.43185 13.6445 8.70284 13.2117 8.70412C11.9751 8.70839 10.7385 8.70796 9.50185 8.70412C9.16702 8.70327 8.93147 8.53768 8.83777 8.26243C8.75267 8.01277 8.82015 7.72386 9.04366 7.5762C9.17175 7.49128 9.34368 7.43622 9.49755 7.43238C10.1191 7.41617 10.7415 7.42555 11.363 7.42555C11.9776 7.42555 12.5923 7.42427 13.2074 7.42598C13.6363 7.42726 13.9183 7.6735 13.9243 8.04819Z"
        fill="currentColor"
      />
      <path
        d="M11.9397 10.6057C11.9504 10.939 11.693 11.2437 11.3332 11.2548C10.6919 11.2753 10.0489 11.2757 9.40759 11.2552C9.03493 11.2428 8.79079 10.9403 8.80798 10.5839C8.82475 10.243 9.09381 9.9933 9.46088 9.9869C9.7609 9.98177 10.0605 9.98604 10.3601 9.98604C10.667 9.98604 10.9739 9.98177 11.2808 9.9869C11.6543 9.9933 11.9285 10.2549 11.9397 10.6057Z"
        fill="currentColor"
      />

      <g clipPath="url(#clip0_1301_34624)">
        <path
          d="M16.1207 19.0205C15.8111 19.0205 15.5568 18.8246 15.5431 18.5758L15.2606 15.6064C15.2387 15.2123 15.6306 14.7529 16.1207 14.7529C16.6103 14.7529 17 15.2123 16.9782 15.6064L16.6983 18.5758C16.6847 18.8246 16.4298 19.0205 16.1207 19.0205Z"
          fill="currentColor"
        />
        <path
          d="M16.1193 20.6697C16.4042 20.6697 16.6351 20.4404 16.6351 20.1576C16.6351 19.8748 16.4042 19.6455 16.1193 19.6455C15.8344 19.6455 15.6035 19.8748 15.6035 20.1576C15.6035 20.4404 15.8344 20.6697 16.1193 20.6697Z"
          fill="currentColor"
        />
      </g>

      <defs>
        <clipPath id="clip0_1301_34624">
          <rect
            width="1.7193"
            height="6.82813"
            fill="white"
            transform="translate(15.2598 14.1836)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
