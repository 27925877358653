import React from "react"

import { StyleSheet, Text, View } from "@react-pdf/renderer"

export default function Footer() {
  const styles = StyleSheet.create({
    footer: {
      left: 0,
      right: 0,
      bottom: 0,
      width: "100%",
      height: 64,
      paddingHorizontal: 32,
      paddingTop: 16,
      paddingBottom: 32,
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      alignItems: "center"
    }
  })

  return (
    <View style={styles.footer} fixed>
      <Text
        style={{
          color: "#0E052B",
          fontSize: 10,
          fontWeight: 400,
          textAlign: "right"
        }}
        render={({ pageNumber, totalPages }) => `${pageNumber - 1} / ${totalPages - 1}`}
        fixed
      />
    </View>
  )
}
