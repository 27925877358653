import { toast } from "sonner"
import { create } from "zustand"

import adminUsersApi from "@/api/adminUsersApi"

import { CLIENT_MSG } from "@repo/i18n"

interface IAdminUserState {
  loading: boolean
  reloadRequired: boolean

  getListAction: (params: any, onSuccessCallback?: (data: any, totalCount: number) => void) => void
  getOneByUuidAction: (userUuid: string, onSuccessCallback?: (data: any) => void) => void
  updateAction: (userUuid: string, params: any, onSuccessCallback?: () => void) => void
  archiveAction: (userUuid: string) => void
  approveAction: (userUuid: string) => void
  lockAction: (userUuid: string) => void
}

const useAdminUserStore = create<IAdminUserState>()((set, get) => ({
  loading: false,
  reloadRequired: false,

  getListAction: async (params, onSuccessCallback) => {
    try {
      set({ loading: true })

      const response = await adminUsersApi.getAdminUserList(params)

      if (response?.data) {
        const { data } = response.data

        if (typeof onSuccessCallback === "function") {
          onSuccessCallback(data.data, data.totalCount)
        }
      }
    } catch (err: any) {
      console.error(err)

      if (err?.response?.data?.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error(CLIENT_MSG.SOMETHING_WENT_WRONG)
      }
    } finally {
      set({ loading: false })
    }
  },

  getOneByUuidAction: async (userUuid, onSuccessCallback) => {
    try {
      set({ loading: true })

      const response = await adminUsersApi.getAdminUserByUuid(userUuid)

      if (response?.data) {
        const { data } = response.data

        if (typeof onSuccessCallback === "function") {
          onSuccessCallback(data)
        }
      }
    } catch (err: any) {
      console.error(err)

      if (err?.response?.data?.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error(CLIENT_MSG.SOMETHING_WENT_WRONG)
      }
    } finally {
      set({ loading: false })
    }
  },

  updateAction: async (userUuid, params, onSuccessCallback) => {
    try {
      set({ loading: true })

      const response = await adminUsersApi.updateAdminUser(userUuid, params)

      if (response?.data) {
        const { message } = response.data

        toast.success(message)

        if (typeof onSuccessCallback === "function") {
          onSuccessCallback()
        }
      }
    } catch (err: any) {
      console.error(err)

      if (err?.response?.data?.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error(CLIENT_MSG.SOMETHING_WENT_WRONG)
      }
    } finally {
      set({ loading: false })
    }
  },

  archiveAction: async (userUuid) => {
    try {
      const response = await adminUsersApi.archiveAdminUser(userUuid)

      if (response?.data) {
        const { message } = response.data
        set({ reloadRequired: !get().reloadRequired })
        toast.success(message)
      }
    } catch (err: any) {
      console.error(err)

      if (err?.response?.data?.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error(CLIENT_MSG.SOMETHING_WENT_WRONG)
      }
    }
  },

  approveAction: async (userUuid) => {
    try {
      const response = await adminUsersApi.approveAdminUser(userUuid)

      if (response?.data) {
        const { message } = response.data
        set({ reloadRequired: !get().reloadRequired })
        toast.success(message)
      }
    } catch (err: any) {
      console.error(err)

      if (err?.response?.data?.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error(CLIENT_MSG.SOMETHING_WENT_WRONG)
      }
    }
  },

  lockAction: async (userUuid) => {
    try {
      const response = await adminUsersApi.lockAdminUser(userUuid)

      if (response?.data) {
        const { message } = response.data
        set({ reloadRequired: !get().reloadRequired })
        toast.success(message)
      }
    } catch (err: any) {
      console.error(err)

      if (err?.response?.data?.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error(CLIENT_MSG.SOMETHING_WENT_WRONG)
      }
    }
  }
}))

export default useAdminUserStore
