import { toast } from "sonner"
import { create } from "zustand"

import { apiAssignApplication, apiGetApplicationAssigneesList } from "@/api/applicationAssigneesApi"

import { CLIENT_MSG } from "@repo/i18n"

interface IApplicationAssigneeState {
  loading: boolean

  getListAction: (
    applicationUuid: string,
    params: any,
    onSuccessCallback?: (data: any) => void
  ) => void

  assignAction: (
    applicationUuid: string,
    params: any,
    onSuccessCallback?: (data: any) => void
  ) => void
}

const useApplicationAssigneeStore = create<IApplicationAssigneeState>()((set, get) => ({
  loading: false,

  getListAction: async (applicationUuid, params, onSuccessCallback) => {
    try {
      set({ loading: true })

      const response = await apiGetApplicationAssigneesList(applicationUuid, params)

      if (response?.data) {
        const { data } = response.data

        if (typeof onSuccessCallback === "function") {
          onSuccessCallback(data)
        }
      }
    } catch (err: any) {
      console.error(err)

      if (err?.response?.data?.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error(CLIENT_MSG.SOMETHING_WENT_WRONG)
      }
    } finally {
      set({ loading: false })
    }
  },

  assignAction: async (applicationUuid, params, onSuccessCallback) => {
    try {
      set({ loading: true })

      const response = await apiAssignApplication(applicationUuid, params)

      if (response?.data) {
        const { data } = response.data

        if (typeof onSuccessCallback === "function") {
          onSuccessCallback(data)
        }
      }
    } catch (err: any) {
      console.error(err)

      if (err?.response?.data?.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error(CLIENT_MSG.SOMETHING_WENT_WRONG)
      }
    } finally {
      set({ loading: false })
    }
  }
}))

export default useApplicationAssigneeStore
