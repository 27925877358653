import { toast } from "sonner"
import { create } from "zustand"

import {
  apiArchiveAdminLead,
  apiGetAdminLeadByUuid,
  apiGetAdminLeadsList
} from "@/api/adminLeadsApi"

import { CLIENT_MSG } from "@repo/i18n"

interface IAdminLeadState {
  loading: boolean
  reloadRequired: boolean

  getListAction: (params: any, onSuccessCallback?: (data: any) => void) => void
  getOneByUuidAction: (uuid: string, onSuccessCallback?: (data: any) => void) => void
  archiveAction: (uuid: string) => void
}

const useAdminLeadStore = create<IAdminLeadState>()((set, get) => ({
  loading: false,
  reloadRequired: false,

  getListAction: async (params, onSuccessCallback) => {
    try {
      set({ loading: true })

      const response = await apiGetAdminLeadsList(params)

      if (response?.data) {
        const { data } = response.data

        if (typeof onSuccessCallback === "function") {
          onSuccessCallback(data)
        }
      }
    } catch (err: any) {
      console.error(err)

      if (err?.response?.data?.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error(CLIENT_MSG.SOMETHING_WENT_WRONG)
      }
    } finally {
      set({ loading: false })
    }
  },

  getOneByUuidAction: async (uuid, onSuccessCallback) => {
    try {
      set({ loading: true })

      const response = await apiGetAdminLeadByUuid(uuid)

      if (response?.data) {
        const { data } = response.data

        if (typeof onSuccessCallback === "function") {
          onSuccessCallback(data)
        }
      }
    } catch (err: any) {
      console.error(err)

      if (err?.response?.data?.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error(CLIENT_MSG.SOMETHING_WENT_WRONG)
      }
    } finally {
      set({ loading: false })
    }
  },

  archiveAction: async (uuid: string) => {
    try {
      const response = await apiArchiveAdminLead(uuid)

      if (response?.data) {
        const { message } = response.data
        set({ reloadRequired: !get().reloadRequired })
        toast.success(message)
      }
    } catch (err: any) {
      console.error(err)

      if (err?.response?.data?.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error(CLIENT_MSG.SOMETHING_WENT_WRONG)
      }
    }
  }
}))

export default useAdminLeadStore
