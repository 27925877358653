import React from "react"

import { motion } from "framer-motion"
import { Link } from "react-router-dom"

import Logo from "@/components/_layout/components/Logo"
import { LoadingButton } from "@/components/_uiext"
import useSignupStore from "@/stores/useSignupStore"
import { ANIMATION_DURATION } from "@/utils/constants"

import StageIndicator from "./components/StageIndicator"

export default function Step03() {
  const store = useSignupStore()

  const variants = {
    initial: {
      opacity: 0,
      y: 20
    },
    animate: (i: number) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: i * ANIMATION_DURATION,
        duration: ANIMATION_DURATION
      }
    })
  }

  const handleResendEmail = () => {
    store.resendEmailAction()
  }

  return (
    <div className="flex h-full w-full flex-col gap-8">
      <motion.div
        custom={0}
        initial="initial"
        animate="animate"
        variants={variants}
        className="flex flex-wrap items-center justify-center gap-4 md:justify-between"
      >
        <Logo />
      </motion.div>

      <div className="flex flex-1 flex-col justify-center">
        <motion.h3
          custom={1}
          initial="initial"
          animate="animate"
          variants={variants}
          className="text-main mb-2.5 text-lg font-semibold"
        >
          Verify your email address
        </motion.h3>

        <motion.p
          custom={2}
          initial="initial"
          animate="animate"
          variants={variants}
          className="mb-2.5 text-sm font-normal"
        >
          We sent an email to{" "}
          <span className="text-main text-sm font-semibold">{store.data?.email ?? ""}</span>
        </motion.p>

        <motion.p
          custom={3}
          initial="initial"
          animate="animate"
          variants={variants}
          className="mb-8 text-sm font-normal"
        >
          Just click on the link in that email to complete your sign up. If you don’t see it, you
          may need to check your spam folder.
        </motion.p>

        <motion.p
          custom={4}
          initial="initial"
          animate="animate"
          variants={variants}
          className="mb-2.5 text-sm font-normal"
        >
          Still can’t find an email?
        </motion.p>

        <motion.div custom={5} initial="initial" animate="animate" variants={variants}>
          <LoadingButton className="mb-8" loading={store.loading} onClick={handleResendEmail}>
            Resend email
          </LoadingButton>
        </motion.div>

        <motion.p
          custom={6}
          initial="initial"
          animate="animate"
          variants={variants}
          className="text-center text-sm font-normal"
        >
          Need help?{" "}
          <Link className="text-main font-medium" to="/contact">
            Contact us
          </Link>
        </motion.p>
      </div>

      <motion.div custom={7} initial="initial" animate="animate" variants={variants}>
        <StageIndicator />
      </motion.div>
    </div>
  )
}
