import React from "react"

interface Props {
  className?: string
}

export default function IconPicture({ className }: Readonly<Props>) {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <clipPath id="clip0_1301_23827">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>

      <g clipPath="url(#clip0_1301_23827)">
        <path
          d="M19.9979 20.9995H4.00391C2.89891 20.9995 2.00391 20.1045 2.00391 18.9995V4.99951C2.00391 3.89451 2.89891 2.99951 4.00391 2.99951H19.9979C21.1029 2.99951 21.9979 3.89451 21.9979 4.99951V18.9995C21.9979 20.1045 21.1019 20.9995 19.9979 20.9995Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.41593 7.58481C10.197 8.36586 10.197 9.63219 9.41593 10.4132C8.63488 11.1943 7.36855 11.1943 6.5875 10.4132C5.80645 9.63219 5.80645 8.36586 6.5875 7.58481C7.36855 6.80376 8.63488 6.80376 9.41593 7.58481"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22.0012 15.9999L17.7822 12.6249C17.3512 12.2799 16.7212 12.3499 16.3762 12.7809L13.0402 16.9509C12.6952 17.3819 12.0662 17.4519 11.6342 17.1069L9.76622 15.6119C9.34122 15.2719 8.72222 15.3339 8.37322 15.7529L4.00122 20.9999"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  )
}
